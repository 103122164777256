import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { GdList, ItemMode } from '../../../components/GdList';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { useRootStore } from '../../../context/storeContext';
import { ListContainer } from '../../Common/styles';
import { useOrganizationQuery } from '../hooks/useOrganizationQuery';
import { useOrganizationsMeta } from '../hooks/useOrganizationsMeta';

export const OrganizationsList = observer(() => {
  const history = useHistory();

  const { orgListStore } = useRootStore();
  const { loading, loadMoreOrgs } = useOrganizationQuery();

  const onSelectOrg = useCallback(
    (org) => {
      history.push(`/admin/organizations/${org.id}`);
    },
    [history]
  );

  const listMeta = useOrganizationsMeta({
    onOrgSelected: onSelectOrg,
  });

  const { orgs, amountInfo } = orgListStore;

  return (
    <ListContainer>
      <GdList
        split={false}
        heightDelta={415}
        itemMode={ItemMode.Card}
        data={orgs}
        columnMeta={listMeta}
        loadMore={loadMoreOrgs}
        totalCount={amountInfo.amountLeft}
        onItemClick={onSelectOrg}
        loading={{
          spinning: loading,
          size: 'large',
          indicator: <Spin indicator={<GdLoadingIndicator />} />,
        }}
      />
    </ListContainer>
  );
});
