import { DefaultTheme } from 'styled-components/macro';

export const defaultTheme: DefaultTheme = {
  colors: {
    mainBackground: '',
    transparent: 'transparent',
    white: '#FFFFFF',
    whiteF9: '#fff9f9',
    lightWhite: '#F0F0F0',
    strongWhite: '#c4c4c4',
    ultraGrey: '#ADADAD',
    lightGrey: '#F9876E28',
    mediumGrey: '#CCCCCC',
    black012: '#0000001E',
    black025: '#0000003F',
    black032: '#00000051',
    black048: '#0000007A',
    black06: '#00000099',
    black085: '#000000D8',
    black: '#000000',
    lightGreen: '#5AAA86',
    lightRose: '#fdcfce',
    thinOrange: '#F9876E',
    lightOrange: '#F56A00',
    strongOrange: '#ed593b',
    mediumRed: '#F93B10',
    strongBlue: '#1501FD',
    strongBlueHighlighted: '#1501FDD1',
    strongBlue016: '#1501fd29',
    blueBackground: '#1102F1',
  },
  fonts: {
    bold: 'montserrat-bold',
    semiBold: 'montserrat-semi-bold',
    medium: 'montserrat-medium',
    regular: 'montserrat-regular',
    black: 'montserrat-black',
  },
  defaultFontSize: 14,
};
