import { notification } from 'antd';
import { useCallback, useEffect } from 'react';

import { Result } from '../services/rootStore';
import { BaseQuery } from '../types/commonTypes';
import { useLoading } from './useLoading';

type DataQuery = {
  loading: boolean;
  loadMore: () => Promise<any>;
};

type DataQueryObject = {
  dataFunc: (loadMore: boolean) => Promise<Result<any>>;
  clearFunc?: () => void;
  showMsgIfFailedFunc?: (res: Result<any>) => string;
  showDescIfFailedFunc?: (res: Result<any>) => string;
};

export const useDataQuery = ({
  dataFunc,
  clearFunc,
  showMsgIfFailedFunc,
  showDescIfFailedFunc,
}: DataQueryObject): DataQuery => {
  const { loading, putInProgress } = useLoading();

  const showResult = useCallback(
    (res: Result<any>) => {
      notification.error({
        message: showMsgIfFailedFunc ? showMsgIfFailedFunc(res) : '',
        description: showDescIfFailedFunc ? showDescIfFailedFunc(res) : '',
        placement: 'bottomRight',
        duration: 2,
      });
    },
    [showDescIfFailedFunc, showMsgIfFailedFunc]
  );

  const queryData = useCallback(
    async (loadMore: boolean = false) => {
      const res = await putInProgress(async () => await dataFunc(loadMore));
      if (res.failed && showMsgIfFailedFunc && showDescIfFailedFunc) {
        showResult(res);
      }
    },
    [
      putInProgress,
      showMsgIfFailedFunc,
      showDescIfFailedFunc,
      dataFunc,
      showResult,
    ]
  );

  useEffect(() => {
    queryData().then(() => {});

    return () => {
      clearFunc && clearFunc();
    };
  }, [clearFunc, queryData]);

  const loadMore = useCallback(async () => {
    await queryData(true);
  }, [queryData]);

  return { loading, loadMore };
};

type DataQueryByIdObject = {
  id: string;
  loadAvailable?: (id: string, body: BaseQuery) => Promise<any>;
  loadOwned: (id: string, body: BaseQuery, loadMore: boolean) => Promise<any>;
};

export const useDataQueryById = ({
  id,
  loadAvailable = () => Promise.resolve(),
  loadOwned,
}: DataQueryByIdObject) => {
  const { loading, putInProgress } = useLoading();

  const getBody = useCallback((searchText: string = '', offset: number = 0) => {
    return {
      offset,
      count: 10,
      textToSearch: searchText,
      orderingColumns: {},
    };
  }, []);

  const loadData = useCallback(
    async (
      isAll: boolean = false,
      searchText: string,
      loadMore: boolean = false,
      offset: number = 0
    ) => {
      const body = getBody(searchText, offset);
      await putInProgress(async () =>
        isAll
          ? await loadAvailable(id, body)
          : await loadOwned(id, body, loadMore)
      );
    },
    [getBody, id, loadAvailable, loadOwned, putInProgress]
  );

  const loadAll = useCallback(
    async (searchText: string) => {
      await loadData(true, searchText);
    },
    [loadData]
  );

  const loadAssigned = useCallback(
    async (searchText: string = '') => {
      await loadData(false, searchText);
    },
    [loadData]
  );

  const loadMore = useCallback(
    async (searchText: string, isInEdit: boolean, offset: number) => {
      await loadData(isInEdit, searchText, true, offset);
    },
    [loadData]
  );

  return { loading, loadAll, loadAssigned, loadMore };
};
