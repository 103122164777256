import { Badge } from 'antd';
import styled from 'styled-components/macro';

import {
  Activity,
  LoginStateStatuses,
} from '../domain/AdminOrganizations/stores/objects/Organization';

const StatusContainer = styled.div`
  & .ant-badge-status-dot {
    height: 8px;
    width: 8px;
  }
`;
type Props = {
  state: LoginStateStatuses | undefined;
};

export const GdStateBadge = ({ state }: Props) => {
  if (!state) return <Badge status={'default'} />;
  const stateObj = {
    [state as LoginStateStatuses]: Activity[state],
  };
  return (
    <StatusContainer>
      <Badge status={stateObj['Green'] || 'default'} />
      <Badge status={stateObj['Yellow'] || 'default'} />
      <Badge status={stateObj['Red'] || 'default'} />
    </StatusContainer>
  );
};
