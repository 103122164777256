import { makeAutoObservable, runInAction, toJS } from 'mobx';

import { TransportLayer } from '../../../api/transportLayer';
import { Result, RootStore } from '../../../services/rootStore';
import {
  BaseQuery,
  ListResult,
  SortDirection,
} from '../../../types/commonTypes';
import { getSortFunction, sortBy } from '../../../utils';

export class AcademyStore {
  rootStore: RootStore;
  transportLayer: TransportLayer;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.transportLayer = rootStore.transportLayer;
    makeAutoObservable(this);
  }

  public async prepareAcademy() {
    return await this.rootStore.transportLayer.prepareAcademy();
  }
}
