import { Card } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useRootStore } from '../../../context/storeContext';
import { DetailsListsContainer } from '../../Common/styles';
import { GroupGuidosList } from './GroupGuidosList';
import { GroupUserList } from './GroupUserList';

const GroupDetailsListsContainer = styled(DetailsListsContainer)``;

export const GroupDetailsLists = () => {
  const { teamListsStore } = useRootStore();
  useEffect(() => {
    return () => {
      teamListsStore.clear();
    };
  }, [teamListsStore]);
  return (
    <GroupDetailsListsContainer>
      <Card className="gd-card">
        <GroupUserList />
      </Card>
      <Card className="gd-card">
        <GroupGuidosList />
      </Card>
    </GroupDetailsListsContainer>
  );
};
