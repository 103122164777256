import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import academyPageTranslations from '../domain/Academy/translations/en.json';
import adminDashboardTranslations from '../domain/AdminDashboard/translations/en.json';
import adminGuidosTranslations from '../domain/AdminGuidos/translations/en.json';
import adminOrganizationsTranslations from '../domain/AdminOrganizations/translations/en.json';
import landingTranslations from '../domain/Common/translations/en.json';
import companyPageTranslations from '../domain/Company/translations/en.json';
import dashboardPageTranslations from '../domain/Dashboard/translations/en.json';
import groupsPageTranslations from '../domain/Groups/translations/en.json';
import guidosPageTranslations from '../domain/Guidos/translations/en.json';
import identityTranslations from '../domain/Identity/translations/en.json';
import usersPageTranslations from '../domain/Users/translations/en.json';
import enTranslations from './locales/en/translations.json';

const instance = i18n.createInstance();

instance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        landingPage: landingTranslations,
        SignInUpPage: enTranslations,
        identity: identityTranslations,
        users: usersPageTranslations,
        guidos: guidosPageTranslations,
        groups: groupsPageTranslations,
        dashboard: dashboardPageTranslations,
        academy: academyPageTranslations,
        admDashboard: adminDashboardTranslations,
        admOrgs: adminOrganizationsTranslations,
        admGuidos: adminGuidosTranslations,
        company: companyPageTranslations,
      },
    },
  });

instance.languages = ['en'];

export default instance;
