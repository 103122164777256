import { useCallback, useState } from 'react';

export const useToggle = (
  initialState: boolean = false
): [boolean, () => void] => {
  const [isModalShown, setIsModalShown] = useState<boolean>(initialState);

  const showModal = useCallback(() => {
    setIsModalShown((isModalShown) => !isModalShown);
  }, []);

  return [isModalShown, showModal];
};
