import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsMeta, GdListHeader } from '../../../components/GdListHeader';
import { useRootStore } from '../../../context/storeContext';
import { SortDirection } from '../../../types/commonTypes';

export const GroupsListHeader = () => {
  const { t } = useTranslation();

  const { teamsStore } = useRootStore();

  const sortingHandler = useCallback(
    (order, field) => {
      teamsStore.sortTeams(order, field);
    },
    [teamsStore]
  );

  const columns: ColumnsMeta = useMemo(() => {
    return {
      name: {
        title: t('groups:Labels:groupNameListLbl'),
        colspan: 5,
        leftPadding: 24,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      usersAmount: {
        title: t('groups:Labels:usersOfGroupNumberLbl'),
        colspan: 5,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      guidos: {
        title: t('groups:Labels:assignedGuidosSize'),
        colspan: 5,
      },
      size: {
        title: 'Total Guidos Size',
        colspan: 8,
      },
    };
  }, [sortingHandler, t]);
  return <GdListHeader columns={columns} />;
};
