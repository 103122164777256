import { DefaultTheme, createGlobalStyle, css } from 'styled-components/macro';

export const gdInputGeneral = () => css`
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const gdFlexGeneral = () => css`
  display: flex;
  align-items: start;
  justify-content: center;
`;

export const clearDivider = (defaultMargin: string = '0 0 16px 0') => css`
  border-top: 2px solid rgba(0, 0, 0, 0.06);
  margin: ${defaultMargin};
`;

export const singleSearchItemControlStyle = () => css`
  & .gd-search-form {
    padding-top: unset;

    & .gd-filter-form-item.gd-form-item {
      margin-right: unset;

      & .gd-input-item {
        height: 50px;
      }
    }

    & .gd-filters-bar {
      & > div {
        padding: unset !important;
      }
    }
  }
`;

export default createGlobalStyle<{ theme: DefaultTheme }>`
  html body {
    margin: 0;
    font-size: 0;
    font-family: -apple-system, 'montserrat-medium', 'montserrat-bold', 'montserrat-regular', 'montserrat-semi-bold', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${({ theme: { colors } }) => colors.whiteF9};
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  body .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme: { colors } }) => colors.strongBlue};
    border-color: ${({ theme: { colors } }) => colors.strongBlue};
  }

  .gd-form-item-icon {
    color: ${({ theme: { colors } }) => colors.black025};
  }

  .gd-form-item {
    margin-bottom: 16px;

    & .gd-form-item {
      margin-bottom: 0;
    }

    & .ant-form-item-label {
      padding: 0;
    }

    & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }

    & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-left: 4px;
      color: ${({ theme: { colors } }) => colors.strongBlue};
      font-size: 14px;
      font-family: ${({ theme: { fonts } }) => fonts.medium};
      line-height: 1;
      content: '*';
    }

    & .ant-select-arrow {

    }

    & .ant-form-item-control {
      //& .ant-select-selector,
      & .gd-input-item {
        ${gdInputGeneral()};

        & .ant-select-selector {
          ${gdInputGeneral()};
        }
      }
    }
  }

  .gd-form-forgot {
    margin-bottom: 40px;
    font-size: 11px;
    color: black;
    text-decoration: underline;
  }

  .gd-form-button {
    border-radius: 8px;
    height: 40px;
    font-family: ${({ theme: { fonts } }) => fonts.bold};
    font-size: 16px;

    &-primary {
      border-radius: 8px;
      background: ${({ theme: { colors } }) => colors.strongBlue};
    }

    &-full-width {
      width: 100%;
      border-radius: 4px;
    }

    &-link-primary:hover,
    &-link-primary:focus,
    &-link-primary {
      border-radius: 4px;
      color: ${({ theme: { colors } }) => colors.strongBlue};
    }

    &-text {
      color: ${({ theme: { colors } }) => colors.black048};
    }

    &.ant-btn-text:focus,
    &.ant-btn-text:hover {
      color: ${({ theme: { colors } }) => colors.black085};
      background: ${({ theme: { colors } }) => colors.transparent};
      border-color: ${({ theme: { colors } }) => colors.transparent};
    }

    &.ant-btn-primary:not(.ant-btn-primary[disabled]):focus,
    &.ant-btn-primary:not(.ant-btn-primary[disabled]):hover {
      color: ${({ theme: { colors } }) => colors.white};
      border-color: ${({ theme: { colors } }) => colors.strongBlueHighlighted};
      background: ${({ theme: { colors } }) => colors.strongBlueHighlighted};
    }
  }

  .gd-form-rememberme {
    & > span:nth-child(2) {
      font-size: 11px;
    }
  }

  .ant-form-item-label > label::after {
    content: '';
  }

  .gd-search-form {
    padding-top: 34px;

    & .gd-filters-bar {
      width: 100%;
    }

    & .gd-filter-count-input {
      & .ant-form-item {
        margin-right: 0;
      }

      & .ant-input {
        text-align: right;
        color: ${({ theme: { colors } }) => colors.black};
        font-weight: bold;
        cursor: pointer;
      }

      & .gd-form-item.gd-filter-form-item {
        & .ant-col.ant-form-item-label {
          display: inline-block;
        }
      }
    }
  }

  .gd-modal {
    & .ant-modal-content {
      border-radius: 12px;
    }
  }

  .ant-modal-mask {
    background-color: ${({ theme: { colors } }) => colors.black06};
  }

  .gd-card {
    background: ${({ theme: { colors } }) => colors.white};
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px
  }

  .gd-dropdown-menu {
    & .ant-dropdown-menu {
      border-radius: 4px;
      box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08), inset 0 0 1px rgba(0, 0, 0, 0.12);
    }

    & .ant-dropdown-menu-item-active {
      background: ${({ theme: { colors } }) => colors.lightGrey};
    }

    background: ${({ theme: { colors } }) => colors.white};
  }

  .ant-checkbox-wrapper {
    padding-right: 5px;
  }

  .ant-switch {
    &.ant-switch-checked {
      background-color: ${({ theme: { colors } }) => colors.strongBlue};
    }
  }
`;
