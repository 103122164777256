export const FinalLogo = () => {
  return (
    <svg
      width="30%"
      height="100%"
      viewBox="0 0 300 250"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.5586 234.399L85.6162 236.162L94.8254 217.494L87.361 214.891L80.5586 234.399Z"
        fill="#A0616A"
      />
      <path
        d="M87.1404 237.203L88.3361 233.774L86.9782 233.392L81.4541 231.825L80.1747 231.465L75.2123 242.614L74.9463 243.212L78.5374 244.464L78.866 243.893L80.3567 241.296L80.7342 244.544L80.816 245.259L90.3367 248.579C90.9045 248.773 91.5259 248.735 92.066 248.474C92.6061 248.212 93.0212 247.748 93.2212 247.183C94.2776 245.446 87.775 238.628 87.1404 237.203Z"
        fill="#2F2E41"
      />
      <path
        d="M44.7894 238.356H50.1454L52.6936 217.695L44.7881 217.696L44.7894 238.356Z"
        fill="#A0616A"
      />
      <path
        d="M51.9278 238.83V235.199L50.5197 235.285L44.7877 235.625L43.4611 235.706L42.4469 247.868L42.3926 248.52H46.1958L46.318 247.872L46.8704 244.929L48.2966 247.872L48.609 248.52H58.6921C59.2921 248.516 59.8664 248.275 60.2903 247.851C60.7141 247.426 60.9533 246.851 60.9559 246.251C61.3815 244.264 52.9963 239.967 51.9278 238.83Z"
        fill="#2F2E41"
      />
      <path
        d="M83.0146 108.707L91.4268 119.857C91.4268 119.857 86.7811 119.534 91.4268 121.554C96.0725 123.573 96.2041 122.859 96.1383 125.307C96.0724 127.754 91.3367 127.388 93.7045 129.894C96.0724 132.4 111.934 165.713 111.934 165.713C111.934 165.713 98.9367 213.945 97.2723 215.913C95.6079 217.881 93.7496 216.022 95.6079 217.881C97.4662 219.739 99.789 217.416 97.4662 219.739C95.1433 222.062 91.9572 226.161 91.9572 226.161L82.6 224.849C82.6 224.849 72.3795 215.093 88.6394 198.833C88.6394 198.833 92.7327 182.566 90.9183 182.57C89.104 182.573 89.1039 185.196 89.1039 179.936V174.676L67.5712 134.337C67.5712 134.337 53.8297 123.617 54.0455 118.717C54.2613 113.817 83.0146 108.707 83.0146 108.707Z"
        fill="#2F2E41"
      />
      <path
        d="M67.7341 119.858C67.7341 119.858 77.49 169.102 76.0963 170.496C74.7026 171.89 74.7026 175.387 74.7026 175.387L52.4033 224.85L43.5765 225.315C43.3681 211.163 43.3107 200.429 51.0096 190.472L54.7262 117.535L67.7341 119.858Z"
        fill="#2F2E41"
      />
      <path
        d="M80.3994 61.4022L69.7669 53.0081L64.1856 52.6836L55.9038 57.9891L53.8759 68.3347L49.0799 79.9847L56.1144 111.424C56.1144 111.424 56.7741 113.68 55.0452 113.951C53.3163 114.222 53.5236 117.548 53.5236 117.548C53.5236 117.548 53.8757 120.43 52.1969 120.684C50.5181 120.938 46.0415 120.938 49.9587 125.974C53.8759 131.01 84.8588 111.962 84.8588 111.962L89.3589 88.3497C89.3589 88.3497 98.086 76.6281 88.6876 69.9149L80.3994 61.4022Z"
        fill="#E6E6E6"
      />
      <path
        d="M33.984 132.782C33.5155 132.198 33.1809 131.518 33.0038 130.79C32.8266 130.062 32.8112 129.304 32.9586 128.569C33.1061 127.835 33.4127 127.142 33.8571 126.538C34.3014 125.935 34.8726 125.437 35.5305 125.078L48.9045 80.1953L57.2671 84.4056L42.7026 127.425C43.2316 128.57 43.3223 129.87 42.9574 131.077C42.5925 132.284 41.7974 133.316 40.7227 133.976C39.6481 134.637 38.3685 134.88 37.1265 134.66C35.8846 134.44 34.7664 133.772 33.9841 132.782L33.984 132.782Z"
        fill="#A0616A"
      />
      <path
        d="M57.8248 57.5722C57.8248 57.5722 55.4343 56.3018 53.7992 61.7995C52.4901 66.2008 41.868 92.4171 41.7325 98.791C40.8584 98.9762 40.0707 99.447 39.4936 100.129C38.9165 100.811 38.5827 101.666 38.5449 102.559L51.6782 104.319C53.9731 102.383 54.5752 99.7691 53.6873 98.9932L56.5388 91.2051L68.4351 66.4262L59.5692 58.4599L57.8248 57.5722Z"
        fill="#E6E6E6"
      />
      <path
        d="M128.001 118.031C128.17 117.302 128.178 116.544 128.023 115.811C127.868 115.078 127.554 114.388 127.103 113.789C126.653 113.191 126.076 112.698 125.415 112.346C124.753 111.995 124.023 111.792 123.275 111.754L92.5949 76.7148L86.0869 84.4804L117.823 116.971C117.841 118.233 118.321 119.443 119.172 120.374C120.023 121.305 121.186 121.891 122.441 122.022C123.695 122.153 124.954 121.819 125.979 121.083C127.004 120.348 127.723 119.262 128.001 118.031Z"
        fill="#A0616A"
      />
      <path
        d="M73.9802 60.5274C73.9802 60.5274 75.5863 58.3481 79.4381 62.5981C82.5217 66.0005 103.436 85.0453 106.315 90.7338C107.183 90.5228 108.097 90.6067 108.912 90.9722C109.728 91.3378 110.398 91.9642 110.818 92.7528L99.7377 100.019C96.8314 99.2663 95.158 97.1694 95.6231 96.0858L89.6841 90.2967L68.2422 73.099L72.7913 62.0821L73.9802 60.5274Z"
        fill="#E6E6E6"
      />
      <path
        d="M78.2745 40.8533C80.6891 34.6443 77.6132 27.6536 71.4042 25.239C65.1953 22.8244 58.2045 25.9003 55.79 32.1093C53.3754 38.3182 56.4513 45.309 62.6603 47.7236C68.8692 50.1381 75.86 47.0622 78.2745 40.8533Z"
        fill="#A0616A"
      />
      <path
        d="M81.4439 25.4234C80.7179 24.6406 79.7563 24.1162 78.705 23.9298C77.6538 23.7434 76.5704 23.9053 75.6196 24.3909C75.8624 23.0353 76.1051 21.6796 76.3478 20.324C74.3307 20.0152 72.3135 19.7065 70.2964 19.3977C67.2533 18.9319 64.1175 18.4691 61.1248 19.1913C58.9823 19.7395 57.0301 20.862 55.4786 22.4379C53.9271 24.0138 52.835 25.9833 52.3203 28.134C51.0169 27.2503 49.4919 26.7498 47.9184 26.6891C46.3448 26.6285 44.7858 27.0103 43.4183 27.7911C42.0508 28.5718 40.9296 29.7204 40.182 31.1063C39.4344 32.4922 39.0903 34.0599 39.1888 35.6315C39.3338 37.9448 40.4155 40.0792 41.0849 42.2982C43.6284 50.7306 39.4159 60.6838 31.5918 64.7283C34.1117 66.6399 36.7141 68.593 39.7657 69.4249C42.8173 70.2568 46.4652 69.71 48.4749 67.2675C49.503 66.018 49.9992 64.4268 50.4725 62.8794C52.3859 56.6228 54.2993 50.3663 56.2127 44.1097L59.3061 49.6948C60.294 51.076 59.9404 53.0089 59.2288 54.5507C58.5171 56.0926 57.4833 57.52 57.1647 59.188C56.8462 60.856 57.5865 62.9229 59.2441 63.2914C61.4837 58.0229 63.7713 52.4086 62.9025 46.7501C62.5644 44.5479 61.7544 42.2893 62.3907 40.154C62.8824 38.504 64.1841 37.2056 65.6303 36.2714C67.1443 35.2949 68.8448 34.6439 70.6238 34.3596C74.5553 33.7343 79.4488 34.574 81.7565 31.3303C82.3547 30.4459 82.6479 29.3905 82.5914 28.3244C82.535 27.2582 82.1321 26.2397 81.4439 25.4234Z"
        fill="#2F2E41"
      />
      <path
        d="M73.775 100.134C79.4168 100.134 83.9904 95.5604 83.9904 89.9185C83.9904 84.2767 79.4168 79.7031 73.775 79.7031C68.1332 79.7031 63.5596 84.2767 63.5596 89.9185C63.5596 95.5604 68.1332 100.134 73.775 100.134Z"
        fill="#F9876E"
      />
      <path
        d="M76.1597 84.7804C76.214 85.0024 76.2953 85.2169 76.4016 85.4191C76.4913 85.6474 76.6424 85.8464 76.8382 85.9941C77.034 86.1418 77.2669 86.2324 77.511 86.256C77.552 86.2542 77.5927 86.2484 77.6325 86.2385C78.0693 86.1308 78.3357 85.6018 78.3401 84.9411C78.3401 84.7125 78.3123 84.4849 78.2572 84.2631C78.2039 84.0432 78.1237 83.8308 78.0183 83.6306C77.7078 83.0447 77.2237 82.6967 76.7857 82.8047C76.2055 82.9478 75.9259 83.8327 76.1597 84.7804Z"
        fill="white"
      />
      <path
        d="M76.9475 85.4143C77.0301 85.7523 77.23 86.0499 77.5115 86.2543C77.5525 86.2525 77.5932 86.2467 77.633 86.2368C78.0697 86.1291 78.3362 85.6001 78.3405 84.9394C78.3406 84.7109 78.3128 84.4832 78.2577 84.2614C78.2044 84.0415 78.1241 83.8291 78.0187 83.6289C77.6404 83.7245 77.3153 83.9659 77.1146 84.3005C76.9139 84.6351 76.8538 85.0356 76.9475 85.4143Z"
        fill="#3F3D56"
      />
      <path
        d="M71.814 85.8468C72.0384 86.7568 72.6548 87.3944 73.2168 87.318C73.2404 87.315 73.2637 87.3106 73.2868 87.3049C73.8656 87.1621 74.1452 86.2773 73.9115 85.3295C73.8583 85.1101 73.7781 84.898 73.6728 84.6983C73.3621 84.1111 72.878 83.7631 72.4399 83.8711C71.8599 84.0142 71.5802 84.8991 71.814 85.8468Z"
        fill="white"
      />
      <path
        d="M72.6447 86.4766C72.7282 86.8181 72.9313 87.1183 73.2171 87.3229C73.2407 87.3198 73.2641 87.3154 73.2871 87.3097C73.866 87.167 74.1456 86.2821 73.9118 85.3343C73.8586 85.1149 73.7785 84.9029 73.6732 84.7031C73.3054 84.8079 72.9926 85.0513 72.8007 85.3821C72.6089 85.713 72.5529 86.1054 72.6447 86.4766Z"
        fill="#3F3D56"
      />
      <path
        d="M80.42 88.8477C81.0415 91.3676 80.057 93.7774 78.2211 94.2302C76.3851 94.683 74.393 93.0073 73.7715 90.4874C76.0372 91.0562 78.2549 90.5441 80.42 88.8477Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M63.8568 92.3839C63.3546 90.3474 63.4912 88.2056 64.2481 86.2494C65.005 84.2932 66.3456 82.6173 68.0878 81.4492C66.4621 83.3167 65.5699 85.7109 65.577 88.1869C65.5842 90.6628 66.4903 93.0519 68.1268 94.9098C69.7633 96.7678 72.0188 97.9683 74.474 98.2881C76.9292 98.6078 79.417 98.0251 81.4747 96.6482C80.2466 98.0583 78.6549 99.1042 76.8732 99.6718C75.0915 100.239 73.1881 100.307 71.3706 99.8671C69.5531 99.4272 67.8913 98.4967 66.5664 97.1771C65.2414 95.8576 64.3042 94.1996 63.8568 92.3839Z"
        fill="black"
      />
      <path
        d="M2.2911e-06 248.749C-0.000200445 248.803 0.0130536 248.858 0.0389972 248.908C0.0649408 248.959 0.103066 249.005 0.151176 249.044C0.199287 249.082 0.256432 249.113 0.319329 249.134C0.382226 249.155 0.449639 249.166 0.517675 249.165H299.482C299.62 249.165 299.751 249.121 299.848 249.043C299.945 248.965 300 248.859 300 248.749C300 248.638 299.945 248.532 299.848 248.454C299.751 248.376 299.62 248.332 299.482 248.332H0.517649C0.449615 248.332 0.382209 248.343 0.319316 248.363C0.256422 248.384 0.199277 248.415 0.15117 248.454C0.103063 248.492 0.06494 248.538 0.0389972 248.589C0.0130544 248.64 -0.000198119 248.694 2.2911e-06 248.749Z"
        fill="#CCCCCC"
      />
      <path
        d="M211.824 241.802L205.514 241.801L202.512 217.461L211.825 217.462L211.824 241.802Z"
        fill="#FFB6B6"
      />
      <path
        d="M212.046 248.383L192.641 248.382V248.137C192.641 246.133 193.437 244.212 194.853 242.796C196.269 241.38 198.191 240.584 200.194 240.584H200.194L203.739 237.895L210.352 240.584L212.046 240.584L212.046 248.383Z"
        fill="#2F2E41"
      />
      <path
        d="M239.351 241.802L233.041 241.801L230.039 217.461L239.352 217.462L239.351 241.802Z"
        fill="#FFB6B6"
      />
      <path
        d="M239.573 248.383L220.168 248.382V248.137C220.168 246.133 220.964 244.212 222.38 242.796C223.797 241.38 225.718 240.584 227.721 240.584H227.721L231.266 237.895L237.88 240.584L239.573 240.584L239.573 248.383Z"
        fill="#2F2E41"
      />
      <path
        d="M196.111 109.18C196.111 109.18 190.04 127.827 191.161 130.281C192.281 132.736 194.193 130.824 192.281 132.736C190.369 134.648 188.7 132.615 190.491 135.862C192.281 139.109 194.994 165.839 196.187 167.133C197.38 168.427 199.02 168.462 197.563 169.719C196.105 170.976 194.193 168.427 196.105 170.976C198.017 173.526 203.091 232.337 203.091 232.337H213.002C213.002 232.337 215.471 229.491 214.074 227.64C212.676 225.788 214.196 225.074 214.074 223.837C213.951 222.601 214.416 219.935 214.502 218.719C214.588 217.502 216.263 188.523 212.239 171.783L219.577 139.908L228.466 178.433C228.466 178.433 224.377 210.747 228.466 221.136C228.466 221.136 225.17 223.881 226.632 225.472C228.094 227.062 229.368 225.788 228.094 227.062C226.819 228.337 230.006 232.798 230.006 232.798L240.841 232.161L246.973 171.783C246.973 171.783 248.007 165.486 246.973 164.407C245.939 163.328 245.939 167.152 245.939 163.328V158.229C245.939 158.229 245.34 126.869 245.261 125.022C245.181 123.176 237.533 109.791 237.533 109.791L196.111 109.18Z"
        fill="#2F2E41"
      />
      <path
        d="M196.202 44.7737V81.5435C197.389 85.1038 198.001 88.8305 198.016 92.5835C197.977 98.8344 196.602 106.038 196.607 109.187C196.588 109.605 196.634 110.023 196.742 110.427C202.827 128.066 235.029 112.036 237.957 110.536C238.096 110.466 238.17 110.427 238.17 110.427L237.535 90.6696L237.644 84.9886L238.396 46.0178L237.722 45.4784L231.815 40.7674L231.236 40.3064L229.462 42.8946L213.641 41.8071L207.603 40.1367L205.72 40.9023L197.229 44.3561L196.202 44.7737Z"
        fill="#F9876E"
      />
      <path
        d="M186.834 118.756L188.914 107.266L195.644 107.538L193.013 119.608C193.368 120.243 193.539 120.964 193.506 121.69C193.474 122.417 193.24 123.12 192.83 123.72C192.42 124.321 191.851 124.795 191.186 125.09C190.521 125.385 189.788 125.489 189.067 125.389C188.347 125.29 187.669 124.992 187.108 124.528C186.548 124.064 186.129 123.453 185.897 122.764C185.665 122.075 185.63 121.335 185.795 120.627C185.961 119.918 186.321 119.271 186.834 118.756V118.756Z"
        fill="#FFB6B6"
      />
      <path
        d="M197.236 44.6953C200.694 45.591 202.669 50.0739 203.884 53.4334C205.447 57.7496 202.911 84.3598 199.826 88.2412C197.433 91.2517 198.001 90.673 198.791 93.1663C199.581 95.6596 201.405 93.2277 199.763 96.9365C198.12 100.645 196.722 99.9761 197.816 101.557C198.91 103.139 200.187 102.956 198.788 105.328C197.389 107.699 197.51 108.55 197.206 109.462C196.902 110.374 196.535 116.941 196.535 116.941C196.535 116.941 189.421 115.784 185.712 117.182C185.712 117.182 188.267 110.737 187.234 109.581C186.2 108.426 184.863 108.182 185.957 106.723C187.052 105.264 189.241 105.386 186.931 104.413C184.62 103.439 186.57 89.6973 186.57 89.6973C183.976 80.6524 185.783 67.1234 188.409 52.7607C188.797 50.6367 189.87 48.6983 191.464 47.2418C193.058 45.7853 195.086 44.8909 197.236 44.6953Z"
        fill="#F9876E"
      />
      <path
        d="M242.117 117.992L242.62 106.326L249.325 105.684L248.354 117.999C248.792 118.579 249.059 119.271 249.126 119.995C249.192 120.719 249.056 121.447 248.731 122.098C248.406 122.749 247.906 123.296 247.288 123.678C246.669 124.06 245.956 124.262 245.229 124.262C244.502 124.261 243.789 124.057 243.172 123.674C242.554 123.29 242.055 122.742 241.732 122.091C241.409 121.439 241.273 120.711 241.341 119.987C241.409 119.263 241.678 118.572 242.117 117.992Z"
        fill="#FFB6B6"
      />
      <path
        d="M235.108 44.8828L235.296 44.9049C237.044 45.1111 238.715 45.7401 240.165 46.7375C241.614 47.7349 242.799 49.0707 243.616 50.6291C245.751 54.6935 252.504 82.2214 250.851 85.9991C249.309 89.5222 249.372 88.6559 250.493 91.019C251.614 93.3822 253.092 90.7254 251.967 94.6227C250.842 98.5199 249.366 98.0465 250.665 99.4648C251.963 100.883 253.203 100.53 252.139 103.068C251.074 105.607 251.31 106.434 251.132 107.379C250.954 108.324 251.481 114.88 251.481 114.88C251.481 114.88 244.276 114.698 240.79 116.586C240.79 116.586 242.448 109.854 241.268 108.849C240.087 107.844 238.729 107.784 239.616 106.19C240.503 104.596 242.688 104.421 240.267 103.769C237.846 103.118 237.914 89.239 237.914 89.239L235.108 44.8828Z"
        fill="#F9876E"
      />
      <path
        d="M217.835 30.0161C218.118 30.0814 218.4 30.1379 218.683 30.1814C219.547 30.3233 220.424 30.3685 221.298 30.3163C223.573 30.1941 225.771 29.4439 227.647 28.1488C229.522 26.8538 231.002 25.0646 231.923 22.9796C232.844 20.8947 233.169 18.5954 232.863 16.3369C232.557 14.0783 231.631 11.9488 230.188 10.1843C228.745 8.41991 226.842 7.0896 224.689 6.34092C222.536 5.59224 220.218 5.45446 217.992 5.94287C215.766 6.43127 213.718 7.52677 212.076 9.10786C210.435 10.689 209.263 12.6938 208.691 14.9002C208.665 15.0003 208.639 15.096 208.618 15.196C207.875 18.3835 208.429 21.7353 210.158 24.5145C211.886 27.2936 214.648 29.2725 217.835 30.0161Z"
        fill="#FFB6B6"
      />
      <path
        d="M229.223 28.5582C230.939 27.215 231.106 24.6548 232.571 23.0409C235.019 20.3425 236.17 17.4395 234.887 14.2422C233.752 11.4143 233.994 7.93807 231.55 6.11777C232.75 5.68333 233.033 4.78112 231.974 4.76447C231.26 4.75323 228.598 6.11802 229.878 4.41786C231.159 2.7177 228.735 3.38879 228.895 2.69285C229.055 1.99692 230.084 1.61147 230.537 2.16313C229.089 0.466399 225.927 0.828139 224.9 2.80805C223.456 0.64496 221.924 -0.843444 219.716 0.529251C217.546 1.87743 217.575 3.54398 215.242 3.14346C214.146 2.95519 214.042 5.02697 213.293 5.84963C212.544 6.67228 211.449 7.30897 210.355 7.10236C209.262 6.89575 208.426 5.53327 209.039 4.60447L206.462 10.483L205.423 9.20921C203.773 10.2846 204.315 13.0908 205.976 14.1493C207.637 15.2078 210.807 12.7613 211.709 14.5125C213.13 17.273 216.463 15.879 218.752 13.9282C221.528 11.5623 224.879 18.7558 223.542 22.149C224.929 20.7865 227.496 21.9172 228.215 23.7241C228.934 25.531 228.407 27.5667 227.874 29.4369L229.223 28.5582Z"
        fill="#2F2E41"
      />
      <path
        d="M205.72 40.9019L206.15 41.45L207.307 42.9203L211.59 55.5698L231.815 40.767L231.236 40.306L228.157 33.7594L212.336 32.6719L207.603 40.1363L205.72 40.9019Z"
        fill="#3F3D56"
      />
      <path
        d="M227.25 73.1645C226.231 84.1103 218.941 92.3819 210.966 91.6396C202.991 90.8974 197.352 81.4224 198.371 70.4766C206.644 75.9036 216.229 76.9395 227.25 73.1645Z"
        fill="white"
      />
      <path
        d="M208.41 60.1446C208.369 60.7954 208.248 61.4387 208.052 62.0603C207.524 63.6886 206.501 64.8357 205.356 64.969C205.24 64.9846 205.123 64.9886 205.006 64.9809C203.725 64.8981 202.711 63.5464 202.366 61.6927C202.251 61.0509 202.215 60.3975 202.258 59.7468C202.297 59.1024 202.415 58.4653 202.611 57.85C203.188 56.0483 204.373 54.8272 205.657 54.9102C207.359 55.0203 208.589 57.365 208.41 60.1446Z"
        fill="white"
      />
      <path
        d="M206.518 62.3274C206.456 63.3182 206.044 64.2548 205.356 64.9705C205.24 64.9861 205.123 64.9901 205.006 64.9825C203.725 64.8997 202.711 63.5479 202.366 61.6943C202.251 61.0525 202.215 60.399 202.258 59.7484C202.297 59.104 202.415 58.4669 202.611 57.8516C203.721 57.9296 204.756 58.4443 205.488 59.2831C206.22 60.1219 206.59 61.2164 206.518 62.3274Z"
        fill="#3F3D56"
      />
      <path
        d="M221.153 60.9727C220.98 63.6414 219.57 65.7425 217.953 65.8107C217.885 65.8141 217.817 65.8135 217.749 65.809C216.052 65.6993 214.821 63.3545 215.001 60.575C215.04 59.9318 215.158 59.296 215.354 58.6819C215.931 56.8764 217.116 55.6553 218.4 55.7384C220.102 55.8484 221.332 58.1932 221.153 60.9727Z"
        fill="white"
      />
      <path
        d="M219.135 63.1436C219.072 64.1446 218.653 65.09 217.953 65.8084C217.885 65.8118 217.817 65.8112 217.749 65.8068C216.052 65.697 214.821 63.3522 215.001 60.5727C215.04 59.9295 215.158 59.2937 215.354 58.6797C216.439 58.7888 217.44 59.3152 218.146 60.1479C218.851 60.9806 219.206 62.0546 219.135 63.1436Z"
        fill="#3F3D56"
      />
      <path
        d="M113.965 35.6362C117.286 35.6362 119.978 32.9439 119.978 29.6228C119.978 26.3017 117.286 23.6094 113.965 23.6094C110.643 23.6094 107.951 26.3017 107.951 29.6228C107.951 32.9439 110.643 35.6362 113.965 35.6362Z"
        fill="#1501FD"
      />
      <path
        d="M114.726 27.1215C114.72 27.2559 114.73 27.3905 114.757 27.5224C114.77 27.6662 114.822 27.8037 114.908 27.9197C114.994 28.0356 115.11 28.1255 115.244 28.1793C115.268 28.1851 115.292 28.1886 115.316 28.1896C115.58 28.2013 115.819 27.9468 115.931 27.5744C115.969 27.4454 115.991 27.3122 115.997 27.1778C116.003 27.0448 115.993 26.9115 115.967 26.7809C115.889 26.3985 115.673 26.1215 115.408 26.1098C115.057 26.0942 114.752 26.5475 114.726 27.1215Z"
        fill="white"
      />
      <path
        d="M115.065 27.6083C115.056 27.8129 115.119 28.0142 115.244 28.1764C115.268 28.1822 115.292 28.1857 115.316 28.1867C115.58 28.1984 115.819 27.9439 115.931 27.5715C115.969 27.4425 115.991 27.3093 115.997 27.1749C116.003 27.0419 115.993 26.9086 115.967 26.778C115.737 26.7691 115.514 26.8515 115.345 27.0071C115.176 27.1627 115.075 27.3789 115.065 27.6083Z"
        fill="#F2F2F2"
      />
      <path
        d="M112.094 27.0043C112.07 27.5555 112.312 28.018 112.642 28.0682C112.656 28.0704 112.67 28.0718 112.684 28.0724C113.035 28.0879 113.34 27.6347 113.365 27.0606C113.371 26.9279 113.361 26.7948 113.335 26.6645C113.257 26.2813 113.041 26.0043 112.776 25.9926C112.425 25.977 112.12 26.4303 112.094 27.0043Z"
        fill="white"
      />
      <path
        d="M112.459 27.4948C112.449 27.7015 112.514 27.9048 112.642 28.0678C112.655 28.07 112.669 28.0714 112.683 28.072C113.034 28.0875 113.339 27.6342 113.364 27.0602C113.371 26.9274 113.361 26.7944 113.334 26.6641C113.109 26.6621 112.892 26.7477 112.729 26.9026C112.566 27.0576 112.469 27.2699 112.459 27.4948Z"
        fill="#F2F2F2"
      />
      <path
        d="M116.457 30.1198C116.389 31.6461 115.433 32.8435 114.321 32.7942C113.209 32.745 112.362 31.4677 112.43 29.9414C113.037 30.2918 113.72 30.491 114.421 30.522C115.122 30.553 115.82 30.4151 116.457 30.1198Z"
        fill="white"
      />
      <path
        d="M145.719 97.8472C149.041 97.8472 151.733 95.1549 151.733 91.8337C151.733 88.5126 149.041 85.8203 145.719 85.8203C142.398 85.8203 139.706 88.5126 139.706 91.8337C139.706 95.1549 142.398 97.8472 145.719 97.8472Z"
        fill="#5AAA83"
      />
      <path
        d="M146.48 89.3169C146.474 89.4512 146.484 89.5859 146.511 89.7177C146.524 89.8616 146.576 89.999 146.662 90.115C146.748 90.2309 146.864 90.3208 146.998 90.3746C147.022 90.3804 147.046 90.3839 147.07 90.3849C147.334 90.3967 147.573 90.1422 147.685 89.7697C147.723 89.6407 147.745 89.5075 147.751 89.3731C147.757 89.2401 147.747 89.1068 147.721 88.9762C147.643 88.5938 147.427 88.3168 147.162 88.3051C146.811 88.2895 146.506 88.7428 146.48 89.3169Z"
        fill="white"
      />
      <path
        d="M146.82 89.8115C146.811 90.0161 146.874 90.2173 146.999 90.3795C147.023 90.3853 147.047 90.3888 147.071 90.3898C147.335 90.4016 147.574 90.1471 147.686 89.7746C147.724 89.6456 147.746 89.5124 147.752 89.378C147.758 89.245 147.748 89.1117 147.722 88.9811C147.492 88.9722 147.269 89.0546 147.1 89.2102C146.931 89.3658 146.83 89.582 146.82 89.8115Z"
        fill="#F2F2F2"
      />
      <path
        d="M143.848 89.2075C143.824 89.7586 144.066 90.2211 144.396 90.2713C144.41 90.2736 144.424 90.275 144.438 90.2756C144.789 90.2911 145.093 89.8378 145.119 89.2638C145.125 89.131 145.115 88.9979 145.089 88.8676C145.011 88.4844 144.795 88.2075 144.53 88.1957C144.179 88.1801 143.874 88.6334 143.848 89.2075Z"
        fill="white"
      />
      <path
        d="M144.214 89.6979C144.204 89.9046 144.269 90.1079 144.397 90.2709C144.41 90.2731 144.424 90.2746 144.438 90.2752C144.789 90.2907 145.094 89.8374 145.119 89.2633C145.126 89.1306 145.116 88.9975 145.089 88.8672C144.864 88.8653 144.647 88.9508 144.484 89.1058C144.32 89.2607 144.224 89.473 144.214 89.6979Z"
        fill="#F2F2F2"
      />
      <path
        d="M148.211 92.3268C148.144 93.8531 147.188 95.0505 146.076 95.0013C144.964 94.952 144.117 93.6748 144.184 92.1484C144.792 92.4989 145.475 92.698 146.176 92.729C146.877 92.7601 147.575 92.6221 148.211 92.3268Z"
        fill="white"
      />
      <path
        d="M261.426 27.3745C264.747 27.3745 267.439 24.6822 267.439 21.3611C267.439 18.04 264.747 15.3477 261.426 15.3477C258.104 15.3477 255.412 18.04 255.412 21.3611C255.412 24.6822 258.104 27.3745 261.426 27.3745Z"
        fill="#1501FD"
      />
      <path
        d="M262.187 18.852C262.181 18.9864 262.191 19.121 262.218 19.2529C262.231 19.3967 262.283 19.5342 262.369 19.6501C262.455 19.7661 262.571 19.8559 262.705 19.9098C262.729 19.9156 262.753 19.919 262.777 19.9201C263.041 19.9318 263.28 19.6773 263.392 19.3049C263.43 19.1759 263.452 19.0427 263.458 18.9083C263.464 18.7752 263.454 18.6419 263.428 18.5114C263.35 18.1289 263.134 17.852 262.869 17.8402C262.518 17.8247 262.213 18.278 262.187 18.852Z"
        fill="white"
      />
      <path
        d="M262.527 19.3427C262.518 19.5473 262.581 19.7486 262.706 19.9108C262.73 19.9166 262.754 19.92 262.778 19.9211C263.042 19.9328 263.281 19.6783 263.393 19.3059C263.431 19.1769 263.453 19.0437 263.459 18.9093C263.465 18.7762 263.455 18.6429 263.429 18.5124C263.199 18.5035 262.976 18.5859 262.807 18.7415C262.638 18.8971 262.537 19.1133 262.527 19.3427Z"
        fill="#F2F2F2"
      />
      <path
        d="M259.556 18.7426C259.532 19.2938 259.774 19.7563 260.104 19.8065C260.118 19.8087 260.132 19.8101 260.146 19.8107C260.497 19.8262 260.801 19.3729 260.827 18.7989C260.833 18.6661 260.823 18.5331 260.797 18.4028C260.719 18.0196 260.503 17.7426 260.238 17.7309C259.887 17.7153 259.582 18.1685 259.556 18.7426Z"
        fill="white"
      />
      <path
        d="M259.921 19.2253C259.911 19.432 259.976 19.6352 260.104 19.7983C260.117 19.8005 260.131 19.8019 260.145 19.8025C260.496 19.818 260.801 19.3647 260.826 18.7907C260.833 18.6579 260.823 18.5249 260.796 18.3946C260.571 18.3926 260.354 18.4781 260.191 18.6331C260.027 18.7881 259.931 19.0004 259.921 19.2253Z"
        fill="#F2F2F2"
      />
      <path
        d="M263.919 21.858C263.851 23.3844 262.895 24.5818 261.783 24.5325C260.671 24.4833 259.824 23.206 259.891 21.6797C260.499 22.0301 261.182 22.2292 261.883 22.2603C262.584 22.2913 263.282 22.1534 263.919 21.858Z"
        fill="white"
      />
      <path
        d="M8.26147 187.011C11.5826 187.011 14.2749 184.319 14.2749 180.998C14.2749 177.677 11.5826 174.984 8.26147 174.984C4.94035 174.984 2.24805 177.677 2.24805 180.998C2.24805 184.319 4.94035 187.011 8.26147 187.011Z"
        fill="#F93B10"
      />
      <path
        d="M9.02323 178.493C9.01705 178.627 9.0273 178.762 9.05373 178.894C9.06649 179.037 9.11878 179.175 9.20483 179.291C9.29088 179.407 9.40731 179.497 9.54127 179.55C9.56471 179.556 9.58868 179.56 9.61281 179.561C9.87733 179.572 10.1157 179.318 10.2279 178.946C10.2659 178.816 10.288 178.683 10.2937 178.549C10.3001 178.416 10.2901 178.283 10.2638 178.152C10.1858 177.77 9.97023 177.493 9.70492 177.481C9.35354 177.465 9.04865 177.919 9.02323 178.493Z"
        fill="white"
      />
      <path
        d="M9.36225 178.987C9.35279 179.192 9.4162 179.393 9.54121 179.555C9.56466 179.561 9.58862 179.565 9.61275 179.566C9.87728 179.577 10.1156 179.323 10.2278 178.95C10.2658 178.821 10.2879 178.688 10.2937 178.554C10.3001 178.421 10.29 178.287 10.2638 178.157C10.0343 178.148 9.81056 178.23 9.64162 178.386C9.47268 178.542 9.37224 178.758 9.36225 178.987Z"
        fill="#F2F2F2"
      />
      <path
        d="M6.39134 178.379C6.36693 178.93 6.60912 179.393 6.93918 179.443C6.95301 179.445 6.96696 179.447 6.98094 179.447C7.33155 179.463 7.63643 179.01 7.66185 178.436C7.66827 178.303 7.65822 178.17 7.63193 178.039C7.55396 177.656 7.33837 177.379 7.07306 177.368C6.72166 177.352 6.41677 177.805 6.39134 178.379Z"
        fill="white"
      />
      <path
        d="M6.75679 178.874C6.74722 179.08 6.81204 179.284 6.93949 179.447C6.95331 179.449 6.96726 179.45 6.98125 179.451C7.33185 179.466 7.63674 179.013 7.66216 178.439C7.66857 178.306 7.65853 178.173 7.63224 178.043C7.40711 178.041 7.19002 178.127 7.02672 178.282C6.86341 178.437 6.76663 178.649 6.75679 178.874Z"
        fill="#F2F2F2"
      />
      <path
        d="M10.7545 181.503C10.6869 183.029 9.73059 184.226 8.61855 184.177C7.50651 184.128 6.65982 182.851 6.72741 181.324C7.33518 181.675 8.01831 181.874 8.71917 181.905C9.42004 181.936 10.1181 181.798 10.7545 181.503Z"
        fill="white"
      />
      <path
        d="M9.56616 91.7495C12.8873 91.7495 15.5796 89.0572 15.5796 85.7361C15.5796 82.415 12.8873 79.7227 9.56616 79.7227C6.24504 79.7227 3.55273 82.415 3.55273 85.7361C3.55273 89.0572 6.24504 91.7495 9.56616 91.7495Z"
        fill="#5AAA83"
      />
      <path
        d="M10.3289 83.2348C10.3227 83.3692 10.333 83.5038 10.3594 83.6357C10.3722 83.7795 10.4244 83.917 10.5105 84.0329C10.5965 84.1489 10.713 84.2387 10.8469 84.2926C10.8704 84.2984 10.8943 84.3019 10.9185 84.3029C11.183 84.3146 11.4213 84.0601 11.5335 83.6877C11.5715 83.5587 11.5936 83.4255 11.5994 83.2911C11.6058 83.1581 11.5958 83.0247 11.5695 82.8942C11.4915 82.5118 11.2759 82.2348 11.0106 82.223C10.6592 82.2075 10.3543 82.6608 10.3289 83.2348Z"
        fill="white"
      />
      <path
        d="M10.6669 83.7216C10.6575 83.9262 10.7209 84.1275 10.8459 84.2897C10.8693 84.2955 10.8933 84.299 10.9174 84.3C11.182 84.3117 11.4203 84.0572 11.5325 83.6848C11.5705 83.5558 11.5926 83.4226 11.5983 83.2882C11.6048 83.1552 11.5947 83.0218 11.5684 82.8913C11.3389 82.8824 11.1153 82.9648 10.9463 83.1204C10.7774 83.276 10.6769 83.4922 10.6669 83.7216Z"
        fill="#F2F2F2"
      />
      <path
        d="M7.69701 83.1176C7.6726 83.6688 7.91478 84.1313 8.24485 84.1815C8.25867 84.1837 8.27262 84.1851 8.28661 84.1857C8.63721 84.2012 8.9421 83.748 8.96752 83.1739C8.97393 83.0411 8.96388 82.9081 8.93759 82.7778C8.85963 82.3946 8.64404 82.1176 8.37872 82.1059C8.02733 82.0903 7.72243 82.5436 7.69701 83.1176Z"
        fill="white"
      />
      <path
        d="M8.06147 83.6081C8.0519 83.8148 8.11673 84.0181 8.24418 84.1811C8.258 84.1833 8.27195 84.1847 8.28594 84.1853C8.63654 84.2008 8.94142 83.7475 8.96685 83.1735C8.97326 83.0407 8.96321 82.9077 8.93692 82.7774C8.7118 82.7754 8.49471 82.8609 8.3314 83.0159C8.1681 83.1709 8.07132 83.3832 8.06147 83.6081Z"
        fill="#F2F2F2"
      />
      <path
        d="M12.0592 86.2369C11.9916 87.7633 11.0353 88.9607 9.92324 88.9114C8.8112 88.8622 7.9645 87.5849 8.0321 86.0586C8.63987 86.409 9.32299 86.6081 10.0239 86.6392C10.7247 86.6702 11.4228 86.5323 12.0592 86.2369Z"
        fill="white"
      />
    </svg>
  );
};
