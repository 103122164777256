import { toJS } from 'mobx';
import { useCallback } from 'react';

import { DEFAULT_ITEMS_COUNT } from '../../../constants';
import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';
import { GetUsersQuery } from '../../Users/stores/usersStore';

export const useGetOrgUsersQuery = () => {
  const { orgListStore } = useRootStore();
  const { selectedOrg } = orgListStore;
  const { usersFilter } = selectedOrg;

  const getOrgUsers = useCallback(
    async (loadMore = false) => {
      const body: GetUsersQuery = {
        offset: loadMore ? selectedOrg.users!.length : 0,
        count: DEFAULT_ITEMS_COUNT,
        ...usersFilter,
        loadImages: false,
      };
      return await selectedOrg.getOrgUsers!(body);
    },
    [selectedOrg, usersFilter]
  );

  const clearFunc = useCallback(() => {
    selectedOrg.clearUsers!();
  }, [selectedOrg]);

  const { loading, loadMore: loadMoreUsers } = useDataQuery({
    dataFunc: getOrgUsers,
    clearFunc,
  });

  return {
    users: toJS(selectedOrg.users!.map((item) => item.asObject)),
    loading,
    loadMoreUsers,
  };
};
