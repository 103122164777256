import { notification } from 'antd';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { RefObject } from 'react';

import { BaseStringType } from '../domain/Identity/stores/authStore';
import { PlainObject } from '../domain/Users/stores/usersStore';
import { Result } from '../services/rootStore';
import { SortDirection } from '../types/commonTypes';

type ValueType = string | number | boolean;

export function sortBy<
  T extends { [key: string]: ValueType },
  Tout extends ValueType
>(field: string, reverse: boolean, primer?: (value: ValueType) => Tout) {
  const key = function (x: T) {
    return primer ? primer(x[field]) : x[field];
  };

  const order = !reverse ? 1 : -1;

  return function (a: T, b: T) {
    const left = key(a);
    const right = key(b);
    // @ts-ignore
    return order * ((left > right) - (right > left));
  };
}

export const getSortFunction = (field: string, order: SortDirection) => {
  return field.includes('Date')
    ? sortBy(field, order === SortDirection.Asc, (value) =>
        new Date(value as number).getTime()
      )
    : sortBy(field, order === SortDirection.Asc, (value) => {
        const int = parseInt(String(value), 10);
        return isNaN(int) ? String(value).toUpperCase() : int;
      });
};
export const setAvatarLetters = (name: string) => {
  if (!name) {
    return 'FL';
  }
  const splitted = name.split(' ').filter((i) => i);
  return splitted.map((namePart) => namePart[0].toUpperCase()).join('');
};

export const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const addMonths = (numOfMonths: number, date: Date = new Date()) => {
  const dateCopy = new Date(date.getTime());

  dateCopy.setMonth(dateCopy.getMonth() + numOfMonths);

  return dateCopy;
};

export const subWeeks = (numOfWeeks: number, date: Date = new Date()) => {
  const dateCopy = new Date(date.getTime());

  dateCopy.setDate(dateCopy.getDate() - numOfWeeks * 7);

  return dateCopy;
};

export const showLocalTime = (date: string) => {
  return new Date(date).toLocaleTimeString();
};

export const showLocalDate = (
  date: string | number,
  locale: string = 'en-US',
  months:
    | 'long'
    | 'numeric'
    | '2-digit'
    | 'short'
    | 'narrow'
    | undefined = 'long'
) => {
  return !isNaN(new Date(date).getTime())
    ? new Date(date).toLocaleDateString(locale, {
        year: 'numeric',
        month: months,
        day: 'numeric',
      })
    : '';
};

export const splitCamelCased = (strToSplit: string) => {
  return strToSplit && strToSplit.replace(/([a-z](?=[A-Z]))/g, '$1 ');
};

export const getResultAndShowNotification = async (
  func: () => Promise<Result<string>>,
  successMsg: string,
  failMsg: string,
  onClose: () => void,
  showNotification: boolean = true
): Promise<Result<string>> => {
  let action = 'success';
  let description;
  let message = successMsg;
  const res = await func();
  if (res.failed) {
    action = 'error';
    message = failMsg;
    description = res.message;
  } else {
    description = res.message;
  }
  if (showNotification) {
    // @ts-ignore
    notification[action]({
      description,
      message,
      placement: 'bottomRight',
      duration: 5,
      onClose,
    });
  }

  return res;
};

export const getCurrentDomain = (userEmail: string) => {
  const domainMatchPattern = /@*?.?((\w+)?-?(\w+.\w+))$/;
  const match = userEmail.match(domainMatchPattern);
  return (match && match[1]) || '';
};

export const isDomainInValid = (emails: string[], signedInUser: string) => {
  const domain = getCurrentDomain(signedInUser);
  const re = new RegExp(`\\.${domain}$|@${domain}$`);
  return domain && !emails.every((d) => d.match(re));
};

export const validateEmailDomain = (
  emails: string[],
  signedInUser: string,
  errorMsg: string
) => {
  if (isDomainInValid(emails, signedInUser as string)) {
    return Promise.reject(new Error(errorMsg));
  }
  return Promise.resolve();
};

export const validateConfirmPassword =
  //@ts-ignore
  ({ getFieldValue }) => ({
    validator(_: RuleObject, value: StoreValue) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error('The two passwords that you entered do not match!')
      );
    },
  });

export const validatePasswordRules =
  (msg: string) =>
  //@ts-ignore
  (_) => ({
    validator(_: RuleObject, value: StoreValue): Promise<void> {
      const isPassRulesMatched = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{8,}$/
      ).test(value);
      if (isPassRulesMatched) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(msg));
    },
  });

export const findKeyByValue = (obj: PlainObject, value: string) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const buildImageUrlFromBase64 = (imageBase64: string) => {
  return `data:image/png;base64,${imageBase64}`;
};

export const normalizeVideoAdvSize = (
  videoRef: RefObject<HTMLIFrameElement>,
  advertRef: RefObject<HTMLDivElement>
) => {
  const aspect = 0.5625;
  if (videoRef.current?.parentElement && advertRef.current) {
    const { width: parentWidth, height: parentHeight } =
      videoRef.current.parentElement.getBoundingClientRect();

    if (parentHeight < parentWidth) {
      const width = Math.round(parentHeight / aspect);
      videoRef.current.style.width = `${width}px`;
      videoRef.current.style.height = `${parentHeight}px`;
      advertRef.current.style.paddingLeft = `${width * 0.9}px`;
      advertRef.current.style.paddingTop = `${parentHeight * 0.12}px`;
    } else if (parentWidth < parentHeight) {
      const height = Math.round(parentWidth * aspect);
      videoRef.current.style.height = `${height}px`;
      videoRef.current.style.width = `${parentWidth}px`;
      advertRef.current.style.paddingLeft = `${parentWidth * 0.8}px`;
      advertRef.current.style.paddingTop = `${height * 0.2}px`;
    }
  }
};
export const DEFAULT_DATA_PAGE_SIZE = 8;
export const setMaxListHeight = <T>(
  data: T[],
  rowHeight: number,
  maxRows: number = DEFAULT_DATA_PAGE_SIZE
) => {
  return data.length >= maxRows ? maxRows * rowHeight : data.length * rowHeight;
};

export const readImage = async (file: File) => {
  return await new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
  });
};

export const setItemsCache = <T extends { id: string }>(
  currentCache: T[],
  currentItemsValue: T[],
  useCache: boolean = false
) => {
  const existingItems = currentCache.map((i) => i.id);
  const newCache = [
    ...currentCache,
    ...currentItemsValue.filter((x) => !existingItems.includes(x.id)),
  ];

  let newValues = useCache
    ? newCache.filter((x) => currentItemsValue.map((z) => z.id).includes(x.id))
    : newCache;

  return { newValues, newCache };
};

export const capitalizeFirstLetter = (word: string | undefined) => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
};

export const calculateProgress = (
  pricingTierUsers: number | string,
  orgUsers: number | string | undefined
) => {
  const users = Number(orgUsers);
  const priceUsers = Number(pricingTierUsers);
  if (users >= priceUsers) return 100;
  return (users / priceUsers) * 100;
};

export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (!+bytes) return '0 B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return i < 0
    ? `0 ${sizes[0]}`
    : `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const trimCodeValues = (
  values: BaseStringType,
  keysToTrim: string[]
) => {
  keysToTrim.forEach((key) => {
    values[key] = values[key]?.trim() || null;
  });
  return values;
};

export type RoleLabel = 'Admin' | 'Editor' | 'Viewer';
type CompanyLabels = {
  [key in RoleLabel]: string;
};

export const showCompanyRoleLabel = (roleLabel: RoleLabel) => {
  const labels: CompanyLabels = {
    Admin: 'Global Administrator',
    Editor: 'Manager',
    Viewer: 'Viewer',
  };
  return labels[roleLabel];
};

export const sortRoles = (data: PlainObject) =>
  Object.keys(data)
    .map((k) => ({ key: k, value: data[k] || '' }))
    .sort(sortBy('value', false, (value) => (value as string).toUpperCase()))
    .reduce((acc, { key, value }) => {
      // @ts-ignore
      acc[key] = value;
      return acc;
    }, {});
