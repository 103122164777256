import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GdChart } from '../../../components/GdChart';
import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';
import { GraphCardContainer } from './GraphCardContainer';

export const SubscriptionBarChart = () => {
  const { t } = useTranslation();
  const { admDashboardStore } = useRootStore();
  const fetchChart = useCallback(
    async (loadMore = false) => {
      return await admDashboardStore.getOrganizationSubscriptionsChart();
    },
    [admDashboardStore]
  );

  const { loading } = useDataQuery({
    dataFunc: fetchChart,
  });

  return (
    <GraphCardContainer loading={loading}>
      <GdChart
        type="bar"
        data={admDashboardStore.orgsBySubscriptionChart}
        info={t('admDashboard:Label:subscriptionChartLbl')}
      />
    </GraphCardContainer>
  );
};
