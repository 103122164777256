import { Button, Col, Form, Input, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { getResultAndShowNotification } from '../../../utils';
import {
  ButtonsContainer,
  UserContainer as BaseContainer,
} from '../../Common/styles';

const AddGroupContainer = styled(BaseContainer)``;

const AddGroupFormContainer = styled.div`
  padding-top: 14px;
`;

export const AddGroup = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { teamsStore } = useRootStore();
  const [form] = Form.useForm();

  const onClickCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onFinishForm = useCallback(
    async (values) => {
      setLoading(true);
      const res = await getResultAndShowNotification(
        async () => await teamsStore.add(values),
        t('groups:AddGroup:groupAddedSuccess'),
        t('groups:AddGroup:groupAddedFailed'),
        () => {}
      );
      if (res.succeeded) {
        form.resetFields();
        history.push('/groups');
      }
      setLoading(false);
    },
    [history, form, teamsStore, t]
  );

  return (
    <AddGroupContainer>
      <PageTitle title={t('groups:AddGroup:mainTitle')} />
      <AddGroupFormContainer>
        <Form
          form={form}
          name="users-adduser-form"
          layout="vertical"
          onFinish={onFinishForm}
          className="gd-users-adduser-form"
        >
          <Row>
            <Col span={12}>
              <Form.Item
                className="gd-form-item"
                label={<Text>{t('groups:AddGroup:groupNameLbl')}</Text>}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('groups:AddGroup:groupNameValidationMessage'),
                  },
                ]}
              >
                <Input
                  className="gd-input-item"
                  placeholder={t('groups:AddGroup:groupNamePlaceHolder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <ButtonsContainer>
                <Button
                  type="text"
                  className="gd-form-button gd-form-button-text"
                  onClick={onClickCancel}
                  disabled={loading}
                >
                  {t('groups:AddGroup:cancelGroupButtonLbl')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="gd-form-button gd-form-button-primary"
                  disabled={loading}
                  loading={loading}
                >
                  {t('groups:AddGroup:addGroupButtonLbl')}
                </Button>
              </ButtonsContainer>
            </Col>
          </Row>
        </Form>
      </AddGroupFormContainer>
    </AddGroupContainer>
  );
};
