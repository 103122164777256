import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/macro';

import Text from '../../../../components/Text';

const UserCompanyRoleExplanationContainer = styled.div`
  margin: 15px 0 15px 0;
  height: 50px;
  font-size: 14px;

  & > .ant-row {
    display: flex;
    align-items: center;
    flex-wrap: inherit;

    & > span {
      padding-right: 5px;
    }

    & > span[aria-label='close'] {
      color: red;
    }

    & > span[aria-label='check'] {
      color: green;
    }
  }
`;

type Props = {
  role: string;
};

const AdminRole = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <CheckOutlined />
        <Text fontFamily="montserrat-medium">
          {t('users:Explanations:Admin:accept1')}
        </Text>
      </Row>
    </>
  );
};

const EditorRole = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <Row>
        <CheckOutlined />
        <Text fontFamily="montserrat-medium">
          {t('users:Explanations:Editor:accept1')}
        </Text>
      </Row>
      <Row>
        <CloseOutlined />
        <Text fontFamily="montserrat-medium" color={theme.colors.mediumRed}>
          {t('users:Explanations:Editor:decline1')}
        </Text>
      </Row>
      <Row>
        <CloseOutlined />
        <Text fontFamily="montserrat-medium" color={theme.colors.mediumRed}>
          {t('users:Explanations:Editor:decline2')}
        </Text>
      </Row>
    </>
  );
};

export const UserCompanyRoleExplanation = ({ role = 'Viewer' }: Props) => {
  return (
    <UserCompanyRoleExplanationContainer>
      {role === 'Admin' && <AdminRole />}
      {role === 'Editor' && <EditorRole />}
      {role === 'Viewer' && <EditorRole />}
    </UserCompanyRoleExplanationContainer>
  );
};
