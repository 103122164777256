import {Row, Select} from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterMeta, GdFilterBar } from '../../../components/GdFilterBar';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../context/storeContext';
import { splitCamelCased } from '../../../utils';
import Text from "../../../components/Text";
import styled, {useTheme} from "styled-components/macro";

const FiltersBarContainer = styled.div`
  width: 100%;
  padding-right: 46px;
`;

const HintContainer = styled.div`
  padding-top: 6px;
  padding-left: 6px;
  width: 100%;
`;

export const FiltersBar = observer(() => {
  const { t } = useTranslation();
  const { fonts, defaultFontSize, colors } = useTheme();
  const { usersStore } = useRootStore();
  const { teams, statuses } = usersStore;

  useEffect(() => {
    async function getUsersFiltersMeta() {
      await usersStore.getFilterBarMeta();
    }

    getUsersFiltersMeta();
  }, [usersStore]);

  const onFilter = useCallback(
    (values) => {
      const filter = {
        ...values,
        filterByStatuses: values.filterByStatuses
          ? [values.filterByStatuses]
          : [],
      };
      usersStore.setFilter(filter);
    },
    [usersStore]
  );

  const formMeta: FilterMeta = {
    nameOrEmail: {
      span: 6,
      label: t('users:Labels:searchLabel'),
      placeholder: t('users:Labels:searchPlaceHolder'),
      isSearch: true,
    },
    filterByGroups: {
      span: 6,
      label: t('users:Labels:groupsFilterLbl'),
      render: (form) => {
        return (
          <Select
            style={{ width: '100%' }}
            className="gd-input-item"
            placeholder={t('users:Labels:groupsFilterPlaceholder')}
            mode="multiple"
            notFoundContent={null}
            allowClear
            maxTagCount="responsive"
            optionLabelProp="label"
            onChange={() => {
              form.submit();
            }}
            options={Object.keys(teams).map((jt) => ({
              label: teams[jt],
              value: jt,
            }))}
          />
        );
      },
    },
    filterByStatuses: {
      span: 6,
      label: t('users:Labels:statusLbl'),
      render: (form) => {
        return (
          <Select
            style={{ width: '100%' }}
            className="gd-input-item"
            placeholder={t('users:Labels:statusPlaceholder')}
            allowClear
            onChange={() => {
              form.submit();
            }}
          >
            {statuses.map((jt) => (
              <Select.Option
                className="gd-users-select-option"
                key={`${jt}-st`}
                value={jt}
              >
                {splitCamelCased(jt as string)}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    totalAmount: {
      span: 6,
      label: t('users:Labels:numberOfUsersLbl'),
      disabled: true,
      bordered: false,
      className: 'gd-filter-count-input',
    },
  };

  return (
      <FiltersBarContainer>
        <GdFilterBar
        meta={formMeta}
        onFilter={onFilter}
        initialValues={usersStore.amountInfo}
      />
        <HintContainer>{usersStore.filter?.filterByGroups?.length > 0 ? <Text fontSize={10} color={colors.black06}>{t('users:Explanations:Filter:Teams')}</Text> : ''}</HintContainer>
      </FiltersBarContainer>
  );
});
