import {
  BaseEntity,
  BaseListsStore,
  listDefault,
  ModifiedElement,
} from './BaseListsStore';
import {action, makeObservable, observable, runInAction} from 'mobx';
import { UsersResponseDto } from '../domain/Groups/stores/teamsListsStore';
import { UsersGuidoResponseDto } from '../domain/Guidos/stores/guidosListsStore';
import { BaseQuery, ListResult } from '../types/commonTypes';

export type GuidoUser = {
  name: string;
  email: string;
  role: string;
} & BaseEntity;

export type UpdateAssignedUsersRequest = {
  assignedUsers: ModifiedElement<UserAssignedToTeamItem>[];
};

export type UserAssignedToTeamItem = {
  userId: string;
  roleId: string;
};

export class BaseUsersStore extends BaseListsStore {
  usersSearchText: string = '';

  usersListInfo = listDefault;
  userscheckAll: boolean = false;

  users: GuidoUser[] = [];
  usersCache: GuidoUser[] = [];

  constructor() {
    super();
    makeObservable(this, {
      users: observable,
      usersCache: observable,
      usersSearchText: observable,
      usersListInfo: observable,
      userscheckAll: observable,
      setUsersData: action,
      clear: action,
    });
  }

  clear() {
    this.users = [];
    this.usersCache = [];
  }

  public createUserItem(
    user: UsersGuidoResponseDto | UsersResponseDto,
    idx: number,
    isOriginal: boolean
  ): GuidoUser {
    return {
      id: user.userId,
      changed: false,
      checked: isOriginal,
      name: '',
      role: user.roleId,
      original: isOriginal,
      email: user.email,
    };
  }

  public setUsersData<T extends UsersGuidoResponseDto | UsersResponseDto>(
    data: ListResult<T>,
    loadMore: boolean = false
  ) {
    const { totalAmount, amountLeft, amount, hasNext } = data;
    this.usersListInfo = { totalAmount, amountLeft, amount, hasNext };
    const users = data.items.map((g, idx) => this.createUserItem(g, idx, true));
    this.users = loadMore ? [...this.users, ...users] : users;
    this.setAllChecked('users');
  }

  public setUserCache<T extends UsersGuidoResponseDto | UsersResponseDto>(
    data: ListResult<T>,
    payload: BaseQuery
  ) {
    const { totalAmount, amountLeft, amount, hasNext } = data;
    this.usersListInfo = { totalAmount, amountLeft, amount, hasNext };
    const availableUsers = data.items.map((g, idx) =>
      this.createUserItem(g, idx, !!g.roleId)
    );
    const existingItems = this.usersCache.map((i) => i.id);
    this.usersCache = [
      ...this.usersCache,
      ...availableUsers.filter((x) => !existingItems.includes(x.id)),
    ];
    if (payload.textToSearch) {
      this.users = this.usersCache.filter((x) =>
        availableUsers.map((z) => z.id).includes(x.id)
      );
    } else {
      this.users = this.usersCache;
    }
    this.setAllChecked('users');
  }

  cleanUsersAfterUpdate() {
    runInAction(() => {
      this.usersCache = [];
      this.users = this.usersCache;
      this.origValues = new Map<
        string,
        Map<string, number | string | boolean>
        >();
      this.usersListInfo = listDefault;
    });
  }
}
