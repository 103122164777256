import { action, makeObservable, observable, runInAction, toJS } from 'mobx';

import { TransportLayer } from '../../../api/transportLayer';
import { BaseStore } from '../../../services/baseStore';
import { Result, RootStore } from '../../../services/rootStore';
import { BaseQuery, SortDirection } from '../../../types/commonTypes';
import { getSortFunction } from '../../../utils';

enum OwnerType {
  Me = 'Me',
  Shared = 'Shared',
  NotBelongToMe = 'NotBelongToMe',
}

const Owner: { [key: string]: string } = {
  [OwnerType.Me]: 'Me',
  [OwnerType.Shared]: 'Shared',
  [OwnerType.NotBelongToMe]: 'Company',
};

export type Guido = {
  id: string;
  tittle: string;
  createdDateUtc: string;
  lastUpdatedDateUtc: string;
  size: number;
  owner: string;
  published: boolean;
  assignedUsersCount: number;
  assignedTeamsCount: number;
  publicLink?: string;
  cretedBy?: string;
  tenantId: string;
  externalViews: number;
};

type GuidoFilter = {
  name: string;
};

export class GuidosStore extends BaseStore<GuidoFilter> {
  rootStore: RootStore;

  guidos: Guido[] = [];

  filter: GuidoFilter = {
    name: '',
  };

  sortOrder: SortDirection = SortDirection.Asc;
  selectedGuido: Partial<Guido> = {};
  restoreGuidoName: string = '';
  transportLayer: TransportLayer;

  constructor(_rootStore: RootStore) {
    super();
    this.rootStore = _rootStore;
    this.transportLayer = _rootStore.transportLayer;
    makeObservable(this, {
      guidos: observable,
      filter: observable,
      selectedGuido: observable,
      restoreGuidoName: observable,
      sortOrder: observable,
      sortGuidos: action,
      getAll: action,
      clearGuidos: action,
    });
  }

  clearGuidos() {
    this.guidos = [];
    this.filter = {
      name: '',
    };
  }

  clearSelectedGuido() {
    this.selectedGuido = {};
  }

  public setNewGuidoName(tittle: string) {
    this.selectedGuido = { ...this.selectedGuido, tittle };
  }

  public setRestoreName(restore: boolean) {
    if (restore) {
      this.restoreGuidoName = this.selectedGuido.tittle as string;
    } else {
      this.selectedGuido = {
        ...this.selectedGuido,
        tittle: this.restoreGuidoName,
      };
    }
  }

  public updateUsersCount(newCount: number) {
    this.selectedGuido = {
      ...this.selectedGuido,
      assignedUsersCount: newCount,
    };
  }

  public updateTeamsCount(newCount: number) {
    this.selectedGuido = {
      ...this.selectedGuido,
      assignedTeamsCount: newCount,
    };
  }

  public setFilter(newFilter: GuidoFilter) {
    this.filter = { ...newFilter };
  }

  public setSelectedGuido(guidoId: string) {
    this.selectedGuido = this.guidos.find((ug) => ug.id === guidoId) || {};
  }

  public sortGuidos(order: SortDirection, field: string) {
    const sortFunc = getSortFunction(field, order);
    const guidos = toJS(this.guidos);
    this.guidos = guidos.sort(sortFunc);
    this.sortOrder = order;
  }

  public async updateGuidoDetails(guidoId: string) {
    const res: Result<string> = await this.rootStore.ApiClient.put(
      `/guido/updateGuidoName/${guidoId}`,
      {
        title: this.selectedGuido.tittle,
      }
    );

    const result: Result<string> = {
      data: 'Update guido failed',
      message: res.message,
      succeeded: false,
      failed: true,
    };

    if (res.succeeded) {
      result.succeeded = true;
      result.failed = false;
    }

    return result;
  }

  public async delete(guidoId: string) {
    try {
      const res = await this.rootStore.ApiClient.delete(
        `/guido/delete/${guidoId}`
      );
      if (res.succeeded) {
        runInAction(() => {
          this.guidos = this.guidos.filter(({ id }) => id !== guidoId);
          const { totalAmount, amountLeft } = this.amountInfo;
          this.amountInfo = {
            ...this.amountInfo,
            amountLeft: amountLeft - 1,
            totalAmount: totalAmount - 1,
          };
        });
      }
      return res;
    } catch (error) {
      return {
        data: 'Delete failed',
        message: `${error}`,
        succeeded: false,
        failed: true,
      };
    }
  }

  public async getAll(query: BaseQuery, loadMore: boolean = false) {
    const resp = await this.transportLayer.fetchAllGuidos(query);

    if (resp.succeeded) {
      runInAction(() => {
        const { items, totalAmount, hasNext, amount, amountLeft } = resp.data;
        this.amountInfo = { totalAmount, hasNext, amount, amountLeft };
        const guidos = items.map((g) => {
          g.owner = Owner[g.owner];
          g.size = g.size * 1024;
          return g;
        });
        this.guidos = loadMore ? this.guidos.concat([...guidos]) : guidos;
        this.sortGuidos(this.sortOrder, 'owner');
        this.sortGuidos(this.sortOrder, 'createdDateUtc');
      });
    }
    return resp;
  }

  public async getGuidoData(guidoId: string) {
    const result = await this.rootStore.ApiClient.get<Result<Guido>>(
      `/guido/guido/${guidoId}`
    );
    if (result.succeeded) {
      runInAction(() => {
        const sizeInBytes = result.data.size * 1024;
        this.selectedGuido = { ...result.data, size: sizeInBytes };
      });
    }

    return result;
  }
}
