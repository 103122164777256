import { Checkbox, Col } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';

import { SortDirection } from '../types/commonTypes';
import { IconInt } from './Icons';
import Text from './Text';

type Column = {
  title: string;
  colspan: number;
  sortingHandler?: (sort: SortDirection, field: string) => void;
  direction?: SortDirection;
  leftPadding?: number;
};

const sortIcons: { [key: string]: React.ReactElement } = {
  desc: <IconInt icon="ArrowDown" />,
  asc: <IconInt icon="ArrowUp" />,
};

const directionMappings: { [key: string]: SortDirection } = {
  asc: SortDirection.Desc,
  desc: SortDirection.Asc,
};
export type ColumnsMeta = { [key: string]: Column };

type ListHeaderContainerProps = {
  topOffset?: number;
};

const ListHeaderContainer = styled.div<ListHeaderContainerProps>`
  padding-top: ${({ topOffset }) => (topOffset ? `${topOffset}px` : '37px')};
  padding-right: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: baseline;
`;
const ColumnNameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SortIconContainer = styled.div<{ isActive?: boolean }>`
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span > svg {
    width: 20px;
    height: 14px;
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.strongBlue : 'rgba(0, 0, 0, 0.38)'}}; 
  }
`;

export type ListHeaderProps = {
  columns: ColumnsMeta;
  edit?: boolean;
  onCheckBoxTriggered?: (checked: boolean) => void;
  checkAll?: boolean;
  activeSortField?: string;
} & ListHeaderContainerProps;

export const GdListHeader = ({
  columns,
  topOffset = 37,
  edit = false,
  onCheckBoxTriggered = () => {},
  checkAll = false,
  activeSortField = 'name',
}: ListHeaderProps) => {
  const [headerColumns, setHeaderColumns] = useState<ColumnsMeta>(columns);
  const [checkboxChecked, setCheckboxChecked] = useState(checkAll);
  const [activeColumn, setActiveColumn] = useState<string>(activeSortField);

  const theme = useTheme();

  useEffect(() => {
    setCheckboxChecked(checkAll);
  }, [checkAll]);

  const onClickSort = useCallback(
    (key) => {
      return (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const column = headerColumns[key];
        const newSortDirection =
          directionMappings[column.direction as SortDirection];
        column.direction = newSortDirection;
        const newColumns = { ...headerColumns };
        setHeaderColumns(newColumns);
        setActiveColumn(key);
        if (column.sortingHandler) {
          column.sortingHandler(newSortDirection, key);
        }
      };
    },
    [headerColumns]
  );

  const onCheckboxChanged = useCallback(
    ({ target: { checked } }) => {
      setCheckboxChecked(checked);
      onCheckBoxTriggered && onCheckBoxTriggered(checked);
    },
    [setCheckboxChecked, onCheckBoxTriggered]
  );

  const renderBody = useCallback(() => {
    const body = Object.keys(headerColumns).map((key, idx) => {
      const display = headerColumns[key].sortingHandler ? 'flex' : 'block';
      return (
        <Col key={idx} span={headerColumns[key].colspan} style={{ display }}>
          <ColumnNameContainer>
            <Text
              fontSize={12}
              color="rgba(0, 0, 0, 0.38)"
              fontFamily={theme.fonts.bold}
              selectText={false}
              leftPadding={headerColumns[key].leftPadding || 0}
            >
              {headerColumns[key].title}
            </Text>
          </ColumnNameContainer>
          {headerColumns[key].sortingHandler &&
            headerColumns[key] &&
            headerColumns[key].direction && (
              <SortIconContainer
                isActive={key === activeColumn}
                onClick={onClickSort(key)}
              >
                {sortIcons[headerColumns[key].direction as SortDirection]}
              </SortIconContainer>
            )}
        </Col>
      );
    });

    if (edit) {
      const checkBox = (
        <Checkbox
          key="header-checkbox"
          checked={checkboxChecked}
          onChange={onCheckboxChanged}
        />
      );
      return [checkBox, ...body];
    }
    return body;
  }, [checkboxChecked, onCheckboxChanged, onClickSort, edit, headerColumns]);

  return (
    <ListHeaderContainer topOffset={topOffset}>
      {renderBody()}
    </ListHeaderContainer>
  );
};
