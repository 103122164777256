import { PropsWithChildren, useEffect, useState } from 'react';
import ReactGa4 from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { env } from '../env';

export const useAnalyticsEventTracker = (category: string) => {
  return (action: string, label?: string) => {
    ReactGa4.event({
      category: category,
      action: action,
      label: label,
    });
  };
};

export const useAnalytic = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGa4.initialize(env.REACT_APP_GOOGLE_TRACK_ID as string);
    setInitialized(true);
  }, []);

  return initialized;
};

type AnalyticsProps = {
  initialized: boolean;
  children?: PropsWithChildren<any>;
};

export const Analytics = ({ initialized, children }: AnalyticsProps) => {
  const location = useLocation();

  const pageView = (path: string) => {
    return ReactGa4.send({ hitType: 'pageview', page: path });
  };

  useEffect(() => {
    if (initialized) {
      pageView(`${location.pathname}${location.search}`);
    }
  }, [initialized, location]);
  return children;
};
