import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';

const DEFAULT_ITEMS_AMOUNT = 10;
const START_OFFSET = 0;

export const useOrganizationQuery = () => {
  const { orgListStore } = useRootStore();
  const {
    orderingColumns,
    filter: { name, filterBySubscription, filterByState },
  } = orgListStore;
  const { t } = useTranslation();

  const setBody = useCallback(
    (offset: number, count: number) => {
      return {
        offset,
        count,
        textToSearch: name,
        filterBySubscription,
        filterByState,
        orderingColumns,
      };
    },
    [name, filterBySubscription, filterByState, orderingColumns]
  );

  const fetchOrgs = useCallback(
    async (loadMore = false) => {
      const body = loadMore
        ? setBody(orgListStore.orgs.length, DEFAULT_ITEMS_AMOUNT)
        : setBody(START_OFFSET, DEFAULT_ITEMS_AMOUNT);
      return await orgListStore.getAll(body, loadMore);
    },
    [orgListStore, setBody]
  );

  const clearOrgs = useCallback(() => {
    orgListStore.clear();
  }, [orgListStore]);

  const showMsgIfFailedFunc = useCallback(
    () => t('admOrgs:Messages:fetchOrgsFailed'),
    [t]
  );
  const showDescIfFailedFunc = useCallback(
    (res) =>
      `${t('admOrgs:Messages:fetchOrgsFailedDescription')}/n ${res.message}}`,
    [t]
  );

  const { loading, loadMore: loadMoreOrgs } = useDataQuery({
    dataFunc: fetchOrgs,
    clearFunc: clearOrgs,
    showDescIfFailedFunc,
    showMsgIfFailedFunc,
  });

  return { loading, loadMoreOrgs };
};
