import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { useRootStore } from '../../../context/storeContext';

const QuickNavigationBarContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;

  & > button {
    margin-right: 15px;
  }

  & .gd-card {
    background: ${({ theme: { colors } }) => colors.thinOrange};

    & .ant-card-body {
      height: 128px;
      display: flex;
    }
  }
`;

export const QuickNavigationBar = observer(() => {
  const { t } = useTranslation();
  const { authorizeStore } = useRootStore();
  const sendAnalytic = useAnalyticsEventTracker('Dashboard Page');

  const history = useHistory();

  const onEdit = useCallback(
    (path) => {
      return () => {
        sendAnalytic(`Edit ${path}`, 'Clicked');
        history.push(`/${path}`);
      };
    },
    [history, sendAnalytic]
  );

  return (
    <QuickNavigationBarContainer>
      {authorizeStore.isAuthorizedToEditUsers() && (
        <Button
          type="primary"
          className="gd-form-button gd-form-button-primary"
          onClick={onEdit('users')}
        >
          {t('dashboard:Labels:navigationButtons:editUsersLbl')}
        </Button>
      )}
      <Button
        type="primary"
        className="gd-form-button gd-form-button-primary"
        onClick={onEdit('groups')}
      >
        {t('dashboard:Labels:navigationButtons:editGroupsLbl')}
      </Button>
      <Button
        type="primary"
        className="gd-form-button gd-form-button-primary"
        onClick={onEdit('guidos')}
      >
        {t('dashboard:Labels:navigationButtons:editGuidosLbl')}
      </Button>
    </QuickNavigationBarContainer>
  );
});
