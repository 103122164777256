import React from 'react';
import { TFunction } from 'react-i18next';
import { IconInt } from '../../../../components/Icons';

export const steps = (translate: TFunction) => [
  {
    title: translate('SignInUpPage:Text:SignUp:Wizard:createAccount'),
    icon: <IconInt icon="CreateUser" className="signup-step-icon" />,
  },
  {
    title: translate('SignInUpPage:Text:SignUp:Wizard:companyDetails'),
    icon: <IconInt icon="Company" className="signup-step-icon" />,
  },
  {
    title: translate('SignInUpPage:Text:SignUp:Wizard:addUser'),
    icon: <IconInt icon="AddUser" className="signup-step-icon" />,
  },
  {
    title: translate('SignInUpPage:Text:SignUp:Wizard:addGroup'),
    icon: <IconInt icon="AddUserGroup" className="signup-step-icon" />,
  },
  {
    title: translate('SignInUpPage:Text:SignUp:Wizard:startGuiding'),
    icon: <IconInt icon="Rocket" className="signup-step-icon" />,
  },
];
