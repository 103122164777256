import { Button, Col, Form, Input, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import {
  getResultAndShowNotification,
  validateConfirmPassword,
  validatePasswordRules,
} from '../../../utils';
import { UserContainer } from '../../Common/styles';
import { RulesContainer } from '../../Identity/components/ResetPassword';
import { SubmitContainer } from '../../Identity/styled';

const ChangePasswordContainer = styled(UserContainer)`
  & .gd-reset-password-form {
    padding-top: 33px;
  }

  & .gd-newpassword {
    margin-bottom: 5px;
  }
`;

export const ChangePassword = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [resetToken, setResetToken] = useState('');

  const history = useHistory();
  const { authStore } = useRootStore();

  const [form] = Form.useForm();

  const user = useMemo(() => {
    return authStore.getUser();
  }, [authStore]);

  useEffect(() => {
    async function getToken() {
      const res = await authStore.getPasswordResetToken(user.id);
      if (res.succeeded) {
        setResetToken(res.data.token);
      }
    }

    getToken();
  }, [user, authStore]);

  const onFinish = useCallback(
    async (values) => {
      const body = {
        userId: user.id,
        resetToken: resetToken,
        password: values.password,
        confirmedPassword: values.confirmPassword,
      };

      setLoading(true);
      await getResultAndShowNotification(
        async () => {
          const res = await authStore.resetPassword(body);
          if (res.succeeded) {
            form.resetFields();
          }
          return res;
        },
        t('identity:ResetPassword:requestSuccess'),
        t('identity:ResetPassword:requestFailed'),
        () => {}
      );
      setLoading(false);
    },
    [form, user, resetToken, t, authStore]
  );

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <ChangePasswordContainer>
      <Col xs={12} sm={20} md={12} lg={12} xl={8} xxl={6}>
        <Row>
          <PageTitle title={t('dashboard:mainTitle')} />
        </Row>
        <Row className="gd-reset-password-form">
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Form.Item
              name="password"
              label={<Text>{t('identity:ResetPassword:newPasswordLbl')}</Text>}
              className="gd-form-item gd-newpassword"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                validatePasswordRules(
                  t('identity:ErrorMessages:PasswordRules')
                ),
              ]}
            >
              <Input.Password
                type="password"
                className="gd-input-item"
                placeholder="Password"
                onChange={async () => {
                  await form.validateFields(['confirmPassword']);
                }}
              />
            </Form.Item>
            <RulesContainer>
              <Text
                fontSize={10}
                fontFamily="montserrat-semi-bold"
                color={colors.lightGreen}
              >
                {t('identity:ResetPassword:passwordRules')}
              </Text>
            </RulesContainer>
            <Form.Item
              name="confirmPassword"
              label={
                <Text>{t('identity:ResetPassword:confirmNewPasswordLbl')}</Text>
              }
              className="gd-form-item"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                validateConfirmPassword,
              ]}
            >
              <Input.Password
                type="password"
                className="gd-input-item"
                placeholder="Confirm Password"
              />
            </Form.Item>
            <SubmitContainer>
              <Button
                type="text"
                className="gd-form-button gd-form-button-text"
                onClick={onCancel}
                disabled={loading}
              >
                {t('dashboard:Labels:FormChangePassword:cancelBtnLbl')}
              </Button>
              <Button
                type="primary"
                disabled={loading}
                loading={loading}
                htmlType="submit"
                className="gd-form-button gd-form-button-primary"
              >
                {t('dashboard:Labels:FormChangePassword:storeBtnLbl')}
              </Button>
            </SubmitContainer>
          </Form>
        </Row>
      </Col>
    </ChangePasswordContainer>
  );
};
