import { Card } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useRootStore } from '../../../context/storeContext';
import { DetailsListsContainer } from '../../Common/styles';
import { GuidoGroupList } from './GuidoGroupList';
import { GuidoUsersList } from './GuidoUsersList';

const GuidoDetailsListContainer = styled(DetailsListsContainer)``;

export const GuidoDetailsList = () => {
  const { guidosListsStore } = useRootStore();
  useEffect(() => {
    return () => {
      guidosListsStore.clear();
    };
  }, [guidosListsStore]);
  return (
    <GuidoDetailsListContainer>
      <Card className="gd-card">
        <GuidoUsersList />
      </Card>
      <Card className="gd-card">
        <GuidoGroupList />
      </Card>
    </GuidoDetailsListContainer>
  );
};
