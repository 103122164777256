import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GdChart } from '../../../components/GdChart';
import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';
import { GraphCardContainer } from './GraphCardContainer';

export const UsersInOrgChart = observer(() => {
  const { t } = useTranslation();

  const { admDashboardStore } = useRootStore();

  const fetchChart = useCallback(
    async (loadMore = false) => {
      return await admDashboardStore.getTopFourOrgsByUserChart();
    },
    [admDashboardStore]
  );

  const { loading } = useDataQuery({
    dataFunc: fetchChart,
  });
  return (
    <GraphCardContainer loading={loading}>
      <GdChart
        type="progress"
        data={admDashboardStore.topFourOrgByUserChart}
        info={t('admDashboard:Label:usersInOrgLbl')}
        progressTitle={t(
          'admDashboard:Label:ProgressCharts:usersProgressTitle'
        )}
      />
    </GraphCardContainer>
  );
});
