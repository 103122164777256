import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../../../context/storeContext';
import { useSubscriptionsQuery } from '../hooks/useOrgSettingsMeta';
import {Command, CommandBar} from "../../../components/CommandBar";
import styled from "styled-components/macro";
import {CardInfoContainer} from "../../Common/styles";

export const OrganizationsCommandBar = observer(() => {
    const { t } = useTranslation();
    
    useSubscriptionsQuery();
    
    const CommandBarContainer = styled(CardInfoContainer)`
      padding-top: 20px;
      text-align: right;
  `;

    const { admDashboardStore } = useRootStore();

    const commands: Command[] = [
        {
            title: t('admOrgs:CommandBar:exportCsv'),
            handler: () => {
                admDashboardStore.downloadOrganizationCSV();
            },
        },
    ];

    return (
        <CommandBarContainer>
            <CommandBar commands={commands}></CommandBar>
        </CommandBarContainer>
    );
});
