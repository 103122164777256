import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterMeta, GdFilterBar } from '../../../components/GdFilterBar';
import { useRootStore } from '../../../context/storeContext';
import { Select } from 'antd';


export const GuidosFilterBar = observer(() => {
  const { t } = useTranslation();

  const { guidoListStore } = useRootStore();

  useEffect(() => {
    return () => {
      guidoListStore.clearFilter();
    };
  }, [guidoListStore]);

  const formMeta: FilterMeta = {
    tittle: {
      span: 6,
      label: t('admGuidos:Label:Filter:nameLbl'),
      placeholder: t('admGuidos:Label:Filter:namePlaceLbl'),
      isSearch: true,
    },
    filterByTenant: {
      span: 12,
      label: t('admGuidos:Label:Filter:tenantLbl'),
      render: (form) => {
        return (
          <Select
            style={{ width: '100%' }}
            className="gd-input-item"
            placeholder={t('admGuidos:Label:Filter:tenantPlaceLbl')}
            notFoundContent={null}
            allowClear
            maxTagCount="responsive"
            optionLabelProp="label"
            onChange={() => {
              form.submit();
            }}
            options={Array.from(guidoListStore.orgMap.keys()).map(
              (jt) => ({
                label: guidoListStore.orgMap.get(jt) ?? '',
                value: jt,
              })
            )}
          />
        );
      },
    }
  };

  const onFilter = useCallback(
    (values) => {
      const filter = {
        ...values,
      };

      console.log(values);

      guidoListStore.setFilter(filter);
    },

    [guidoListStore]
  );

  return (
    <GdFilterBar
      meta={formMeta}
      onFilter={onFilter}
      initialValues={guidoListStore.amountInfo}
    />
  );
});
