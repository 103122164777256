import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { GdCollapse } from '../../../components/GdCollapse';
import { GdList, ItemMode, ListMeta } from '../../../components/GdList';
import { ColumnsMeta, GdListHeader } from '../../../components/GdListHeader';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { IconInt } from '../../../components/Icons';
import Text from '../../../components/Text';
import { EMPTY_STRING, GuidosCollection } from '../../../constants';
import { useRootStore } from '../../../context/storeContext';
import { useDataQueryById } from '../../../hooks/useDataQuery';
import { SortDirection } from '../../../types/commonTypes';
import {
  getResultAndShowNotification,
  setMaxListHeight,
  showLocalDate,
} from '../../../utils';
import { CollapsableListContainer } from '../../Common/styles';
import { PanelBodyContainer } from '../../Users/styled';
import { TeamGuido } from '../stores/teamsListsStore';

const GroupGuidosListContainer = styled(CollapsableListContainer)``;

export const GroupListMeta: ListMeta = {
  name: {
    span: 8,
    render: (item) => {
      return (
        <Text fontSize={12} fontFamily="montserrat-regular">
          {item.name}
        </Text>
      );
    },
  },
  creationDate: {
    span: 8,
    render: (item) => {
      return (
        <Text fontSize={12} fontFamily="montserrat-regular">
          {showLocalDate(item.creationDate as string | number)}
        </Text>
      );
    },
  },
  groups: {
    span: 7,
    render: (item) => {
      return (
        <Text fontSize={12} fontFamily="montserrat-regular">
          {item.groups}
        </Text>
      );
    },
  },
};

export const GroupGuidosList = observer(() => {
  const { t } = useTranslation();
  const { groupId } = useParams<{ groupId: string }>();
  const { teamListsStore, teamsStore, authorizeStore } = useRootStore();
  const { numberOfGuidos } = teamsStore.selectedGroup;

  const [editMode, setEditMode] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const loadAvailable = useCallback(
    async (groupId, body) => {
      await teamListsStore.getAvailableGuidosForTeam(groupId, body);
    },
    [teamListsStore]
  );

  const loadOwned = useCallback(
    async (groupId, body, loadMore) => {
      await teamListsStore.getGuidosForTeam(groupId, body, loadMore);
    },
    [teamListsStore]
  );

  const { loading, loadAll, loadMore, loadAssigned } = useDataQueryById({
    id: groupId,
    loadAvailable,
    loadOwned,
  });

  const sortingHandler = useCallback(
    (order, field) => {
      teamListsStore.sortProperty(GuidosCollection, order, field);
    },
    [teamListsStore]
  );

  const columns: ColumnsMeta = useMemo(() => {
    return {
      name: {
        colspan: 8,
        title: t('groups:Labels:Lists:nameLbl'),
      },
      creationDate: {
        colspan: 8,
        title: t('groups:Labels:Lists:creationDateLbl'),
        sortingHandler,
        direction: SortDirection.Asc,
      },
      users: {
        colspan: 7,
        title: t('groups:Labels:Lists:usersLbl'),
      },
    };
  }, [t, sortingHandler]);

  const onEdit = useCallback(async () => {
    const newMode = !editMode;
    setEditMode(newMode);
    if (newMode) {
      teamListsStore.clearGuidos();
      await loadAll(EMPTY_STRING);
      teamListsStore.setAllChecked(GuidosCollection);
    } else {
      teamListsStore.clearEditState(GuidosCollection);
    }
  }, [loadAll, editMode, teamListsStore]);

  const onSearch = useCallback(
    async (searchText) => {
      teamListsStore.setFilter(GuidosCollection, searchText);
      await loadAll(searchText);
    },
    [loadAll, teamListsStore]
  );

  const onGroupChecked = useCallback(
    (group, checked) => {
      teamListsStore.setChecked(GuidosCollection, group.id, checked);
      teamListsStore.setAllChecked(GuidosCollection);
    },
    [teamListsStore]
  );

  const onCheckAll = useCallback(
    (checked) => {
      teamListsStore.setChecked(GuidosCollection, 0, checked);
    },
    [teamListsStore]
  );

  const onEditCancelHandler = useCallback(() => {
    setEditMode(false);
    teamListsStore.clearEditState(GuidosCollection);
    teamListsStore.setAllChecked(GuidosCollection);
  }, [teamListsStore]);

  const onPanelActive = useCallback(
    async (activePanels: string[]) => {
      teamListsStore.clearGuidos();
      if (activePanels.length) {
        await loadAssigned();
      } else if (editMode) {
        onEditCancelHandler();
      }
    },
    [teamListsStore, loadAssigned, onEditCancelHandler, editMode]
  );

  const getNewGuidosList = useCallback(async () => {
    setEditMode(false);
    await loadAssigned();
    const numberOfGuidos = teamListsStore.guidos.length;
    const totalGuidosSize = teamListsStore.guidos.reduce<number>(
      (acc: number, guido: TeamGuido) => {
        acc += guido.size;
        return acc;
      },
      0
    );
    teamsStore.updateTeamData({ numberOfGuidos, totalGuidosSize });
  }, [teamListsStore, teamsStore, loadAssigned]);

  const onEditSaveHandler = useCallback(async () => {
    setSaving(true);
    teamListsStore.setFilter(GuidosCollection, EMPTY_STRING);
    await getResultAndShowNotification(
      async () => await teamListsStore.updateGuidosAssignedToTeam(groupId),
      t('groups:Labels:Notifications:assignGuidosSuccess'),
      t('groups:Labels:Notifications:assignGuidosFailed'),
      () => {}
    );
    setSaving(false);
    await getNewGuidosList();
  }, [getNewGuidosList, teamListsStore, t, groupId]);

  const { guidosListInfo, guidos, guidoscheckAll } = teamListsStore;
  const isCollectionChanged =
    teamListsStore.isCollectionChanged(GuidosCollection);

  const onLoadMore = useCallback(async () => {
    await loadMore(EMPTY_STRING, editMode, guidos.length);
  }, [loadMore, editMode, guidos]);

  return (
    <GroupGuidosListContainer>
      <GdCollapse
        icon={<IconInt icon="RoadMap" />}
        headerText={t('groups:Labels:Lists:guidosListLbl', {
          number: numberOfGuidos,
        })}
        editable={authorizeStore.isAuthorizedToEditUsersList()}
        showEditPenAlways={authorizeStore.isAuthorizedToEditUsersList()}
        onActive={onPanelActive}
        isInEditeMode={editMode}
        onEdit={onEdit}
        onSearch={onSearch}
        editControls={{
          isSaveDisabled: !isCollectionChanged || isSaving,
          editCancelText: 'Cancel',
          editSaveText: 'Save Changes',
          onEditCancel: onEditCancelHandler,
          onEditSave: onEditSaveHandler,
        }}
      >
        <PanelBodyContainer>
          <GdListHeader
            onCheckBoxTriggered={onCheckAll}
            checkAll={guidoscheckAll}
            edit={editMode}
            columns={columns}
            topOffset={25}
          />
          <GdList
            split
            editable={editMode}
            loadMore={onLoadMore}
            onItemCheck={onGroupChecked}
            itemMode={ItemMode.Basic}
            data={guidos}
            loading={{
              spinning: loading || isSaving,
              size: 'large',
              indicator: <Spin indicator={<GdLoadingIndicator size={12} />} />,
            }}
            columnMeta={GroupListMeta}
            totalCount={guidosListInfo.amountLeft}
            maxHeight={setMaxListHeight<TeamGuido>(guidos, 35)}
            rowHeight={35}
            useMaxHeight
          />
        </PanelBodyContainer>
      </GdCollapse>
    </GroupGuidosListContainer>
  );
});
