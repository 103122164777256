import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { BasePageContainer, CardsDetailsContainer } from '../../Common/styles';
import { UserCommands } from './UserCommands';
import { UserDetailsCard } from './UserInfo/UserDetailsCard';
import { UserDetailsLists } from './UserInfo/UserDetailsLists';

const UserDetailsContainer = styled(BasePageContainer)``;

const UserCardDetailsContainer = styled(CardsDetailsContainer)`
  & .gd-user-details-help-text {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    font-size: 0.8rem;

    & > span {
      padding-right: 5px;
    }
  }
`;

export const UserDetails = () => {
  const { t } = useTranslation();
  const { usersStore } = useRootStore();
  const { userId } = useParams<{ userId: string }>();

  useEffect(() => {
    async function getUserDetails() {
      await Promise.all([
        usersStore.getUserMeta(),
        usersStore.getUserData(userId),
      ]);
    }

    getUserDetails();

    return () => {
      usersStore.clearUser();
    };
  }, [userId, usersStore]);

  return (
    <UserDetailsContainer>
      <Row>
        <UserCommands title={t('users:UserDetails:mainTitle')} />
      </Row>
      <UserCardDetailsContainer>
        <Row className="gd-user-details-help-text">
          <QuestionCircleOutlined />
          <Text fontFamily="montserrat-medium">
            {t('users:UserDetails:helpText')}
          </Text>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <UserDetailsCard />
          </Col>
          <Col span={18} className="gd-details-list">
            <UserDetailsLists />
          </Col>
        </Row>
      </UserCardDetailsContainer>
    </UserDetailsContainer>
  );
};
