import { Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { PageTitle } from '../../components/PageTitle';
import {
  BasePageContainer,
  CommandsContainer as TitleContainer,
} from '../Common/styles';
import { Banner } from './components/Banner';
import { useRootStore } from '../../context/storeContext';

const AcademyPageContainer = styled(BasePageContainer)``;
const ContentContainer = styled.div`
  width: 100%;
  height: 75vh;
  overflow: auto;
  padding-top: 28px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const AcademyPage = () => {
  const { t } = useTranslation();
  const { academyStore } = useRootStore();
  
  return (
    <AcademyPageContainer>
      <Row>
        <TitleContainer>
          <PageTitle title={t('academy:mainTitle')} />
        </TitleContainer>
      </Row>
      <Row>
        <ContentContainer>
          <Row>
            <Banner />
          </Row>
        </ContentContainer>
      </Row>
    </AcademyPageContainer>
  );
};
