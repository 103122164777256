import { makeAutoObservable, runInAction } from 'mobx';

import { Result, RootStore } from '../../../services/rootStore';
import { UserMeta } from '../../Users/stores/usersStore';

// import firebase from "firebase/compat";

export type LoginModel = {
  password: string;
  email: string;
  remember: boolean;
};

export type Token = {
  access_token: string;
  firebase_token: string;
  id: string;
  email: string;
  userName: string;
  companyName: string;
  roles: string[];
  isVerified: boolean;
  issuedOn: string;
  expiresOn: string;
};

export type User = Omit<Token, 'access_token'>;

export type SignUpMeta = {
  jobTitles: { [key: string]: string };
  infoSources: { [key: string]: string };
  userCounts: { [key: string]: string };
  companyBranch: { [key: string]: string };
};

export type BaseStringType = {
  [key: string]: string | null;
};

type ResetPasswordRequest = {
  email: string | null;
};

type ResetToken = {
  token: string;
};

type ResetPassword = {
  resetToken: string | null;
  password: string | null;
  userId: string | null;
} & ResetPasswordRequest;

type ConfirmEmailBase = {
  userId: string | null;
  confirmationToken: string | null;
  added: boolean | null;
};

export type ConfirmEmailInvitedUser = {
  firstName: string | null;
  lastName: string | null;
  password: string | null;
} & ConfirmEmailBase;

export type ConfirmEmailType = ConfirmEmailBase | ConfirmEmailInvitedUser;

type ImageRequest = {
  size: number;
  userIds: string[];
};

type SignedInUser = {
  userName: string;
  companyName: string;
  companyId: string;
  id: string;
  role: string;
  roleId: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isEditor: boolean;
  isViewer: boolean;
  email: string;
};

export class AuthStore {
  userToken: Token | null = null;
  token = '';
  error = '';

  signedInUser: Partial<SignedInUser> = {
    role: '',
  };

  signUpMeta: SignUpMeta = {
    jobTitles: {},
    infoSources: {},
    userCounts: {},
    companyBranch: {},
  };

  accountAvatar: string = '';

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  public getUser(): User {
    const { sessionStore } = this.rootStore;
    const tokenJson = sessionStore.getItem('token');
    const token = JSON.parse(tokenJson);
    const { role, role_id, is_admin, is_super } = JSON.parse(
      atob(token.access_token.split('.')[1])
    );
    this.signedInUser = {
      id: token.id,
      userName: token.userName,
      companyName: token.companyName,
      companyId: token.companyId,
      role,
      roleId: role_id,
      isAdmin: is_admin,
      isSuperAdmin: is_super,
      isViewer: role.toLowerCase() === 'viewer',
      isEditor: role.toLowerCase() === 'editor',
      email: token.email,
    };

    return token as User;
  }

  public updateSignedUserName({
    firstName = '',
    lastName = '',
    email,
  }: {
    firstName?: string;
    lastName?: string;
    email?: string;
  }) {
    const newValue = {
      userName:
        !firstName && !lastName
          ? this.signedInUser.userName || ''
          : `${firstName} ${lastName}`,
      email: email || this.signedInUser.email,
    };
    runInAction(() => {
      this.signedInUser = {
        ...this.signedInUser,
        ...newValue,
      };
    });
  }

  public updateSignedUserCompanyName(companyName: string) {
    this.signedInUser = {
      ...this.signedInUser,
      companyName,
    };
  }

  async getSignUpMeta(): Promise<Result<SignUpMeta | string>> {
    const result = await this.rootStore.ApiClient.get<Result<SignUpMeta>>(
      '/meta/createaccount',
      false
    );
    if (result.succeeded) {
      runInAction(() => {
        this.signUpMeta = result.data;
      });
    }
    return result;
  }

  async signIn(loginModel: LoginModel): Promise<Result<Token | string>> {
    const result = await this.rootStore.ApiClient.post<
      LoginModel,
      Result<Token>
    >('auth/login', loginModel, false);
    if (result.succeeded) {
      runInAction(() => {
        this.userToken = result.data;
        this.token = this.userToken.access_token;
      });
      const singInCount = localStorage.getItem('signInCount');
      if (singInCount === null) {
        localStorage.setItem('signInCount', String(0));
      } else if (Number(singInCount) === 0) {
        const newNumber = String(Number(singInCount) + 1);
        localStorage.setItem('signInCount', newNumber);
      }
      sessionStorage.setItem('token', JSON.stringify(this.userToken));
    }
    return result;
  }

  async requestResetPassword(email: string): Promise<Result<string>> {
    const body = {
      email,
    };
    return await this.rootStore.ApiClient.post<
      ResetPasswordRequest,
      Result<string>
    >('auth/forgotpassword', body, false);
  }

  async resetPassword(body: Partial<ResetPassword>): Promise<Result<string>> {
    return await this.rootStore.ApiClient.post<
      Partial<ResetPassword>,
      Result<string>
    >('auth/resetpassword', body, false);
  }

  async confirmEmail(body: Partial<ConfirmEmailType>): Promise<Result<string>> {
    return await this.rootStore.ApiClient.post<
      Partial<ConfirmEmailType>,
      Result<string>
    >('auth/confirmemail', body, false);
  }

  async confirmEmailChange(
    body: Omit<ConfirmEmailType, 'added' | 'userId'>
  ): Promise<Result<string>> {
    return await this.rootStore.ApiClient.post<
      Partial<ConfirmEmailType>,
      Result<string>
    >('auth/confirmemailchange', body, false);
  }

  async cancelConfirmEmailChange(
    body: Omit<ConfirmEmailType, 'added' | 'userId'>
  ): Promise<Result<string>> {
    return await this.rootStore.ApiClient.post<
      Partial<ConfirmEmailType>,
      Result<string>
    >('auth/CancelChangeEmailRequest', body, false);
  }

  async getPasswordResetToken(userId: string): Promise<Result<ResetToken>> {
    return await this.rootStore.ApiClient.get<Result<ResetToken>>(
      `auth/ResetPasswordToken/${userId}`
    );
  }

  async getAccountAvatar(userId: string) {
    const userIds = [userId];
    const body: ImageRequest = {
      userIds,
      size: 1,
    };
    const result = await this.rootStore.ApiClient.post<
      ImageRequest,
      Result<{ [key: string]: string }>
    >('/image/usersImages', body);
    runInAction(() => {
      this.accountAvatar = result.data[userId];
    });
    return result;
  }

  async getUserMeta(userId: string) {
    const result = await this.rootStore.ApiClient.get<Result<UserMeta>>(
      `/user/userdetails/${userId}`
    );
    if (result.succeeded) {
      const { firstName, lastName } = result.data;
      this.updateSignedUserName({ firstName, lastName });
    }
  }
}
