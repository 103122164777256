import dayjs, { Dayjs } from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';

import { Result, RootStore } from '../../../services/rootStore';
import {
  BaseQuery,
  Event,
  ListResult,
  NoSqlList,
} from '../../../types/commonTypes';
import { showLocalDate, showLocalTime } from '../../../utils';
import { Team } from '../../Groups/stores/teamsStore';
import { Guido } from '../../Guidos/stores/guidosStore';
import { ApplicationUser, GetUsersQuery } from '../../Users/stores/usersStore';
import { EventsMap } from '../types/orgTypes';

const defaultFilter: EventFilter = {
  dateRange: [dayjs().subtract(2, 'w'), dayjs()],
  eventType: null,
  eventAuthor: null,
  subjectId: null,
  subjectType: null,
};

type EventFilter = {
  dateRange: [Dayjs, Dayjs];
  eventType: string | null;
  eventAuthor: string | null;
  subjectType: string | null;
  subjectId: string | null;
};

type SelectOption = {
  label: string;
  value: string;
};

export type LogEvent = {
  date: string;
  time: string;
  eventOwner: string;
  event: string;
  id: string;
};

export type GetEventsRequest = {
  startDate: string;
  endDate: string;
  tenantId: string;
  userPerformedOperationId: string | null;
  eventType: string | null;
  eventSubject: string | null;
  eventSubjectId: string | null;
  continuationToken: string | null;
  itemsCountLoadPerRequest: number;
};

type Payload = GroupEventDto;

type GroupEventDto = {
  id: string;
  groupId: string;
  groupTitle: string;
  creatorId: string;
  creatorName: string;
};
export type SubjectType = 'User' | 'Guido' | 'Group';
type SubjectQuery<T> = T extends 'User' ? GetUsersQuery : BaseQuery;
type SubjectItem<T> = T extends 'User'
  ? ApplicationUser
  : T extends 'Group'
  ? Team
  : Guido;

export class LogEventsStore {
  rootStore: RootStore;
  eventsFilter: EventFilter = defaultFilter;
  eventAuthors: SelectOption[] = [];
  subjects: SelectOption[] = [];
  logEvents: LogEvent[] = [];
  totalEventsCount: number = 0;
  continuationToken: string | null = null;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  clearEvents() {
    this.logEvents = [];
  }

  clearEventsFilter() {
    this.eventsFilter = defaultFilter;
  }

  clearLogFiltersMeta() {
    this.subjects = [];
    this.eventAuthors = [];
  }

  searchEvents(filter: EventFilter) {
    this.eventsFilter = { ...this.eventsFilter, ...filter };
  }

  getEventString(event: Event<Payload>) {
    const { eventType: type } = event;

    const payloadPropName: string = EventsMap[type].sourceProp;
    const destPropName: string | undefined = EventsMap[type].destProp;
    // @ts-ignore
    const destStringPostfix = event.payload[destPropName]
      ? // @ts-ignore
        ` to ${event.payload[destPropName]}`
      : '';
    // @ts-ignore
    return `${EventsMap[type].action} "${event.payload[payloadPropName]}" ${destStringPostfix}`;
  }

  mapEvents(event: Event<Payload>): LogEvent {
    return {
      id: event.id,
      date: showLocalDate(event.eventDateTimeUtc),
      time: showLocalTime(event.eventDateTimeUtc),
      eventOwner: event.currentUserName,
      // @ts-ignore
      event: this.getEventString(event),
    };
  }

  async loadEvents(
    orgId: string,
    body: Partial<GetEventsRequest>,
    loadMore: boolean = false
  ) {
    const query: Partial<GetEventsRequest> = {
      ...body,
      continuationToken: this.continuationToken,
    };
    const result = await this.rootStore.ApiClient.post<
      Partial<GetEventsRequest>,
      Result<NoSqlList<Payload>>
    >('/admin/ActivityLog/Search', query);

    runInAction(() => {
      const events = result.data.events.map(this.mapEvents.bind(this));
      this.logEvents = loadMore ? this.logEvents.concat([...events]) : events;
      this.logEvents.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      
      this.totalEventsCount = result.data.continuationToken
        ? this.logEvents.length + 1
        : this.logEvents.length;

      this.continuationToken = result.data.continuationToken;
    });
  }
  async getUserOptionsByName(searchText: string, tenantId: string) {
    if (!searchText) {
      this.eventAuthors = [];
      return;
    }
    const { transportLayer } = this.rootStore;
    const query: GetUsersQuery = {
      textToSearch: searchText,
      loadImages: false,
      customTenantId: tenantId,
    };
    const result = await transportLayer.fetchUsers(query as GetUsersQuery);

    if (result.succeeded) {
      runInAction(() => {
        this.eventAuthors = result.data.items.map((user) => {
          return {
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          };
        });
      });
    }
  }

  async getSubjectData(
    subjectType: SubjectType | undefined,
    searchText: string,
    tenantId: string
  ) {
    if (!subjectType) return;
    const getSubject: {
      [key in SubjectType]: {
        run: (
          query: BaseQuery
        ) => Promise<Result<ListResult<SubjectItem<typeof subjectType>>>>;
        value: (item: SubjectItem<typeof subjectType>) => string;
      };
    } = {
      User: {
        run: async (query: SubjectQuery<typeof subjectType>) =>
          await this.rootStore.transportLayer.fetchUsers(
            query as GetUsersQuery
          ),
        value: (item) => {
          const user = item as ApplicationUser;
          return `${user.firstName} ${user.lastName}`;
        },
      },
      Guido: {
        run: async (query: SubjectQuery<typeof subjectType>) =>
          await this.rootStore.transportLayer.fetchAllGuidos(query),
        value: (item) => {
          const guido = item as Guido;
          return guido.tittle;
        },
      },
      Group: {
        run: async (query: SubjectQuery<typeof subjectType>) =>
          await this.rootStore.transportLayer.fetchAllTeams(query),
        value: (item) => {
          const team = item as Team;
          return team.name;
        },
      },
    };
    let query: SubjectQuery<typeof subjectType> = {
      textToSearch: searchText,
      customTenantId: tenantId,
      loadImages: false,
    };

    const result = await getSubject[subjectType].run(query);
    if (result.succeeded) {
      runInAction(() => {
        this.subjects = result.data.items.map((x) => ({
          value: x.id,
          label: getSubject[subjectType].value(x),
        }));
      });
    }
  }
}
