import {
  Col,
  Divider,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  Switch,
} from 'antd';
import { ReactNode, useCallback } from 'react';
import styled, { useTheme } from 'styled-components/macro';

import Text from '../../../../components/Text';
import {
  clearDivider,
  gdFlexGeneral,
  gdInputGeneral,
} from '../../../../theme/CssResetStyles';
import { PlainObject } from '../../../Users/stores/usersStore';
import { FeatureType, SettingValue } from '../../stores/objects/SettingsItem';
import DatePicker from "../../../../components/GdDatePicker";
import dayjs from "dayjs";

type InputType = 'boolean' | 'number' | 'selection' | 'unlimited' | 'date' | 'bytes';
export type OptionsObj = { [key: string]: string };

type SettingItemMeta = {
  label: string;
  description: string;
  input: InputType;
  name: FeatureType;
  options?: OptionsObj;
};

export type SectionMeta = {
  title: string;
  settingMetaItems: SettingItemMeta[];

  disabled?: boolean;
};

type Props = {
  meta: SectionMeta[];
  data?: PlainObject;
  form?: FormInstance;

  onFieldChanged?: (name: FeatureType, value: SettingValue) => void;
};

const getDisabledOpacity = (isDisabled: boolean) => {
  return isDisabled ? 0.35 : 1;
};

export const SettingsForm = ({ meta, form, onFieldChanged }: Props) => {
  const theme = useTheme();

  const onFormFieldChanged = useCallback(
    (fields) => {
      const [field] = fields;
      const [name] = field.name;
      onFieldChanged && onFieldChanged(name, field.value);
    },
    [onFieldChanged]
  );

  return (
    <SettingsFormContainer>
      <Form layout={'vertical'} form={form} onFieldsChange={onFormFieldChanged}>
        {meta.map((section, idx) => {
          const textOpacity = getDisabledOpacity(!!section.disabled);
          return (
            <Row
              key={`${section.title}-${idx}`}
              className="gd-subscription-section"
            >
              <Col span={24}>
                <Text opacity={textOpacity} fontFamily={theme.fonts.semiBold}>
                  {section.title}
                </Text>
              </Col>
              <Divider />
              <FormBody sectionMeta={section} />
            </Row>
          );
        })}
      </Form>
    </SettingsFormContainer>
  );
};

type FormBodyProps = {
  sectionMeta: SectionMeta;
};
const FormBody = ({ sectionMeta }: FormBodyProps) => {
  const theme = useTheme();
  return (
    <>
      {sectionMeta.settingMetaItems.map((setting, idx) => {
        const { input, description, name, label, options } = setting;
        
        const textOpacity = getDisabledOpacity(!!sectionMeta.disabled);
        const valuePropName = input === 'boolean' ? 'checked' : 'value';
        return (
          <Row
            align={'middle'}
            justify={'space-between'}
            key={`${name}-${idx}-${idx + 1}`}
            className="gd-subscription-setting"
          >
            <Col className="gd-subscription-meta">
              <Text opacity={textOpacity} fontFamily={theme.fonts.semiBold}>
                {label}
              </Text>
              <Text
                fontSize={11}
                opacity={0.35}
                fontFamily={theme.fonts.semiBold}
              >
                {description}
              </Text>
            </Col>
            <Col className="gd-subscription-meta">
              <Form.Item shouldUpdate noStyle>
                {(form) => {
                  let fieldValue = form.getFieldValue(name);
                  
                  const type: InputType =
                    typeof fieldValue === 'number' && fieldValue < 0
                      ? 'unlimited'
                      : input;

                  return (
                    <Form.Item
                      name={name}
                      className="gd-org-settings-item"
                      valuePropName={valuePropName}
                      required={true}
                    >
                      {getInput({
                        type,
                        options,
                        disabled: !!sectionMeta.disabled,
                      })}
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

type InputProps = {
  type: InputType;
  options?: OptionsObj;
  disabled: boolean;
};

const getInput = ({ type, options = {}, disabled }: InputProps): ReactNode => {
  switch (type) {
    case 'boolean':
      return <Switch disabled={disabled} />;
    case 'selection':
      return (
        <Select
          className="gd-input-item"
          notFoundContent={null}
          disabled={disabled}
          optionLabelProp="label"
          options={Object.keys(options).map((jt) => ({
            label: options[jt],
            value: jt,
          }))}
        />
      );
    case 'date':
      return (
          <DatePicker
              className="gd-input-item gd-input-item-unlimited"
              disabled={disabled}
              bordered={!disabled}
              disabledDate={(current) => { return current && current < dayjs()}}
              clearIcon={false}
              showNow={true}
          />
      );
    case 'unlimited':
      return (
        <InputNumber
          className="gd-input-item gd-input-item-unlimited"
          disabled={disabled}
          formatter={(value) =>
            Number(value) === -1 ? 'Unlimited' : String(value)
          }
          parser={(value) => String(value)}
          bordered={!disabled}
          style={{ width: disabled ? '100px' : '90px' }}
        />
      );
    case 'bytes':
    return (
      // Value will be read and input in GB
      <InputNumber
        min={0 as number}
        className="gd-input-item"
        disabled={disabled}
        controls={false}
        formatter={(value) =>
          (value! / (1000*1000*1000)).toString()
        }
        parser={(value) => Number((parseInt(value!) * (1000*1000*1000)))}
      />
    );
    case 'number':
    default:
      return (
        <InputNumber
          min={0 as number}
          className="gd-input-item"
          disabled={disabled}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          }
          parser={(value) => Number(value!.replace(/\$\s?|(\.*)/g, ''))}
        />
      );
  }
};

const SettingsFormContainer = styled.div`
  & .ant-col {
    &.gd-subscription-meta {
      flex-direction: column;
      ${gdFlexGeneral()}
    }
  }
  & .gd-org-settings-item {
    margin-bottom: 0;
    & .gd-input-item {
      ${gdInputGeneral()}
      & .ant-input-number-input {
        text-align: center;
        font-family: ${({ theme }) => theme.fonts.semiBold};
      }

      & .ant-select-selector {
        ${gdInputGeneral()}
        width: 150px
      }

      &.gd-input-item-unlimited {
        &.ant-input-number-disabled {
          background-color: unset;
        }
      }
    }
  }

  & .ant-divider-horizontal {
    ${clearDivider('5px 0 15px 0')}
  }

  & .gd-subscription-setting:not(:last-of-type) {
    padding-bottom: 10px;
  }
  & .gd-subscription-section:not(:first-of-type) {
    padding-top: 20px;
  }
  & .gd-subscription-setting {
    width: 100%;
  }
`;
