import dayjs, { Dayjs } from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';

import { TransportLayer } from '../../../api/transportLayer';
import { Result, RootStore } from '../../../services/rootStore';
import { capitalizeFirstLetter } from '../../../utils';
import { OrgSubscriptionCardProps } from '../../AdminOrganizations/components/OrgSubscriptionCard';
import { Subscription } from '../../AdminOrganizations/stores/objects/Organization';
import { Feature } from '../../AdminOrganizations/stores/objects/SettingsItem';

export type CompanyDetails = {
  name: string;
  city: string;
  street: string;
  houseNumber: string;
  zip: string;
  companySizeId: string;
  usersOfGuidoAppId: string;
  companyBranchId: string;
  vatId: string;
  logo: { src: string };
  isGuestAllowed: boolean;
  subscription: Subscription;
};

type SubscriptionData = OrgSubscriptionCardProps;

type EditCompanyCommand = Omit<CompanyDetails, 'logo'>;

export class CompanyStore {
  rootStore: RootStore;
  transportLayer: TransportLayer;
  companyDetails: Partial<CompanyDetails> = {
    name: '',
    city: '',
    street: '',
    houseNumber: '',
    zip: '',
    companySizeId: '',
    usersOfGuidoAppId: '',
    companyBranchId: '',
    vatId: '',
    logo: { src: '' },
    isGuestAllowed: false,
    subscription: {
      name: '',
      features: [],
      isDefault: false,
      id: '',
      isActive: false,
      price: '',
    },
  };

  subscriptionData: Partial<SubscriptionData> = {
    planName: '',
    limitStorageSize: 0,
    totalStorageSize: 0,
    limitUsers: 0,
    totalUsers: 0,
    subscriptionEndDate: dayjs(0),
    hasShareCare: false,
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.transportLayer = rootStore.transportLayer;
    makeAutoObservable(this);
  }

  public async getCompanyDetails(companyId: string) {
    const res = await this.transportLayer.fetchCompanyDetails(companyId);
    if (res.succeeded) {
      const usersFeature: Feature = res.data.subscription.features.find(
        (f) => f.featureType === 'UsersLimit'
      )!;
      const storageFeature: Feature = res.data.subscription.features.find(
        (f) => f.featureType === 'StorageSize'
      )!;
      const endDate: Feature = res.data.subscription.features.find(
        (f) => f.featureType === 'SubscriptionEndDate'
      )!;

      const shareCare: Feature = res.data.subscription.features.find(
        (f) => f.featureType === 'ExternalSharing'
      )!;

      runInAction(() => {
        this.subscriptionData = {
          planName: capitalizeFirstLetter(res.data.subscription.name),
          totalUsers: usersFeature.currentValue as number,
          limitUsers: usersFeature.data as number,
          totalStorageSize: Number(storageFeature.currentValue) * 1024,
          limitStorageSize: storageFeature.data as number,
          subscriptionEndDate: endDate.data as Dayjs,
          hasShareCare: shareCare.data as boolean,
        };
        this.companyDetails = { ...res.data, logo: { src: res.data.logo } };
      });
      return res.data;
    }
    return null;
  }

  public async updateCompany(body: EditCompanyCommand, image: File | null) {
    const companyId = this.rootStore.dashboardStore.companyId;
    const requests: Promise<Result<string>>[] = [
      this.rootStore.ApiClient.put<EditCompanyCommand, Result<string>>(
        `/company/updateCompany/${companyId}`,
        body
      ),
    ];

    if (image) {
      const form = new FormData();
      form.append('image', image);
      requests.push(
        this.rootStore.ApiClient.postForm(
          `/image/UpdateCompanyLogo/${companyId}`,
          form
        ).then(() => {
          return {
            succeeded: true,
            message: 'Success',
            failed: false,
            data: '',
          } as Result<string>;
        })
      );
    }
    const res = await Promise.all(requests);
    return res[0];
  }
}
