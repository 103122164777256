import { Card, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { CommandBar } from '../../../../components/CommandBar';
import { GdMetaLabel, GdMetaValue } from '../../../../components/GdMeta';
import Text from '../../../../components/Text';
import { UserControl as UserAvatar } from '../../../../components/UserControl';
import { useRootStore } from '../../../../context/storeContext';
import {
  RoleLabel,
  showCompanyRoleLabel,
  showLocalDate,
  splitCamelCased,
} from '../../../../utils';
import {
  AvatarContainer,
  CardInfoContainer,
  MetaContainer,
} from '../../../Common/styles';

export const UserDetailsCard = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const { usersStore, authorizeStore } = useRootStore();
  const {
    firstName,
    lastName,
    email,
    status,
    createdDateUtc,
    image,
    companyRoleId,
  } = usersStore.selectedUser;
  const usersCommands = [
    {
      title: t('users:UserDetails:UserDetailedCard:editUserBtnLbl'),
      handler: useCallback(() => {
        history.push(`/users/edituser/${usersStore.selectedUser.id}`);
      }, [usersStore, history]),
    },
  ];
  return (
    <CardInfoContainer>
      <Card className="gd-card">
        <Row>
          <AvatarContainer>
            <UserAvatar
              nameFontSize={20}
              name={`${firstName || ''} ${lastName || ''}`}
              email={email || 'Loading...'}
              direction="reverse"
              image={image}
            />
          </AvatarContainer>
        </Row>
        <MetaContainer>
          <Row className="gd-meta-item-row">
            <Col span={8}>
              <GdMetaLabel label="State" />
            </Col>
            <Col span={16}>
              <Text fontSize={15} color={theme.colors.lightGreen}>
                {splitCamelCased(status as string) || 'Loading...'}
              </Text>
            </Col>
          </Row>
          <Row className="gd-meta-item-row">
            <Col span={8}>
              <GdMetaLabel label="Created" />
            </Col>
            <Col span={16}>
              <GdMetaValue
                value={showLocalDate(createdDateUtc as string | number)}
              />
            </Col>
          </Row>
          <Row className="gd-meta-item-row">
            <Col span={8}>
              <GdMetaLabel label="Role" />
            </Col>
            <Col span={16}>
              <GdMetaValue
                value={
                  (companyRoleId &&
                    showCompanyRoleLabel(
                      usersStore.roles[companyRoleId] as RoleLabel
                    )) ||
                  ''
                }
              />
            </Col>
          </Row>
        </MetaContainer>
      </Card>
      {authorizeStore.isAuthorizedToEditUsers() && (
        <Card className="gd-action-card">
          <CommandBar commands={usersCommands} />
        </Card>
      )}
    </CardInfoContainer>
  );
});
