import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { GdCollapse } from '../../../components/GdCollapse';
import { GdList, ItemMode } from '../../../components/GdList';
import { ColumnsMeta, GdListHeader } from '../../../components/GdListHeader';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { IconInt } from '../../../components/Icons';
import { EMPTY_STRING, UsersCollection } from '../../../constants';
import { useRootStore } from '../../../context/storeContext';
import { useDataQueryById } from '../../../hooks/useDataQuery';
import { GuidoUser } from '../../../services/baseUsersStore';
import { SortDirection } from '../../../types/commonTypes';
import { getResultAndShowNotification, setMaxListHeight } from '../../../utils';
import { CollapsableListContainer } from '../../Common/styles';
import { ListColumnMeta } from '../../Groups/components/GroupUserList';
import { PanelBodyContainer } from '../../Users/styled';

const GuidoUsersListContainer = styled(CollapsableListContainer)``;

export const GuidoUsersList = observer(() => {
  const { t } = useTranslation();
  const { guidoId } = useParams<{ guidoId: string }>();
  const { usersStore, guidosListsStore, guidosStore, authorizeStore } =
    useRootStore();

  const [editMode, setEditMode] = useState(false);
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    async function getRoleOptions() {
      await usersStore.getRolesOptions();
    }

    getRoleOptions();
  }, [usersStore]);

  const sortingHandler = useCallback(
    (order, field) => {
      guidosListsStore.sortProperty(UsersCollection, order, field);
    },
    [guidosListsStore]
  );

  const onRoleChanged = useCallback(
    (item, value) => {
      const user = guidosListsStore.users.find((g) => g.id === item.id);
      if (user) {
        guidosListsStore.updateProperty(user, 'role', value, UsersCollection);
      }
    },
    [guidosListsStore]
  );

  const loadAvailable = useCallback(
    async (guidoId, body) => {
      await guidosListsStore.getAvailableGuidoUsers(guidoId, body);
    },
    [guidosListsStore]
  );

  const loadOwned = useCallback(
    async (guidoId, body, loadMore) => {
      await guidosListsStore.getGuidoUsers(guidoId, body, loadMore);
    },
    [guidosListsStore]
  );

  const { loading, loadAll, loadMore, loadAssigned } = useDataQueryById({
    id: guidoId,
    loadAvailable,
    loadOwned,
  });

  const columns: ColumnsMeta = useMemo(() => {
    return {
      name: {
        colspan: 8,
        title: t('groups:Labels:Lists:nameLbl'),
      },
      email: {
        colspan: 8,
        title: t('groups:Labels:Lists:emailLbl'),
        sortingHandler,
        direction: SortDirection.Asc,
      },
      role: {
        colspan: 7,
        title: t('groups:Labels:Lists:roleLbl'),
      },
    };
  }, [t, sortingHandler]);

  const onEdit = useCallback(async () => {
    const newMode = !editMode;
    setEditMode(newMode);
    if (newMode) {
      guidosListsStore.clear();
      guidosListsStore.setAllChecked(UsersCollection);
      await loadAll(EMPTY_STRING);
    } else {
      guidosListsStore.clearEditState(UsersCollection);
    }
  }, [loadAll, editMode, guidosListsStore]);

  const onSearch = useCallback(
    async (searchText) => {
      guidosListsStore.setFilter(UsersCollection, searchText);
      await loadAll(searchText);
    },
    [loadAll, guidosListsStore]
  );

  const onEditCancelHandler = useCallback(() => {
    setEditMode(false);
    guidosListsStore.clearEditState(UsersCollection);
    guidosListsStore.setAllChecked(UsersCollection);
  }, [guidosListsStore]);

  const getNewUsersList = useCallback(async () => {
    setEditMode(false);
    await loadAssigned();
    guidosStore.updateUsersCount(guidosListsStore.users.length);
  }, [guidosListsStore, guidosStore, loadAssigned]);

  const onEditSaveHandler = useCallback(async () => {
    setSaving(true);
    guidosListsStore.setFilter(UsersCollection, EMPTY_STRING);
    await getResultAndShowNotification(
      async () => await guidosListsStore.updateGuidoAssignedUsers(guidoId),
      t('users:Notifications:assignGroupsSuccess'),
      t('users:Notifications:assignGroupsFailed'),
      () => {}
    );
    setSaving(false);
    await getNewUsersList();
  }, [getNewUsersList, t, guidoId, guidosListsStore]);

  const onPanelActive = useCallback(
    async (activePanels: string[]) => {
      guidosListsStore.clear();
      if (activePanels.length) {
        await loadAssigned();
      } else if (editMode) {
        onEditCancelHandler();
      }
    },
    [guidosListsStore, loadAssigned, onEditCancelHandler, editMode]
  );

  const onGroupChecked = useCallback(
    (user, checked) => {
      guidosListsStore.setChecked(UsersCollection, user.id, checked);
      guidosListsStore.setAllChecked(UsersCollection);
    },
    [guidosListsStore]
  );

  const onCheckAll = useCallback(
    (checked) => {
      guidosListsStore.setChecked(UsersCollection, 0, checked);
    },
    [guidosListsStore]
  );

  const { usersListInfo, users, userscheckAll } = guidosListsStore;
  const isCollectionChanged =
    guidosListsStore.isCollectionChanged(UsersCollection);

  const onLoadMore = useCallback(async () => {
    await loadMore(EMPTY_STRING, editMode, users.length);
  }, [loadMore, editMode, users]);

  const isUsersEditable = authorizeStore.isAuthorizedToEditGuido();

  return (
    <GuidoUsersListContainer>
      <GdCollapse
        icon={<IconInt icon="AddUser" />}
        headerText={t('groups:Labels:Lists:userListLbl', {
          number: guidosStore.selectedGuido.assignedUsersCount,
        })}
        extended
        editable={isUsersEditable}
        showEditPenAlways={isUsersEditable}
        onEdit={onEdit}
        isInEditeMode={editMode}
        onActive={onPanelActive}
        onSearch={onSearch}
        editControls={{
          isSaveDisabled: !isCollectionChanged || isSaving,
          editCancelText: 'Cancel',
          editSaveText: 'Save Changes',
          onEditCancel: onEditCancelHandler,
          onEditSave: onEditSaveHandler,
        }}
      >
        <PanelBodyContainer>
          <GdListHeader
            onCheckBoxTriggered={onCheckAll}
            checkAll={userscheckAll}
            edit={editMode}
            columns={columns}
            topOffset={25}
          />
          <GdList
            editable={editMode}
            loadMore={onLoadMore}
            onItemCheck={onGroupChecked}
            split
            itemMode={ItemMode.Basic}
            data={users}
            loading={{
              spinning: loading || isSaving,
              size: 'large',
              indicator: <Spin indicator={<GdLoadingIndicator size={12} />} />,
            }}
            columnMeta={ListColumnMeta(usersStore, editMode, onRoleChanged)}
            totalCount={usersListInfo.amountLeft}
            maxHeight={setMaxListHeight<GuidoUser>(users, 40)}
            rowHeight={40}
            useMaxHeight
          />
        </PanelBodyContainer>
      </GdCollapse>
    </GuidoUsersListContainer>
  );
});
