import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsMeta, GdListHeader } from '../../../components/GdListHeader';
import { useRootStore } from '../../../context/storeContext';
import { SortDirection } from '../../../types/commonTypes';

export const OrganizationsListHeader = observer(() => {
  const { orgListStore } = useRootStore();
  const { t } = useTranslation();
  const sortingHandler = useCallback(
    (order, field) => {
      orgListStore.sortOrgs(order, field);
    },
    [orgListStore]
  );
  const columns: ColumnsMeta = useMemo(() => {
    return {
      name: {
        title: t('admOrgs:ListHeader:nameLbl'),
        colspan: 3,
        leftPadding: 24,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      owner: {
        title: t('admOrgs:ListHeader:ownerLbl'),
        colspan: 3,
        leftPadding: 24,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      subscription: {
        title: t('admOrgs:ListHeader:subscriptionLbl'),
        colspan: 2,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      subscriptionEndDate: {
        title: t('admOrgs:ListHeader:subscriptionEndDateLbl'),
        colspan: 3,
      },
      users: {
        title: t('admOrgs:ListHeader:usersLbl'),
        colspan: 1,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      guidos: {
        title: t('admOrgs:ListHeader:guidosLbl'),
        colspan: 1,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      state: {
        title: t('admOrgs:ListHeader:stateLbl'),
        colspan: 2,
      },
      lastActiveDate: {
        title: t('admOrgs:ListHeader:lastActiveLbl'),
        colspan: 3,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      storage: {
        title: t('admOrgs:ListHeader:storageLbl'),
        colspan: 2,
        sortingHandler,
        direction: SortDirection.Desc,
      },
    };
  }, [sortingHandler, t]);
  return <GdListHeader activeSortField={'storage'} columns={columns} />;
});
