import { useCallback, useState } from 'react';
import { Result } from '../services/rootStore';

export const useLoading = () => {
  const [loading, setLoading] = useState(false);

  const putInProgress = useCallback(async (fn: () => Promise<Result<any>>) => {
    setLoading(true);
    const result = await fn();
    setLoading(false);
    return result;
  }, []);

  return { loading, putInProgress };
};
