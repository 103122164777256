import styled from 'styled-components/macro';
import { AddGroupsStep } from './WizardSteps/AddGroupsStep';
import { CreateCompanyStep } from './WizardSteps/CreateCompanyStep';
import { CreateAccountStep } from './WizardSteps/CreateAccountStep';
import {FinalStep} from "./WizardSteps/FinalStep";
import { InviteUsersStep } from './WizardSteps/InviteUsersStep';

const content = [
  (props: ContentProps) => <CreateAccountStep {...props} />,
  (props: ContentProps) => <CreateCompanyStep {...props} />,
  (props: ContentProps) => <InviteUsersStep {...props} />,
  (props: ContentProps) => <AddGroupsStep {...props} />,
  (props: ContentProps) => <FinalStep {...props} />,
];

export type ContentProps = {
  current: number;
  onNext: () => void;
  onBack: () => void;
};

const ContentContainer = styled.div`
  padding-top: 5%;
  height: 100%;
`;

function Content(props: ContentProps) {
  return <ContentContainer>{content[props.current](props)}</ContentContainer>;
}

export { Content };
