import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { ColumnsMeta, GdListHeader } from '../../../components/GdListHeader';
import { useRootStore } from '../../../context/storeContext';
import { SortDirection } from '../../../types/commonTypes';

export const ListHeader = observer(() => {
  const { usersStore } = useRootStore();

  const sortingHandler = useCallback(
    (order, field) => {
      usersStore.sortUsers(order, field);
    },
    [usersStore]
  );

  const columns: ColumnsMeta = useMemo(() => {
    return {
      name: {
        title: 'Name',
        colspan: 5,
        leftPadding: 24,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      email: {
        title: 'E-Mail',
        colspan: 5,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      guidos: {
        title: 'Guidos',
        colspan: 4,
      },
      status: {
        title: 'Status',
        colspan: 4,
      },
    };
  }, [sortingHandler]);

  return <GdListHeader columns={columns} />;
});
