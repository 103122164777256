import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/macro';

import { PageTitle } from '../../components/PageTitle';
import Text from '../../components/Text';
import {
  BasePageContainer,
  CommandsContainer as TitleContainer,
} from '../Common/styles';
import { GuidosInOrganizationChart } from './components/GuidosInOrganizationChart';
import { OrgActivityLineChart } from './components/OrgActivityLineChart';
import { PeriodPicker } from './components/PeriodPicker';
import { StorageInOrganizationChart } from './components/StorageInOrganizationChart';
import { SubscriptionBarChart } from './components/SubscriptionBarChart';
import { UsersInOrgChart } from './components/UsersInOrgChart';

export const AdminDashboard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <AdminDashboardContainer>
      <Row className="gd-board-row">
        <TitleContainer>
          <PageTitle isSuper title={t('admDashboard:Label:mainTitle')} />
          <PeriodPicker />
        </TitleContainer>
      </Row>
      <Row gutter={16} className="gd-board-row">
        <Col span={12}>
          <OrgActivityLineChart />
        </Col>
        <Col span={12}>
          <SubscriptionBarChart />
        </Col>
      </Row>
      <Row className="gd-board-row">
        <Text fontSize={14} fontFamily={theme.fonts.semiBold}>
          {t('admDashboard:Label:moreStatisticLbl')}
        </Text>
      </Row>
      <Row gutter={8} className="gd-board-row">
        <Col span={8}>
          <UsersInOrgChart />
        </Col>
        <Col span={8}>
          <StorageInOrganizationChart />
        </Col>
        <Col span={8}>
          <GuidosInOrganizationChart />
        </Col>
      </Row>
    </AdminDashboardContainer>
  );
};

const AdminDashboardContainer = styled(BasePageContainer)`
  height: calc(100vh - 214px);
  display: flex;
  flex-direction: column;
  overflow: auto;

  .gd-board-row:nth-of-type(1) {
    flex: 0 1 auto;
  }

  .gd-board-row:nth-of-type(2) {
    flex: 1 3 auto;
    padding-top: 40px;
  }

  .gd-board-row:nth-of-type(3) {
    flex: 0 1 auto;
    padding-bottom: 15px;
  }

  .gd-board-row:nth-of-type(4) {
    flex: 1 1 auto;
  }
`;
