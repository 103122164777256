import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/macro';
import { PageTitle } from '../../../../../components/PageTitle';
import Text from '../../../../../components/Text';
import { useRootStore } from '../../../../../context/storeContext';
import { getCurrentDomain } from '../../../../../utils';
import { ExplanationTextContainer } from '../../../styled';
import { ListType } from '../../hooks/useSignUpListMeta';
import {
  AddListContainer,
  AddListContentContainer,
  CreateAccountButtonsContainer,
} from '../../styled';
import { ContentProps } from '../Content';
import { SignUpList } from './shared/SignUpList';

export const InviteUsersStep = observer(({ onNext, onBack }: ContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { signUpStore } = useRootStore();

  const { users } = signUpStore.signUpRequest;

  const domain = useMemo(() => {
    const { email } = signUpStore.signUpRequest.owner;
    return getCurrentDomain(email as string);
  }, [signUpStore]);

  const onAddNewUser = useCallback(
    (userName) => {
      signUpStore.addToInvitationList(`${userName}@${domain}`);
    },
    [domain, signUpStore]
  );

  const onDeleteUser = useCallback(
    (item) => {
      signUpStore.removeFromInvitation(item.id);
    },
    [signUpStore]
  );

  return (
    <AddListContainer>
      <AddListContentContainer>
        <Row className="gd-sign-up-row">
          <PageTitle title={t('identity:InviteUsersStep:mainTitle')} />
        </Row>
        <Row className="gd-sign-up-row">
          <ExplanationTextContainer>
            <Text fontSize={14} fontFamily={theme.fonts.medium}>
              {t('identity:InviteUsersStep:mainDescription')}
            </Text>
          </ExplanationTextContainer>
        </Row>
        <Row>
          <SignUpList
            validate
            listName={ListType.Users}
            items={users}
            onAddItem={onAddNewUser}
            actions={[
              <DeleteOutlined key="delete-user" onClick={onDeleteUser} />,
            ]}
            domain={`@${domain || 'test.com'}`}
            placeholderText={t('identity:InviteUsersStep:listPlaceHolder')}
          />
        </Row>
        <Row>
          <Col span={24}>
            <CreateAccountButtonsContainer>
              <Button
                type="text"
                className="gd-form-button gd-form-button-text"
                onClick={() => {
                  onBack();
                }}
                disabled={false}
              >
                {t('SignInUpPage:Buttons:Back')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={false}
                className="gd-form-button gd-form-button-primary"
                onClick={() => {
                  onNext();
                }}
              >
                {users.length
                  ? t('identity:InviteUsersStep:Buttons:inviteBtn')
                  : t('identity:InviteUsersStep:Buttons:skipBtn')}
              </Button>
            </CreateAccountButtonsContainer>
          </Col>
        </Row>
      </AddListContentContainer>
    </AddListContainer>
  );
});
