import { RootStore } from '../../../services/rootStore';

export class AuthorizeStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  getSignedInUser() {
    const {
      authStore: { signedInUser },
    } = this.rootStore;
    return signedInUser;
  }

  isAuthorizedToEditCompanyProfile() {
    return this.getSignedInUser().isAdmin as boolean;
  }

  isAuthorizedToUpdatePlan(): boolean {
    return this.getSignedInUser().isAdmin as boolean;
  }

  isAuthorizedToEditUsers(): boolean {
    return this.getSignedInUser().isAdmin as boolean;
  }

  isAuthorizedToViewDashboard() {
    return !(this.getSignedInUser().isViewer as boolean);
  }

  isAuthorizedToAddNewGroup() {
    return !(this.getSignedInUser().isViewer as boolean);
  }

  isAuthorizedToAddNewUser() {
    return this.getSignedInUser().isAdmin as boolean;
  }

  isAuthorizedToDeleteGroup() {
    return !(this.getSignedInUser().isViewer as boolean);
  }

  isAuthorizedToDeleteUser() {
    return this.getSignedInUser().isAdmin as boolean;
  }

  isAuthorizedToEditGroup() {
    return !(this.getSignedInUser().isViewer as boolean);
  }

  isAuthorizedToEditUsersList() {
    return !(this.getSignedInUser().isViewer as boolean);
  }

  isAuthorizedToEditGuidosList() {
    return !(this.getSignedInUser().isViewer as boolean);
  }

  isAuthorizedToEditGroupsList() {
    return !(this.getSignedInUser().isViewer as boolean);
  }

  isAuthorizedToAddNewGuido() {
    return !(this.getSignedInUser().isViewer as boolean);
  }

  isAuthorizedToDeleteGuido() {
    return !(this.getSignedInUser().isViewer as boolean);
  }

  isAuthorizedToEditGuido() {
    return !(this.getSignedInUser().isViewer as boolean);
  }
}
