import dayjs, { Dayjs } from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import { RangeValue } from 'rc-picker/lib/interface';

import { TransportLayer } from '../../../api/transportLayer';
import { ChartData } from '../../../components/GdChart';
import { Result, RootStore } from '../../../services/rootStore';
import { capitalizeFirstLetter } from '../../../utils';
import { OrgMetrics } from '../hooks/useOrgMetricsMapping';

type Period = {
  date: [Dayjs, Dayjs];
};

export type NumbersSummary = {
  totalTenantsCount: number;
  totalUsersCount: number;
  newTenantsCount: number;
  totalGuidosSize: number;
} & { [key: string]: number };

type DateRange = {
  from: string;
  to: string;
};

export type NumbersSummaryRequest = DateRange;

export type ChartDataRequest = {
  category: string;
} & DateRange;

export type OrgsChartResponse = {
  chart: {
    items: ChartData[];
  };
};

const defaultPeriod = (): Period => ({
  date: [dayjs().subtract(28, 'd'), dayjs()],
});

export class AdmDashboardStore {
  rootStore: RootStore;
  transportLayer: TransportLayer;
  period: Period = defaultPeriod();
  orgSummary: NumbersSummary = {
    totalTenantsCount: 0,
    newTenantsCount: 0,
    totalUsersCount: 0,
    totalGuidosSize: 0,
  };
  orgActivityChart: ChartData[] = [];
  topFourOrgByUserChart: ChartData[] = [];
  topFourOrgByGuidosChart: ChartData[] = [];
  orgsBySubscriptionChart: ChartData[] = [];
  orgsByStorageChart: ChartData[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.transportLayer = rootStore.transportLayer;
    this.period = defaultPeriod();
    makeAutoObservable(this);
  }

  setDefaultPeriod() {
    this.period = defaultPeriod();
  }

  setPeriod(dates: RangeValue<Dayjs>) {
    console.log('period');
    this.period = {
      date:
        dates === null
          ? defaultPeriod().date
          : [dates[0] as Dayjs, dates[1] as Dayjs],
    };
  }

  getCategoryByKey(key: OrgMetrics) {
    const categories: { [key in OrgMetrics]: string } = {
      totalTenantsCount: 'OrganizationsCount',
      newTenantsCount: 'NewOrganizationsCount',
      totalUsersCount: 'UsersCount',
      totalGuidosSize: 'StorageUsed',
    };
    return categories[key];
  }

  async downloadOrganizationCSV() {
    const blob = await this.transportLayer.generateOrganisationCSV();
    const fileUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = 'organizationList.csv';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  private getDates(period: Period) {
    return [
      this.period.date[0].toISOString(),
      this.period.date[1].toISOString(),
    ];
  }
  get timeRange(): DateRange {
    const [from, to] = this.getDates(this.period);
    return {
      from,
      to,
    };
  }

  async getNumbersSummary(period: Period) {
    const request: NumbersSummaryRequest = this.timeRange;
    const response = await this.rootStore.ApiClient.post<
      NumbersSummaryRequest,
      Result<NumbersSummary>
    >('/admin/Dashboard/SummaryValuesByCategories', request);

    if (response.succeeded) {
      runInAction(() => {
        this.orgSummary = response.data;
      });
    }
  }

  async getChartDataByCategory(key: OrgMetrics) {
    const category = this.getCategoryByKey(key);
    const result = await this.transportLayer.fetchChartValuesByCategory({
      ...this.timeRange,
      category,
    });

    if (result.succeeded) {
      runInAction(() => {
        const chartValues = result.data.values;
        this.orgActivityChart = Object.keys(chartValues).map((key) => {
          return {
            x: dayjs(key).format('D MMM'),
            y: chartValues[key],
          };
        });
      });
    }
    return result;
  }

  async getStorageSizeDistribution(period: Period) {
    const [from, to] = this.getDates(period);
    const sizeCategory = this.getCategoryByKey('totalGuidosSize');
    const result = await this.transportLayer.fetchChartValuesByCategory({
      from,
      to,
      category: sizeCategory,
    });
    if (result.succeeded) {
      runInAction(() => {
        const chartValues = result.data.values;
        this.orgsByStorageChart = Object.keys(chartValues).map((key) => {
          return {
            x: dayjs(key).format('D.MM'),
            y: chartValues[key] / 1024 / 1024,
          };
        });
      });
    }
    return result;
  }

  async getTopFourOrgsByUserChart() {
    const result = await this.transportLayer.fetchOrgUsersChartValues();

    if (result.succeeded) {
      runInAction(() => {
        this.topFourOrgByUserChart = result.data.chart.items;
      });
    }
    return result;
  }

  async getTopFourOrgsByGuidosChart() {
    const result = await this.transportLayer.fetchOrgGuidosChartValues();

    if (result.succeeded) {
      runInAction(() => {
        this.topFourOrgByGuidosChart = result.data.chart.items;
      });
    }
    return result;
  }

  async getOrganizationSubscriptionsChart() {
    const result = await this.transportLayer.fetchOrgSubscriptionsChartValues();

    if (result.succeeded) {
      runInAction(() => {
        this.orgsBySubscriptionChart = result.data.chart.items.map((item) => ({
          ...item,
          y: capitalizeFirstLetter(item.y as string),
        }));
      });
    }
    return result;
  }
}
