import styled from 'styled-components/macro';
import { BasePageContainer } from '../Common/styles';
import { Row } from 'antd';
import { GroupsCommandPanel } from './components/GroupsCommandPanel';
import { useTranslation } from 'react-i18next';
import { GroupsFilterBar } from './components/GroupsFilterbar';
import { GroupsListHeader } from './components/GroupsListHeader';
import { GroupsList } from './components/GroupsList';
import { useRootStore } from '../../context/storeContext';
import { useEffect } from 'react';

const GroupsPageContainer = styled(BasePageContainer)``;

export const GroupsPage = () => {
  const { t } = useTranslation();

  const { teamsStore } = useRootStore();

  useEffect(() => {
    return () => {
      teamsStore.clearTeams();
    };
  }, [teamsStore]);

  return (
    <GroupsPageContainer>
      <Row>
        <GroupsCommandPanel title={t('groups:Labels:mainTitle')} />
      </Row>
      <Row gutter={8}>
        <GroupsFilterBar />
      </Row>
      <Row>
        <GroupsListHeader />
      </Row>
      <Row>
        <GroupsList />
      </Row>
    </GroupsPageContainer>
  );
};
