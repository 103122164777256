import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Col, Row, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { GdEditableInput } from '../../../components/GdEditableInput';
import { GdMetaLabel, GdMetaValue } from '../../../components/GdMeta';
import { useRootStore } from '../../../context/storeContext';
import { formatBytes, showLocalDate } from '../../../utils';
import { CardInfoContainer, MetaContainer } from '../../Common/styles';

const GuidoDetailsCardContainer = styled(CardInfoContainer)``;

export const GuidoDetailsCard = observer(() => {
  const { t } = useTranslation();
  const { guidosStore, authorizeStore } = useRootStore();
  const { guidoId } = useParams<{ guidoId: string }>();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmGuido');

  const [isNameEdited, setNameEdited] = useState(false);

  const {
    tittle,
    createdDateUtc,
    lastUpdatedDateUtc,
    size,
    cretedBy,
    published,
  } = guidosStore.selectedGuido;

  const onChangeGuidoName = useCallback(
    (e) => {
      guidosStore.setNewGuidoName(e.target.value as string);
    },
    [guidosStore]
  );

  const onEndNameEdit = useCallback(async () => {
    setNameEdited(false);
    const result = await guidosStore.updateGuidoDetails(guidoId as string);
    if (result?.failed) {
      notification.error({
        message: t('guidos:Details:updatedGuidoNameFailed'),
        description: result.message,
        placement: 'bottomRight',
        duration: 2,
      });
    } else {
      sendAnalyticEvent(
        'Update guido name',
        `Guido ${guidoId} ${guidosStore.selectedGuido.tittle}`
      );
    }
  }, [sendAnalyticEvent, t, guidosStore, guidoId]);

  const setEditMode = useCallback(async () => {
    if (isNameEdited) {
      await onEndNameEdit();
    }

    setNameEdited(!isNameEdited);
  }, [isNameEdited, onEndNameEdit]);

  return (
    <GuidoDetailsCardContainer>
      <Card className="gd-card">
        <Row>
          <GdEditableInput
            value={tittle || ''}
            setEditMode={setEditMode}
            onChangeName={onChangeGuidoName}
            isNameEdited={isNameEdited}
            onEndNameEdit={onEndNameEdit}
            editable={authorizeStore.isAuthorizedToEditGuido()}
          />
        </Row>
        <MetaContainer>
          <Row className="gd-meta-item-row">
            <Col span={11}>
              <GdMetaLabel label={t('guidos:Details:sizeLbl')} />
            </Col>
            <Col span={12}>
              <GdMetaValue value={formatBytes(Number(size))} />
            </Col>
          </Row>
          <Row className="gd-meta-item-row">
            <Col span={11}>
              <GdMetaLabel label={t('guidos:Details:creatorLbl')} />
            </Col>
            <Col span={12}>
              <GdMetaValue value={cretedBy} />
            </Col>
          </Row>
          <Row className="gd-meta-item-row">
            <Col span={11}>
              <GdMetaLabel label={t('guidos:Details:creationDateLbl')} />
            </Col>
            <Col span={12}>
              <GdMetaValue
                value={showLocalDate(createdDateUtc as string | number)}
              />
            </Col>
          </Row>
          <Row className="gd-meta-item-row">
            <Col span={11}>
              <GdMetaLabel label={t('guidos:Details:lastUpdatedLbl')} />
            </Col>
            <Col span={12}>
              <GdMetaValue
                value={showLocalDate(lastUpdatedDateUtc as string | number)}
              />
            </Col>
          </Row>
          <Row className="gd-meta-item-row">
            <Col span={11}>
              <GdMetaLabel label={t('guidos:Details:publishedLbl')} />
            </Col>
            <Col span={12}>
              {published ? <CheckOutlined /> : <CloseOutlined />}
            </Col>
          </Row>
        </MetaContainer>
      </Card>
    </GuidoDetailsCardContainer>
  );
});
