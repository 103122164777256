import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useRootStore } from '../../../context/storeContext';
import { BasePageContainer, CardsDetailsContainer } from '../../Common/styles';
import { GroupDetailsCard } from './GroupDetailsCard';
import { GroupDetailsLists } from './GroupDetailsLists';
import { GroupsCommandPanel } from './GroupsCommandPanel';

const GroupDetailsContainer = styled(BasePageContainer)``;

export const GroupDetails = () => {
  const { t } = useTranslation();
  const { teamsStore } = useRootStore();
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    async function getGroupDetails() {
      await teamsStore.getGroupData(groupId);
    }

    getGroupDetails();
    return () => {
      teamsStore.clearSelectedGroup();
    };
  }, [groupId, teamsStore]);

  return (
    <GroupDetailsContainer>
      <Row>
        <GroupsCommandPanel title={t('groups:Labels:mainTitle')} />
      </Row>
      <CardsDetailsContainer>
        <Row gutter={24}>
          <Col span={6}>
            <GroupDetailsCard />
          </Col>
          <Col span={18} className="gd-details-list">
            <GroupDetailsLists />
          </Col>
        </Row>
      </CardsDetailsContainer>
    </GroupDetailsContainer>
  );
};
