import { Button, Col, Modal, Row } from 'antd';
import React, { ReactChild, ReactChildren, useMemo } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components/macro';

import { ButtonsContainer } from '../domain/Common/styles';
import { PageTitle } from './PageTitle';

const DEFAULT_MODAL_WIDTH = 416;

const GdModalContainer = styled.div``;
export const ModalContentContainer = styled.div<Pick<Props, 'useCss'>>`
  padding: 30px;
  ${({ useCss }) => (useCss ? useCss() : '')}
`;

const ModalContentBody = styled.div`
  padding-top: 20px;
`;

type Props = {
  isVisible: boolean;
  disableButtons?: boolean;
  isClosable: boolean;
  onCancel?: () => void;
  onCloseModal?: () => void;
  cancelButtonText?: string;
  onOkHandler?: () => void;
  okButtonText?: string;
  renderBody?: React.ReactElement;
  title: string | ReactChild | ReactChild[];
  useCss?: () => FlattenSimpleInterpolation;
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  width?: string | number;
};

export const GdModal: React.FC<Props> = ({
  isVisible,
  isClosable,
  disableButtons,
  onCloseModal,
  cancelButtonText,
  onOkHandler,
  okButtonText,
  renderBody,
  onCancel,
  title,
  useCss,
  children,
  width = DEFAULT_MODAL_WIDTH,
}) => {
  const TitleComponent = useMemo(() => {
    return typeof title === 'string' ? (
      <PageTitle title={title as string} />
    ) : (
      title
    );
  }, [title]);
  return (
    <GdModalContainer>
      <Modal
        centered
        destroyOnClose
        visible={isVisible}
        closable={isClosable}
        footer={false}
        className="gd-modal"
        onCancel={onCloseModal || onCancel}
        width={width}
      >
        <ModalContentContainer useCss={useCss}>
          <Row>
            <Col span={24}>{TitleComponent}</Col>
          </Row>
          <Row>
            <Col span={24}>
              <ModalContentBody>{renderBody || children}</ModalContentBody>
            </Col>
          </Row>
          {(onCloseModal || onOkHandler) && (
            <ButtonsContainer>
              {onCloseModal && (
                <Button
                  type="text"
                  className="gd-form-button gd-form-button-text"
                  onClick={onCloseModal}
                  disabled={disableButtons}
                >
                  {cancelButtonText || 'Cancel'}
                </Button>
              )}
              {onOkHandler && (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="gd-form-button gd-form-button-primary"
                  onClick={onOkHandler}
                  disabled={disableButtons}
                  loading={disableButtons}
                >
                  {okButtonText || 'OK'}
                </Button>
              )}
            </ButtonsContainer>
          )}
        </ModalContentContainer>
      </Modal>
    </GdModalContainer>
  );
};
