import { Route, useRouteMatch } from 'react-router-dom';
import { AdminDashboard } from '../../AdminDashboard';
import { AdminOrganizations } from '../../AdminOrganizations';
import {OrgDetails} from "../../AdminOrganizations/components/OrgDetails";
import { AdminGuidos } from '../../AdminGuidos';

export const GuidoSuperAdminRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Route exact path={`${path}/dashboard`}>
        <AdminDashboard />
      </Route>
      <Route exact path={`${path}/organizations`}>
        <AdminOrganizations />
      </Route>
      <Route exact path={`${path}/organizations/:orgId`}>
        <OrgDetails />
      </Route>
      <Route exact path={`${path}/guidos`}>
        <AdminGuidos />
      </Route>
    </>
  );
};
