import { Breadcrumb, Button } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { IconInt } from '../../../components/Icons';

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 46px;
`;

export const BreadCrumbs = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const breadcrumbNameMap = useMemo(
    () => ({
      users: t('landingPage:Labels:usersMenu'),
      guidos: t('landingPage:Labels:guidosMenu'),
      groups: t('landingPage:Labels:groupsMenu'),
      'groups/addgroup': t('landingPage:Labels:addGroup'),
      academy: t('landingPage:Labels:academyMenu'),
      'users/adduser': t('landingPage:Labels:addUser'),
      'users/edituser': t('landingPage:Labels:editUser'),
      changepassword: t('landingPage:Labels:changePassword'),
      'company/edit': t('landingPage:Labels:companyEdit'),
    }),
    [t]
  );

  const pathSnippets = history.location.pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      let url = `${pathSnippets.slice(0, index + 1).join('/')}`;

      // @ts-ignore
      let title = breadcrumbNameMap[url]; // || pathSnippets[pathSnippets.length - 1];
      if (title) {
        title = title.toLowerCase().includes('edit') ? `${title}` : title;
        url = title.toLowerCase().includes('edit')
          ? `${url}/${pathSnippets[pathSnippets.length - 1]}`
          : url;
        return (
          <Breadcrumb.Item key={url}>
            <Link to={`/${url}`}>{title}</Link>
          </Breadcrumb.Item>
        );
      }
      return null;
    })
    .filter((b) => b);

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const isDashboardPage = useMemo(
    () => pathSnippets.length === 1 && pathSnippets[0] === 'dashboard',
    [pathSnippets]
  );

  return (
    <NavContainer>
      {!isDashboardPage && (
        <Button
          className="navigation-back"
          type="link"
          onClick={onBack}
          icon={<IconInt icon="ArrowBack" />}
        >
          Back
        </Button>
      )}
      <Breadcrumb className="navigation-breadcrumbs">
        <Breadcrumb.Item>
          {t('landingPage:Labels:Breadcrumbs:dashboard')}
        </Breadcrumb.Item>
        {extraBreadcrumbItems}
      </Breadcrumb>
    </NavContainer>
  );
};
