import { notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { Command, CommandBar } from '../../../components/CommandBar';
import { GdModal } from '../../../components/GdModal';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { CommandsContainer } from '../../Common/styles';

type Props = {
  title: string;
};

export const UserCommands = observer(({ title }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const { usersStore, authorizeStore } = useRootStore();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmUsers');

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const usersCommands: Command[] = [];

  const addGroupHandler = useCallback(() => {
    history.push('/groups/addgroup');
  }, [history]);

  const addUserHandler = useCallback(() => {
    history.push('/users/adduser');
  }, [history]);

  if (!userId && authorizeStore.isAuthorizedToAddNewGroup()) {
    usersCommands.push({
      title: t('users:Commands:addUserGroupLbl'),
      handler: addGroupHandler,
    });
  }

  if (authorizeStore.isAuthorizedToAddNewUser()) {
    usersCommands.push({
      title: t('users:Commands:addUserLbl'),
      handler: addUserHandler,
    });

    if (
      usersStore.selectedUser &&
      Object.entries(usersStore.selectedUser).length &&
      authorizeStore.isAuthorizedToDeleteUser()
    ) {
      const onDeleteUser = () => {
        setShowModal(true);
      };
      usersCommands.push({
        title: t('users:Commands:deleteUserLbl'),
        handler: onDeleteUser,
        isDelete: true,
      });
    }
  }

  const onCloseDeleteModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const sendAnalytic = useCallback(
    (isSuccess: boolean | undefined) => {
      const state = isSuccess ? 'Success' : 'Failed';

      sendAnalyticEvent(
        'Delete User',
        `${state}|User ${usersStore.selectedUser?.name} ${usersStore.selectedUser?.id} deleted`
      );
    },
    [sendAnalyticEvent, usersStore]
  );

  const handleDeleteUser = useCallback(async () => {
    setLoading(true);
    const res =
      usersStore.selectedUser &&
      (await usersStore.deleteUser(usersStore.selectedUser.id as string));
    if (res?.failed) {
      notification.error({
        message: t('users:Notifications:deleteUserFailed'),
        description: res.message,
        placement: 'bottomRight',
        duration: 2,
      });
    }
    sendAnalytic(res.succeeded);
    history.push('/users');
    setLoading(false);
    setShowModal(false);
  }, [sendAnalytic, history, t, usersStore]);

  return (
    <>
      <CommandsContainer>
        <PageTitle title={title} />
        <CommandBar commands={usersCommands} />
      </CommandsContainer>
      <GdModal
        isVisible={showModal}
        disableButtons={loading}
        isClosable={!loading}
        onCloseModal={onCloseDeleteModal}
        onOkHandler={handleDeleteUser}
        cancelButtonText={t('users:AddUser:Labels:cancelAddUserlbl')}
        okButtonText={t('users:Labels:deleteUserBtnLbl')}
        title={t('users:Labels:deleteUserModalTitle')}
        renderBody={
          <Text fontSize={16} fontFamily="montserrat-regular">
            {usersStore.selectedUser &&
              t('users:deleteUserText', {
                user: usersStore.selectedUser.email,
              })}
          </Text>
        }
      />
    </>
  );
});
