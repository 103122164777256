import { Progress, Row } from 'antd';
import { ReactElement } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import styled, { DefaultTheme, useTheme } from 'styled-components/macro';

import Text from './Text';

const ChartBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & .gd-barchart-item {
    flex: 1 2 auto;
    padding-bottom: 12px;
    align-items: baseline;
    justify-content: center;

    & > span {
      display: inline-flex;
      align-items: center;
      height: 100%;
    }
  }

  & .gd-barchart-item:nth-of-type(2) {
    padding-bottom: 0;
  }
`;
export type AxisValue = string | number;
export type ChartData = { y: AxisValue; x: AxisValue };

type ChartsProps = {
  data: ChartData[];
  theme: DefaultTheme;
  label?: string;
};

const GdBar = ({ data, theme }: ChartsProps) => {
  const { colors } = theme;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        layout="vertical"
        barCategoryGap={5}
        margin={{
          top: 5,
          right: 20,
          left: 5,
          bottom: 5,
        }}
      >
        <XAxis
          type="number"
          tickLine={false}
          axisLine={false}
          tick={{ fill: colors.mediumGrey, fontSize: 10 }}
        />
        <YAxis
          type="category"
          allowDataOverflow={true}
          dataKey="y"
          tickLine={false}
          axisLine={true}
          tick={{ fill: colors.black, fontSize: 10 }}
        />
        <CartesianGrid horizontal={false} />
        <Bar dataKey="x" fill={colors.strongBlue} barSize={20} />
      </BarChart>
    </ResponsiveContainer>
  );
};

const GdLine = ({ data, theme }: ChartsProps) => {
  const { colors } = theme;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: -20,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="x"
          tickLine={false}
          stroke={colors.mediumGrey}
          tick={{ fill: colors.mediumGrey, fontSize: 10 }}
        />
        <YAxis
          orientation="right"
          stroke={colors.mediumGrey}
          tickLine={false}
          axisLine={false}
          tick={{ fill: colors.mediumGrey, fontSize: 10 }}
        ></YAxis>
        <Line
          type="linear"
          dataKey="y"
          stroke={colors.strongBlue}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const GdProgressContainer = styled.div`
  width: 100%;

  & .gd-progress-chart-title-line {
    padding-bottom: 12px;
  }

  & .gd-progress-chart-title-line,
  & .gd-progress-chart-item-line {
    justify-content: space-between;

    & > span:nth-of-type(1) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 75%;
    }
  }

  & .ant-progress-line {
    font-size: 0.8em;
  }
`;

const GdProgress = ({ data, theme, label }: ChartsProps) => {
  const maxVal = Math.max(...data.map((item) => Number(item.x)));
  const MAX = Math.ceil(maxVal + maxVal * 0.05);
  const labels = ['Organization', label];
  return (
    <GdProgressContainer key={`${label}-${maxVal}`}>
      <Row className="gd-progress-chart-title-line" key={`${label}`}>
        {labels.map((lbl, idx) => (
          <Text
            key={`${idx}-${lbl}`}
            fontSize={11}
            fontFamily={theme.fonts.medium}
            color={theme.colors.black048}
          >
            {lbl}
          </Text>
        ))}
      </Row>
      {data.map((item, idy) => {
        const val = (Number(item.x) / MAX) * 100;
        const labels = [item.y, item.x];
        return (
          <>
            <Row
              key={`${idy + 2}-${item.y}-${item.x}`}
              className="gd-progress-chart-item-line"
            >
              {labels.map((lbl, idz) => (
                <Text
                  title={lbl as string}
                  key={`${idy}-${idz}-${lbl}`}
                  fontSize={12}
                  fontFamily={theme.fonts.semiBold}
                >
                  {lbl}
                </Text>
              ))}
            </Row>
            <Row key={`${idy}-${idy + Number(item.x)}-${item.y}-${item.x}`}>
              <Progress
                key={`${idy}-${item.y}-${item.x}`}
                percent={val}
                showInfo={false}
                strokeWidth={3}
                strokeColor={theme.colors.strongBlue}
              />
            </Row>
          </>
        );
      })}
    </GdProgressContainer>
  );
};

const getChart = (type: string, props: ChartsProps) => {
  const chartComponents: {
    [key: string]: ({ data, theme, label }: ChartsProps) => JSX.Element;
  } = {
    line: GdLine,
    bar: GdBar,
    progress: GdProgress,
  };
  const Component = chartComponents[type];
  return <Component {...props} />;
};

type GdChartProps = {
  type: 'line' | 'bar' | 'progress';
  data: ChartData[];
  info: string | ReactElement[] | ReactElement;
  progressTitle?: string;
  loading?: boolean;
};

export const GdChart = ({ type, data, info, progressTitle }: GdChartProps) => {
  const theme = useTheme();

  return (
    <ChartBody>
      {typeof info === 'string' ? (
        <Row className="gd-barchart-item">
          <Text fontSize={12} fontFamily={theme.fonts.semiBold}>
            {info}
          </Text>
        </Row>
      ) : (
        <Row className="gd-barchart-item">{info}</Row>
      )}
      <Row className="gd-barchart-item">
        {getChart(type, { data, theme, label: progressTitle })}
      </Row>
    </ChartBody>
  );
};
