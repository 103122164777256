import { Card, Col, Divider, Row } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { GdMetaLabel, GdMetaValue } from '../../../components/GdMeta';
import { GdStateBadge } from '../../../components/GdStateBadge';
import { UserControl as UserAvatar } from '../../../components/UserControl';
import { useRootStore } from '../../../context/storeContext';
import { clearDivider } from '../../../theme/CssResetStyles';
import { showLocalDate } from '../../../utils';
import {
  AvatarContainer,
  CardInfoContainer,
  MetaContainer,
} from '../../Common/styles';
import { FeaturesLimits, UsersByRoles } from '../stores/objects/Organization';
import { OrgDetailsDto } from '../stores/orgListStore';

const OrgDetailsCardContainer = styled(CardInfoContainer)`
  ${MetaContainer} {
    width: 100%;

    & .gd-meta-item-row {
      padding-bottom: 16px;
    }
  }

  & .gd-meta-divider {
    ${clearDivider()}
  }
`;

export const OrgMetaDataCard = observer(() => {
  const { t } = useTranslation();

  const { orgListStore } = useRootStore();
  const { selectedOrg } = orgListStore;

  const {
    name,
    lastLogin,
    activity,
    owner,
    managers,
    editors,
    viewers,
    guidosCount,
    teamsCount,
  } = selectedOrg.dataAsObject as OrgDetailsDto & UsersByRoles;

  const { viewerLimit, groupsLimit, guidosLimit, managersLimit, editorsLimit } =
    selectedOrg.featuresLimits as FeaturesLimits;

  // const orgCommands = [
  //   {
  //     title: t('admOrgs:ListMenu:signInToAppLbl'),
  //     handler: useCallback(() => {}, []),
  //   },
  // ];

  return (
    <OrgDetailsCardContainer>
      <Card className="gd-card">
        <Row>
          <AvatarContainer>
            <UserAvatar
              nameFontSize={20}
              name={name as string}
              direction="reverse"
              image={undefined}
            />
          </AvatarContainer>
          <MetaContainer>
            <Row className="gd-meta-item-row">
              <Col span={8}>
                <GdMetaLabel label={t('admOrgs:ListHeader:stateLbl')} />
              </Col>
              <Col span={15}>
                <GdStateBadge state={activity} />
              </Col>
            </Row>
            <Row className="gd-meta-item-row">
              <Col span={8}>
                <GdMetaLabel label={t('admOrgs:ListHeader:lastActiveLbl')} />
              </Col>
              <Col span={15}>
                <GdMetaValue
                  value={showLocalDate(dayjs(lastLogin).valueOf())}
                />
              </Col>
            </Row>
            <Row className="gd-meta-item-row">
              <Col span={8}>
                <GdMetaLabel label={t('admOrgs:ListHeader:ownerLbl')} />
              </Col>
              <Col span={15}>
                <GdMetaValue value={owner} />
              </Col>
            </Row>
            <Divider className="gd-meta-divider" />
            <Row className="gd-meta-item-row">
              <Col span={8}>
                <GdMetaLabel label={t('admOrgs:ListHeader:managersLbl')} />
              </Col>
              <Col span={15}>
                <GdMetaValue
                  postFix={managersLimit as string}
                  value={Number(managers)}
                />
              </Col>
            </Row>
            <Row className="gd-meta-item-row">
              <Col span={8}>
                <GdMetaLabel label={t('admOrgs:ListHeader:editorsLbl')} />
              </Col>
              <Col span={15}>
                <GdMetaValue
                  postFix={editorsLimit as string}
                  value={Number(editors)}
                />
              </Col>
            </Row>
            <Row className="gd-meta-item-row">
              <Col span={8}>
                <GdMetaLabel label={t('admOrgs:ListHeader:viewersLbl')} />
              </Col>
              <Col span={15}>
                <GdMetaValue
                  postFix={viewerLimit as string}
                  value={Number(viewers)}
                />
              </Col>
            </Row>
            <Row className="gd-meta-item-row">
              <Col span={8}>
                <GdMetaLabel label={t('admOrgs:ListHeader:guidosLbl')} />
              </Col>
              <Col span={15}>
                <GdMetaValue
                  value={Number(guidosCount)}
                  postFix={guidosLimit as string}
                />
              </Col>
            </Row>
            <Row className="gd-meta-item-row">
              <Col span={8}>
                <GdMetaLabel label={t('admOrgs:ListHeader:groupsLbl')} />
              </Col>
              <Col span={15}>
                <GdMetaValue
                  value={Number(teamsCount)}
                  postFix={groupsLimit as string}
                />
              </Col>
            </Row>
          </MetaContainer>
        </Row>
      </Card>
      {/*<Card className="gd-action-card">*/}
      {/*  <CommandBar commands={orgCommands} />*/}
      {/*</Card>*/}
    </OrgDetailsCardContainer>
  );
});
