import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components/macro';

import Text from '../../../../../../components/Text';
import { validateEmail } from '../../../../../../utils';
import { ListType } from '../../../hooks/useSignUpListMeta';

const ITEMS_LIMIT = 4;
export const DEFAULT_ITEMS_LIMIT = 5;

type SuffixProps = {
  preActionText?: string;
  action: () => void;
};

export type Actions =
  | JSX.Element
  | ((group: {
      [key: string]: string | number | boolean | any;
    }) => JSX.Element);

export type SignUpListProps<T> = {
  placeholderText?: string;
  validate: boolean;
  domain?: string;
  items: T;
  onAddItem?: (item: string) => void;
  actions: Actions[];
  listName: ListType;
};

const SuffixContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  & .anticon-plus {
    padding-right: 3px;

    & > svg {
      width: 22px;
      height: 22px;
    }
  }
`;

const SuffixText = styled.span`
  border-right: 2px solid ${({ theme }) => theme.colors.mediumGrey};
  padding-right: 10px;
  margin-right: 10px;
`;

const PreSuffixText = ({ text }: { text: string }) => {
  const theme = useTheme();
  return (
    <SuffixText>
      <Text
        color={theme.colors.black048}
        fontSize={15}
        fontFamily={theme.fonts.medium}
      >
        {text}
      </Text>
    </SuffixText>
  );
};

const Suffix = ({ preActionText, action }: SuffixProps) => {
  return (
    <SuffixContainer>
      {preActionText && <PreSuffixText text={preActionText} />}
      <PlusOutlined onClick={action} />
    </SuffixContainer>
  );
};

type QuickFormProps<T> = {
  domain?: string;
  items: T;
  onAddItem?: (item: string) => void;
  validate: boolean;
  placeholderText?: string;
};

export const AddListItemControl = <
  T extends { [key: string]: string | number | boolean | any }[]
>({
  onAddItem,
  domain,
  items,
  validate,
  placeholderText,
}: QuickFormProps<T>) => {
  const [form] = Form.useForm();
  const onSubmitValue = useCallback(async () => {
    const value = await form.validateFields(['name']);
    if (
      value.name &&
      onAddItem &&
      !value.errorFields &&
      items.length <= ITEMS_LIMIT
    ) {
      onAddItem(value.name);
      form.resetFields();
    }
  }, [items, onAddItem, form]);

  const validateInput = useCallback(
    ({ getFieldValue }) => ({
      validator(_: RuleObject, value: StoreValue) {
        const newVal: string = `${getFieldValue('name')}${domain}`;
        if (!value || validateEmail(newVal)) {
          const contains = items.some((item) => item.name === newVal);
          if (contains) {
            return Promise.reject(new Error('Duplicated email!'));
          }
          return Promise.resolve();
        }
        return Promise.reject(new Error('Invalid email!'));
      },
    }),
    [items, domain]
  );

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => {}}
      onSubmitCapture={onSubmitValue}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            className="gd-form-item"
            name="name"
            rules={validate ? [validateInput] : []}
          >
            <Input
              autoFocus
              className="gd-input-item"
              placeholder={placeholderText}
              suffix={<Suffix action={onSubmitValue} preActionText={domain} />}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
