import { sessionStore, SessionStore } from '../hooks/useSessionStore';
import { Token } from '../domain/Identity/stores/authStore';
import { Result } from '../services/rootStore';
import * as H from 'history';

export class RestClient {
  sessionStore: SessionStore;
  history: H.History<H.LocationState>;

  constructor(history: H.History<H.LocationState>) {
    this.sessionStore = sessionStore;
    this.history = history;
  }

  setHistory(history: H.History<H.LocationState>) {
    this.history = history;
  }

  fallbackToSignInIf401(response: Response) {
    if (response.status === 401) {
      this.history && this.history.push('/signin');
    }
  }

  getHeaders(addauth: boolean = true, useJson: boolean = true) {
    const usersToken: Token = JSON.parse(this.sessionStore.getItem('token'));
    const headers: HeadersInit = useJson
      ? {
          'Content-Type': 'application/json;charset=utf-8',
        }
      : {};

    if (usersToken && addauth) {
      headers['Authorization'] = `Bearer ${usersToken.access_token}`;
    }
    return headers;
  }

  async post<Tin, Tout>(
    url: string,
    body: Tin,
    addAuth: boolean = true
  ): Promise<Tout> {
    const result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.getHeaders(addAuth),
    });

    this.fallbackToSignInIf401(result);
    const res = (await result.json()) as Tout;
    //@ts-ignore
    res.statusCode = result.status;
    return res;
  }
  
  async postDownload<Tin>(
      url: string,
      body: Tin,
      addAuth: boolean = true
  ): Promise<Blob> {
    const result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.getHeaders(addAuth),
    });

    this.fallbackToSignInIf401(result);
    return result.blob();
  }

  async postForm(url: string, formData: FormData) {
    const result = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        ...this.getHeaders(true, false),
      },
    });
    this.fallbackToSignInIf401(result);
  }

  async put<Tin, Tout>(
    url: string,
    body: Tin,
    addAuth: boolean = true
  ): Promise<Tout> {
    const result = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: this.getHeaders(addAuth),
    });
    this.fallbackToSignInIf401(result);

    const res = (await result.json()) as Tout;
    //@ts-ignore
    res.statusCode = result.status;
    return res;
  }

  async get<Tout>(url: string, addAuth: boolean = true): Promise<Tout> {
    const result = await fetch(url, {
      method: 'GET',
      headers: this.getHeaders(addAuth),
    });
    this.fallbackToSignInIf401(result);
    return (await result.json()) as Tout;
  }

  async delete(url: string, addAuth: boolean = true): Promise<Result<string>> {
    const result = await fetch(url, {
      method: 'DELETE',
      headers: this.getHeaders(addAuth),
    });
    const res: Result<string> = {
      data: 'Delete failed',
      message: 'Delete failed',
      succeeded: false,
      failed: true,
    };
    this.fallbackToSignInIf401(result);
    if (result.status === 204) {
      res.succeeded = true;
      res.message = 'Success';
      res.failed = false;
      res.data = '';
    }
    if (result.status === 403) {
      const data = (await result.json()) as Result<string>;
      res.message = data.message;
    }
    return res;
  }
}
