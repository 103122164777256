import { action, makeObservable, observable, runInAction } from 'mobx';

import {
  BaseEntity,
  ModifiedElement,
  listDefault,
} from '../../../services/BaseListsStore';
import {
  BaseUsersStore,
  UpdateAssignedUsersRequest,
  UserAssignedToTeamItem,
} from '../../../services/baseUsersStore';
import { Result, RootStore } from '../../../services/rootStore';
import {
  BaseQuery,
  ListResult,
  SortDirection,
} from '../../../types/commonTypes';
import { findKeyByValue, setItemsCache } from '../../../utils';

export type User = {
  name: string;
  email: string;
  role: string;
} & BaseEntity;

export type TeamGuido = {
  name: string;
  creationDate: string | number;
  groups: number;
  size: number;
} & BaseEntity;

export type UsersResponseDto = {
  userId: string;
  roleId: string;
  firstName: string;
  lastName: string;
  email: string;
  isGlobalAdmin: boolean;
};

export type GuidosResponseDto = {
  id: string;
  tittle: string;
  createdDateUtc: string;
  assigned: boolean;
  size: number;
};

type AssignedUsersRequest = {
  onlyAssignedUsers: boolean;
  assignedUsersFirst: boolean;
};

type AssignedGuidoRequest = {
  assignedOnly: boolean;
  assignedFirst: boolean;
};

type GuidoAssignedToTeamItem = {
  guidoId: string;
};

type UpdateAssignedGuidosToTeamRequest = {
  assignedGuidos: ModifiedElement<GuidoAssignedToTeamItem>[];
};

export class TeamsListsStore extends BaseUsersStore {
  rootStore: RootStore;

  guidosListInfo = listDefault;
  guidoscheckAll: boolean = false;
  guidosSearchText: string = '';
  guidos: TeamGuido[] = [];
  guidosCache: TeamGuido[] = [];

  constructor(_rootStore: RootStore) {
    super();
    this.rootStore = _rootStore;
    makeObservable(this, {
      guidos: observable,
      guidoscheckAll: observable,
      guidosSearchText: observable,
      guidosListInfo: observable,
      getTeamUsers: action,
      getAvailableTeamUsers: action,
      updateTeamAssignedUsers: action,
      getAvailableGuidosForTeam: action,
      getGuidosForTeam: action,
    });
  }

  clearGuidos() {
    this.guidos = [];
    this.guidosCache = [];
  }

  public createUserItem(
    user: UsersResponseDto,
    idx: number,
    isOriginal: boolean
  ) {
    return {
      id: user.userId,
      changed: false,
      checked: isOriginal,
      name: `${user.firstName || ''} ${user.lastName || ''}`,
      role: user.roleId,
      original: isOriginal,
      email: user.email,
      isGlobalAdmin: user.isGlobalAdmin,
      disabled: user.isGlobalAdmin,
    };
  }

  public async getTeamUsers(
    groupId: string,
    payload: BaseQuery,
    loadMore: boolean = false
  ) {
    const body: AssignedUsersRequest = {
      ...payload,
      onlyAssignedUsers: true,
      assignedUsersFirst: false,
    };
    const res = await this.rootStore.ApiClient.post<
      AssignedUsersRequest,
      Result<ListResult<UsersResponseDto>>
    >(`/team/assignedUsers/${groupId}`, body);
    if (res.succeeded) {
      runInAction(() => {
        this.setUsersData(res.data, loadMore);
      });
    }
  }

  public createGuidoItem(gd: GuidosResponseDto): TeamGuido {
    return {
      id: gd.id,
      changed: false,
      checked: gd.assigned,
      name: gd.tittle,
      original: gd.assigned,
      creationDate: gd.createdDateUtc,
      groups: 0,
      size: gd.size,
    };
  }

  public async getGuidosForTeam(
    groupId: string,
    payload: BaseQuery,
    loadMore: boolean = false
  ) {
    const body: AssignedGuidoRequest = {
      ...payload,
      assignedFirst: false,
      assignedOnly: true,
    };
    const res = await this.rootStore.ApiClient.post<
      AssignedGuidoRequest,
      Result<ListResult<GuidosResponseDto>>
    >(`/guido/GuidosAssignedToTeam/${groupId}`, body);
    if (res.succeeded) {
      runInAction(() => {
        const { totalAmount, amountLeft, amount, hasNext } = res.data;
        this.guidosListInfo = { totalAmount, amountLeft, amount, hasNext };
        const guidos = res.data.items.map((gd) => this.createGuidoItem(gd));
        this.guidos = loadMore ? [...this.guidos, ...guidos] : guidos;
        this.setAllChecked('guidos');
        this.sortProperty('guidos', SortDirection.Asc, 'creationDate');
      });
    }
  }

  public async getAvailableTeamUsers(groupId: string, payload: BaseQuery) {
    const payloadWithSearch = {
      ...payload,
      textToSearch: this.usersSearchText,
    };
    const body: AssignedUsersRequest = {
      ...payloadWithSearch,
      onlyAssignedUsers: false,
      assignedUsersFirst: true,
    };
    const res = await this.rootStore.ApiClient.post<
      AssignedUsersRequest,
      Result<ListResult<UsersResponseDto>>
    >(`/team/assignedUsers/${groupId}`, body);
    if (res.succeeded) {
      runInAction(() => {
        this.setUserCache(res.data, payloadWithSearch);
      });
    }
  }

  public async getAvailableGuidosForTeam(groupId: string, payload: BaseQuery) {
    const payloadWithSearch = {
      ...payload,
      textToSearch: this.guidosSearchText,
    };
    const body: AssignedGuidoRequest = {
      ...payloadWithSearch,
      assignedFirst: true,
      assignedOnly: false,
    };

    const res = await this.rootStore.ApiClient.post<
      AssignedGuidoRequest,
      Result<ListResult<GuidosResponseDto>>
    >(`/guido/GuidosAssignedToTeam/${groupId}`, body);
    if (res.succeeded) {
      runInAction(() => {
        const { totalAmount, amountLeft, amount, hasNext } = res.data;
        this.guidosListInfo = { totalAmount, amountLeft, amount, hasNext };
        const availableGuidos = res.data.items.map((gd) =>
          this.createGuidoItem(gd)
        );

        const { newValues, newCache } = setItemsCache(
          this.guidosCache,
          availableGuidos,
          !!payloadWithSearch.textToSearch
        );

        this.guidosCache = newCache;
        this.guidos = newValues;

        this.setAllChecked('guidos');
        this.sortProperty('guidos', SortDirection.Asc, 'creationDate');
      });
    }
  }

  private getChangedUsers(defaultRole: string) {
    const body: UpdateAssignedUsersRequest = {
      assignedUsers: this.getChangedItems<UserAssignedToTeamItem, User>(
        'users',
        (user) => ({
          userId: user.id,
          roleId: (user as User).role || defaultRole,
        })
      ),
    };
    return body;
  }

  public async updateTeamAssignedUsers(groupId: string) {
    const defaultRole: string = findKeyByValue(
      this.rootStore.usersStore.roles,
      'Viewer'
    ) as string;
    const body = this.getChangedUsers(defaultRole);
    const result = await this.rootStore.ApiClient.put<
      UpdateAssignedUsersRequest,
      Result<string>
    >(`/team/modifyAssignedUsers/${groupId}`, body);

    if (result.succeeded) {
      this.cleanUsersAfterUpdate();
    }

    return result;
  }

  public getChangedGuidos() {
    const body: UpdateAssignedGuidosToTeamRequest = {
      assignedGuidos: this.getChangedItems<GuidoAssignedToTeamItem, TeamGuido>(
        'guidos',
        (guido) => ({
          guidoId: guido.id,
        })
      ),
    };
    return body;
  }

  public async updateGuidosAssignedToTeam(
    groupId: string
  ): Promise<Result<string>> {
    const body = this.getChangedGuidos();

    const result = await this.rootStore.ApiClient.put<
      UpdateAssignedGuidosToTeamRequest,
      Result<string>
    >(`/team/assignGuidos/${groupId}`, body);

    if (result.succeeded) {
      runInAction(() => {
        this.guidosCache = [];
        this.guidos = this.guidosCache;
        this.origValues = new Map<
          string,
          Map<string, number | string | boolean>
        >();
        this.guidosListInfo = listDefault;
      });
    }

    return result;
  }
}
