import { action, makeObservable, observable } from 'mobx';
import { AmountInfo } from '../domain/Users/stores/usersStore';

export class BaseStore<T extends { [key: string]: any }> {
  amountInfo: AmountInfo = {
    amount: 0,
    totalAmount: 0,
    hasNext: false,
    amountLeft: 0,
  };

  filter: Partial<T> = {};

  constructor() {
    makeObservable(this, {
      amountInfo: observable,
      setFilter: action,
    });
  }

  setFilter(newFilter: T) {
    this.filter = { ...newFilter };
  }
}
