import { Tabs } from 'antd';
import { ReactChild, ReactChildren } from 'react';
import styled from 'styled-components/macro';

const GdTabsContainer = styled.div`
  & .ant-tabs-top > .ant-tabs-nav::before {
    right: 65%;
    border-bottom: unset;
  }
  & .gd-tabs {
    & .ant-tabs-tab-btn[aria-selected='true'] {
      color: ${({ theme: { colors } }) => colors.strongBlue};
    }

    & .ant-tabs-ink-bar {
      background: ${({ theme: { colors } }) => colors.strongBlue};
    }

    & .ant-tabs-tab:hover {
      color: unset;
    }
  }
`;

type TabItem = {
  title: string;
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
};

type Props = {
  items: TabItem[];
};
export const GdTabs = ({ items }: Props) => {
  return (
    <GdTabsContainer>
      <Tabs className="gd-tabs">
        {items.map((tab, index) => {
          return (
            <Tabs.TabPane tab={tab.title} key={index}>
              {tab.children}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </GdTabsContainer>
  );
};
