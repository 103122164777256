import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GdCollapse } from '../../../../components/GdCollapse';
import { GdList, ItemMode } from '../../../../components/GdList';
import { GdListHeader } from '../../../../components/GdListHeader';
import { GdLoadingIndicator } from '../../../../components/GdLoadingIndicator';
import { IconInt } from '../../../../components/Icons';
import { GroupsCollection } from '../../../../constants';
import { useRootStore } from '../../../../context/storeContext';
import { useDataQueryById } from '../../../../hooks/useDataQuery';
import {
  getResultAndShowNotification,
  setMaxListHeight,
} from '../../../../utils';
import { CollapsableListContainer } from '../../../Common/styles';
import { useUserGroupsMeta } from '../../hooks/useUserGroupsMeta';
import { TeamGroup } from '../../stores/userListsStore';
import { PanelBodyContainer } from '../../styled';

export const UserGroupsList = observer(() => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();
  const { usersStore, userListsStore, authorizeStore } = useRootStore();
  const { teams } = usersStore.selectedUser;

  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    async function getRoleOptions() {
      await usersStore.getRolesOptions();
    }

    getRoleOptions();
  }, [usersStore]);

  const [editMode, setEditMode] = useState(false);
  const { columnMeta, listMeta } = useUserGroupsMeta(editMode);

  const loadAvailable = useCallback(
    async (userId, body) => {
      await userListsStore.getAvailableTeams(userId, body);
    },
    [userListsStore]
  );

  const loadOwned = useCallback(
    async (userId, body, loadMore) => {
      await userListsStore.getUserTeams(userId, body, loadMore);
    },
    [userListsStore]
  );

  const { loading, loadAll, loadMore, loadAssigned } = useDataQueryById({
    id: userId,
    loadAvailable,
    loadOwned,
  });

  const onEdit = useCallback(async () => {
    const newMode = !editMode;
    setEditMode(newMode);
    if (newMode) {
      userListsStore.clearGroups();
      userListsStore.setAllChecked(GroupsCollection);
      await loadAll('');
    } else {
      userListsStore.clearEditState(GroupsCollection);
    }
  }, [loadAll, editMode, userListsStore]);

  const onSearch = useCallback(
    async (searchText) => {
      userListsStore.setFilter(GroupsCollection, searchText);
      await loadAll(searchText);
    },
    [loadAll, userListsStore]
  );

  const onGroupChecked = useCallback(
    (group, checked) => {
      userListsStore.setChecked(GroupsCollection, group.id, checked);
      userListsStore.setAllChecked(GroupsCollection);
    },
    [userListsStore]
  );

  const onCheckAll = useCallback(
    (checked) => {
      userListsStore.setChecked(GroupsCollection, 0, checked);
    },
    [userListsStore]
  );

  const onEditCancelHandler = useCallback(() => {
    setEditMode(false);
    userListsStore.clearEditState(GroupsCollection);
    userListsStore.setAllChecked(GroupsCollection);
  }, [userListsStore]);

  const getNewGroupsList = useCallback(async () => {
    setEditMode(false);
    await loadAssigned();
    usersStore.updateTeamsCount(userListsStore.groups.length);
  }, [userListsStore, usersStore, loadAssigned]);

  const onEditSaveHandler = useCallback(async () => {
    setSaving(true);
    userListsStore.setFilter(GroupsCollection, '');
    await getResultAndShowNotification(
      async () => await userListsStore.updateUserAssignedTeams(userId),
      t('users:Notifications:assignGroupsSuccess'),
      t('users:Notifications:assignGroupsFailed'),
      () => {}
    );
    setSaving(false);
    await getNewGroupsList();
  }, [getNewGroupsList, t, userListsStore, userId]);

  const onPanelActive = useCallback(
    async (activePanels: string[]) => {
      userListsStore.clearGroups();
      if (activePanels.length) {
        await loadAssigned();
      } else if (editMode) {
        onEditCancelHandler();
      }
    },
    [loadAssigned, onEditCancelHandler, editMode, userListsStore]
  );

  const { groupsListInfo, groups, groupscheckAll } = userListsStore;

  const isCollectionChanged =
    userListsStore.isCollectionChanged(GroupsCollection);

  const onLoadMore = useCallback(async () => {
    await loadMore('', editMode, groups.length);
  }, [loadMore, editMode, groups]);

  return (
    <CollapsableListContainer>
      <GdCollapse
        icon={<IconInt icon="AddUserGroup" />}
        headerText={t('users:UserDetails:Lists:groupListLbl', {
          number: teams,
        })}
        editable={authorizeStore.isAuthorizedToEditUsers()}
        showEditPenAlways={authorizeStore.isAuthorizedToEditUsers()}
        extended
        onEdit={onEdit}
        isInEditeMode={editMode}
        onActive={onPanelActive}
        onSearch={onSearch}
        editControls={{
          isSaveDisabled: !isCollectionChanged || isSaving,
          editCancelText: 'Cancel',
          editSaveText: 'Save Changes',
          onEditCancel: onEditCancelHandler,
          onEditSave: onEditSaveHandler,
        }}
      >
        <PanelBodyContainer>
          <GdListHeader
            onCheckBoxTriggered={onCheckAll}
            checkAll={groupscheckAll}
            edit={editMode}
            columns={columnMeta}
            topOffset={25}
          />
          <GdList
            editable={editMode}
            loadMore={onLoadMore}
            onItemCheck={onGroupChecked}
            split
            itemMode={ItemMode.Basic}
            data={groups}
            loading={{
              spinning: loading || isSaving,
              size: 'large',
              indicator: <Spin indicator={<GdLoadingIndicator size={12} />} />,
            }}
            columnMeta={listMeta}
            totalCount={groupsListInfo.amountLeft}
            maxHeight={setMaxListHeight<TeamGroup>(groups, 40)}
            rowHeight={40}
            useMaxHeight
          />
        </PanelBodyContainer>
      </GdCollapse>
    </CollapsableListContainer>
  );
});
