import { groupEvents } from './GroupEvents';
import { guidoEvents } from './GuidoEvents';
import { subscription } from './SubscriptionEvents';
import { tenantEvents } from './TenantEvents';
import { userEvents } from './UserEvents';

export type EventType =
  | 'GroupCreatedEvent'
  | 'GroupNameUpdatedEvent'
  | 'GroupDeletedEvent'
  | 'UserDeletedEvent'
  | 'UserCreatedEvent';

export type EventMeta = {
  action: string;
  sourceProp: string;
  destProp?: string;
};

export type EventMapType = {
  [key in EventType | string]: EventMeta;
};

export const RED = 'Red';
export const YELLOW = 'Yellow';
export const GREEN = 'Green';

export const ActivityIndicator = {
  [RED]: RED,
  [YELLOW]: YELLOW,
  [GREEN]: GREEN,
};

export const EventsMap: EventMapType = {
  ...subscription,
  ...userEvents,
  ...groupEvents,
  ...guidoEvents,
  ...tenantEvents,
};
