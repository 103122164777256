import { Avatar, Menu, Spin, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { GdList, ItemMode, ListMeta } from '../../../components/GdList';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { GdModal } from '../../../components/GdModal';
import Text from '../../../components/Text';
import { DEFAULT_ITEMS_COUNT } from '../../../constants';
import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';
import { SortDirection } from '../../../types/commonTypes';
import {
  buildImageUrlFromBase64,
  setAvatarLetters,
  splitCamelCased,
} from '../../../utils';
import { ListContainer } from '../../Common/styles';
import { ApplicationUser } from '../stores/usersStore';

type AvatarProps = {
  hasImage: boolean;
  name: string;
  imageBase64: string;
  isCurrentUser: boolean;
  isGuest: boolean;
};

const YouPointer = styled.span<{ isCurrentUser: boolean }>`
  color: ${({ theme: { colors }, isCurrentUser }) =>
    isCurrentUser ? colors.lightGreen : colors.strongBlue};
  background-color: transparent !important;
`;

const UserAvatar = ({
  hasImage,
  name,
  imageBase64,
  isCurrentUser,
  isGuest = false,
}: AvatarProps) => {
  const { t } = useTranslation();
  const AvatarComp = () =>
    hasImage ? (
      <Avatar src={buildImageUrlFromBase64(imageBase64 as string)} />
    ) : (
      <Avatar>{setAvatarLetters(name as string)}</Avatar>
    );

  const getPointerText = useCallback(() => {
    if (isCurrentUser) return t('users:Self');
    if (isGuest) return t('users:Guest');
    return '';
  }, [isCurrentUser, isGuest, t]);

  return (
    <>
      <AvatarComp />
      {`${name}`}&nbsp;
      <YouPointer isCurrentUser={isCurrentUser}>{getPointerText()}</YouPointer>
    </>
  );
};

export const UserList = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmUsers');
  const { usersStore, authorizeStore } = useRootStore();
  const { filter } = usersStore;

  const [showModal, setShowModal] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const fetchUsers = useCallback(
    async (loadMore = false) => {
      const body = {
        offset: loadMore ? usersStore.tenantUsers.length : 0,
        count: DEFAULT_ITEMS_COUNT,
        orderingColumns: {
          firstName: SortDirection.Desc,
        },
        ...filter,
        textToSearch: usersStore.filter.nameOrEmail,
      };
      return await usersStore.getUsers(body, loadMore);
    },
    [usersStore, filter]
  );

  const showMsgIfFailedFunc = useCallback(
    () => t('users:Notifications:fetchUsersFailed'),
    [t]
  );
  const showDescIfFailedFunc = useCallback(() => t('users:fetchFailed'), [t]);
  const clearFunc = useCallback(() => usersStore.clearUser(), [usersStore]);

  const { loading, loadMore: loadMoreUsers } = useDataQuery({
    dataFunc: fetchUsers,
    showDescIfFailedFunc,
    showMsgIfFailedFunc,
    clearFunc,
  });

  const [selectedUser, setSelectedUser] = useState<ApplicationUser | null>(
    null
  );

  const handleSelectUser = useCallback(
    (user) => {
      usersStore.setSelectedUser(user.id);
      history.push(`/users/${user.id}`);
    },
    [usersStore, history]
  );

  const sendAnalytic = useCallback(
    (isSuccess: boolean | undefined) => {
      const state = isSuccess ? 'Success' : 'Failed';

      sendAnalyticEvent(
        'Delete User',
        `${state}|User ${selectedUser?.name} ${selectedUser?.id} deleted`
      );
    },
    [sendAnalyticEvent, selectedUser]
  );

  const handleDeleteUser = useCallback(async () => {
    setDeleteInProgress(true);
    const res = selectedUser && (await usersStore.deleteUser(selectedUser.id));
    if (res?.failed) {
      notification.error({
        message: t('users:Notifications:deleteUserFailed'),
        description: res.message,
        placement: 'bottomRight',
        duration: 2,
      });
    }
    sendAnalytic(res?.succeeded);
    setDeleteInProgress(false);
    setShowModal(false);
  }, [sendAnalytic, t, selectedUser, usersStore]);

  const showDeleteConfirmationModal = useCallback(async (user) => {
    setShowModal(true);
    setSelectedUser(user);
  }, []);

  const handleDeleteMenuOption = useCallback(
    (user) => {
      return showDeleteConfirmationModal.bind(this, user);
    },
    [showDeleteConfirmationModal]
  );
  const onEditHandler = useCallback(
    (user) => {
      history.push(`/users/edituser/${user.id}`);
    },
    [history]
  );
  const onEditUser = useCallback(
    (user) => {
      return onEditHandler.bind(this, user);
    },
    [onEditHandler]
  );

  const onCloseDeleteModal = useCallback(() => {
    setSelectedUser(null);
    setShowModal(false);
  }, [setShowModal]);

  let listMeta: ListMeta = {
    name: {
      span: 5,
      overrideStyle: true,
      className: 'gd-user-list-card-avatar',
      render: (item) => {
        return (
          <UserAvatar
            hasImage={!!item.image}
            name={item.name as string}
            imageBase64={item.image as string}
            isCurrentUser={item.isCurrentUser as boolean}
            isGuest={item.isGuest as boolean}
          />
        );
      },
    },
    email: {
      span: 5,
    },
    guidosCount: {
      span: 4,
    },
    status: {
      span: 8,
      className: 'gd-user-list-status',
      render: (item) => {
        return splitCamelCased(item.status as string);
      },
    },
  };
  if (authorizeStore.isAuthorizedToEditUsers()) {
    listMeta = {
      ...listMeta,
      menu: {
        render: (item) => {
          const user = item as ApplicationUser;
          return (
            <Menu>
              <Menu.Item key={1} onClick={onEditUser(user)}>
                {t('users:Labels:editUserLbl')}
              </Menu.Item>
              {!item.isCurrentUser && (
                <Menu.Item key={2} onClick={handleDeleteMenuOption(user)}>
                  {t('users:Labels:deleteUserLbl')}
                </Menu.Item>
              )}
            </Menu>
          );
        },
      },
    };
  }

  const DeleteModalBody = () => {
    return (
      <Text fontSize={16} fontFamily="montserrat-regular">
        {selectedUser &&
          t('users:deleteUserText', { user: selectedUser.email })}
      </Text>
    );
  };

  return (
    <ListContainer>
      <GdList
        split={false}
        itemMode={ItemMode.Card}
        data={usersStore.tenantUsers}
        columnMeta={listMeta}
        loadMore={loadMoreUsers}
        totalCount={usersStore.amountInfo.amountLeft}
        onItemClick={handleSelectUser}
        loading={{
          spinning: loading,
          size: 'large',
          indicator: <Spin indicator={<GdLoadingIndicator />} />,
        }}
      />
      <GdModal
        isVisible={showModal}
        disableButtons={deleteInProgress}
        isClosable={!deleteInProgress}
        onCloseModal={onCloseDeleteModal}
        onOkHandler={handleDeleteUser}
        cancelButtonText={t('users:AddUser:Labels:cancelAddUserlbl')}
        okButtonText={t('users:Labels:deleteUserBtnLbl')}
        title={t('users:Labels:deleteUserModalTitle')}
        renderBody={<DeleteModalBody />}
      />
    </ListContainer>
  );
});
