import 'antd/dist/antd.css';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GdRouter } from './components/GdRouter';
import { LandingCssOverrides } from './domain/Common/styles';
import CssReset from './theme/CssResetStyles';
import { defaultTheme } from './theme/defaultTheme';

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssReset />
      <LandingCssOverrides />
      <GdRouter />;
    </ThemeProvider>
  );
}

export default App;
