import { Col, Row } from 'antd';
import { useCallback } from 'react';
import styled from 'styled-components/macro';

import { GdNumber, ValueContainer } from '../../../components/GdNumber';
import { TextContainer } from '../../../components/Text';
import {
  OrgMetrics,
  useOrgMetricsMapping,
} from '../hooks/useOrgMetricsMapping';
import { NumbersSummary } from '../stores/admDashboardStore';

type OrgNumbersPanelProps = {
  numbers: NumbersSummary;
  onCategoryChanged?: (key: string) => void;
  category: string;
};
export const OrgMetricsPanel = ({
  numbers,
  onCategoryChanged,
  category,
}: OrgNumbersPanelProps) => {
  const { getMetricTranslation, formatMetric } = useOrgMetricsMapping();

  const onDigitClicked = useCallback(
    (key) => {
      return () => {
        onCategoryChanged && onCategoryChanged(key);
      };
    },
    [onCategoryChanged]
  );
  return (
    <OrgNumbersPanelContainer>
      <Row gutter={8}>
        {Object.keys(numbers).map((key, idx) => {
          const numCategory = key as OrgMetrics;
          return (
            <Col
              key={`${numCategory}-${idx}`}
              span={6}
              data-selected={category === numCategory}
            >
              <GdNumber
                onNumberClicked={onDigitClicked(numCategory)}
                value={formatMetric(numCategory, numbers[numCategory])}
                title={getMetricTranslation(numCategory)}
              />
            </Col>
          );
        })}
      </Row>
    </OrgNumbersPanelContainer>
  );
};

const OrgNumbersPanelContainer = styled.div`
  width: 100%;
  padding-left: 20px;

  & [data-selected='true'] {
    ${ValueContainer} {
      ${TextContainer} {
        background: ${({ theme: { colors } }) => colors.strongBlue};
        border-radius: 8px;
        color: ${({ theme: { colors } }) => colors.white};
        padding: 0 4px;
      }
    }
  }
`;
