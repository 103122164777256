import { Col, Form, Row, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/macro';

import Text from '../../../../components/Text';
import { useRootStore } from '../../../../context/storeContext';
import { gdFlexGeneral } from '../../../../theme/CssResetStyles';
import { useOrgSettingsMeta } from '../../hooks/useOrgSettingsMeta';
import { FeatureType, SettingValue } from '../../stores/objects/SettingsItem';
import { SaveSettingsButtons } from './SaveSettingsButtons';
import { OptionsObj, SettingsForm } from './SettingsForm';
import dayjs from "dayjs";

export const OrgSubscriptionSettings = observer(
  ({ subOptions }: { subOptions: OptionsObj }) => {
    const { t } = useTranslation();
    const { orgListStore } = useRootStore();

    const { selectedOrg } = orgListStore;
    const [settingsMeta, toggleOverride, isInEditMode] =
      useOrgSettingsMeta(subOptions);

    const theme = useTheme();
    const [form] = Form.useForm();

    useEffect(() => {
      form.setFields(
        selectedOrg.settings!.map((setting) => ({
          name: setting.name,
          value: setting.name === 'SubscriptionEndDate' ? dayjs(setting.value as string) : setting.value, // TODO(mreinfurt): This is a bit ugly
        }))
      );
    }, [selectedOrg.settings, form]);

    const onSaveSettings = useCallback(async () => {
      const [saveSubRes, saveFeatRes] = await selectedOrg.saveSettings!();
      toggleOverride();
    }, [selectedOrg, toggleOverride]);

    const onSettingChanged = useCallback(
      async (name: FeatureType, value: SettingValue) => {
        selectedOrg.updateSetting!(name, value);
        if (name === 'Subscription') {
          await selectedOrg.switchSubscription!(value as string);
        }
      },
      [selectedOrg]
    );

    const onResetSettings = useCallback(() => {
      selectedOrg.resetSettingsToDefaults!();
    }, [selectedOrg]);

    const overrideSettings = useCallback(() => {
      toggleOverride();
      if (selectedOrg.isSettingsDirty) {
        onResetSettings();
      }
    }, [onResetSettings, selectedOrg.isSettingsDirty, toggleOverride]);

    return (
      <OrgSubscriptionSettingsContainer>
        <FormControlsContainer>
          <Row className="gd-override-settings-controls-container">
            <Col span={8}>
              {selectedOrg.isSettingsDirty && (
                <SaveSettingsButtons
                  onSave={onSaveSettings}
                  onReset={onResetSettings}
                />
              )}
            </Col>
            <Col span={12}>
              <Text
                fontFamily={theme.fonts.semiBold}
                color={theme.colors.strongBlue}
              >
                {t('admOrgs:ManageOrgModal:SettingsTab:overrideSettingsLbl')}
              </Text>
            </Col>
            <Col span={4}>
              <Switch
                className="gd-override-settings-btn"
                checked={!isInEditMode}
                onClick={overrideSettings}
              />
            </Col>
          </Row>
        </FormControlsContainer>
        <SettingsForm
          form={form}
          meta={settingsMeta}
          onFieldChanged={onSettingChanged}
        />
      </OrgSubscriptionSettingsContainer>
    );
  }
);
const OrgSubscriptionSettingsContainer = styled.div`
  position: relative;
`;

const FormControlsContainer = styled.div`
  height: 40px;
  width: 65%;
  position: absolute;
  right: 0;
  top: -10px;

  ${gdFlexGeneral()}
  align-items: center;

  & .gd-override-settings-controls-container {
    width: 100%;
    & > div:not(:first-child) {
      ${gdFlexGeneral()}
      align-items: center;
    }

    & > div:first-child {
      ${gdFlexGeneral()}
      justify-content: end;
    }
  }

  & .gd-override-settings-btn {
    z-index: 3;
  }
`;
