import { makeAutoObservable, runInAction, toJS } from 'mobx';

import { TransportLayer } from '../../../api/transportLayer';
import { Result, RootStore } from '../../../services/rootStore';
import {
  BaseQuery,
  ListResult,
  SortDirection,
} from '../../../types/commonTypes';
import { getSortFunction, sortBy } from '../../../utils';

export type Team = {
  id: string;
  name: string;
  userHasAccess: boolean;
  assignedGuidosCount: number;
  assignedGuidosSpaceAmount: number;
  usersAmount: number;
  totalGuidosSize: number;
};

export type TeamMeta = {
  id: string;
  name: string;
  creationDateUtc: string;
  creatorId: string;
  creator: string;
  numberOfUsers: number;
  numberOfGuidos: number;
};

type TeamFilter = {
  name: string;
};

export type GetTeamsQuery = BaseQuery;
type AddGroupCommand = {
  name: string;
};

export class TeamsStore {
  rootStore: RootStore;
  amountInfo: Omit<ListResult<Team>, 'items'> = {
    amount: 0,
    totalAmount: 0,
    hasNext: false,
    amountLeft: 0,
  };

  teams: Team[] = [];
  filter: TeamFilter = {
    name: '',
  };
  sortOrder: SortDirection = SortDirection.Asc;
  selectedGroup: Partial<Team> & Partial<TeamMeta> = {};
  restoreGroupName: string = '';
  transportLayer: TransportLayer;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.transportLayer = rootStore.transportLayer;
    makeAutoObservable(this);
  }

  clearTeams() {
    this.teams = [];
    this.filter = {
      name: '',
    };
  }

  public clearSelectedGroup() {
    this.selectedGroup = {};
  }

  public setFilter(newFilter: TeamFilter) {
    this.filter = { ...newFilter };
  }

  public setSelectedGroup(groupId: string) {
    this.selectedGroup = this.teams.find((ug) => ug.id === groupId) || {};
  }

  public updateTeamData(data: Partial<Team> & Partial<TeamMeta>) {
    this.selectedGroup = { ...this.selectedGroup, ...data };
  }

  public setRestoreName(restore: boolean) {
    if (restore) {
      this.restoreGroupName = this.selectedGroup.name as string;
    } else {
      this.selectedGroup = {
        ...this.selectedGroup,
        name: this.restoreGroupName,
      };
    }
  }

  public sortTeams(order: SortDirection, field: string) {
    const sortFunc = getSortFunction(field, order);
    const teams = toJS(this.teams);
    this.teams = teams.sort(sortFunc);
    this.sortOrder = order;
  }

  public async getGroupData(groupId: string) {
    const result = await this.rootStore.ApiClient.get<
      Result<TeamMeta & Pick<Team, 'totalGuidosSize'>>
    >(`/team/teamDetails/${groupId}`);
    if (result.succeeded) {
      runInAction(() => {
        const totalGuidosSize = result.data.totalGuidosSize * 1024;
        this.selectedGroup = { ...result.data, totalGuidosSize };
      });
    }
  }

  public async getAll(query: GetTeamsQuery, loadMore: boolean = false) {
    const resp = await this.transportLayer.fetchAllTeams(query);

    if (resp.succeeded) {
      runInAction(() => {
        const { items, totalAmount, hasNext, amount, amountLeft } = resp.data;
        const teams = items.map((team) => {
          team.totalGuidosSize = team.totalGuidosSize * 1024;
          return team;
        });
        this.amountInfo = { totalAmount, hasNext, amount, amountLeft };
        this.teams = loadMore ? this.teams.concat([...teams]) : teams;
        this.sortTeams(this.sortOrder, 'usersAmount');
      });
    }
    return resp;
  }

  public async add(body: AddGroupCommand) {
    return await this.rootStore.ApiClient.post<AddGroupCommand, Result<string>>(
      '/team/add',
      body
    );
  }

  public async updateGroupDetails(groupId: string) {
    await this.rootStore.ApiClient.put(`/team/modifyTeamDetails/${groupId}`, {
      name: this.selectedGroup.name,
    });
  }

  public async delete(teamId: string): Promise<Result<string>> {
    try {
      const res = await this.rootStore.ApiClient.delete(
        `/team/delete/${teamId}`
      );
      if (res.succeeded) {
        runInAction(() => {
          this.teams = this.teams.filter(({ id }) => id !== teamId);
          const { totalAmount, amountLeft } = this.amountInfo;
          this.amountInfo = {
            ...this.amountInfo,
            amountLeft: amountLeft - 1,
            totalAmount: totalAmount - 1,
          };
        });
      }
      return res;
    } catch (error) {
      return {
        data: 'Delete failed',
        message: `${error}`,
        succeeded: false,
        failed: true,
      };
    }
  }
}
