import { Button, Card, Checkbox, Col, Dropdown, List, Row } from 'antd';
import { ListProps } from 'antd/lib/list';
import VirtualList from 'rc-virtual-list';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components/macro';

import { IconInt } from './Icons';

type ContainerProps = {
  fixScroll: boolean;
  heightDelta: number;
  maxHeight?: number;
  fixScrollStartCellNumber?: number;
  useMaxHeight?: boolean;
};

type Item = { [key: string]: string | number | boolean };

export enum ItemMode {
  Basic = 'basic',
  Card = 'card',
}

enum ActionType {
  Menu,
  Single,
}

const DELTA_THRESHOLD = 2;
const ACTION_KEYS = ['menu', 'action'];

const GdListContainer = styled.div<ContainerProps>`
  padding-top: 5px;
  width: 100%;

  ${({ maxHeight, heightDelta, useMaxHeight }) =>
    useMaxHeight
      ? `max-height: ${maxHeight}px;`
      : `height: calc(100vh - ${heightDelta || 447}px);`}
  & .ant-list-item {
    padding: 6px 8px 6px 0;
  }

  .gd-list-card {
    background: ${({ theme: { colors } }) => colors.white};
    box-shadow: 3px 4px 7px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 100%;
    height: 50px;
    padding: 0;

    &:hover {
      cursor: pointer;
      background: linear-gradient(
          0deg,
          rgba(249, 135, 110, 0.16),
          rgba(249, 135, 110, 0.16)
        ),
        ${({ theme: { colors } }) => colors.white};
    }

    & .ant-card-body {
      padding: 0;
      height: 100%;
    }

    & .gd-list-card-body {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  & .gd-list-meta {
    font-size: 11px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & .gd-input-item {
      width: 100%;

      & .ant-select-selector {
        border-radius: 4px;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }

  & .ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightWhite};
  }

  & .ant-checkbox-inner {
    border-radius: 4px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & .ant-btn-link {
    color: ${({ theme: { colors } }) => colors.strongWhite};
  }
`;

export type ListItemMeta = {
  render?: (item: {
    [key: string]: string | number | boolean;
  }) => string | React.ReactElement;
  className?: string;
  span?: number;
  overrideStyle?: boolean;
};

export type ListMeta = {
  [key: string]: ListItemMeta;
};

type Props<T> = {
  data: T;
  columnMeta: ListMeta;
  loadMore?: () => Promise<any>;
  totalCount: number;
  onItemClick?: (item: { [key: string]: string | number }) => void;
  onItemCheck?: (
    item: { [key: string]: string | number },
    checked: boolean
  ) => void;
  itemMode: ItemMode;
  split: boolean;
  heightDelta?: number;
  maxHeight?: number;
  rowHeight?: number;
  editable?: boolean;
  fixScrollStartCellNumber?: number;
  useMaxHeight?: boolean;
} & ListProps<T>;

function Actions(props: {
  metaItem: ListItemMeta;
  colSpan: number;
  item: { [p: string]: string | number | boolean };
  type?: ActionType;
}) {
  const actionType = props.type || ActionType.Menu;
  const isMenu = actionType === ActionType.Menu;
  const preventClickBubbling = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <Col span={props.colSpan}>
      <ActionsContainer onClick={preventClickBubbling}>
        {isMenu ? (
          <Dropdown
            overlayClassName="gd-dropdown-menu"
            overlay={
              (props.metaItem.render &&
                props.metaItem.render(props.item)) as React.ReactElement
            }
            placement="bottomRight"
            trigger={['click']}
          >
            <Button type="link" icon={<IconInt icon="MoreHoriz" />} />
          </Dropdown>
        ) : (
          ((props.metaItem.render &&
            props.metaItem.render(props.item)) as React.ReactElement)
        )}
      </ActionsContainer>
    </Col>
  );
}

export const GdList = <
  T extends { [key: string]: string | number | boolean }[]
>({
  data,
  columnMeta,
  loadMore,
  totalCount,
  onItemClick = () => {},
  onItemCheck = () => {},
  itemMode = ItemMode.Basic,
  split = false,
  heightDelta = 425,
  maxHeight,
  rowHeight = 63,
  editable = false,
  fixScrollStartCellNumber = 2,
  useMaxHeight = false,
  ...props
}: Props<T>) => {
  const loadMoreData = useCallback(async () => {
    loadMore && (await loadMore());
  }, [loadMore]);

  const onItemClickHandler = useCallback(
    (item) => {
      return () => {
        if (onItemClick) onItemClick(item);
      };
    },
    [onItemClick]
  );
  const onChangeCheckBox = useCallback(
    (item, e) => {
      onItemCheck && onItemCheck(item, e.target.checked);
    },
    [onItemCheck]
  );

  const onItemChecked = useCallback(
    (item) => {
      return onChangeCheckBox.bind(this, item);
    },
    [onChangeCheckBox]
  );

  const isActionProp = useCallback((key: string) => {
    return ACTION_KEYS.includes(key);
  }, []);

  const getActionType = useCallback((itemProp) => {
    return itemProp === 'menu' ? ActionType.Menu : ActionType.Single;
  }, []);

  const renderActions = useCallback(
    (item: Item, itemMeta: ListItemMeta, itemProp: string) => {
      const span = itemMeta.span || 2;
      const actionType = getActionType(itemProp);
      return (
        <Actions
          key={`actions-${item.id}`}
          colSpan={span}
          metaItem={itemMeta}
          item={item}
          type={actionType}
        />
      );
    },
    [getActionType]
  );

  const renderDataColumn = useCallback((item, itemMeta, itemProp) => {
    const className = itemMeta.overrideStyle
      ? itemMeta.className
      : `gd-list-meta ${itemMeta.className ? itemMeta.className : ''}`;
    return (
      <Col
        key={`col-${itemProp}-${item.id}`}
        span={itemMeta.span}
        className={className}
        title={item[itemProp] && item[itemProp].toString()}
      >
        {itemMeta.render ? itemMeta.render(item) : item[itemProp]}
      </Col>
    );
  }, []);

  const addCheckBox = useCallback(
    (item, rowBody) => {
      const checkBox = (
        <Checkbox
          key={`checkbox-${item.id}`}
          checked={item.checked as boolean}
          onChange={onItemChecked(item)}
          disabled={!!item.disabled}
        />
      );
      return editable ? [checkBox, ...rowBody] : rowBody;
    },
    [editable, onItemChecked]
  );

  const getListItemBody = useCallback(
    (item: Item) => {
      let rowBody = Object.keys(columnMeta).map((itemProp) => {
        const itemMeta = columnMeta[itemProp];

        return isActionProp(itemProp)
          ? renderActions(item, itemMeta, itemProp)
          : renderDataColumn(item, itemMeta, itemProp);
      });
      rowBody = addCheckBox(item, rowBody);
      return rowBody;
    },
    [addCheckBox, columnMeta, renderActions, renderDataColumn, isActionProp]
  );

  //@ts-ignore
  const ListItem = (item, ref: any) => {
    const listItemBody =
      itemMode === ItemMode.Basic ? (
        getListItemBody(item)
      ) : (
        <Card
          key={`card-${item.id}`}
          className="gd-list-card"
          onClick={onItemClickHandler(item)}
        >
          <Row key={`row-${item.id}`} className="gd-list-card-body">
            {getListItemBody(item)}
          </Row>
        </Card>
      );
    return <List.Item aria-disabled={true}>{listItemBody}</List.Item>;
  };

  const ForwardItem = forwardRef(ListItem);
  const parRef = useRef<HTMLDivElement>(null);
  const [listHeight, setHeight] = useState(
    window.innerHeight - (heightDelta || 447)
  );

  const adjustListHeight = useCallback(() => {
    setHeight(window.innerHeight - (heightDelta || 447));
  }, [heightDelta]);

  useEffect(() => {
    window.addEventListener('resize', adjustListHeight);
    return () => {
      window.removeEventListener('resize', adjustListHeight);
    };
  }, [adjustListHeight]);

  useEffect(() => {
    const dataHeight = data.length * rowHeight;
    const parentHeight =
      parRef.current && parRef.current.getBoundingClientRect().height;
    if (
      parentHeight &&
      dataHeight < parentHeight &&
      dataHeight !== 0 &&
      data.length < totalCount
    ) {
      loadMoreData();
    }
  }, [loadMoreData, rowHeight, data.length, listHeight, totalCount]);

  const isLoading = useRef(false);

  const onScroll = useCallback(
    async (e) => {
      const { height: parentHeight } = e.target.getBoundingClientRect();
      // This scroll Delta is needed for screen scales more the 100%. It's freaked out the list couple of times.
      const scrollDriftDelta = Math.abs(
        Math.round(e.target.scrollHeight - e.target.scrollTop) -
          Math.round(parentHeight)
      );
      if (
        scrollDriftDelta <= DELTA_THRESHOLD &&
        data.length < totalCount &&
        !isLoading.current
      ) {
        // isLoading fixing auto scroll for Firefox
        // Firefox firing scroll when data is loaded even before scrolling
        isLoading.current = true;
        await loadMoreData();
        isLoading.current = false;
      }
    },
    [totalCount, data.length, loadMoreData]
  );

  const setListHeight = useCallback(() => {
    const parentH =
      parRef.current && parRef.current.getBoundingClientRect().height;
    if (maxHeight && parentH && parentH < maxHeight) {
      return maxHeight;
    }
    return parentH || maxHeight;
  }, [maxHeight, parRef]);

  return (
    <GdListContainer
      ref={parRef}
      maxHeight={maxHeight}
      heightDelta={heightDelta}
      fixScroll={data.length > 8}
      fixScrollStartCellNumber={fixScrollStartCellNumber}
      useMaxHeight={useMaxHeight}
    >
      <List split={split} {...props}>
        <VirtualList
          data={data}
          height={setListHeight() as number}
          itemHeight={rowHeight}
          itemKey={(item) => `${item.id}`}
          onScroll={onScroll}
        >
          {(item) => <ForwardItem {...item} />}
        </VirtualList>
      </List>
    </GdListContainer>
  );
};
