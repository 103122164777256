import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  fontFamily?: string;
  font?: string;
  fontSize?: number;
  color?: string;
  background?: string;
  selectText?: boolean;
  leftPadding?: number;
  opacity?: number;
  title?: string;
  className?: string;
};

type TextStyleProps = Omit<Props, 'children'>;

export const TextContainer = styled.span<TextStyleProps>`
  font-family: ${({ fontFamily }) => fontFamily || 'montserrat-bold'};
  font-size: ${({ fontSize }) => `${fontSize}px` || '11px'};

  letter-spacing: -0.01em;

  color: ${({ color, theme: { colors } }) => color || colors.black};
  opacity: ${({ opacity }) => (opacity ? opacity : '1')};
  ${({ background }) =>
    background
      ? `background: ${background};
    width: fit-content;
    height: fit-content;
    padding: 2px 4px;
    line-height: 100%;
 `
      : ''}
  ${({ selectText }) => (!selectText ? 'user-select: none;' : '')}
  ${({ leftPadding }) => (leftPadding ? `padding-left: ${leftPadding}px;` : '')}
`;

function Text({ children, title = '', ...props }: Props) {
  return (
    <TextContainer title={title} {...props}>
      {children}
    </TextContainer>
  );
}

export default Text;
