import {Token} from "../domain/Identity/stores/authStore";

export class SessionStore {
  public getItem(key: string): string {
    return sessionStorage.getItem(key) as string;
  }

  public deleteItem(key: string) {
    return sessionStorage.removeItem(key);
  }

  public getToken() {
    const tokenJson = this.getItem('token');
    const token: Token = JSON.parse(tokenJson);
    return token;
  }
}

export const sessionStore = new SessionStore();

export function useSessionStore(): SessionStore {
  return sessionStore;
}
