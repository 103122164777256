import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../../../context/storeContext';
import { OptionsObj, SectionMeta } from '../components/Management/SettingsForm';

export const useSubscriptionsQuery = () => {
  const { orgListStore } = useRootStore();
  useEffect(() => {
    const getSubscriptions = async () => {
      await orgListStore.getSubscriptionOptions();
    };

    getSubscriptions();
  }, [orgListStore]);
};

export const useOrgSettingsMeta = (
  subscriptionOptions: OptionsObj
): [SectionMeta[], () => void, boolean] => {
  const { t } = useTranslation();

  const [isMetaDisabled, setIsMetaDisabled] = useState<boolean>(true);

  const overrideMeta = useCallback(() => {
    setIsMetaDisabled((isMetaDisabled) => !isMetaDisabled);
  }, []);

  const settingsMeta: SectionMeta[] = [
    {
      title: t('admOrgs:ManageOrgModal:SettingsTab:subscriptionModelLbl'),
      settingMetaItems: [
        {
          name: 'Subscription',
          description: 'Overall subscription plan',
          input: 'selection',
          label: t('admOrgs:ManageOrgModal:SettingsTab:changeModelLbl'),
          options: subscriptionOptions,
        },
        {
          name: 'SubscriptionEndDate',
          description: 'Subscription will change to "Free" after end date',
          input: 'date',
          label: t('admOrgs:ManageOrgModal:SettingsTab:changeSubEndDatelbl'),
        },
        {
          name: 'StorageSize',
          description: 'Overall storage that can be used in GB',
          input: 'bytes',
          label: t('admOrgs:ManageOrgModal:SettingsTab:changeStoragelbl'),
        },
      ],
    },
    {
      title: t('admOrgs:ManageOrgModal:SettingsTab:usersLbl'),
      settingMetaItems: [
        {
          name: 'UsersLimit',
          description: t('admOrgs:ManageOrgModal:SettingsTab:limitUsersDesc'),
          input: 'number',
          label: t('admOrgs:ManageOrgModal:SettingsTab:limitUsersLbl'),
        },
        {
          name: 'ManagersLimit',
          description: t(
            'admOrgs:ManageOrgModal:SettingsTab:limitManagersDesc'
          ),
          input: 'number',
          label: t('admOrgs:ManageOrgModal:SettingsTab:limitManagersLbl'),
        },
        {
          name: 'EditorsLimit',
          description: t('admOrgs:ManageOrgModal:SettingsTab:limitEditorsDesc'),
          input: 'number',
          label: t('admOrgs:ManageOrgModal:SettingsTab:limitEditorsLbl'),
        },
      ],
    },
    {
      title: t('admOrgs:ManageOrgModal:SettingsTab:guidosLbl'),
      settingMetaItems: [
        {
          name: 'GuidosLimit',
          description: t('admOrgs:ManageOrgModal:SettingsTab:limitGuidosDesc'),
          input: 'number',
          label: t('admOrgs:ManageOrgModal:SettingsTab:limitGuidosLbl'),
        },
      ],
    },
    {
      title: t('admOrgs:ManageOrgModal:SettingsTab:groupsLbl'),
      settingMetaItems: [
        {
          name: 'GroupsLimit',
          description: t('admOrgs:ManageOrgModal:SettingsTab:limitGroupsDesc'),
          input: 'number',
          label: t('admOrgs:ManageOrgModal:SettingsTab:limitGroupsLbl'),
        },
      ],
    },
    {
      title: t('admOrgs:ManageOrgModal:SettingsTab:specialFeaturesLbl'),
      settingMetaItems: [
        {
          name: 'GuestAllowed',
          description: t('admOrgs:ManageOrgModal:SettingsTab:allowGuestDesc'),
          input: 'boolean',
          label: t('admOrgs:ManageOrgModal:SettingsTab:specialAllowGuestLbl'),
        },
        {
          name: 'ExternalSharing',
          description: t(
            'admOrgs:ManageOrgModal:SettingsTab:allowExtSharingDesc'
          ),
          input: 'boolean',
          label: t(
            'admOrgs:ManageOrgModal:SettingsTab:specialExternalSharingLbl'
          ),
        },
        {
          name: 'FeedbackAnnotations',
          description: t(
            'admOrgs:ManageOrgModal:SettingsTab:allowVidAnnotationsDesc'
          ),
          input: 'boolean',
          label: t('admOrgs:ManageOrgModal:SettingsTab:specialAllowVideoLbl'),
        },
      ],
    },
  ];

  settingsMeta.map((item) => {
    item.disabled = isMetaDisabled;
    return item;
  });

  return [settingsMeta, overrideMeta, isMetaDisabled];
};
