import { Card, Divider, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { PageTitle } from '../../../components/PageTitle';
import { useRootStore } from '../../../context/storeContext';
import { OrgSubscriptionCard } from '../../AdminOrganizations/components/OrgSubscriptionCard';
import {
  BasePageContainer,
  CommandsContainer,
  DetailsListsContainer,
} from '../../Common/styles';
import { UpgradeSubscription } from './UpgradeSubscription';

export const ManageSubscription = observer(() => {
  const { t } = useTranslation();
  const { companyStore } = useRootStore();

  const {
    planName,
    totalStorageSize,
    totalUsers,
    limitStorageSize,
    limitUsers,
    subscriptionEndDate,
    hasShareCare,
  } = companyStore.subscriptionData;

  const onUpgradePlanClicked = useCallback(() => {
    window.open('https://guided-doing.com/preise/', '_blank');
  }, []);

  return (
    <ManageSubscriptionContainer>
      <Row>
        <CommandsContainer>
          <PageTitle title={t('dashboard:manageSubscriptionPageTitle')} />
        </CommandsContainer>
      </Row>
      <Row>
        <SubscriptionPanelContainer>
          <Card className="gd-card">
            <OrgSubscriptionCard
              planName={planName as string}
              totalUsers={totalUsers as number}
              limitUsers={limitUsers as number}
              totalStorageSize={totalStorageSize as number}
              limitStorageSize={limitStorageSize as number}
              subscriptionEndDate={subscriptionEndDate as Dayjs}
              hasShareCare={hasShareCare!}
            />
          </Card>
        </SubscriptionPanelContainer>
      </Row>
    </ManageSubscriptionContainer>
  );
});

const ManageSubscriptionContainer = styled(BasePageContainer)``;

const SubscriptionPanelContainer = styled(DetailsListsContainer)`
  margin-top: 40px;
`;
