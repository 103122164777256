export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum UpdateCommandEnum {
  Added = 0,
  Removed = 1,
  Modified = 2,
}

export type OrderColumn = {
  [key: string]: SortDirection;
};

export type BaseQuery = {
  offset?: number;
  count?: number;
  orderingColumns?: OrderColumn | null;
  textToSearch?: string | null;
  publishedOnly?: boolean;
  customTenantId?: string;
};

export type ListResult<T> = {
  items: T[];
  amount: number;
  totalAmount: number;
  hasNext: boolean;
  amountLeft: number;
};

export type Event<T extends { [key: string]: string | number }> = {
  payload: T;
  id: string;
  eventDateTimeUtc: string;
  eventType: string;
  tenantId: string;
  currentUserId: string;
  currentUserName: string;
  subjectType: string;
  subjectId: string;
  summaryMessage: string;
};

export type NoSqlList<T extends { [key: string]: string | number }> = {
  continuationToken: string;
  eventsCount: number;
  requestCost: number;
  events: Event<T>[];
  failed: boolean;
  message: string;
  succeeded: boolean;
};
