import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsMeta, GdListHeader } from '../../../components/GdListHeader';
import { useRootStore } from '../../../context/storeContext';
import { SortDirection } from '../../../types/commonTypes';

export const GuidosListHeader = observer(() => {
  const { orgListStore } = useRootStore();
  const { t } = useTranslation();
  const sortingHandler = useCallback(
    (order, field) => {
      orgListStore.sortOrgs(order, field);
    },
    [orgListStore]
  );
  const columns: ColumnsMeta = useMemo(() => {
    return {
      name: {
        title: t('admGuidos:ListHeader:nameLbl'),
        leftPadding: 24,
        colspan: 4,
      },
      tenantName: {
        title: t('admGuidos:ListHeader:tenantLbl'),
        colspan: 5,
      },
      size: {
        title: t('admGuidos:ListHeader:sizeLbl'),
        colspan: 2,
      },
      published: {
        title: t('admGuidos:ListHeader:publishedLbl'),
        colspan: 2,
      },
      externalViews: {
        title: t('admGuidos:ListHeader:externalViewsLbl'),
        colspan: 2,
      },
      createdDateUtc: {
        title: t('admGuidos:ListHeader:creationDateLbl'),
        colspan: 3,
      },
      lastUpdatedDateUtc: {
        title: t('admGuidos:ListHeader:lastUpdateLbl'),
        colspan: 3,
      },
    };
  }, [sortingHandler, t]);
  return <GdListHeader activeSortField={'storage'} columns={columns} />;
});
