import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ListMeta } from '../../../components/GdList';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { formatBytes, showLocalDate } from '../../../utils';
import { Guido } from '../stores/guidosStore';

const PublishedPropContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
`;

type GuidosMetaOutput = {
  columnsMeta: ListMeta;
};

type GuidosMetaInput = {
  onDeleteClicked?: (guido: Guido) => void;
};
export const useGuidosMeta = ({
  onDeleteClicked,
}: GuidosMetaInput): GuidosMetaOutput => {
  const { authorizeStore } = useRootStore();
  const history = useHistory();

  const onGuidoDetailsMenuClicked = useCallback(
    (guido) => {
      return () => {
        history.push(`/guidos/${guido.id}`);
      };
    },
    [history]
  );

  const onDeleteMenuClicked = useCallback(
    (guido) => {
      return () => {
        onDeleteClicked && onDeleteClicked(guido);
      };
    },
    [onDeleteClicked]
  );

  let columnMeta: ListMeta = {
    tittle: {
      span: 4,
      overrideStyle: true,
      className: 'gd-list-title',
    },
    size: {
      span: 2,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {formatBytes(item.size as number)}
          </Text>
        );
      },
    },
    createdDateUtc: {
      span: 4,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {showLocalDate(item.createdDateUtc as string | number)}
          </Text>
        );
      },
    },
    lastUpdatedDateUtc: {
      span: 4,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {showLocalDate(item.lastUpdatedDateUtc as string | number)}
          </Text>
        );
      },
    },
    owner: {
      span: 3,
    },
    assignedUsersCount: {
      span: 2,
    },
    published: {
      span: 3,
      render: (item) => {
        return (
          <PublishedPropContainer>
            {item.published ? <CheckOutlined /> : <CloseOutlined />}
          </PublishedPropContainer>
        );
      },
    },
  };

  if (authorizeStore.isAuthorizedToEditGuidosList()) {
    columnMeta = {
      ...columnMeta,
      menu: {
        render: (item) => {
          return (
            <Menu>
              <Menu.Item key={1} onClick={onGuidoDetailsMenuClicked(item)}>
                Edit
              </Menu.Item>
              <Menu.Item key={2} onClick={onDeleteMenuClicked(item)}>
                Delete
              </Menu.Item>
            </Menu>
          );
        },
      },
    };
  }
  return {
    columnsMeta: columnMeta,
  };
};
