import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';

const DEFAULT_ITEMS_AMOUNT = 10;
const START_OFFSET = 0;

export const useGuidosQuery = () => {
  const { guidoListStore } = useRootStore();
  const {
    orderingColumns,
    filter: { tittle, filterByTenant },
  } = guidoListStore;
  const { t } = useTranslation();

  const setBody = useCallback(
    (offset: number, count: number) => {
      return {
        offset,
        count,
        textToSearch: tittle,
        filterByTenant,
        orderingColumns,
      };
    },
    [tittle, filterByTenant, orderingColumns]
  );

  const fetchGuidos = useCallback(
    async (loadMore = false) => {
      const body = loadMore
        ? setBody(guidoListStore.guidos.length, DEFAULT_ITEMS_AMOUNT)
        : setBody(START_OFFSET, DEFAULT_ITEMS_AMOUNT);
      return await guidoListStore.getAll(body, loadMore);
    },
    [guidoListStore, setBody]
  );

  const clearGuidos = useCallback(() => {
    guidoListStore.clear();
  }, [guidoListStore]);

  const showMsgIfFailedFunc = useCallback(
    () => t('admGuidos:Messages:fetchGuidosFailed'),
    [t]
  );
  const showDescIfFailedFunc = useCallback(
    (res) =>
      `${t('admGuidos:Messages:fetchGuidosFailedDescription')}/n ${res.message}}`,
    [t]
  );

  const { loading, loadMore: loadMoreGuidos } = useDataQuery({
    dataFunc: fetchGuidos,
    clearFunc: clearGuidos,
    showDescIfFailedFunc,
    showMsgIfFailedFunc,
  });

  return { loading, loadMoreGuidos };
};
