import {Card, Spin} from 'antd';
import { ReactElement } from 'react';
import styled from 'styled-components/macro';
import {GdLoadingIndicator} from "../../../components/GdLoadingIndicator";
import { CardInfoContainer } from '../../Common/styles';

const GraphCardContainerContainer = styled(CardInfoContainer)`
  height: 100%;

  .gd-card {
    height: 92%;
    border-radius: 8px;

    .ant-card-body {
      height: 100%;
      padding: 15px 15px;
    }
    
    & .ant-spin-spinning {
      position: absolute;
    }
  }
`;

type Props = {
  children: ReactElement[] | ReactElement;
  loading?: boolean
};

export const GraphCardContainer = ({ children, loading = false }: Props) => {
  return (
    <GraphCardContainerContainer>
      <Card className="gd-card">
        {loading && <Spin indicator={<GdLoadingIndicator />} />}
        {children}
      </Card>
    </GraphCardContainerContainer>
  );
};
