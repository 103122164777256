import { Button, Form, Input } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { env } from '../../../env';
import { getResultAndShowNotification } from '../../../utils';
import {
  DetailsContainer,
  LogoImage,
  SignInFormContainer,
  SignInPageContainer,
  SubmitContainer,
} from '../styled'; 
import { TextMainLogo } from './TextMainLogo';

const ForgotPasswordContainer = styled(SignInPageContainer)``;
const ForgotPasswordEmailFormContainer = styled(SignInFormContainer)``;

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { authStore } = useRootStore();

  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const onCloseMessage = useCallback(() => {
    history.push('/signin');
  }, [history]);

  const onFinish = useCallback(
    async (values) => {
      setLoading(true);
      await getResultAndShowNotification(
        async () => await authStore.requestResetPassword(values.email),
        t('identity:ForgotPassword:requestSuccess'),
        t('identity:ForgotPassword:requestFailed'),
        onCloseMessage
      );
      setLoading(false);
    },
    [t, onCloseMessage, authStore]
  );

  const onCancel = useCallback(() => {
    history.push('/signin');
  }, [history]);

  return (
    <ForgotPasswordContainer>
      <TextMainLogo />
      <LogoImage src={`${env.PUBLIC_URL}/loginLogo.png`} />
      <ForgotPasswordEmailFormContainer>
        <DetailsContainer>
          <PageTitle
            title={t('identity:ForgotPassword:mainTitle')}
            textSize={32}
          />
          <Text fontFamily="montserrat-medium" fontSize={14}>
            {t('identity:ForgotPassword:mainText')}
          </Text>
        </DetailsContainer>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            className="gd-form-item"
            label={<Text>{t('SignInUpPage:Labels:email')}</Text>}
            name="email"
            rules={[
              {
                required: true,
                message: `Email ${t('identity:Errors:empty')}`,
              },
              { type: 'email', message: 'The input is not valid E-mail!' },
            ]}
          >
            <Input
              className="gd-input-item"
              placeholder={t('identity:ForgotPassword:emailPlaceholder')}
            />
          </Form.Item>
          <SubmitContainer>
            <Button
              type="text"
              loading={loading}
              className="gd-form-button gd-form-button-text"
              onClick={onCancel}
            >
              {t('identity:ForgotPassword:cancelBtn')}
            </Button>
            <Button
              type="primary"
              disabled={loading}
              htmlType="submit"
              className="gd-form-button gd-form-button-primary"
            >
              {t('identity:ForgotPassword:sendBtn')}
            </Button>
          </SubmitContainer>
        </Form>
      </ForgotPasswordEmailFormContainer>
    </ForgotPasswordContainer>
  );
};
