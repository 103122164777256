import { Card } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../../components/Text';

export const CompanyDetailsInfo = () => {
  const { t } = useTranslation();
  return (
    <Card className="gd-card">
      <Text>{t('SignInUpPage:Labels:detailsLbl')}</Text>
      <br />
      <Text fontFamily="montserrat-medium" fontSize={14}>
        {t('SignInUpPage:Text:SignUp:detailsCompany')}
      </Text>
    </Card>
  );
};
