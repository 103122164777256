import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/macro';

import { ListMeta } from '../../../components/GdList';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { formatBytes, showLocalDate } from '../../../utils';

const PublishedPropContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
`;

export const useGuidosMeta = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { guidoListStore } = useRootStore();

  const onGuidoViewClicked = useCallback(
    (id) => {
      return async () => {
        const shareLink = await guidoListStore.getShareLink(id);
        window.open(shareLink, '_blank', 'noreferrer');
      };
    },
    [guidoListStore]
  );

  const listMeta: ListMeta = {
    tittle: {
      span: 4,
      overrideStyle: true,
      className: 'gd-list-title',
    },
    tenantName: {
      span: 5,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily={theme.fonts.medium}>
            {item.tenantName}
          </Text>
        );
      },
    },
    size: {
      span: 2,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily={theme.fonts.medium}>
            {formatBytes(Number(item.size))}
          </Text>
        );
      },
    },
    published: {
      span: 2,
      render: (item) => {
        return (
          <PublishedPropContainer>
            {item.published ? <CheckOutlined /> : <CloseOutlined />}
          </PublishedPropContainer>
        );
      },
    },
    externalViews: {
      span: 2,
      overrideStyle: true,
      className: 'gd-list-title gd-list-title_regular',
    },
    createdDateUtc: {
      span: 3,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {showLocalDate(item.createdDateUtc as string | number)}
          </Text>
        );
      },
    },
    lastUpdatedDateUtc: {
      span: 3,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {showLocalDate(item.lastUpdatedDateUtc as string | number)}
          </Text>
        );
      },
    },
    view: {
      span: 3,
      render: (item) => {
        if (item.published)
          return (
            <Button onClick={onGuidoViewClicked(item.id)} type="link">
              View
            </Button>
          );
        else return '';
      },
    },
  };
  return listMeta;
};
