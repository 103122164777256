import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { GdChart } from '../../../components/GdChart';
import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';
import { GraphCardContainer } from './GraphCardContainer';
import { OrgMetricsPanel } from './OrgMetricsPanel';

export const OrgActivityLineChart = observer(() => {
  const { admDashboardStore } = useRootStore();
  const { period } = admDashboardStore;
  const [currentCategory, setCategory] = useState('totalTenantsCount');

  const fetchChart = useCallback(
    async (loadMore = false) => {
      setCategory('totalTenantsCount');
      await admDashboardStore.getNumbersSummary(period);
      return await admDashboardStore.getChartDataByCategory(
        'totalTenantsCount'
      );
    },
    [admDashboardStore, period]
  );

  const { loading } = useDataQuery({
    dataFunc: fetchChart,
  });

  const onNumberClicked = useCallback(
    async (key) => {
      setCategory(key);
      await admDashboardStore.getChartDataByCategory(key);
    },
    [admDashboardStore]
  );

  return (
    <GraphCardContainer loading={loading}>
      <GdChart
        type="line"
        data={admDashboardStore.orgActivityChart}
        info={
          <OrgMetricsPanel
            category={currentCategory}
            numbers={admDashboardStore.orgSummary}
            onCategoryChanged={onNumberClicked}
          />
        }
      />
    </GraphCardContainer>
  );
});
