import * as H from 'history';

import { RestClient } from '../api/restClient';
import { TransportLayer } from '../api/transportLayer';
import { AdmDashboardStore } from '../domain/AdminDashboard/stores/admDashboardStore';
import { GuidoListStore } from '../domain/AdminGuidos/stores/guidosListStore';
import { LogEventsStore } from '../domain/AdminOrganizations/stores/logEventsStore';
import { OrgListStore } from '../domain/AdminOrganizations/stores/orgListStore';
import { CompanyStore } from '../domain/Company/stores/companyStore';
import { DashboardStore } from '../domain/Dashboard/stores/dashboardStore';
import { TeamsListsStore } from '../domain/Groups/stores/teamsListsStore';
import { TeamsStore } from '../domain/Groups/stores/teamsStore';
import { GuidosListsStore } from '../domain/Guidos/stores/guidosListsStore';
import { GuidosStore } from '../domain/Guidos/stores/guidosStore';
import { AuthStore } from '../domain/Identity/stores/authStore';
import { AuthorizeStore } from '../domain/Identity/stores/authorizeStore';
import { SignUpStore } from '../domain/Identity/stores/signUpStore';
import { UserListsStore } from '../domain/Users/stores/userListsStore';
import { UsersStore } from '../domain/Users/stores/usersStore';
import { SessionStore, sessionStore } from '../hooks/useSessionStore';
import { AcademyStore } from '../domain/Academy/stores/academyStore';

export type Result<T> = {
  data: T;
  failed: boolean;
  message: string;
  succeeded: boolean;
  statusCode?: number;
};

export class RootStore {
  ApiClient: RestClient;
  transportLayer: TransportLayer;
  authStore: AuthStore;
  sessionStore: SessionStore;
  usersStore: UsersStore;
  userListsStore: UserListsStore;
  teamListsStore: TeamsListsStore;
  teamsStore: TeamsStore;
  academyStore: AcademyStore;
  dashboardStore: DashboardStore;
  guidosStore: GuidosStore;
  guidosListsStore: GuidosListsStore;
  companyStore: CompanyStore;
  authorizeStore: AuthorizeStore;
  admDashboardStore: AdmDashboardStore;
  signUpStore: SignUpStore;
  orgEventStore: LogEventsStore;
  orgListStore: OrgListStore;
  guidoListStore: GuidoListStore;

  constructor(history: H.History<H.LocationState>) {
    this.ApiClient = new RestClient(history);
    this.transportLayer = new TransportLayer(this.ApiClient);
    this.authStore = new AuthStore(this);
    this.sessionStore = sessionStore;
    this.usersStore = new UsersStore(this);
    this.userListsStore = new UserListsStore(this);
    this.teamsStore = new TeamsStore(this);
    this.academyStore = new AcademyStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.teamListsStore = new TeamsListsStore(this);
    this.guidosStore = new GuidosStore(this);
    this.guidosListsStore = new GuidosListsStore(this);
    this.companyStore = new CompanyStore(this);
    this.authorizeStore = new AuthorizeStore(this);
    this.admDashboardStore = new AdmDashboardStore(this);
    this.signUpStore = new SignUpStore(this);
    this.orgEventStore = new LogEventsStore(this);
    this.orgListStore = new OrgListStore(this);
    this.guidoListStore = new GuidoListStore(this);
  }

  setHistory(history: H.History<H.LocationState>) {
    this.ApiClient.setHistory(history);
  }
}
