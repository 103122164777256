import { Button, Col, Form, Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import Avatar from 'antd/es/avatar/avatar';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../analytics/googleAnalytics';
import { GdCompanyForm } from '../../components/GdCompanyForm';
import { PageTitle } from '../../components/PageTitle';
import Text from '../../components/Text';
import { useRootStore } from '../../context/storeContext';
import {
  buildImageUrlFromBase64,
  getResultAndShowNotification,
  readImage,
} from '../../utils';
import { ButtonsContainer, UserContainer } from '../Common/styles';
import { AvatarImage, ImageInput } from '../Users/components/EditUser';

const CompanyEditContainer = styled(UserContainer)`
  & .gd-upload-image {
    display: flex;
    align-items: start;
    flex-direction: column;
    padding-top: 33px;
  }

  & .gd-company-edit-form-container {
    padding-top: 20px;
  }

  & .gd-edit-company-form,
  & .gd-company-edit-form-container {
    width: 100%;
  }

  & .signup-form-item-row:not(:last-child) {
    padding-bottom: 0;
  }

  ${ButtonsContainer} {
    margin-top: 15px;
  }
`;

export const CompanyEdit = observer(() => {
  const { t } = useTranslation();
  const sendAnalyticEvent = useAnalyticsEventTracker('CompanyEdit');
  const [image, setImage] = useState<AvatarImage>({ src: '', file: null });
  const [form] = Form.useForm();

  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const { authStore, dashboardStore, companyStore } = useRootStore();
  const {
    signUpMeta: { companyBranch, userCounts },
  } = authStore;

  useEffect(() => {
    async function fetchMeta() {
      dashboardStore.getCompanyName();
      const response = await Promise.all([
        authStore.getSignUpMeta(),
        companyStore.getCompanyDetails(dashboardStore.companyId),
      ]);
      const companyDetails: { [key: string]: string } | null = response[1] as {
        [key: string]: string;
      } | null;

      if (companyDetails) {
        const fields = Object.keys(companyDetails).map((key: string) => ({
          name: key,
          value: companyDetails[key],
        }));
        form.setFields(fields);

        if (companyDetails.logo) {
          setImage({
            src: buildImageUrlFromBase64(companyDetails.logo),
            file: null,
          });
        }
      }
    }

    fetchMeta();
  }, [form, authStore, companyStore, dashboardStore]);

  const onImageChanged = useCallback(
    async (file) => {
      const src = await readImage(file);

      setImage({ src, file });
    },
    [setImage]
  );

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onClickSave = useCallback(() => {
    sendAnalyticEvent(
      'Save Company Details',
      `CompanyID ${dashboardStore.companyId}`
    );
    form.submit();
  }, [dashboardStore.companyId, form, sendAnalyticEvent]);

  const updateSignedUserCompanyName = useCallback(
    (companyName) => {
      authStore.updateSignedUserCompanyName(companyName);
    },
    [authStore]
  );

  const onFinish = useCallback(
    async (values) => {
      setLoading(true);
      const res = await getResultAndShowNotification(
        async () => await companyStore.updateCompany(values, image.file),
        t('company:Notifications:companyUpdateSuccess'),
        t('company:Notifications:companyUpdateFailed'),
        () => {}
      );
      if (res.succeeded) {
        updateSignedUserCompanyName(values.name);
      }
      setLoading(false);
    },
    [image, companyStore, t, updateSignedUserCompanyName]
  );

  return (
    <CompanyEditContainer>
      <Col xs={12} sm={20} md={12} lg={12} xl={8} xxl={8}>
        <Row>
          <PageTitle title={t('company:Settings:mainTitle')} />
        </Row>
        <Row className="gd-upload-image">
          <Text fontSize={14}>Company Image</Text>
          <ImageInput>
            <Avatar size={64} src={image.src} />
            <ImgCrop rotate onModalOk={onImageChanged}>
              <Upload fileList={[]} customRequest={() => Promise.resolve('')}>
                <Button type="link">Upload New Image</Button>
              </Upload>
            </ImgCrop>
          </ImageInput>
        </Row>
        <Row className="gd-company-edit-form-container">
          <GdCompanyForm
            userCounts={userCounts}
            companyBranch={companyBranch}
            form={form}
            useSubmitButtons
            onFinish={onFinish}
            onSubmit={onClickSave}
            onCancel={onCancel}
            isLoading={loading}
          />
        </Row>
      </Col>
    </CompanyEditContainer>
  );
});
