import { Button } from 'antd';
import styled from 'styled-components/macro';

export type Command = {
  title: string;
  handler: () => void;
  isDelete?: boolean;
};

type Props = {
  commands: Command[];
};

export const CommandBarContainer = styled.div`
  & .ant-btn-link {
    color: ${({ theme: { colors } }) => colors.strongBlue};
    font-family: ${({ theme: { fonts } }) => fonts.bold};
  }

  & .gd-delete-btn {
    color: red;
  }
`;

export const CommandBar = ({ commands }: Props) => {
  return (
    <CommandBarContainer>
      {commands.map((command, idx) => (
        <Button
          key={`${command.title}-${idx}`}
          type="link"
          title={command.title}
          onClick={command.handler}
          className={command.isDelete ? 'gd-delete-btn' : ''}
        >
          {command.title}
        </Button>
      ))}
    </CommandBarContainer>
  );
};
