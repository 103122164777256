import { Col, Row, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import styled from 'styled-components/macro';

import {
  DISTANCE_BETWEEN_FILTER_INPUTS,
  GdFilterBar,
} from '../../../../components/GdFilterBar';
import { GdList, ItemMode } from '../../../../components/GdList';
import { GdListHeader } from '../../../../components/GdListHeader';
import { GdLoadingIndicator } from '../../../../components/GdLoadingIndicator';
import { useRootStore } from '../../../../context/storeContext';
import { useBaseSearchMeta } from '../../../../hooks/useBaseSearchMeta';
import { singleSearchItemControlStyle } from '../../../../theme/CssResetStyles';
import { setMaxListHeight } from '../../../../utils';
import { ListContainer, clearAntListSpinner } from '../../../Common/styles';
import { useGetOrgUsersQuery } from '../../hooks/useGetOrgUsersQuery';
import { useUserSettingsRenderingMeta } from '../../hooks/useUserSettingsRenderingMeta';
import { SaveSettingsButtons } from './SaveSettingsButtons';

const ROW_HEIGHT = 35;

export const OrgManageUsers = observer(() => {
  const { columnsMeta, headersMeta } = useUserSettingsRenderingMeta();
  const { users, loadMoreUsers, loading } = useGetOrgUsersQuery();
  const formMeta = useBaseSearchMeta();

  const { orgListStore } = useRootStore();
  const { selectedOrg } = orgListStore;

  const onUserToggled = useCallback(
    (user, lockState) => {
      selectedOrg.toggleOrgUser!(user.email, lockState);
    },
    [selectedOrg]
  );

  const onSearchUsers = useCallback(
    (value) => {
      selectedOrg.setUsersFilter!(value.searchText);
    },
    [selectedOrg]
  );

  const onResetSettings = useCallback(() => {
    selectedOrg.resetUsers!();
  }, [selectedOrg]);

  const onSaveSettings = useCallback(async () => {
    await selectedOrg.lockUnlockUsers!();
  }, [selectedOrg]);

  return (
    <OrgManageUsersContainer>
      <UsersListContainer>
        <Row>
          <Col span={12}>
            <GdFilterBar
              inputsGutter={DISTANCE_BETWEEN_FILTER_INPUTS}
              meta={formMeta}
              onFilter={onSearchUsers}
            />
          </Col>
          <Col span={12} className="gd-save-users-lock-state">
            {selectedOrg.isUsersSettingIsDirty && (
              <SaveSettingsButtons
                onSave={onSaveSettings}
                onReset={onResetSettings}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GdListHeader columns={headersMeta} topOffset={25} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GdList
              split
              editable
              onItemCheck={onUserToggled}
              itemMode={ItemMode.Basic}
              data={users}
              loadMore={loadMoreUsers}
              columnMeta={columnsMeta}
              totalCount={selectedOrg.usersAmountInfo!.amountLeft}
              fixScrollStartCellNumber={1}
              maxHeight={
                users.length
                  ? setMaxListHeight(users, ROW_HEIGHT)
                  : 2 * ROW_HEIGHT
              }
              loading={{
                spinning: loading,
                size: 'large',
                indicator: <Spin indicator={<GdLoadingIndicator />} />,
              }}
              rowHeight={ROW_HEIGHT}
              useMaxHeight
            />
          </Col>
        </Row>
      </UsersListContainer>
    </OrgManageUsersContainer>
  );
});

const OrgManageUsersContainer = styled.div``;
const UsersListContainer = styled(ListContainer)`
  width: 100%;
  & > div {
    width: 100%;
  }
  ${clearAntListSpinner()}

  ${singleSearchItemControlStyle()}

  & .gd-list-title {
    padding-left: 0;
    font-family: ${({ theme: { fonts } }) => fonts.semiBold};

    &.gd-list-title_regular {
      font-family: ${({ theme: { fonts } }) => fonts.medium};
    }
  }

  & .gd-save-users-lock-state {
    display: flex;
    justify-content: end;
    align-items: end;
    flex: 0 0 40%;
  }
`;
