import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Analytics, useAnalytic } from '../analytics/googleAnalytics';
import { LandingPage } from '../domain/Common';
import { ConfirmEmail } from '../domain/Identity/components/ConfirmEmail';
import { ForgotPassword } from '../domain/Identity/components/ForgotPassword';
import { ResetPassword } from '../domain/Identity/components/ResetPassword';
import { SignInPage } from '../domain/Identity/SignIn';
import { SignUpPageWizard } from '../domain/Identity/SignUp';
import SignUpPage from '../domain/Identity/SignUp/SignUpPage';
import ProtectedRoute from './ProtectedRoute';

export const GdRouter = () => {
  const initialized = useAnalytic();
  return (
    <Router>
      <Analytics initialized={initialized}>
        <Switch>
          <Route path="/signin">
            <SignInPage />
          </Route>
          <Route exact path="/forgotpassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/resetpassword">
            <ResetPassword />
          </Route>
          <Route exact path="/confirmemail">
            <ConfirmEmail />
          </Route>
          <Route exact path="/confirmemailchange">
            <ConfirmEmail />
          </Route>
          <Route path="/register">
            <SignUpPageWizard />
          </Route>
          <Route path="/signup">
            <SignUpPage />
          </Route>
          <ProtectedRoute path="/" component={LandingPage} />
        </Switch>
      </Analytics>
    </Router>
  );
};
