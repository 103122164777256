import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsMeta, GdListHeader } from '../../../components/GdListHeader';
import { useRootStore } from '../../../context/storeContext';
import { SortDirection } from '../../../types/commonTypes';

export const GuidosListHeader = observer(() => {
  const { t } = useTranslation();
  const { guidosStore } = useRootStore();
  const sortingHandler = useCallback(
    (order, field) => {
      guidosStore.sortGuidos(order, field);
    },
    [guidosStore]
  );

  const columns: ColumnsMeta = useMemo(() => {
    return {
      tittle: {
        title: t('guidos:ListHeader:titleLbl'),
        colspan: 4,
        leftPadding: 24,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      size: {
        title: 'Size',
        colspan: 2,
      },
      createdDateUtc: {
        title: t('guidos:ListHeader:creationDateLbl'),
        colspan: 4,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      lastUpdatedDateUtc: {
        title: t('guidos:ListHeader:lastUpdateLbl'),
        colspan: 4,
      },
      owner: {
        title: t('guidos:ListHeader:ownerLbl'),
        colspan: 3,
        sortingHandler,
        direction: SortDirection.Asc,
      },
      shared: {
        title: t('guidos:ListHeader:sharedLbl'),
        colspan: 2,
      },
      published: {
        title: t('guidos:ListHeader:publishedLbl'),
        colspan: 3,
      },
    };
  }, [sortingHandler, t]);
  return <GdListHeader activeSortField={'createdDateUtc'} columns={columns} />;
});
