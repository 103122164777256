import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { GdCollapse } from '../../../../components/GdCollapse';
import { GdList, ItemMode } from '../../../../components/GdList';
import { GdListHeader } from '../../../../components/GdListHeader';
import { GdLoadingIndicator } from '../../../../components/GdLoadingIndicator';
import { IconInt } from '../../../../components/Icons';
import { EMPTY_STRING } from '../../../../constants';
import { useRootStore } from '../../../../context/storeContext';
import { useDataQueryById } from '../../../../hooks/useDataQuery';
import { SortDirection } from '../../../../types/commonTypes';
import { setMaxListHeight } from '../../../../utils';
import { CollapsableListContainer } from '../../../Common/styles';
import { useGuidosMeta } from '../../hooks/useGuidosMeta';
import { UserGuido } from '../../stores/userListsStore';
import { PanelBodyContainer } from '../../styled';

const UserGuidosListContainer = styled(CollapsableListContainer)``;

type Props = {
  guidosType: string;
};

export const UserGuidosList = observer(({ guidosType }: Props) => {
  const { usersStore, userListsStore } = useRootStore();
  const { userId } = useParams<{ userId: string }>();
  const { assignedGuidos, createdGuidos } = usersStore.selectedUser;
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState(false);
  const { columnMeta, listMeta } = useGuidosMeta(guidosType);

  useEffect(() => {
    userListsStore.sortProperty(guidosType, SortDirection.Asc, 'creationDate');
  }, [guidosType, userListsStore]);

  const loadOwned = useCallback(
    async (userId, body, loadMore) => {
      await userListsStore.getGuidos(userId, body, guidosType, loadMore);
    },
    [userListsStore, guidosType]
  );

  const { loading, loadMore, loadAssigned } = useDataQueryById({
    id: userId,
    loadOwned,
  });

  const onEdit = useCallback(() => {
    setEditMode(!editMode);
  }, [editMode]);

  const onGuidoChecked = useCallback(
    (guido, checked) => {
      userListsStore.setChecked(guidosType, guido.id, checked);
      userListsStore.setAllChecked(guidosType);
    },
    [guidosType, userListsStore]
  );

  const onCheckAll = useCallback(
    (checked) => {
      userListsStore.setChecked(guidosType, 0, checked);
    },
    [guidosType, userListsStore]
  );

  const onEditCancelHandler = useCallback(() => {
    setEditMode(false);
    userListsStore.clearEditState(guidosType);
    userListsStore.setAllChecked(guidosType);
  }, [guidosType, userListsStore]);

  const onEditSaveHandler = useCallback(() => {}, []);

  const onPanelActive = useCallback(
    async (activePanels: string[]) => {
      userListsStore.clearGuidos(guidosType);
      if (activePanels.length) {
        await loadAssigned();
      } else if (editMode) {
        onEditCancelHandler();
      }
    },
    [loadAssigned, onEditCancelHandler, editMode, userListsStore, guidosType]
  );

  const onLoadMore = useCallback(async () => {
    await loadMore(
      EMPTY_STRING,
      false,
      (userListsStore as any)[guidosType].length
    );
  }, [userListsStore, loadMore, guidosType]);

  const headerText =
    guidosType === 'assignedGuidos'
      ? t('users:UserDetails:Lists:guidosAssignedListLbl', {
          number: assignedGuidos,
        })
      : t('users:UserDetails:Lists:guidosCreateListLbl', {
          number: createdGuidos,
        });

  const { createdGuidoscheckAll, assignedGuidoscheckAll } = userListsStore;
  return (
    <UserGuidosListContainer>
      <GdCollapse
        icon={<IconInt icon="RoadMap" />}
        headerText={headerText}
        editable={false}
        onActive={onPanelActive}
        onEdit={onEdit}
        editControls={{
          editCancelText: 'Cancel',
          editSaveText: 'Save Changes',
          onEditCancel: onEditCancelHandler,
          onEditSave: onEditSaveHandler,
        }}
      >
        <PanelBodyContainer>
          <GdListHeader
            onCheckBoxTriggered={onCheckAll}
            checkAll={
              guidosType === 'assignedGuidos'
                ? assignedGuidoscheckAll
                : createdGuidoscheckAll
            }
            edit={editMode}
            columns={columnMeta}
            topOffset={25}
          />
          <GdList
            split
            editable={editMode}
            onItemCheck={onGuidoChecked}
            loadMore={onLoadMore}
            itemMode={ItemMode.Basic}
            data={(userListsStore as any)[guidosType]}
            columnMeta={listMeta}
            totalCount={
              (userListsStore as any).guidosListInfo[guidosType].amountLeft
            }
            maxHeight={setMaxListHeight<UserGuido>(
              (userListsStore as any)[guidosType],
              35
            )}
            loading={{
              spinning: loading,
              size: 'large',
              indicator: <Spin indicator={<GdLoadingIndicator size={12} />} />,
            }}
            rowHeight={35}
            useMaxHeight
          />
        </PanelBodyContainer>
      </GdCollapse>
    </UserGuidosListContainer>
  );
});
