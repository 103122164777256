import { Row } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { CommandBar } from '../../components/CommandBar';
import { PageTitle } from '../../components/PageTitle';
import { useRootStore } from '../../context/storeContext';
import { CommandsContainer } from '../Common/styles';
import { GuidosFilters } from './components/GuidosFilters';
import { GuidosList } from './components/GuidosList';
import { GuidosListHeader } from './components/GuidosListHeader';

const GuidosPageContainer = styled.div`
  width: 100%;
  padding-right: 46px;
`;

export const GuidosPage = () => {
  const { t } = useTranslation();
  const { guidosStore, authorizeStore } = useRootStore();

  useEffect(() => {
    return () => {
      guidosStore.clearGuidos();
    };
  }, [guidosStore]);

  const createNewGuidoHandler = useCallback(() => {
    window.open('https://web.guided-doing.com', '_blank');
  }, []);

  const guidoCommands = authorizeStore.isAuthorizedToAddNewGuido()
    ? [
        {
          title: t('guidos:Commands:addNewGuidosLbl'),
          handler: createNewGuidoHandler,
        },
      ]
    : [];
  return (
    <GuidosPageContainer>
      <Row>
        <CommandsContainer>
          <PageTitle title={t('guidos:mainTitle')} />
          <CommandBar commands={guidoCommands} />
        </CommandsContainer>
      </Row>
      <Row gutter={8}>
        <GuidosFilters />
      </Row>
      <Row>
        <GuidosListHeader />
      </Row>
      <Row>
        <GuidosList />
      </Row>
    </GuidosPageContainer>
  );
};
