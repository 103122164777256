import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useRootStore } from '../../../context/storeContext';
import { BasePageContainer, CardsDetailsContainer } from '../../Common/styles';
import { GuidoCommandBar } from './GuidoComandBar';
import { GuidoDetailsCard } from './GuidoDetailsCard';
import { GuidoDetailsList } from './GuidoDetailsList';

const GuidoDetailsContainer = styled(BasePageContainer)``;

export const GuidoDetails = observer(() => {
  const { guidoId } = useParams<{ guidoId: string }>();

  const { guidosStore } = useRootStore();

  useEffect(() => {
    async function getGroupDetails() {
      await guidosStore.getGuidoData(guidoId);
    }

    getGroupDetails();
  }, [guidoId, guidosStore]);

  return (
    <GuidoDetailsContainer>
      <Row>
        <GuidoCommandBar />
      </Row>
      <CardsDetailsContainer>
        <Row gutter={24}>
          <Col span={6}>
            <GuidoDetailsCard />
          </Col>
          <Col span={18} className="gd-details-list">
            <GuidoDetailsList />
          </Col>
        </Row>
      </CardsDetailsContainer>
    </GuidoDetailsContainer>
  );
});
