import {Row} from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { PageTitle } from '../../components/PageTitle';
import {
    BasePageContainer,
    CommandsContainer as TitleContainer,
} from '../Common/styles';
import FilterCssReset from '../Users/styled';
import { GuidosListHeader } from './components/GuidosListHeader';
import { GuidosList } from './components/GuidosList';
import { GuidosFilterBar } from './components/GuidosFilterBar';

const AdminOrganizationsContainer = styled(BasePageContainer)`
  height: calc(100vh - 214px);
`;

export const AdminGuidos = () => {
  const { t } = useTranslation();
  return (
    <AdminOrganizationsContainer>
      <FilterCssReset />
      <Row>
        <TitleContainer>
          <PageTitle isSuper title={t('admGuidos:Label:mainTitle')} />
        </TitleContainer>
      </Row>
      <Row>
        <GuidosFilterBar />
      </Row>
      <Row>
        <GuidosListHeader />
      </Row>
      <Row>
        <GuidosList />
      </Row>
    </AdminOrganizationsContainer>
  );
};
