import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { GdCollapse } from '../../../components/GdCollapse';
import { GdList, ItemMode, ListMeta } from '../../../components/GdList';
import { ColumnsMeta, GdListHeader } from '../../../components/GdListHeader';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { IconInt } from '../../../components/Icons';
import Text from '../../../components/Text';
import { EMPTY_STRING, GroupsCollection } from '../../../constants';
import { useRootStore } from '../../../context/storeContext';
import { useDataQueryById } from '../../../hooks/useDataQuery';
import { SortDirection } from '../../../types/commonTypes';
import {
  getResultAndShowNotification,
  setMaxListHeight,
  showLocalDate,
} from '../../../utils';
import { CollapsableListContainer } from '../../Common/styles';
import { PanelBodyContainer } from '../../Users/styled';
import { GuidoGroups } from '../stores/guidosListsStore';

const GuidoGroupListContainer = styled(CollapsableListContainer)``;

export const GuidoGroupList = observer(() => {
  const { t } = useTranslation();
  const { guidoId } = useParams<{ guidoId: string }>();
  const { guidosStore, guidosListsStore, authorizeStore } = useRootStore();
  const { assignedTeamsCount } = guidosStore.selectedGuido;

  const [editMode, setEditMode] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const sortingHandler = useCallback(
    (order, field) => {
      guidosListsStore.sortProperty(GroupsCollection, order, field);
    },
    [guidosListsStore]
  );

  const columns: ColumnsMeta = useMemo(() => {
    return {
      name: {
        colspan: 8,
        title: t('guidos:GroupList:nameColumnLbl'),
      },
      creationDate: {
        colspan: 8,
        title: t('guidos:GroupList:dateColumnLbl'),
        sortingHandler,
        direction: SortDirection.Asc,
      },
      users: {
        colspan: 7,
        title: t('guidos:GroupList:usersColumnLbl'),
      },
    };
  }, [t, sortingHandler]);

  const listMeta: ListMeta = useMemo(() => {
    return {
      name: {
        span: 8,
        render: (item) => {
          return (
            <Text fontSize={12} fontFamily="montserrat-regular">
              {item.name}
            </Text>
          );
        },
      },
      creationDate: {
        span: 8,
        render: (item) => {
          return (
            <Text fontSize={12} fontFamily="montserrat-regular">
              {showLocalDate(item.creationDate as string | number)}
            </Text>
          );
        },
      },
      users: {
        span: 7,
        render: (item) => {
          return (
            <Text fontSize={12} fontFamily="montserrat-regular">
              {item.users}
            </Text>
          );
        },
      },
    };
  }, []);

  const loadAvailable = useCallback(
    async (guidoId, body) => {
      await guidosListsStore.getGuidoAvailableTeams(guidoId, body);
    },
    [guidosListsStore]
  );

  const loadOwned = useCallback(
    async (guidoId, body, loadMore) => {
      await guidosListsStore.getGuidoTeams(guidoId, body, loadMore);
    },
    [guidosListsStore]
  );

  const { loading, loadAll, loadMore, loadAssigned } = useDataQueryById({
    id: guidoId,
    loadAvailable,
    loadOwned,
  });

  const onEdit = useCallback(async () => {
    const newMode = !editMode;
    setEditMode(newMode);
    if (newMode) {
      guidosListsStore.clearGroups();
      guidosListsStore.setAllChecked(GroupsCollection);
      await loadAll(EMPTY_STRING);
    } else {
      guidosListsStore.clearEditState(GroupsCollection);
    }
  }, [loadAll, editMode, guidosListsStore]);

  const onSearch = useCallback(
    async (searchText) => {
      guidosListsStore.setFilter(GroupsCollection, searchText);
      await loadAll(searchText);
    },
    [loadAll, guidosListsStore]
  );

  const onGroupChecked = useCallback(
    (group, checked) => {
      guidosListsStore.setChecked(GroupsCollection, group.id, checked);
      guidosListsStore.setAllChecked(GroupsCollection);
    },
    [guidosListsStore]
  );

  const onCheckAll = useCallback(
    (checked) => {
      guidosListsStore.setChecked(GroupsCollection, 0, checked);
    },
    [guidosListsStore]
  );

  const onEditCancelHandler = useCallback(() => {
    setEditMode(false);
    guidosListsStore.clearEditState(GroupsCollection);
    guidosListsStore.setAllChecked(GroupsCollection);
  }, [guidosListsStore]);

  const getNewGroupsList = useCallback(async () => {
    setEditMode(false);
    await loadAssigned();
    guidosStore.updateTeamsCount(guidosListsStore.groups.length);
  }, [guidosListsStore, guidosStore, loadAssigned]);

  const onEditSaveHandler = useCallback(async () => {
    setSaving(true);
    guidosListsStore.setFilter(GroupsCollection, EMPTY_STRING);
    await getResultAndShowNotification(
      async () => await guidosListsStore.updateTeamsAssignedToGuido(guidoId),
      t('users:Notifications:assignGroupsSuccess'),
      t('users:Notifications:assignGroupsFailed'),
      () => {}
    );
    setSaving(false);
    await getNewGroupsList();
  }, [getNewGroupsList, t, guidosListsStore, guidoId]);

  const onPanelActive = useCallback(
    async (activePanels: string[]) => {
      guidosListsStore.clearGroups();
      if (activePanels.length) {
        await loadAssigned();
      } else if (editMode) {
        onEditCancelHandler();
      }
    },
    [loadAssigned, onEditCancelHandler, editMode, guidosListsStore]
  );

  const { groupsListInfo, groups, groupscheckAll } = guidosListsStore;
  const isCollectionChanged =
    guidosListsStore.isCollectionChanged(GroupsCollection);

  const onLoadMore = useCallback(async () => {
    await loadMore(EMPTY_STRING, editMode, groups.length);
  }, [loadMore, editMode, groups]);

  const isGuidosEditable = authorizeStore.isAuthorizedToEditGuido();

  return (
    <GuidoGroupListContainer>
      <GdCollapse
        icon={<IconInt icon="RoadMap" />}
        headerText={t('guidos:GroupList:headerText', {
          number: assignedTeamsCount,
        })}
        editable={isGuidosEditable}
        showEditPenAlways={isGuidosEditable}
        onActive={onPanelActive}
        isInEditeMode={editMode}
        onEdit={onEdit}
        onSearch={onSearch}
        editControls={{
          isSaveDisabled: !isCollectionChanged || isSaving,
          editCancelText: 'Cancel',
          editSaveText: 'Save Changes',
          onEditCancel: onEditCancelHandler,
          onEditSave: onEditSaveHandler,
        }}
      >
        <PanelBodyContainer>
          <GdListHeader
            onCheckBoxTriggered={onCheckAll}
            checkAll={groupscheckAll}
            edit={editMode}
            columns={columns}
            topOffset={25}
          />
          <GdList
            split
            editable={editMode}
            loadMore={onLoadMore}
            onItemCheck={onGroupChecked}
            itemMode={ItemMode.Basic}
            data={groups}
            loading={{
              spinning: loading || isSaving,
              size: 'large',
              indicator: <Spin indicator={<GdLoadingIndicator size={12} />} />,
            }}
            columnMeta={listMeta}
            totalCount={groupsListInfo.amountLeft}
            maxHeight={setMaxListHeight<GuidoGroups>(groups, 35)}
            rowHeight={35}
            useMaxHeight
          />
        </PanelBodyContainer>
      </GdCollapse>
    </GuidoGroupListContainer>
  );
});
