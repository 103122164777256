import { action, makeObservable, observable, runInAction } from 'mobx';
import { BaseStore } from '../../../services/baseStore';
import { Result, RootStore } from '../../../services/rootStore';
import { Token } from '../../Identity/stores/authStore';

type HistoryItem = {
  name: string;
  createdAt: number;
  event: string;
};

type GetCompanyUsersInfo = {
  totalUsersCount: number;
  editorsCount: number;
  groupCounts: number;
  groupWithEditorsCount: number;
};

export class DashboardStore extends BaseStore<any> {
  rootStore: RootStore;
  companyUsersInfo: Partial<GetCompanyUsersInfo> = {
    totalUsersCount: 0,
    editorsCount: 0,
  };
  companyName: string = '';
  events: HistoryItem[] = [];
  companyId = '';

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
    makeObservable(this, {
      companyName: observable,
      getCompanyName: action,
      events: observable,
      companyUsersInfo: observable,
      companyId: observable,
    });
  }

  public getCompanyName() {
    const { sessionStore } = this.rootStore;

    const token: Token = sessionStore.getToken();
    const companyId = JSON.parse(
      atob(token.access_token.split('.')[1])
    ).tenantid;

    this.companyName = token.companyName;
    this.companyId = companyId;
  }

  public async getCompanyData() {
    const result = await this.rootStore.ApiClient.get<
      Result<GetCompanyUsersInfo>
    >(`/dashboard/companyUsersInfo/${this.companyId}`);

    runInAction(() => {
      this.companyUsersInfo = result.data;
    });
  }

  public async getEvents() {
    runInAction(() => {
      this.events = [
        {
          name: 'Eleanor Pena',
          createdAt: 1641925680619,
          event: 'Added new guido. Dog Trainer',
        },
        {
          name: 'Annette Black',
          createdAt: 1641925771203,
          event: 'Invited new user. Alex D',
        },
        {
          name: 'Darlene Robertson',
          createdAt: 1641925816426,
          event: 'Added new guido. Assemble Table',
        },
        {
          name: 'Marvin McKinney',
          createdAt: 1641925828956,
          event: 'Created new Team. Managers',
        },
      ];
    });
    await Promise.resolve();
  }
}
