import { Button, Col, Form, Input, Row } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { env } from '../../../env';
import { useQuery } from '../../../hooks/useQuery';
import {
  getResultAndShowNotification,
  validateConfirmPassword,
  validatePasswordRules,
} from '../../../utils';
import { ConfirmEmailType } from '../stores/authStore';
import {
  DetailsContainer,
  LogoImage,
  SignInFormContainer,
  SignInPageContainer,
} from '../styled';
import { RulesContainer } from './ResetPassword';
import { TextMainLogo } from './TextMainLogo';

const ConfirmEmailContainer = styled(SignInPageContainer)`
  @media (max-width: 912px) {
    ${LogoImage} {
      display: none;
    }

    background: ${({ theme: { colors } }) => colors.white};
  }
`;
const ConfirmEmailFormContainer = styled(SignInFormContainer)`
  & .gd-form-button-link-primary {
    margin-top: 20px;
  }

  @media (max-width: 912px) {
    margin: 0 15px;
    width: 100%;
    ${DetailsContainer} {
      justify-content: center;
      align-items: center;

      & > span {
        text-align: center;
      }
    }
  }

  @media (min-width: 768px) {
    margin: 0 150px;
  }
`;

export const ConfirmEmail = () => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const history = useHistory();
  const params = useQuery();
  const { colors } = useTheme();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const redirectToSignIn = useRef<boolean>(true);

  const isInvitedUser = Number(params.get('added')) === 1;

  const isConfirmemailchange = useMemo(() => {
    const currentRoute = history.location.pathname.replace('/', '');
    return 'confirmemailchange' === currentRoute;
  }, [history]);

  useEffect(() => {
    form.setFields([
      {
        name: 'email',
        value: params.get('mail'),
      },
    ]);
  }, [form, params]);

  const onCloseMessage = useCallback(() => {
    if (redirectToSignIn.current) {
      window.location.href = 'https://web.guided-doing.com/';
    }
  }, []);

  const redirectToSignInPage = useCallback((res) => {
    if (res.failed) {
      redirectToSignIn.current = false;
    }
  }, []);

  const sendConfirmEmailChange = useCallback(async () => {
    const body: Omit<ConfirmEmailType, 'added' | 'userId'> = {
      confirmationToken: params.get('emailConfirmToken'),
    };
    const res = await getResultAndShowNotification(
      async () => await authStore.confirmEmailChange(body),
      t('identity:ConfirmEmail:requestSuccess'),
      t('identity:ConfirmEmail:requestFailed'),
      onCloseMessage
    );
    redirectToSignInPage(res);
  }, [authStore, params]);

  const sendConfirmEmail = useCallback(
    async (values) => {
      let body: ConfirmEmailType = {
        userId: params.get('userId'),
        confirmationToken: params.get('emailConfirmToken'),
        added: !!Number(params.get('added')),
      };

      if (isInvitedUser) {
        body = {
          ...body,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
        };
      }
      const res = await getResultAndShowNotification(
        async () => await authStore.confirmEmail(body),
        t('identity:ConfirmEmail:requestSuccess'),
        t('identity:ConfirmEmail:requestFailed'),
        onCloseMessage
      );
      redirectToSignInPage(res);
    },
    [authStore, isInvitedUser, params]
  );

  const onFinish = useCallback(
    async (values) => {
      setLoading(true);

      if (isConfirmemailchange) {
        await sendConfirmEmailChange();
      } else {
        await sendConfirmEmail(values);
      }
      setLoading(false);
    },
    [isInvitedUser, params, t, onCloseMessage, authStore, isConfirmemailchange]
  );

  const onCancel = useCallback(async () => {
    if (isConfirmemailchange) {
      const body: Omit<ConfirmEmailType, 'added' | 'userId'> = {
        confirmationToken: params.get('emailConfirmToken'),
      };
      const res = await getResultAndShowNotification(
        async () => await authStore.cancelConfirmEmailChange(body),
        t('identity:ConfirmEmail:requestSuccess'),
        t('identity:ConfirmEmail:requestFailed'),
        () => {}
      );
      if (res.succeeded) {
        history.push('/signin');
        return;
      }
    }
    history.push('/signin');
  }, [history]);

  return (
    <ConfirmEmailContainer>
      <TextMainLogo />
      <LogoImage src={`${env.PUBLIC_URL}/resetLogo.png`} />
      <ConfirmEmailFormContainer>
        <DetailsContainer>
          <PageTitle
            title={t('identity:ConfirmEmail:mainTitle')}
            textSize={32}
          />
          <Text fontFamily="montserrat-medium" fontSize={14}>
            {t('identity:ConfirmEmail:mainText')}
          </Text>
        </DetailsContainer>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          {isInvitedUser && (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="gd-form-item"
                    label={<Text>{t('users:EditUser:Form:firstNameLbl')}</Text>}
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your First Name!',
                      },
                    ]}
                  >
                    <Input className="gd-input-item" placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="gd-form-item"
                    label={<Text>{t('users:EditUser:Form:lastNameLbl')}</Text>}
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Last Name!',
                      },
                    ]}
                  >
                    <Input className="gd-input-item" placeholder="Surname" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="password"
                    label={
                      <Text>{t('identity:ResetPassword:newPasswordLbl')}</Text>
                    }
                    className="gd-form-item gd-newpassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      validatePasswordRules(
                        t('identity:ErrorMessages:PasswordRules')
                      ),
                    ]}
                  >
                    <Input.Password
                      type="password"
                      className="gd-input-item"
                      placeholder="Password"
                      onChange={async () => {
                        await form.validateFields(['confirmPassword']);
                      }}
                    />
                  </Form.Item>
                  <RulesContainer>
                    <Text
                      fontSize={10}
                      fontFamily="montserrat-semi-bold"
                      color={colors.lightGreen}
                    >
                      {t('identity:ResetPassword:passwordRules')}
                    </Text>
                  </RulesContainer>
                  <Form.Item
                    name="confirmPassword"
                    label={
                      <Text>
                        {t('identity:ResetPassword:confirmNewPasswordLbl')}
                      </Text>
                    }
                    className="gd-form-item"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      validateConfirmPassword,
                    ]}
                  >
                    <Input.Password
                      type="password"
                      className="gd-input-item"
                      placeholder="Confirm Password"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Button
            type="primary"
            disabled={loading}
            htmlType="submit"
            className="gd-form-button gd-form-button-primary gd-form-button-full-width"
          >
            {t('identity:ConfirmEmail:continueBtnLbl')}
          </Button>
          <Button
            type="link"
            onClick={onCancel}
            className="gd-form-button gd-form-button-link-primary gd-form-button-full-width"
          >
            {isConfirmemailchange
              ? t('identity:ConfirmEmail:cancelConfirmation')
              : t('identity:ConfirmEmail:recognitionLinkBtn')}
          </Button>
        </Form>
      </ConfirmEmailFormContainer>
    </ConfirmEmailContainer>
  );
};
