import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GdFilterBar } from '../../../components/GdFilterBar';
import { useRootStore } from '../../../context/storeContext';

export const GuidosFilters = observer(() => {
  const { t } = useTranslation();
  const { guidosStore } = useRootStore();
  const filters = {
    name: {
      span: 12,
      label: t('guidos:Labels:searchLabel'),
      placeholder: t('guidos:Labels:searchPlaceHolder'),
      isSearch: true,
      style: { width: '336px' },
    },
    totalAmount: {
      span: 12,
      label: t('guidos:Labels:numberOfGuidosLbl'),
      disabled: true,
      bordered: false,
      className: 'gd-filter-count-input',
    },
  };

  const onFilter = useCallback(
    (values) => {
      guidosStore.setFilter(values);
    },
    [guidosStore]
  );

  return (
    <GdFilterBar
      meta={filters}
      onFilter={onFilter}
      initialValues={guidosStore.amountInfo}
    />
  );
});
