import { Button, Card, Col, Form, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GdCompanyForm } from '../../../../../components/GdCompanyForm';
import { PageTitle } from '../../../../../components/PageTitle';
import { useRootStore } from '../../../../../context/storeContext';
import {
  CreateAccountButtonsContainer,
  SignUpStepContainer,
} from '../../styled';
import { ContentProps } from '../Content';
import { CompanyDetailsInfo } from './shared/CompanyDetailsInfo';

export const CreateCompanyStep = observer(
  ({ onBack, onNext }: ContentProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { authStore, signUpStore } = useRootStore();
    const {
      signUpMeta: { companyBranch, userCounts },
    } = authStore;

    useEffect(() => {
      const {
        signUpRequest: { company },
      } = signUpStore;

      const fields = Object.keys(company)
        .filter((k) => k !== 'owner')
        .map((key: string) => ({
          name: key,
          //@ts-ignore
          value: company[key],
        }));
      form.setFields(fields);
    }, [form, signUpStore]);

    const onClickNext = useCallback(() => {
      form.submit();
    }, [form]);

    const onClickBack = useCallback(() => {
      const values = form.getFieldsValue();
      signUpStore.setTenantDetails(values);
      onBack();
    }, [form, signUpStore, onBack]);

    const onFinish = useCallback(
      (values) => {
        signUpStore.setTenantDetails(values);
        onNext();
      },
      [signUpStore, onNext]
    );

    return (
      <SignUpStepContainer>
        <PageTitle title={t('SignInUpPage:Text:SignUp:mainCompany')} />
        <Row className="company-details-sign-up-form" gutter={26}>
          <Col span={16}>
            <Card className="gd-card">
              <GdCompanyForm
                userCounts={userCounts}
                companyBranch={companyBranch}
                form={form}
                useSubmitButtons={false}
                onFinish={onFinish}
                isLoading={false}
                rowGutter={16}
              />
            </Card>
            <CreateAccountButtonsContainer>
              <Button
                type="text"
                className="gd-form-button gd-form-button-text"
                onClick={onClickBack}
              >
                {t('SignInUpPage:Buttons:Back')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="gd-form-button gd-form-button-primary"
                onClick={onClickNext}
              >
                {t('SignInUpPage:Buttons:Next')}
              </Button>
            </CreateAccountButtonsContainer>
          </Col>
          <Col span={8}>
            <CompanyDetailsInfo />
          </Col>
        </Row>
      </SignUpStepContainer>
    );
  }
);
