import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListMeta } from '../../../components/GdList';
import { ColumnsMeta } from '../../../components/GdListHeader';
import Text from '../../../components/Text';
import { SortDirection } from '../../../types/commonTypes';

const LogMetaItem = ({ data }: { data: string }) => (
  <Text fontSize={12} fontFamily="montserrat-regular">
    {data}
  </Text>
);

const useListMeta = (): ListMeta => {
  return useMemo(() => {
    return {
      date: {
        span: 3,
        render: (item) => {
          return <LogMetaItem data={item.date as string} />;
        },
      },
      time: {
        span: 3,
        render: (item) => {
          return <LogMetaItem data={item.time as string} />;
        },
      },
      eventOwner: {
        span: 4,
        render: (item) => {
          return <LogMetaItem data={item.eventOwner as string} />;
        },
      },
      event: {
        span: 12,
        render: (item) => {
          return <LogMetaItem data={item.event as string} />;
        },
      },
    };
  }, []);
};

const useColumnMeta = (): ColumnsMeta => {
  const { t } = useTranslation();

  const sortingHandler = useCallback((order, field) => {}, []);

  return useMemo(() => {
    return {
      date: {
        colspan: 3,
        title: t('admOrgs:ActivityLog:dateColumnHeader'),
      },
      time: {
        colspan: 3,
        title: t('admOrgs:ActivityLog:timeColumnHeader'),
        sortingHandler,
        direction: SortDirection.Asc,
      },
      eventOwner: {
        colspan: 6,
        title: t('admOrgs:ActivityLog:userColumnHeader'),
      },
      event: {
        colspan: 12,
        title: t('admOrgs:ActivityLog:actionTypeColumnHeader'),
      },
    };
  }, [t, sortingHandler]);
};

export const useActivityLogMeta = () => {
  const columnsMeta = useColumnMeta();
  const listMeta = useListMeta();
  return { columnsMeta, listMeta };
};
