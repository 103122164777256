import {
  ChartDataRequest,
  OrgsChartResponse,
} from '../domain/AdminDashboard/stores/admDashboardStore';
import {
  LockUnlockUsersCommand,
  SetSubscriptionCommand,
  Subscription,
  UpdateSubscriptionFeaturesCommand,
} from '../domain/AdminOrganizations/stores/objects/Organization';
import {
  OrgDetailsDto,
  OrgListItemDto,
} from '../domain/AdminOrganizations/stores/orgListStore';
import { CompanyDetails } from '../domain/Company/stores/companyStore';
import { GetTeamsQuery, Team } from '../domain/Groups/stores/teamsStore';
import { Guido } from '../domain/Guidos/stores/guidosStore';
import {
  ApplicationUser,
  GetUsersQuery,
  PlainObject,
} from '../domain/Users/stores/usersStore';
import { Result } from '../services/rootStore';
import { BaseQuery, ListResult } from '../types/commonTypes';
import { RestClient } from './restClient';

export type GuidoDetailsResponse = {
  publicLink: string;
};

export class TransportLayer {
  apiClient: RestClient;

  constructor(client: RestClient) {
    this.apiClient = client;
  }

  public async fetchUsers(
    query: Partial<GetUsersQuery>
  ): Promise<Result<ListResult<ApplicationUser>>> {
    return await this.apiClient.post<
      Partial<GetUsersQuery>,
      Result<ListResult<ApplicationUser>>
    >('/user/users', query);
  }

  public async fetchRolesMeta() {
    return await this.apiClient.get<Result<PlainObject>>('/meta/userRoles');
  }

  public async fetchAllSubscriptionTypes() {
    return await this.apiClient.get<Result<{ [key: string]: string }>>(
      '/admin/subscription/all'
    );
  }

  async fetchSubscription(subscriptionId: string) {
    return await this.apiClient.get<Result<Subscription>>(
      `/admin/subscription/subscription/${subscriptionId}`
    );
  }

  async setTenantSubscription(command: SetSubscriptionCommand) {
    return await this.apiClient.post<
      SetSubscriptionCommand,
      Result<Subscription>
    >('/admin/subscription/setTenantSubscription', command);
  }

  async updateSubscriptionFeatures(command: UpdateSubscriptionFeaturesCommand) {
    return await this.apiClient.put<
      UpdateSubscriptionFeaturesCommand,
      Result<any>
    >('/admin/subscription/updateTenantSubscriptionFeatureValues', command);
  }

  async fetchOrgs(query: BaseQuery) {
    return await this.apiClient.post<
      BaseQuery,
      Result<ListResult<OrgListItemDto>>
    >('/admin/tenant/tenants', query);
  }

  async fetchOrgById(orgId: string) {
    return await this.apiClient.get<Result<OrgDetailsDto>>(
      `/admin/tenant/tenantdetails/${orgId}`
    );
  }

  async generateOrganisationCSV() {
    return await this.apiClient.postDownload<any>(
      `/admin/tenant/ExportTenantsToCsv`,
      {
        fileName: 'companyList.csv',
      }
    );
  }

  async lockUnlockUsers(command: LockUnlockUsersCommand) {
    return await this.apiClient.put<LockUnlockUsersCommand, Result<string>>(
      '/admin/user/lock',
      command
    );
  }

  async fetchOrgUsersChartValues() {
    return await this.apiClient.get<Result<OrgsChartResponse>>(
      '/admin/Dashboard/organization-users/chart'
    );
  }

  async fetchOrgGuidosChartValues() {
    return await this.apiClient.get<Result<OrgsChartResponse>>(
      '/admin/Dashboard/organization-guidos/chart'
    );
  }

  async fetchOrgSubscriptionsChartValues() {
    return await this.apiClient.get<Result<OrgsChartResponse>>(
      '/admin/Dashboard/organization-subscriptions/chart'
    );
  }

  async fetchChartValuesByCategory(request: ChartDataRequest) {
    return await this.apiClient.post<
      ChartDataRequest,
      Result<{ values: { [key: string]: number } }>
    >('/admin/Dashboard/CategoryValuesByPeriod', request);
  }

  async fetchCompanyDetails(companyId: string) {
    return await this.apiClient.get<
      Result<Omit<CompanyDetails, 'logo'> & { logo: string }>
    >(`/company/company/${companyId}`);
  }

  async fetchAllGuidos(query: BaseQuery) {
    return await this.apiClient.post<BaseQuery, Result<ListResult<Guido>>>(
      '/guido/guidos',
      query
    );
  }

  async fetchGuidoDetails(guidoId: string) {
    return await this.apiClient.get<Result<GuidoDetailsResponse>>(
      `/guido/guido/${guidoId}`
    );
  }

  async fetchAllTeams(query: GetTeamsQuery) {
    return await this.apiClient.post<GetTeamsQuery, Result<ListResult<Team>>>(
      '/team/teams',
      query
    );
  }

  async prepareAcademy() {
    return await this.apiClient.post('/academy/prepareacademy', {});
  }
}
