import React, { useCallback, useMemo } from 'react';
import {useTranslation} from "react-i18next";
import { ListMeta } from '../../../components/GdList';
import { ColumnsMeta } from '../../../components/GdListHeader';
import { GdRoleSelector } from '../../../components/GdRoleSelector';
import Text from '../../../components/Text';
import { GroupsCollection } from '../../../constants';
import { useRootStore } from '../../../context/storeContext';
import { SortDirection } from '../../../types/commonTypes';
import { showLocalDate } from '../../../utils';

export const useUserGroupsMeta = (editMode: boolean) => {
  const columnMeta = useColumnsMeta();
  const listMeta = useListMeta(editMode);

  return { columnMeta, listMeta };
};

const useColumnsMeta = () => {
  const { userListsStore } = useRootStore();
  const { t } = useTranslation();
  const sortingHandler = useCallback(
    (order, field) => {
      userListsStore.sortProperty(GroupsCollection, order, field);
    },
    [userListsStore]
  );

  const columns: ColumnsMeta = useMemo(() => {
    return {
      name: {
        colspan: 8,
        title: t('users:UserDetails:Lists:guidoNameLbl'),
      },
      creationDate: {
        colspan: 8,
        title: t('users:UserDetails:Lists:guidoCreationDateLbl'),
        sortingHandler,
        direction: SortDirection.Asc,
      },
      role: {
        colspan: 7,
        title: t('users:UserDetails:Lists:userGroupRoleLbl'),
      },
    };
  }, [t, sortingHandler]);
  return columns;
};

const useListMeta = (isInEditMode: boolean) => {
  const { usersStore, userListsStore } = useRootStore();
  const onRoleChanged = useCallback(
    (item, value) => {
      const group = userListsStore.groups.find((g) => g.id === item.id);
      if (group) {
        userListsStore.updateProperty(group, 'role', value, GroupsCollection);
      }
    },
    [userListsStore]
  );

  const listMeta: ListMeta = useMemo(() => {
    return {
      name: {
        span: 8,
        render: (item) => {
          return (
            <Text fontSize={12} fontFamily="montserrat-regular">
              {item.name}
            </Text>
          );
        },
      },
      creationDate: {
        span: 8,
        render: (item) => {
          return (
            <Text fontSize={12} fontFamily="montserrat-regular">
              {showLocalDate(item.creationDate as string | number)}
            </Text>
          );
        },
      },
      role: {
        className: 'gd-input-item-role',
        span: 7,
        render: (item) => {
          const defaultRoleId =
            Object.keys(usersStore.roles).find(
              (key) => usersStore.roles[key] === 'Viewer'
            ) || '';

          return (
            <GdRoleSelector
              item={item}
              defaultRole={defaultRoleId}
              roles={usersStore.roles}
              onChange={(value) => onRoleChanged(item, value)}
              editMode={isInEditMode}
            />
          );
        },
      },
    };
  }, [onRoleChanged, isInEditMode, usersStore]);

  return listMeta;
};
