import { useCallback } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

import { PostfixComp } from './GdMeta';
import Text from './Text';

const overflowEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GdNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  ${overflowEllipsis}
`;

type GdNumberProps = {
  value: string | number;
  title: string;
  color?: string;
  fontFam?: string;
  numberFontSz?: number;
  titleFontSz?: number;
  postFix?: number | string;
  onNumberClicked?: (key?: string) => void;
};

export const ValueContainer = styled.span`
  display: flex;
  align-items: baseline;
  ${overflowEllipsis}
`;

export const GdNumber = ({
  value,
  title,
  color,
  fontFam,
  numberFontSz,
  titleFontSz,
  postFix,
  onNumberClicked = () => {},
}: GdNumberProps) => {
  const { colors, fonts } = useTheme();
  const textColor = color || colors.black048;
  const fontFamily = fontFam || fonts.medium;
  const nFontSize = numberFontSz || 24;
  const tFontSize = titleFontSz || 12;
  const onValueClicked = useCallback(() => {
    if (onNumberClicked) {
      onNumberClicked();
    }
  }, []);
  return (
    <GdNumberContainer onClick={onValueClicked}>
      <Text fontSize={tFontSize} fontFamily={fontFamily} color={textColor}>
        {title}
      </Text>
      <ValueContainer title={value as string}>
        <Text fontSize={nFontSize} fontFamily={fonts.medium}>
          {value}
        </Text>
        {postFix && <PostfixComp value={postFix} />}
      </ValueContainer>
    </GdNumberContainer>
  );
};
