import { Col, Row } from 'antd';
import React from 'react';
import styled, { useTheme } from 'styled-components/macro';

import {
  ActionsContainer,
  GdList,
  ItemMode,
} from '../../../../../../components/GdList';
import Text from '../../../../../../components/Text';
import { gdInputGeneral } from '../../../../../../theme/CssResetStyles';
import { useSignUpListMeta } from '../../../hooks/useSignUpListMeta';
import {
  AddListItemControl,
  DEFAULT_ITEMS_LIMIT,
  SignUpListProps,
} from './AddListItemControl';

export const SignUpList = <
  T extends { [key: string]: string | number | boolean | any }[]
>({
  placeholderText,
  validate,
  domain,
  items,
  onAddItem,
  actions,
  listName,
}: SignUpListProps<T>) => {
  const columnMeta = useSignUpListMeta(listName, actions);
  const theme = useTheme();
  return (
    <SignUpListContainer>
      <Row>
        <Col span={24}>
          <AddListItemControl
            onAddItem={onAddItem}
            domain={domain}
            validate={validate}
            items={items}
            placeholderText={placeholderText}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="gd-sign-up-list">
          <Text
            fontSize={12}
            color={theme.colors.black048}
            fontFamily={theme.fonts.semiBold}
          >{`${listName} Name - ${items.length}/${DEFAULT_ITEMS_LIMIT}`}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GdList
            split={false}
            itemMode={ItemMode.Card}
            data={items}
            columnMeta={columnMeta}
            totalCount={items.length}
            fixScrollStartCellNumber={1}
            maxHeight={items.length * 65}
            rowHeight={40}
            useMaxHeight
          />
        </Col>
      </Row>
    </SignUpListContainer>
  );
};

const SignUpListContainer = styled.div`
  padding: 5% 0px;
  width: 100%;

  & .gd-sign-up-list {
    padding: 0 8px;
  }

  & .gd-form-item,
  & .ant-list-item {
    padding: 6px;
  }

  & .gd-form-item {
    margin-bottom: 10px;
  }

  & .gd-form-item {
    & .gd-input-item {
      ${gdInputGeneral()};
      height: 56px !important;
      border: 1px solid ${({ theme }) => theme.colors.black};
    }
  }

  ${ActionsContainer} {
    justify-content: end;
  }
`;
