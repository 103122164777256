import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components/macro';

import {
  GdModal,
  ModalContentContainer,
} from '../../../../../components/GdModal';
import { IconInt } from '../../../../../components/Icons';
import { PageTitle } from '../../../../../components/PageTitle';
import Text from '../../../../../components/Text';
import { useRootStore } from '../../../../../context/storeContext';
import { Result } from '../../../../../services/rootStore';
import { getResultAndShowNotification } from '../../../../../utils';
import { GroupDto } from '../../../stores/signUpStore';
import { ExplanationTextContainer } from '../../../styled';
import { ListType } from '../../hooks/useSignUpListMeta';
import {
  AddListContainer,
  AddListContentContainer,
  CreateAccountButtonsContainer,
} from '../../styled';
import { ContentProps } from '../Content';
import { DEFAULT_ITEMS_LIMIT } from './shared/AddListItemControl';
import { AddUserModalBody } from './shared/AddUserModalBody';
import { SignUpList } from './shared/SignUpList';

const AddGroupContainer = styled(AddListContainer)`
  ${ModalContentContainer} {
    padding: 10px;
  }

  & .ant-modal-body {
    padding: 16px 11px;
  }
`;

export const AddGroupsStep = observer(({ onNext, onBack }: ContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { signUpStore } = useRootStore();

  const {
    signUpRequest: { users, groups },
  } = signUpStore;

  const [showModal, setShowModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupDto>({
    name: '',
    id: '',
    users: [],
  });

  const onAddGroup = useCallback(
    (groupName) => {
      signUpStore.addGroupToRegisterRequest(groupName);
    },
    [signUpStore]
  );
  const onDeleteGroup = useCallback(
    (item) => {
      signUpStore.removeGroupFromRegisterRequest(item.id);
    },
    [signUpStore]
  );

  const selectGroup = useCallback(
    (groupId) => {
      const newGroup = signUpStore.signUpRequest.groups.find(
        (g) => g.id === groupId
      );
      setSelectedGroup({ ...newGroup } as SetStateAction<GroupDto>);
    },
    [signUpStore]
  );

  const onInviteUserModalClicked = useCallback(
    (group) => {
      setShowModal(true);
      selectGroup(group.id);
    },
    [selectGroup]
  );

  const onCancelAddUserModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const getModalCss = useCallback(() => {
    return css`
      padding: 0;
    `;
  }, []);

  const onUserChecked = useCallback(
    (user, state) => {
      signUpStore.addRemoveUsersFromGroup(selectedGroup.id, [user], state);
      selectGroup(selectedGroup.id);
    },
    [selectedGroup, signUpStore, selectGroup]
  );
  const onAllUsersChecked = useCallback(
    (state) => {
      signUpStore.addRemoveUsersFromGroup(selectedGroup.id, users, state);
      selectGroup(selectedGroup.id);
    },
    [selectGroup, users, selectedGroup, signUpStore]
  );

  const onSignUp = useCallback(() => {
    getResultAndShowNotification(
      async () => {
        const res = await signUpStore.signUp();
        if (res.succeeded) {
          signUpStore.clearRegisterForms();
        }
        const userObject = { email: signUpStore.signUpRequest.owner.email };
        const data: Result<string> = {
          data: '',
          message: res.succeeded
            ? t('identity:Notification:userCreateSuccess', userObject)
            : res.message,
          failed: res.failed,
          succeeded: res.succeeded,
        };
        return Promise.resolve(data);
      },
      t('identity:Notification:successRegistrationTitle'),
      t('identity:Notification:failedRegistrationTitle'),
      () => {}
    );
    onNext();
  }, [onNext, t, signUpStore]);

  const updateCheckedUsers = useCallback(() => {
    const groupUserIds = selectedGroup.users.map((u) => u.id);
    return users.map((user) => {
      if (groupUserIds.includes(user.id)) {
        return { ...user, checked: true };
      }
      return { ...user };
    });
  }, [users, selectedGroup]);

  return (
    <AddGroupContainer>
      <AddListContentContainer>
        <Row className="gd-sign-up-row">
          <PageTitle title={t('identity:AddGroupsStep:mainTitle')} />
        </Row>
        <Row className="gd-sign-up-row">
          <ExplanationTextContainer>
            <Text fontSize={14} fontFamily={theme.fonts.medium}>
              {t('identity:AddGroupsStep:mainDescription')}
            </Text>
          </ExplanationTextContainer>
        </Row>
        <Row>
          <SignUpList
            validate={false}
            listName={ListType.Groups}
            items={groups}
            onAddItem={onAddGroup}
            actions={[
              (group) => {
                return (
                  <Text
                    fontSize={12}
                    color={theme.colors.black048}
                    fontFamily={theme.fonts.semiBold}
                  >
                    {group.users.length
                      ? `${group.users.length} of ${DEFAULT_ITEMS_LIMIT}`
                      : ''}
                  </Text>
                );
              },
              <IconInt
                key="add-users"
                icon="AddUserPlus"
                onClick={onInviteUserModalClicked}
              />,
              <DeleteOutlined key="delete-group" onClick={onDeleteGroup} />,
            ]}
            placeholderText={t('identity:AddGroupsStep:listPlaceHolder')}
          />
        </Row>
        <Row>
          <Col span={24}>
            <CreateAccountButtonsContainer>
              <Button
                type="text"
                className="gd-form-button gd-form-button-text"
                onClick={() => {
                  onBack();
                }}
                disabled={false}
              >
                {t('SignInUpPage:Buttons:Back')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={false}
                className="gd-form-button gd-form-button-primary"
                onClick={onSignUp}
              >
                {signUpStore.signUpRequest.groups.length
                  ? t('identity:AddGroupsStep:Buttons:inviteBtn')
                  : t('identity:AddGroupsStep:Buttons:skipBtn')}
              </Button>
            </CreateAccountButtonsContainer>
          </Col>
        </Row>
      </AddListContentContainer>
      <GdModal
        isVisible={showModal}
        disableButtons={false}
        useCss={getModalCss}
        isClosable
        onCancel={onCancelAddUserModal}
        onOkHandler={onCancelAddUserModal}
        okButtonText={t('identity:AddGroupsStep:Modal:nextBtn')}
        title={t('identity:AddGroupsStep:Modal:title')}
        renderBody={
          <AddUserModalBody
            usersToInvite={updateCheckedUsers()}
            onUserChecked={onUserChecked}
            onAllUsersChecked={onAllUsersChecked}
          />
        }
      />
    </AddGroupContainer>
  );
});
