import styled, {
  DefaultTheme,
  createGlobalStyle,
} from 'styled-components/macro';

import { ExplanationTextContainer } from '../../styled';

export const SignUpPageContainer = styled.div`
  background: ${({ theme: { colors } }) => colors.whiteF9};
  height: 100vh;
  padding: 0 20%;
  position: relative;
  display: flex;
  flex-direction: column;

  & .gd-sign-up-page-row {
    width: 100%;
  }
  & .gd-sign-up-page-row:nth-of-type(1) {
    flex: 1 1;
  }
  & .gd-sign-up-page-row:nth-of-type(2) {
    flex: 10 1;
  }
`;

export const CreateAccountButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const CreateAccountSignTextButton = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  color: ${({ theme: { colors } }) => colors.black048};
`;

export const SignUpWizardAntDCssReset = createGlobalStyle<{
  theme: DefaultTheme;
}>`
  .signup-step-icon {
    width: 36px;
    height: 36px;
    padding-top: 5px;
    border-radius: 8px;
    background: ${({ theme: { colors } }) => colors.white};
    border: 1px solid ${({ theme: { colors } }) => colors.black032};

    & > svg {
      width: 17px;
      height: 24px;
      color: ${({ theme: { colors } }) => colors.black032};
    }
    & > span {
      color: ${({ theme: { colors } }) => colors.white};
      margin-top: 3px;
    }
  }
  

  .ant-steps-item-active {
    & .signup-step-icon {
      background: ${({ theme: { colors } }) => colors.thinOrange};
      border: none;

      & > svg {
        color: ${({ theme: { colors } }) => colors.black};
      }
    }
    .signup-step-icon[aria-label="exclamation-circle"] {
      background:  ${({ theme: { colors } }) => colors.mediumRed};
      & > svg {
        color: ${({ theme: { colors } }) => colors.white};
      }
    }
  }

  .ant-steps-item-finish {
    & .signup-step-icon {
      background: ${({ theme: { colors } }) => colors.strongBlue};
      border: none;

      & > svg {
        color: ${({ theme: { colors } }) => colors.white};
      }
    }
  }

  .signup-steps-wizard-container {
    padding: 7% 1% 0 0%;

    & .ant-steps-item-title {
      font-weight: bold;
      font-size: 12px;
      line-height: 100%;
    }

    & .ant-steps-item-active,
    & .ant-steps-item-wait {
      & .ant-steps-item-tail::after {
        background-color: ${({ theme: { colors } }) => colors.ultraGrey};
      }
    }

    & .ant-steps-item-finish {
      & .ant-steps-item-tail::after {
        background-color: ${({ theme: { colors } }) => colors.strongBlue};
      }
    }

    &.ant-steps-label-vertical {
      & .ant-steps-item-tail {
        padding: 3.5px 0 3.5px 0;
      }
    }
  }

  .signup-card {
    background: ${({ theme: { colors } }) => colors.white};
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px
  }

  .signup-form-item-row:not(:last-child) {
    padding-bottom: 24px;
  }

  .gd-form-password {
    margin-bottom: 0;
  }
`;

export const SignUpStepContainer = styled.div`
  & .company-details-sign-up-form {
    padding-top: 26px;
  }
`;

export const AddListContainer = styled(SignUpStepContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const AddListContentContainer = styled.div`
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  ${ExplanationTextContainer} {
    width: 80%;
  }

  & .gd-sign-up-row {
    padding: 7% 6px 0 6px;
  }
`;
