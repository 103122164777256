import { TFunction } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';

import { IconInt } from '../../../components/Icons';
import { useRootStore } from '../../../context/storeContext';

const menuItems = (translate: TFunction, hiddenMenus: string[]) =>
  [
    {
      title: translate('landingPage:Labels:dashboardMenu'),
      key: 'landingPageDashboard',
      path: '/dashboard',
      icon: <IconInt icon="Home" />,
    },
    {
      title: translate('landingPage:Labels:usersMenu'),
      key: 'landingPageUsersMenu',
      path: '/users',
      icon: <IconInt icon="AddUser" />,
    },
    {
      title: translate('landingPage:Labels:groupsMenu'),
      key: 'landingPageGroupsMenu',
      path: '/groups',
      icon: <IconInt icon="AddUserGroup" />,
    },
    {
      title: translate('landingPage:Labels:guidosMenu'),
      key: 'landingPageGuidosMenu',
      path: '/guidos',
      icon: <IconInt icon="RoadMap" />,
    },
    {
      title: translate('landingPage:Labels:academyMenu'),
      key: 'landingPageAcademyMenu',
      path: '/academy',
      icon: <IconInt icon="Trophy" />,
    },
    // {
    //   title: translate('landingPage:Labels:historyMenu'),
    //   key: 'landingPageHistoryMenu',
    //   path: '/history',
    //   icon: <IconInt icon="Home" />,
    // },
    {
      title: '',
      key: 'landingPageHistoryMenu',
      path: '',
      icon: '',
    },
  ].filter((m) => !hiddenMenus.includes(m.key));

const superAdminMenus = (translate: TFunction) => [
  {
    title: translate('landingPage:Labels:Admin:dashboardMenu'),
    key: 'adminPageDashboard',
    path: '/admin/dashboard',
    icon: <IconInt icon="Home" />,
  },
  {
    title: translate('landingPage:Labels:Admin:orgMenu'),
    key: 'adminPageOrgMenu',
    path: '/admin/organizations',
    icon: <IconInt icon="AddUserGroup" />,
  },
  {
    title: translate('landingPage:Labels:Admin:guidoMenu'),
    key: 'adminPageGuidoMenu',
    path: '/admin/guidos',
    icon: <IconInt icon="RoadMap" />,
  },
  {
    title: translate('landingPage:Labels:Admin:fireBaseAnalytics'),
    key: 'adminPageFirebaseMenu',
    path: '/admin/firebaseanalytics',
    external: true,
    externalPath:
      'https://console.firebase.google.com/u/0/project/guided-doing/analytics/app/android:com.guideddoing.guido/overview',
    icon: null,
  },
  {
    title: translate('landingPage:Labels:Admin:googleAnalytics'),
    key: 'adminPageGoogleMenu',
    path: '/admin/googleanalytics',
    external: true,
    externalPath: 'https://analytics.google.com/',
    icon: null,
  },
  {
    title: '',
    key: 'adminDumy',
    path: '',
    icon: '',
  },
];

const Menu = styled.ul`
  list-style-type: none;
  padding: 95px 0 0 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled('li')<{ isActive: boolean; activeColor: string }>`
  padding: 0 0 0 37px;
  height: 48px;
  background-color: ${({ isActive, activeColor }) =>
    isActive ? activeColor : 'transparent'};
  ${({ isActive }) => {
    return isActive ? 'border-radius: 0px 48px 48px 0px;' : '';
  }}
  color: ${({ theme, activeColor, isActive }) =>
    theme.colors.strongBlue === activeColor && isActive
      ? theme.colors.white
      : theme.colors.black};
  display: flex;
  align-items: center;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  font-size: 14px;
  line-height: 100%;
  cursor: pointer;

  & > span > svg {
    margin-right: 15px;
  }

  &:last-of-type {
    margin-top: auto;
    margin-bottom: 20px;
  }
`;

export const NavigationMenu = withRouter(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { authStore } = useRootStore();
  const theme = useTheme();

  const menu = useMemo(() => {
    const getAdminMenus = () => {
      const hiddenMenus = authStore.signedInUser.isAdmin
        ? []
        : ['landingPageUsersMenu'];
      return menuItems(t, hiddenMenus);
    };
    const getSuperAdminMenus = () => {
      return superAdminMenus(t);
    };

    return authStore.signedInUser.isSuperAdmin
      ? getSuperAdminMenus()
      : getAdminMenus();
  }, [t, authStore.signedInUser]);

  const [currentOption, setSetCurrentOption] = useState<number>(0);

  useEffect(() => {
    const pathSnippets = history.location.pathname.split('/').filter((i) => i);

    const index = menu.findIndex((item) =>
      authStore.signedInUser.isSuperAdmin
        ? item.path === `/${pathSnippets[0]}/${pathSnippets[1]}`
        : item.path === `/${pathSnippets[0]}`
    );
    setSetCurrentOption(index);
  }, [authStore, history.location, menu]);

  const goToExtenrnal = useCallback((path) => {
    window.open(path, '_blank');
  }, []);

  const isExternal = useCallback((item) => {
    return item.external;
  }, []);

  const onMenuItemClicked = useCallback(
    (idx: number, item) => {
      return () => {
        setSetCurrentOption(idx);
        if (isExternal(item)) {
          goToExtenrnal(item.externalPath);
        } else {
          history.push(item.path);
        }
      };
    },
    [goToExtenrnal, isExternal, setSetCurrentOption, history]
  );

  const activeColor = useMemo(() => {
    return authStore.signedInUser.isSuperAdmin
      ? theme.colors.strongBlue
      : theme.colors.thinOrange;
  }, [authStore.signedInUser.isSuperAdmin, theme]);

  return (
    <Menu>
      {menu.map((m, idx) => (
        <MenuItem
          activeColor={activeColor}
          isActive={currentOption === idx}
          key={m.key}
          onClick={onMenuItemClicked(idx, m)}
        >
          {m.icon}
          {m.title}
        </MenuItem>
      ))}
    </Menu>
  );
});
