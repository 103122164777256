export const userEvents = {
  UserDeletedEvent: {
    action: 'Delete User',
    sourceProp: 'deletedUserName',
  },
  UserCreatedEvent: {
    action: 'User Created',
    sourceProp: 'userName',
  },
  UserRegisteredEvent: {
    action: 'User Registered',
    sourceProp: 'email',
  },
  UserEmailChangeRequestedEvent: {
    action: 'Email changed',
    sourceProp: 'user',
    destProp: 'newEmail',
  },
  CancelChangeEmailRequestEvent: {
    action: 'Cancel email change',
    sourceProp: 'email',
  },
  ChangedEmailConfirmedEvent: {
    action: 'Email confirmed',
    sourceProp: 'confirmedEmail',
  },
};
