import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import React, {
  ReactChild,
  ReactChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { ButtonsContainer } from '../domain/Common/styles';
import { FilterMeta, GdFilterBar } from './GdFilterBar';
import { IconInt } from './Icons';
import Text from './Text';

const { Panel } = Collapse;

type EditControlsMeta = {
  isSaveDisabled?: boolean;
  onEditCancel: () => void;
  onEditSave: () => void;
  editSaveText: string;
  editCancelText: string;
};

type GdCollapseProps = {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  headerText: string;
  icon?: JSX.Element;
  editable?: boolean;
  extended?: boolean;
  showEditPenAlways?: boolean;
  onEdit?: () => void;
  editControls?: EditControlsMeta;

  onSearch?: (searchText: string) => void;
  isInEditeMode?: boolean;
  onActive?: (activePanels: string[]) => void;
};

const PanelHeaderContainer = styled.span`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > span {
    padding-right: 16px;
  }
`;

const EditContainer = styled.div`
  & > span > svg {
    color: ${({ theme: { colors } }) => colors.black};
    opacity: 0.25;
  }
`;

type PanelHeaderProps = {
  text: string;
  icon: JSX.Element | undefined;
  editable: boolean;
  onEdit?: () => void;
};

const PanelHeader = ({ text, icon, editable, onEdit }: PanelHeaderProps) => {
  const { colors, fonts } = useTheme();
  const onEditClicked = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (editable && onEdit) {
        onEdit();
      }
    },
    [editable, onEdit]
  );

  return (
    <PanelHeaderContainer>
      <HeaderTitleContainer>
        {icon}
        <Text fontSize={15} color={colors.black} fontFamily={fonts.bold}>
          {text}
        </Text>
      </HeaderTitleContainer>
      {editable && (
        <EditContainer onClick={onEditClicked}>
          <IconInt icon="EditPen" />
        </EditContainer>
      )}
    </PanelHeaderContainer>
  );
};

const CollapseContainer = styled.div`
  & .gd-collapse {
    background-color: ${({ theme: { colors } }) => colors.white};

    & .gd-collapse-panel {
      border-bottom: 0;

      & .ant-collapse-content-box {
        padding: 0 0 0 20px;
      }
    }

    & .ant-collapse-header {
      display: flex;
      align-items: center;

      & .ant-collapse-header-text {
        width: 100%;
      }
    }

    & .ant-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    & .gd-search-form {
      padding-top: 20px;
    }
  }
`;

const PanelEditButtonsContainer = styled(ButtonsContainer)`
  margin-top: 15px;
`;

export const GdCollapse: React.FC<GdCollapseProps> = ({
  children,
  headerText,
  icon,
  editable,
  extended = false,
  showEditPenAlways = false,
  onEdit,
  editControls,
  onActive,
  onSearch,
  isInEditeMode,
}) => {
  const [canEdit, setCanEdit] = useState(false);
  const [renderChildren, setRenderChildren] = useState(false);
  const [activePanels, setActivePanels] = useState<string[]>([]);

  useEffect(() => {
    if (extended) {
      setRenderChildren(true);
      setActivePanels(['1']);
      if (onActive) {
        onActive(['1']);
      }
    }
  }, [extended]);

  const onCollapseChanged = useCallback(
    (activeItems, inEdit = false) => {
      setCanEdit(!canEdit);
      setRenderChildren(!renderChildren);
      setActivePanels(activeItems);
      if (onActive && !inEdit) {
        onActive(activeItems);
      }
    },
    [onActive, renderChildren, canEdit]
  );

  const onEditMode = useCallback(() => {
    if (!activePanels.length) {
      onCollapseChanged(['1'], true);
    }
    onEdit && onEdit();
  }, [onEdit, onCollapseChanged, activePanels]);

  const onTextChanged = useCallback(
    (data) => {
      const { searchText } = data;
      onSearch && onSearch(searchText);
    },
    [onSearch]
  );

  const PanelEditButtons = ({
    editControls: controls,
  }: Required<Pick<GdCollapseProps, 'editControls'>>) => {
    const {
      onEditCancel,
      onEditSave,
      editCancelText,
      editSaveText,
      isSaveDisabled = false,
    } = controls;

    const onCancelEditMode = useCallback(() => {
      onEditCancel();
    }, [onEditCancel]);
    return (
      <PanelEditButtonsContainer>
        <Button
          type="text"
          className="gd-form-button gd-form-button-text"
          onClick={onCancelEditMode}
        >
          {editCancelText}
        </Button>
        <Button
          disabled={isSaveDisabled}
          type="primary"
          htmlType="submit"
          className="gd-form-button gd-form-button-primary"
          onClick={onEditSave}
        >
          {editSaveText}
        </Button>
      </PanelEditButtonsContainer>
    );
  };

  const formMeta: FilterMeta = {
    searchText: {
      span: 10,
      label: 'Search',
      placeholder: 'Text to search',
      isSearch: true,
    },
  };

  return (
    <CollapseContainer>
      <Collapse
        onChange={onCollapseChanged}
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="gd-collapse"
        destroyInactivePanel
        activeKey={activePanels}
      >
        <Panel
          className="gd-collapse-panel"
          header={
            <PanelHeader
              editable={
                showEditPenAlways ||
                (canEdit && editable !== undefined && editable)
              }
              icon={icon}
              text={headerText}
              onEdit={onEditMode}
            />
          }
          key="1"
        >
          {(isInEditeMode as boolean) && editable && editControls && (
            <GdFilterBar meta={formMeta} onFilter={onTextChanged} />
          )}
          {renderChildren && children}
          {(isInEditeMode as boolean) && editable && editControls && (
            <PanelEditButtons editControls={editControls as EditControlsMeta} />
          )}
        </Panel>
      </Collapse>
    </CollapseContainer>
  );
};
