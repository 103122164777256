import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer } from '../domain/Common/styles';
import Text from './Text';

type Props = {
  form: FormInstance;
  userCounts: { [p: string]: string };
  companyBranch: { [p: string]: string };
  useSubmitButtons: boolean;
  isLoading?: boolean;
  onFinish: (values: { [p: string]: string | boolean | number }) => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  rowGutter?: number;
};

export const GdCompanyForm = ({
  form,
  userCounts,
  companyBranch,
  onFinish,
  onCancel = () => {},
  onSubmit = () => {},
  isLoading: loading,
  useSubmitButtons = false,
  rowGutter = 8,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Form
      name="signup-create-account"
      className="gd-edit-company-form"
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Row className="signup-form-item-row" gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            className="gd-form-item"
            label={<Text>{t('SignInUpPage:Labels:companyName')}</Text>}
            name="name"
            rules={[
              {
                required: true,
                message: `Company Name ${t('identity:Errors:empty')}`,
              },
            ]}
          >
            <Input
              className="gd-input-item"
              placeholder={t('SignInUpPage:Labels:companyName')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="signup-form-item-row" gutter={rowGutter}>
        <Col span={14}>
          <Form.Item
            className="gd-form-item"
            label={<Text>{t('SignInUpPage:Labels:street')}</Text>}
            name="street"
          >
            <Input
              className="gd-input-item"
              placeholder={t('SignInUpPage:Labels:street')}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="houseNumber"
            label={<Text>{t('SignInUpPage:Labels:houseNumber')}</Text>}
            className="gd-form-item"
          >
            <Input
              className="gd-input-item"
              placeholder={t('SignInUpPage:Labels:houseNumber')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="signup-form-item-row" gutter={rowGutter}>
        <Col span={6}>
          <Form.Item
            name="zipCode"
            label={<Text>{t('SignInUpPage:Labels:postCode')}</Text>}
            className="gd-form-item"
          >
            <Input
              className="gd-input-item"
              placeholder={t('SignInUpPage:Labels:postCodeLbl')}
            />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            name="city"
            label={<Text>{t('SignInUpPage:Labels:city')}</Text>}
            className="gd-form-item"
          >
            <Input
              className="gd-input-item"
              placeholder={t('SignInUpPage:Labels:city')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="signup-form-item-row" gutter={rowGutter}>
        <Col span={12}>
          <Form.Item
            name="companySizeId"
            label={<Text>{t('SignInUpPage:Labels:companySize')}</Text>}
            className="gd-form-item"
            rules={[
              {
                required: true,
                message: `Company size ${t('identity:Errors:empty')}`,
              },
            ]}
          >
            <Select
              className="gd-input-item"
              placeholder={t('SignInUpPage:Labels:companySize')}
            >
              {Object.keys(userCounts).map((jt) => (
                <Select.Option key={`${jt}-cs`} value={jt}>
                  {userCounts[jt]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="usersOfGuidoAppId"
            label={<Text>{t('SignInUpPage:Labels:usersOfGuido')}</Text>}
            className="gd-form-item"
            rules={[
              {
                required: true,
                message: `Guido users ${t('identity:Errors:empty')}`,
              },
            ]}
          >
            <Select
              className="gd-input-item"
              placeholder={t('SignInUpPage:Labels:usersOfGuido')}
            >
              {Object.keys(userCounts).map((jt) => (
                <Select.Option key={`${jt}-gu`} value={jt}>
                  {userCounts[jt]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row className="signup-form-item-row" gutter={rowGutter}>
        <Col span={14}>
          <Form.Item
            name="companyBranchId"
            label={<Text>{t('SignInUpPage:Labels:companyBranch')}</Text>}
            rules={[
              {
                required: true,
                message: `Company branch ${t('identity:Errors:empty')}`,
              },
            ]}
            className="gd-form-item"
          >
            <Select
              className="gd-input-item"
              placeholder={t('SignInUpPage:Labels:companyBranch')}
            >
              {Object.keys(companyBranch).map((jt) => (
                <Select.Option key={`${jt}-cs`} value={jt}>
                  {companyBranch[jt]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="vatId"
            label={<Text>{t('SignInUpPage:Labels:vatId')}</Text>}
            className="gd-form-item"
          >
            <Input
              className="gd-input-item"
              placeholder={t('SignInUpPage:Labels:vatId')}
            />
          </Form.Item>
        </Col>
      </Row>
      {useSubmitButtons && (
        <Row>
          <Col span={24}>
            <ButtonsContainer>
              <Button
                type="text"
                className="gd-form-button gd-form-button-text"
                onClick={onCancel}
                disabled={loading}
              >
                {t('users:EditUser:Form:cancelBtnLbl')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="gd-form-button gd-form-button-primary"
                onClick={onSubmit}
                disabled={loading}
                loading={loading}
              >
                {t('users:EditUser:Form:saveBtnLbl')}
              </Button>
            </ButtonsContainer>
          </Col>
        </Row>
      )}
    </Form>
  );
};
