import { Button, Form, Input } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { env } from '../../../env';
import { useQuery } from '../../../hooks/useQuery';
import {
  getResultAndShowNotification,
  validateConfirmPassword,
  validatePasswordRules,
} from '../../../utils';
import {
  DetailsContainer,
  LogoImage,
  SignInFormContainer,
  SignInPageContainer,
  SubmitContainer,
} from '../styled';
import { TextMainLogo } from './TextMainLogo';

const ResetPasswordContainer = styled(SignInPageContainer)``;
const ResetPasswordFormContainer = styled(SignInFormContainer)`
  & .gd-newpassword {
    margin-bottom: 5px;
  }
`;

export const RulesContainer = styled.div`
  padding: 0 115px 16px 5px;
`;

export const ResetPassword = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { authStore } = useRootStore();
  const params = useQuery();

  const [form] = Form.useForm();

  const onCloseMessage = useCallback(() => {
    history.push('/signin');
  }, [history]);

  const onFinish = useCallback(
    async (values) => {
      setLoading(true);
      const userId = params.get('userId');
      const passResetToken = params.get('passResetToken');
      const body = {
        userId,
        resetToken: passResetToken,
        password: values.password,
      };

      await getResultAndShowNotification(
        async () => await authStore.resetPassword(body),
        t('identity:ResetPassword:requestSuccess'),
        t('identity:ResetPassword:requestFailed'),
        onCloseMessage
      );

      setLoading(false);
    },
    [onCloseMessage, t, authStore, params]
  );

  return (
    <ResetPasswordContainer>
      <TextMainLogo />
      <LogoImage src={`${env.PUBLIC_URL}/resetLogo.png`} />
      <ResetPasswordFormContainer>
        <DetailsContainer>
          <PageTitle
            title={t('identity:ResetPassword:mainTitle')}
            textSize={32}
          />
          <Text fontFamily="montserrat-medium" fontSize={14}>
            {t('identity:ResetPassword:mainText')}
          </Text>
        </DetailsContainer>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="password"
            label={<Text>{t('identity:ResetPassword:newPasswordLbl')}</Text>}
            className="gd-form-item gd-newpassword"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              validatePasswordRules(t('identity:ErrorMessages:PasswordRules')),
            ]}
          >
            <Input.Password
              type="password"
              className="gd-input-item"
              placeholder="Password"
              onChange={async () => {
                await form.validateFields(['confirmPassword']);
              }}
            />
          </Form.Item>
          <RulesContainer>
            <Text
              fontSize={10}
              fontFamily="montserrat-semi-bold"
              color={colors.lightGreen}
            >
              {t('identity:ResetPassword:passwordRules')}
            </Text>
          </RulesContainer>
          <Form.Item
            name="confirmPassword"
            label={
              <Text>{t('identity:ResetPassword:confirmNewPasswordLbl')}</Text>
            }
            className="gd-form-item"
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              validateConfirmPassword,
            ]}
          >
            <Input.Password
              type="password"
              className="gd-input-item"
              placeholder="Confirm Password"
            />
          </Form.Item>
          <SubmitContainer>
            <Button
              type="primary"
              disabled={loading}
              htmlType="submit"
              className="gd-form-button gd-form-button-primary gd-form-button-full-width"
            >
              {t('identity:ResetPassword:changePasswordBtn')}
            </Button>
          </SubmitContainer>
        </Form>
      </ResetPasswordFormContainer>
    </ResetPasswordContainer>
  );
};
