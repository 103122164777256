import { useTranslation } from 'react-i18next';

import { ListMeta } from '../../../components/GdList';
import { ColumnsMeta } from '../../../components/GdListHeader';
import { SortDirection } from '../../../types/commonTypes';

export const useUserSettingsRenderingMeta = () => {
  const { t } = useTranslation();
  let columnsMeta: ListMeta = {
    name: {
      span: 7,
      overrideStyle: true,
      className: 'gd-list-title',
    },
    email: {
      span: 12,
    },
    role: {
      span: 4,
    },
  };

  columnsMeta = Object.keys(columnsMeta).reduce((acc, key) => {
    acc[key] =
      key !== 'name'
        ? {
            ...acc[key],
            overrideStyle: true,
            className: 'gd-list-title gd-list-title_regular',
          }
        : acc[key];
    return acc;
  }, columnsMeta);

  const headersMeta: ColumnsMeta = {
    name: {
      colspan: 8,
      title: t('admOrgs:ManageOrgModal:UsersTab:nameLbl'),
      direction: SortDirection.Asc,
      sortingHandler: (direction, field) => {},
    },
    email: {
      colspan: 12,
      title: t('admOrgs:ManageOrgModal:UsersTab:emailLbl'),
    },
    role: {
      title: t('admOrgs:ManageOrgModal:UsersTab:roleLbl'),
      colspan: 4,
    },
  };

  return { columnsMeta, headersMeta };
};
