import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useRootStore } from '../../context/storeContext';
import { FiltersBar } from './components/FiltersBar';
import { ListHeader } from './components/LIstHeader';
import { UserCommands } from './components/UserCommands';
import { UserList } from './components/UserList';
import CssUserPageResetStyles from './styled';

const UsersPageContainer = styled.div`
  width: 100%;
  padding-right: 46px;
`;

export const UsersPage = observer(() => {
  const { t } = useTranslation();

  const { usersStore } = useRootStore();

  useEffect(() => {
    return () => {
      usersStore.clearUsers();
    };
  }, [usersStore]);

  return (
    <UsersPageContainer>
      <CssUserPageResetStyles />
      <Row>
        <UserCommands title={t('users:mainTitle')} />
      </Row>
      <Row gutter={8}>
        <FiltersBar />
      </Row>
      <Row>
        <ListHeader />
      </Row>
      <Row>
        <UserList />
      </Row>
    </UsersPageContainer>
  );
});
