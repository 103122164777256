export const guidoEvents = {
  GuidoCreatedEvent: {
    action: 'Guido Created',
    sourceProp: 'title',
  },
  GuidoDeletedEvent: {
    action: 'Guido Deleted',
    sourceProp: 'title',
  },
  GuidoTitleUpdatedEvent: {
    action: 'Guido Title updated',
    sourceProp: 'oldTitle',
    destProp: 'title'
  },
  GuidoSharedEvent: {
    action: 'Guido Shared',
    sourceProp: 'guidoTitle',
  },
  GuidoAnnotationCreatedEvent: {
    action: 'Guido Annotation Created',
    sourceProp: 'guidoTitle',
  },
  GuidoFeedbackGivenEvent: {
    action: 'Guido Feedback given',
    sourceProp: 'guidoTitle',
  },
  GuidoArchivedEvent: {
    action: 'Guido archived',
    sourceProp: 'firebaseGuidoId',
    destProp: 'markedForDeletionOn',
  },
  GuidoSizeChangedEvent: {
    action: 'Guido size changed',
    sourceProp: 'title',
    destProp: 'NewSize',
  },
  GuidoRestoredEvent: {
    action: 'Guido restored',
    sourceProp: 'guidoId',
  }
};
