import { notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { Command, CommandBar } from '../../../components/CommandBar';
import { GdModal } from '../../../components/GdModal';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { CommandsContainer } from '../../Common/styles';

const GroupsCommandPanelContainer = styled(CommandsContainer)``;

type Props = {
  title: string;
};

export const GroupsCommandPanel = observer(({ title }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { teamsStore, authorizeStore } = useRootStore();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmGroup');

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const createNewGroupHandler = useCallback(() => {
    history.push('/groups/addgroup');
  }, [history]);

  const commands: Command[] = authorizeStore.isAuthorizedToAddNewGroup()
    ? [
        {
          title: t('groups:Labels:addGroupCommandLbl'),
          handler: createNewGroupHandler,
        },
      ]
    : [];

  if (
    Object.entries(teamsStore.selectedGroup).length &&
    authorizeStore.isAuthorizedToDeleteGroup()
  ) {
    const onDeleteGroup = () => {
      setShowModal(true);
    };
    commands.push({
      title: t('groups:Labels:deleteGroupCommandLbl'),
      handler: onDeleteGroup,
      isDelete: true,
    });
  }

  const onCloseDeleteModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const sendAnalytic = useCallback(
    (isSuccess: boolean | undefined) => {
      const state = isSuccess ? 'Success' : 'Failed';

      sendAnalyticEvent(
        'Delete Group Command Bar',
        `${state}|Group ${teamsStore.selectedGroup?.id} ${teamsStore.selectedGroup?.name} deleted`
      );
    },
    [sendAnalyticEvent, teamsStore]
  );

  const onDeleteTeam = useCallback(async () => {
    setLoading(true);
    const res =
      teamsStore.selectedGroup &&
      (await teamsStore.delete(teamsStore.selectedGroup.id as string));
    if (res?.failed) {
      notification.error({
        message: t('groups:DeleteModal:deleteFailedTitle'),
        description: res.message,
        placement: 'bottomRight',
        duration: 2,
      });
    }
    sendAnalytic(res?.succeeded);
    history.push('/groups');
    setLoading(false);
    setShowModal(false);
  }, [sendAnalytic, sendAnalyticEvent, teamsStore, t, history]);

  return (
    <>
      <GroupsCommandPanelContainer>
        <PageTitle title={title} />
        <CommandBar commands={commands} />
      </GroupsCommandPanelContainer>
      <GdModal
        isVisible={showModal}
        disableButtons={loading}
        isClosable={!loading}
        onCloseModal={onCloseDeleteModal}
        onOkHandler={onDeleteTeam}
        cancelButtonText={t('groups:DeleteModal:cancelBtnLbl')}
        okButtonText={t('groups:DeleteModal:deleteBtnLbl')}
        title={t('groups:DeleteModal:title')}
        renderBody={
          <Text fontSize={16} fontFamily="montserrat-regular">
            {teamsStore.selectedGroup &&
              t('groups:DeleteModal:bodyConfirmationText', {
                group: teamsStore.selectedGroup.name,
              })}
          </Text>
        }
      />
    </>
  );
});
