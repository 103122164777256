import { PresetStatusColorType } from 'antd/lib/_util/colors';
import dayjs, { Dayjs } from 'dayjs';
import { computed, makeAutoObservable, runInAction } from 'mobx';

import { DEFAULT_ITEMS_COUNT } from '../../../../constants';
import { Result } from '../../../../services/rootStore';
import { AmountInfo, GetUsersQuery } from '../../../Users/stores/usersStore';
import { OrgDetailsDto, OrgListStore } from '../orgListStore';
import {
  Feature,
  FeatureType,
  SettingItem,
  SettingValue,
} from './SettingsItem';
import { OrgUser } from './User';

export type LoginStateStatuses = 'Green' | 'Yellow' | 'Red';
export const Activity: { [key in LoginStateStatuses]: PresetStatusColorType } =
  {
    Red: 'error',
    Yellow: 'warning',
    Green: 'success',
  };

export type UsersByRoles = {
  viewers: number;
  managers: number;
  editors: number;
};

type FeatureObj = {
  [key in FeatureType]: SettingValue;
};

export type FeaturesLimits = {
  usersLimit: SettingValue;
  managersLimit: SettingValue;
  editorsLimit: SettingValue;
  viewerLimit: SettingValue;
  planName: SettingValue;
  guidosLimit: SettingValue;
  groupsLimit: SettingValue;
  storageLimit: SettingValue;
  subscriptionEndDate: SettingValue;
};

export type Subscription = {
  id: string;
  name: string;
  price: string;
  isDefault: boolean;
  isActive: boolean;
  features: Feature[];
};

export type SetSubscriptionCommand = {
  tenantId: string;
  subscriptionId: string;
};

export type UpdateSubscriptionFeaturesCommand = {
  tenantId: string;
  features: {
    data: SettingValue;
    featureType: FeatureType;
  }[];
};

export type LockUnlockUsersCommand = {
  usersLockData: { [p: string]: boolean };
};

export class Organization {
  private orgsStore: OrgListStore;
  settings: SettingItem<SettingValue>[] = [];
  rawFeatures: Partial<FeatureObj> = {
    EditorsLimit: 0,
    GuidosLimit: 0,
    GroupsLimit: 0,
    StorageSize: 0,
    ManagersLimit: 0,
    UsersLimit: 0,
    Subscription: '',
    SubscriptionEndDate: dayjs(0),
  };
  users: OrgUser[] = [];
  usersAmountInfo: AmountInfo = {
    amount: 0,
    totalAmount: 0,
    hasNext: false,
    amountLeft: 0,
  };
  usersFilter = {
    textToSearch: '',
  };
  id: string;
  name: string;
  private rawData: OrgDetailsDto;

  constructor(orgListStore: OrgListStore, rawOrgData: OrgDetailsDto) {
    this.orgsStore = orgListStore;
    this.rawData = rawOrgData;
    this.id = rawOrgData.id;
    this.name = rawOrgData.name;
    makeAutoObservable(this, {
      transportLayer: false,
      id: false,
      featuresLimits: computed,
      dataAsObject: computed,
      isSettingsDirty: computed,
      isUsersSettingIsDirty: computed,
    });
    const {
      subscription: { features, id },
    } = rawOrgData;

    this.setSettings(features, id);
  }

  get transportLayer() {
    return this.orgsStore.transportLayer;
  }

  get isSettingsDirty() {
    return this.settings.some((s) => s.idChanged);
  }
  get isUsersSettingIsDirty() {
    return this.users.some((u) => u.lockAction !== 'noop'); //&& !!this.users.length
  }

  get dataAsObject(): OrgDetailsDto & UsersByRoles {
    return {
      id: this.rawData.id || '',
      name: this.rawData.name || '',
      totalUsersCount: this.rawData.totalUsersCount || '',
      owner: this.rawData.owner || '',
      activity: this.rawData.activity || '',
      lastLogin: this.rawData.lastLogin || '',
      guidosCount: this.rawData.guidosCount || '',
      teamsCount: this.rawData.teamsCount || '',
      managers: this.getUsersCountByType(this.rawData, 'Admin', 0),
      editors: this.getUsersCountByType(this.rawData, 'Editor', 0),
      viewers: this.getUsersCountByType(this.rawData, 'Viewer', 0),
      subscription: this.rawData.subscription,
      subscriptionEndDate: this.rawData.subscriptionEndDate,
      usersByRoleCounts: this.rawData.usersByRoleCounts,
      totalCompanyGuidosSize: `${
        Number(this.rawData.totalCompanyGuidosSize) * 1024
      }`,
    };
  }
  get featuresLimits(): FeaturesLimits {
    return {
      usersLimit: this.formatLimit(Number(this.rawFeatures.UsersLimit)),
      managersLimit: this.formatLimit(Number(this.rawFeatures.ManagersLimit)),
      editorsLimit: this.formatLimit(Number(this.rawFeatures.EditorsLimit)),
      viewerLimit: this.formatLimit(Number(this.rawFeatures.UsersLimit)),
      planName: this.rawData.subscription.name || '',
      guidosLimit: this.formatLimit(Number(this.rawFeatures.GuidosLimit)),
      groupsLimit: this.formatLimit(Number(this.rawFeatures.GroupsLimit)),
      storageLimit: this.formatLimit(Number(this.rawFeatures.StorageSize)),
      subscriptionEndDate: dayjs(this.rawFeatures.SubscriptionEndDate as Dayjs),
    };
  }
  private getUsersCountByType<T, U extends OrgDetailsDto>(
    data: U,
    type: 'Viewer' | 'Admin' | 'Editor',
    defaultValue: T
  ) {
    return (
      (data.usersByRoleCounts && data.usersByRoleCounts[type]) || defaultValue
    );
  }

  private formatLimit(limitNumber: number) {
    return limitNumber >= 0 ? limitNumber || '0' : 'Unlimited';
  }

  private createSettingsAndFeatures(
    features: Feature[]
  ): [SettingItem<SettingValue>[], Partial<FeatureObj>] {
    const newFeatures: Partial<FeatureObj> = {};
    const settings = features.map((feature) => {
      const { data, featureType, isUnlimited } = feature;
      newFeatures[featureType] = isUnlimited ? -1 : data;

      if (featureType === 'SubscriptionEndDate') {
        newFeatures[featureType] = dayjs(data as string);
      }

      return new SettingItem(featureType, data, isUnlimited);
    });

    return [settings, newFeatures];
  }

  private setSettings(features: Feature[], subscriptionId: string) {
    const [settings, featuresObj] = this.createSettingsAndFeatures(features);
    settings.push(new SettingItem('Subscription', subscriptionId));
    this.settings = settings;
    this.rawFeatures = featuresObj;
  }

  updateSetting(name: string, value: SettingValue) {
    const setting = this.settings.find((os) => os.name === name);
    setting && setting.updateValue(value);
  }

  async saveSettings() {
    const settingsToUpdate = this.settings.filter((st) => st.idChanged);

    const results = [];
    const subscriptionResult = await this.saveSubscription(settingsToUpdate);
    results.push(subscriptionResult);

    if (subscriptionResult.succeeded) {
      const featuresResult = await this.saveFeatures(settingsToUpdate);
      results.push(featuresResult);
    }

    if (results.every((r) => r.succeeded)) {
      await this.refresh();
    } else {
      this.resetSettingsToDefaults();
    }

    return results;
  }

  private async saveFeatures(settingsToUpdate: SettingItem<SettingValue>[]) {
    const command: UpdateSubscriptionFeaturesCommand = {
      tenantId: this.id,
      features: settingsToUpdate.map((s) => ({
        data: s.currentValue,
        featureType: s.name,
        isUnlimited: s.isUnlimited,
      })),
    };

    return await this.transportLayer.updateSubscriptionFeatures(command);
  }
  private async saveSubscription(
    settingsToUpdate: SettingItem<SettingValue>[]
  ): Promise<Result<Subscription | string>> {
    const subIndex = settingsToUpdate.findIndex(
      (obj) => obj.name === 'Subscription'
    );
    if (subIndex > -1) {
      const [subscription] = settingsToUpdate.splice(subIndex, 1);
      const command: SetSubscriptionCommand = {
        tenantId: this.id,
        subscriptionId: subscription.currentValue as string,
      };
      return await this.transportLayer.setTenantSubscription(command);
    }
    return {
      data: '',
      succeeded: true,
      failed: false,
      message: '',
    };
  }
  resetSettingsToDefaults() {
    // update form values, probably need to think about better way of updating state of the store.
    this.settings = this.settings.map((setting) => {
      setting.resetToDefault();
      return setting;
    });
  }
  private reFillSettings(featuresBySubscription: Feature[]) {
    return featuresBySubscription.reduce<{
      [key: string]: { data: SettingValue; isUnlimited: boolean };
    }>((acc, feat) => {
      acc[feat.featureType] = {
        data: feat.data,
        isUnlimited: feat.isUnlimited,
      };
      return acc;
    }, {});
  }
  async switchSubscription(subId: string) {
    const getSubQuery = await this.transportLayer.fetchSubscription(subId);
    if (getSubQuery.succeeded) {
      const { features } = getSubQuery.data;
      const newFeaturesValues = this.reFillSettings(features);
      runInAction(() => {
        this.settings = this.settings.map((s) => {
          if (s.name === 'Subscription') return s;
          const { data, isUnlimited } = newFeaturesValues[s.name];

          // Subscription End Date does not depend on the subscription, so we do not want to change it
          if (s.name !== 'SubscriptionEndDate') {
            s.updateValue(data);
            s.setLimit(isUnlimited);
          }

          return s;
        });
      });
    }
  }
  private async refresh() {
    const orgData = await this.transportLayer.fetchOrgById(this.id);
    if (orgData.succeeded) {
      runInAction(() => {
        const {
          subscription: { features, id },
        } = orgData.data;
        this.rawData = orgData.data;
        this.setSettings(features, id);
      });
    }
  }

  async getOrgUsers(query: GetUsersQuery, loadMore: boolean = false) {
    const body: GetUsersQuery = {
      ...query,
      customTenantId: this.id,
    };
    const res = await this.transportLayer.fetchUsers(body);
    if (res.succeeded) {
      runInAction(() => {
        const { items, totalAmount, hasNext, amount, amountLeft } = res.data;
        this.usersAmountInfo = { totalAmount, hasNext, amount, amountLeft };
        const users = items.map(
          (user) =>
            new OrgUser(
              user.id,
              `${user.firstName} ${user.lastName}`,
              user.email,
              user.roleName,
              !user.isLocked
            )
        );
        this.users = loadMore ? this.users.concat([...users]) : users;
      });
    }
    return res;
  }
  async lockUnlockUsers() {
    const usersLockData = this.users.reduce<{ [key: string]: boolean }>(
      (acc, user) => {
        if (user.lockAction !== 'noop') {
          acc[user.id] = user.lockAction === 'deactivate';
        }
        return acc;
      },
      {}
    );
    const res = await this.transportLayer.lockUnlockUsers({ usersLockData });
    if (res.succeeded) {
      const body: GetUsersQuery = {
        offset: 0,
        count: DEFAULT_ITEMS_COUNT,
        ...this.usersFilter,
        loadImages: false,
      };
      await this.getOrgUsers(body);
    }
  }
  toggleOrgUser(userEmail: string, toggleState: boolean) {
    const user = this.users.find((user) => user.email === userEmail);
    if (user) {
      user.toggle(toggleState);
    }
  }

  resetUsers() {
    this.users.forEach((user) => {
      user.reset();
    });
  }
  setUsersFilter(newFilter: string) {
    this.usersFilter = { textToSearch: newFilter };
  }
  clearUsers() {
    this.users = [];
  }
}
