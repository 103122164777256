import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import {
  getCurrentDomain,
  getResultAndShowNotification,
  validateEmail,
  validateEmailDomain,
} from '../../../utils';
import {
  ButtonsContainer as MainButtons,
  UserContainer,
} from '../../Common/styles';
import { ExplanationTextContainer } from '../styled';

const RenderTextWithEmail = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Text
      selectText
      className="gd-how-to-item"
      fontSize={14}
      fontFamily={theme.fonts.medium}
      key={3}
    >
      {t('users:Explanations:ResetEmail:HowTo:clause3.1')}
      <Text
        selectText
        fontSize={14}
        fontFamily={theme.fonts.medium}
        color={theme.colors.strongBlue}
      >
        no-reply@guided-doing.com
      </Text>
      {t('users:Explanations:ResetEmail:HowTo:clause3.2')}
    </Text>
  );
};

export const ResetEmail = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const { userId } = useParams<{ userId: string }>();
  const { usersStore, authStore } = useRootStore();
  const { selectedUser } = usersStore;

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    async function getUserDetails() {
      await usersStore.getUserData(userId);
    }

    getUserDetails();
    return () => {
      usersStore.clearUser();
    };
  }, [userId, usersStore]);

  useEffect(() => {
    form.setFields([{ name: 'email', value: selectedUser.email }]);
  }, [usersStore, form, selectedUser]);

  const sendAnalyticEvent = useAnalyticsEventTracker('UmUsers');

  const sendAnalytic = useCallback(
    (isSuccess: boolean | undefined, values) => {
      const state = isSuccess ? 'Success' : 'Failed';
      sendAnalyticEvent(
        `User Update`,
        `${state}|User's email ${values.email} id ${userId} updated`
      );
    },
    [userId, sendAnalyticEvent]
  );

  const onCancel = useCallback(() => {
    history.push('/users');
  }, [history]);

  const onClickSave = useCallback(() => {
    form.submit();
  }, [form]);

  const updateIfSignedUser = useCallback(
    (email) => {
      if (userId === authStore.signedInUser.id) {
        authStore.updateSignedUserName({ email });
      }
    },
    [userId, authStore]
  );

  const onFinish = useCallback(
    async (values: { email: string }) => {
      setLoading(true);
      const res = await getResultAndShowNotification(
        async () =>
          await usersStore.resetEmail({ newEmail: values.email, userId }),
        t('users:Notifications:resetUserEmailSucceeded'),
        t('users:Notifications:resetUserEmailFailed'),
        () => {}
      );
      if (res.succeeded) {
        updateIfSignedUser(values.email);
      }
      sendAnalytic(res.succeeded, values);
      setLoading(false);
    },
    [sendAnalytic, t, usersStore, userId, updateIfSignedUser]
  );

  const validateEmails = useCallback(
    ({ getFieldValue }) => ({
      validator(_: RuleObject, value: StoreValue) {
        const email: string = getFieldValue('email');
        if (!value || validateEmail(email)) {
          const domain = getCurrentDomain(
            authStore.signedInUser?.email as string
          );
          return validateEmailDomain(
            [email],
            authStore.signedInUser?.email as string,
            t('users:Validation:domain', {
              currentDomain: domain,
            })
          );
        }
        return Promise.reject(new Error('Invalid email in the list!'));
      },
    }),
    [authStore.signedInUser, t]
  );

  return (
    <ResetEmailContainer>
      <Col xs={12} sm={20} md={16} lg={14} xl={14} xxl={12}>
        <Row>
          <PageTitle title={t('landingPage:Labels:changeEmail')} />
        </Row>
        <Row className="gd-reset-email-legend">
          <IconContainer>
            <InfoCircleOutlined />
          </IconContainer>
          <ExplanationTextContainer>
            <Text selectText fontSize={14} fontFamily={theme.fonts.medium}>
              {t('users:Explanations:ResetEmail:pageText1')}
            </Text>
            <Text selectText fontSize={14} fontFamily={theme.fonts.medium}>
              {t('users:Explanations:ResetEmail:pageText2')}
            </Text>
          </ExplanationTextContainer>
        </Row>
        <Row>
          <HowToContainer>
            <Text fontSize={14} fontFamily={theme.fonts.bold}>
              {t('users:Explanations:ResetEmail:howToTitle')}
              <br />
            </Text>
            {Array.from({ length: 5 }, (_, i) => i + 1).map((idx) =>
              idx === 3 ? (
                <RenderTextWithEmail />
              ) : (
                <Text
                  selectText
                  className="gd-how-to-item"
                  fontSize={14}
                  fontFamily={theme.fonts.medium}
                  key={idx}
                >
                  {t(`users:Explanations:ResetEmail:HowTo:clause${idx}`)}
                  <br />
                </Text>
              )
            )}
          </HowToContainer>
        </Row>
        <Row className="gd-reset-email-form-container">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="gd-reset-email-user-form"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  className="gd-form-item"
                  label={<Text>{t('users:EditUser:Form:emailLbl')}</Text>}
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    validateEmails,
                  ]}
                >
                  <Input className="gd-input-item" placeholder="Username" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row>
          <Col span={24}>
            <MainButtons>
              <Button
                type="text"
                className="gd-form-button gd-form-button-text"
                onClick={onCancel}
                disabled={loading}
              >
                {t('users:EditUser:Form:cancelBtnLbl')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="gd-form-button gd-form-button-primary"
                onClick={onClickSave}
                disabled={loading}
                loading={loading}
              >
                {t('users:EditUser:Form:updateBtnLbl')}
              </Button>
            </MainButtons>
          </Col>
        </Row>
      </Col>
    </ResetEmailContainer>
  );
});

const ResetEmailContainer = styled(UserContainer)`
  & .gd-reset-email-form-container,
  .gd-reset-email-legend {
    padding-top: 33px;
  }

  .gd-reset-email-legend {
    font-size: 14px;
    display: flex;
    align-items: start;
    justify-content: start;
  }

  & .gd-reset-email-user-form {
    width: 100%;
  }
`;

const IconContainer = styled.div`
  width: 4%;
`;

const HowToContainer = styled.div`
  padding-top: 20px;
  & .gd-how-to-item {
    display: block;
  }
`;
