import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Result, RootStore } from '../../../services/rootStore';
import { trimCodeValues } from '../../../utils';
import { BaseStringType } from './authStore';

type RegistrationResponse = {
  userId: string;
  companyId: string;
};

type UserSignDetails =
  | BaseStringType
  | {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      confirmPassword: string;
      jobTitleId: string;
      infoSourceId: string;
      accessCode: string;
    };

type CompanySignUpDetails =
  | BaseStringType
  | {
      companyName: string;
      vatId: string;
      companySizeId: string;
      usersOfGuidoAppId: string;
      companyBranchId: string;
      street: string;
      houseNumber: string;
      zipCode: string;
      city: string;
      owner: string;
    };

export type UserDto = { id: string; name: string; checked?: boolean };
export type GroupDto = { name: string; id: string; users: UserDto[] };

export type RegisterRequest = {
  owner: UserSignDetails;
  company: CompanySignUpDetails;
  users: UserDto[];
  groups: GroupDto[];
};

const defaultUserDetails = {
  firstName: null,
  lastName: null,
  email: 'ademchuk@pixsystem.com',
  password: null,
  confirmPassword: null,
  jobTitleId: null,
  infoSourceId: null,
  accessCode: null,
};

const defaultCompanyDetails = {
  name: null,
  vatId: null,
  companySizeId: null,
  usersOfGuidoAppId: null,
  companyBranchId: null,
  street: null,
  houseNumber: null,
  zipCode: null,
  city: null,
};

export class SignUpStore {
  rootStore: RootStore;

  signUpRequest: RegisterRequest = {
    users: [],
    groups: [],
    owner: defaultUserDetails,
    company: defaultCompanyDetails,
  };

  isSignedUp: boolean = false;
  isSignUpFailed: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setTenantOwner(values: UserSignDetails) {
    const newValues = trimCodeValues(values, ['accessCode', 'email']);
    this.signUpRequest.owner = {
      ...toJS(this.signUpRequest.owner),
      ...newValues,
    };
  }

  setTenantDetails(values: CompanySignUpDetails) {
    this.signUpRequest.company = {
      ...toJS(this.signUpRequest.company),
      ...values,
      owner: this.signUpRequest.owner.email,
    };
  }

  addToInvitationList(user: string) {
    const newUsers = [...this.signUpRequest.users, { name: user, id: user }];
    this.signUpRequest = { ...toJS(this.signUpRequest), users: newUsers };
  }

  removeFromInvitation(itemId: string) {
    this.signUpRequest = {
      ...toJS(this.signUpRequest),
      users: this.signUpRequest.users.filter((x) => x.id !== itemId),
      groups: this.signUpRequest.groups.map((g) => {
        g.users = g.users.filter((x) => x.id !== itemId);
        return g;
      }),
    };
  }

  addGroupToRegisterRequest(groupName: string) {
    const idx = this.signUpRequest.groups.length + 1;
    const newGroups = [
      ...this.signUpRequest.groups,
      { name: groupName, id: `${groupName}-${idx}`, users: [] },
    ];
    this.signUpRequest = { ...toJS(this.signUpRequest), groups: newGroups };
  }

  removeGroupFromRegisterRequest(itemId: string) {
    this.signUpRequest = {
      ...toJS(this.signUpRequest),
      groups: this.signUpRequest.groups.filter((x) => x.id !== itemId),
    };
  }

  addRemoveUsersFromGroup(groupId: string, users: UserDto[], state: boolean) {
    this.signUpRequest.groups = this.signUpRequest.groups.map((g) => {
      const group = g;

      if (group.id === groupId && state) {
        const existingGroupUsers = group.users.reduce<{
          [key: string]: UserDto;
        }>((acc, user) => {
          acc[user.id] = user;
          return acc;
        }, {});
        users.forEach((user) => {
          existingGroupUsers[user.id] = user;
        });
        group.users = [...Object.values(existingGroupUsers)];
      } else if (group.id === groupId && !state) {
        group.users = group.users.filter(
          (u) => !users.some((usr) => usr.id === u.id)
        );
      }
      return group;
    });
  }

  async signUp(): Promise<Result<RegistrationResponse>> {
    const body = this.signUpRequest;
    const result = await this.rootStore.ApiClient.post<
      RegisterRequest,
      Result<RegistrationResponse>
    >('auth/signup', body, false);

    if (result.succeeded) {
      runInAction(() => {
        this.isSignedUp = true;
        this.isSignUpFailed = false;
      });
    } else {
      runInAction(() => {
        this.isSignUpFailed = true;
      });
    }
    return result;
  }

  clearRegisterForms() {
    const cleanObject = (data: BaseStringType) =>
      Object.keys(data).reduce<{ [key: string]: null }>((acc, item) => {
        acc[item] = null;
        return acc;
      }, {});
    this.signUpRequest.owner = cleanObject(this.signUpRequest.owner);
    this.signUpRequest.company = cleanObject(this.signUpRequest.company);
  }
}
