import 'antd/es/date-picker/style/index';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker, {
  RangePickerProps,
} from 'antd/es/date-picker/generatePicker';

import { RangeValue } from 'rc-picker/lib/interface';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const { RangePicker } = DatePicker;

type DateRangeProps = {
  onChange: (values: RangeValue<Dayjs>, formatString: [string, string]) => void;
} & RangePickerProps<Dayjs>;

export const DateRange = ({ onChange, ...props }: DateRangeProps) => {
  // @ts-ignore
  return <RangePicker {...props} onChange={onChange} />;
};
export default DatePicker;
