import { EventMapType } from './orgTypes';

export const subscription: EventMapType = {
  CustomSubscriptionCreatedEvent: {
    action: 'Custom subscription created',
    sourceProp: 'originalSubscription',
    destProp: 'customSubscription',
  },
  CustomSubscriptionUpdatedEvent: {
    action: 'Custom subscription updated',
    sourceProp: 'previousSubscription',
    destProp: 'currentSubscription',
  },
  TenantSubscriptionFeatureValuesUpdatedEvent: {
    action: 'Tenant subscription features changed',
    sourceProp: 'subscriptionName',
  },
};
