import { Col, Row } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import {
  DISTANCE_BETWEEN_FILTER_INPUTS,
  FilterMeta,
  GdFilterBar,
} from '../../../../../../components/GdFilterBar';
import {
  GdList,
  ItemMode,
  ListMeta,
} from '../../../../../../components/GdList';
import {
  ColumnsMeta,
  GdListHeader,
} from '../../../../../../components/GdListHeader';
import { useBaseSearchMeta } from '../../../../../../hooks/useBaseSearchMeta';
import { singleSearchItemControlStyle } from '../../../../../../theme/CssResetStyles';
import { SortDirection } from '../../../../../../types/commonTypes';
import { sortBy } from '../../../../../../utils';
import { UserDto } from '../../../../stores/signUpStore';
import { ListItemName } from './ListItemName';

type Props = {
  usersToInvite: UserDto[];
  onUserChecked: (user: UserDto, state: boolean) => void;
  onAllUsersChecked: (state: boolean) => void;
};

const AddUserModalBodyContainer = styled.div`
  width: 100%;

  & .gd-list-row {
    padding: 0 2px;
  }

  ${singleSearchItemControlStyle()}
`;

const MAX_HEIGHT_STEP = 65;
const ROW_HEIGHT = 40;
export const AddUserModalBody = ({
  usersToInvite,
  onUserChecked,
  onAllUsersChecked,
}: Props) => {
  const { t } = useTranslation();
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc
  );
  const [filter, setFilter] = useState<string>('');

  const sortFunction = useCallback((field, order: SortDirection) => {
    return sortBy(field, order === SortDirection.Asc, (value) =>
      (value as string).toUpperCase()
    );
  }, []);

  const onUsersFilter = useCallback((values) => {
    setFilter(values.searchText);
  }, []);

  const users = useMemo(() => {
    const users = filter
      ? usersToInvite.filter((x) => x.name.includes(filter))
      : usersToInvite;
    const sortFunc = sortFunction('name', sortDirection);
    return [...users.sort(sortFunc)];
  }, [usersToInvite, sortFunction, filter, sortDirection]);

  const sortingHandler = useCallback((order: SortDirection) => {
    setSortDirection(order);
  }, []);

  const formMeta = useBaseSearchMeta();
  const tableMeta: ListMeta = {
    name: {
      span: 23,
      overrideStyle: true,
      className: 'gd-user-list-card-avatar',
      render: (item) => {
        return (
          <ListItemName name={item.name as string} useAvatarLetter={true} />
        );
      },
    },
  };

  const headerMeta: ColumnsMeta = {
    name: {
      title: t('identity:AddGroupsStep:Modal:nameColumnLbl'),
      colspan: 23,
      sortingHandler,
      direction: SortDirection.Asc,
      leftPadding: 15,
    },
  };

  const onUserSelected = useCallback(
    (item, checked) => {
      onUserChecked(item, checked);
    },
    [onUserChecked]
  );

  return (
    <AddUserModalBodyContainer>
      <Row>
        <Col span={24}>
          <GdFilterBar
            inputsGutter={DISTANCE_BETWEEN_FILTER_INPUTS}
            meta={formMeta}
            onFilter={onUsersFilter}
          />
        </Col>
      </Row>
      <Row className="gd-list-row">
        <Col span={24}>
          <GdListHeader
            onCheckBoxTriggered={onAllUsersChecked}
            checkAll={users.every((x) => x.checked) && !!users.length}
            edit
            columns={headerMeta}
            topOffset={25}
          />
        </Col>
      </Row>
      <Row className="gd-list-row">
        <Col span="24">
          <GdList
            split
            useMaxHeight
            editable
            data={users}
            columnMeta={tableMeta}
            totalCount={users.length}
            itemMode={ItemMode.Basic}
            fixScrollStartCellNumber={1}
            onItemCheck={onUserSelected}
            maxHeight={users.length * MAX_HEIGHT_STEP}
            rowHeight={ROW_HEIGHT}
          />
        </Col>
      </Row>
    </AddUserModalBodyContainer>
  );
};
