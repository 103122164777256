import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
} from 'styled-components/macro';

export const PanelBodyContainer = styled.div`
  padding-bottom: 15px;

  & .ant-list-item {
    justify-content: start;
  }
`;

const antSelectedItem = (mixStyle: string, theme: DefaultTheme) => css`
  ${mixStyle}.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${theme.colors.white};

    & .ant-select-item-option-state {
      color: ${theme.colors.strongBlue};
    }
  }
`;

export default createGlobalStyle<{ theme: DefaultTheme }>`
  .ant-select-multiple {
    & .ant-select-selection-item {
      background-color: ${({ theme: { colors } }) => colors.strongBlue};
      color: ${({ theme: { colors } }) => colors.white};
      border-radius: 4px;
    }

    & .ant-select-selection-item-remove:hover,
    & .ant-select-selection-item-remove {
      color: ${({ theme: { colors } }) => colors.white};
    }
  }

  .gd-emails-selection {
    ${({ theme }) => antSelectedItem('& ', theme)};
  }

  .gd-users-select-option {
    ${({ theme }) => antSelectedItem('& ', theme)};
  }

  .gd-list-meta {
    &.gd-user-list-status {
      font-weight: bold !important;
      color: ${({ theme: { colors } }) => colors.lightGreen};
    }
  }

  .gd-user-list-card-avatar {
    padding-left: 24px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    font-family: ${({ theme: { fonts } }) => fonts.bold};
    font-size: 12px;

    & > span {
      margin-right: 12px;
      background-color: ${({ theme: { colors } }) => colors.lightOrange};
    }
  }
`;
