import { action, makeObservable, observable, runInAction } from 'mobx';

import {
  BaseEntity,
  ModifiedElement,
  listDefault,
} from '../../../services/BaseListsStore';
import {
  BaseUsersStore,
  UpdateAssignedUsersRequest,
  UserAssignedToTeamItem,
} from '../../../services/baseUsersStore';
import { Result, RootStore } from '../../../services/rootStore';
import {
  BaseQuery,
  ListResult,
  SortDirection,
} from '../../../types/commonTypes';
import { findKeyByValue, setItemsCache } from '../../../utils';
import { User } from '../../Groups/stores/teamsListsStore';

export type UsersGuidoResponseDto = {
  userId: string;
  roleId: string;
  userName: string;
  email: string;
  isGlobalAdmin: string;
};

export type TeamsGuidoResponseDto = {
  teamId: string;
  roleId: string;
  teamName: string;
  creationDate: string | number;
  users: number;
  isOriginal: boolean;
};

type GuidoAssignedUsersTeamsRequest = {
  assignedOnly: boolean;
  assignedFirst: boolean;
};

type TeamAssignedToGuidoItem = {
  teamId: string;
};

type UpdateAssignedTeamToGuidoRequest = {
  assignedTeams: ModifiedElement<TeamAssignedToGuidoItem>[];
};

export type GuidoGroups = {
  name: string;
  creationDate: string | number;
  users: number;
} & BaseEntity;

export class GuidosListsStore extends BaseUsersStore {
  rootStore: RootStore;

  groupsListInfo = listDefault;
  groupscheckAll: boolean = false;
  groupsSearchText: string = '';

  groups: GuidoGroups[] = [];
  groupsCache: GuidoGroups[] = [];

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;

    makeObservable(this, {
      getGuidoUsers: action,
      groupsListInfo: observable,
      groupscheckAll: observable,
      groupsSearchText: observable,
      groups: observable,
      groupsCache: observable,
    });
  }

  clearGroups() {
    runInAction(() => {
      this.groups = [];
      this.groupsCache = [];
    });
  }

  public createUserItem(
    user: UsersGuidoResponseDto,
    idx: number,
    isOriginal: boolean
  ) {
    return {
      id: user.userId,
      changed: false,
      checked: isOriginal,
      name: user.userName,
      role: user.roleId,
      original: isOriginal,
      email: user.email,
      isGlobalAdmin: user.isGlobalAdmin,
      disabled: user.isGlobalAdmin,
    };
  }

  public createTeamItem(team: TeamsGuidoResponseDto, idx: number) {
    return {
      id: team.teamId,
      changed: false,
      checked: team.isOriginal,
      name: team.teamName,
      role: team.roleId,
      original: team.isOriginal,
      creationDate: team.creationDate,
      users: team.users,
    };
  }

  public async getGuidoUsers(
    guidoId: string,
    payload: BaseQuery,
    loadMore: boolean = false
  ) {
    const body: GuidoAssignedUsersTeamsRequest = {
      ...payload,
      assignedOnly: true,
      assignedFirst: false,
    };
    const res = await this.rootStore.ApiClient.post<
      GuidoAssignedUsersTeamsRequest,
      Result<ListResult<UsersGuidoResponseDto>>
    >(`/User/UsersAssignedToGuido/${guidoId}`, body);
    if (res.succeeded) {
      runInAction(() => {
        this.setUsersData(res.data, loadMore);
      });
    }
  }

  public async getAvailableGuidoUsers(guidoId: string, payload: BaseQuery) {
    const payloadWithSearch = {
      ...payload,
      textToSearch: this.usersSearchText,
    };
    const body: GuidoAssignedUsersTeamsRequest = {
      ...payloadWithSearch,
      assignedOnly: false,
      assignedFirst: true,
    };
    const res = await this.rootStore.ApiClient.post<
      GuidoAssignedUsersTeamsRequest,
      Result<ListResult<UsersGuidoResponseDto>>
    >(`/User/UsersAssignedToGuido/${guidoId}`, body);

    if (res.succeeded) {
      runInAction(() => {
        this.setUserCache(res.data, payloadWithSearch);
      });
    }
  }

  public async getGuidoTeams(
    guidoId: string,
    payload: BaseQuery,
    loadMore: boolean = false
  ) {
    const payloadWithSearch = {
      ...payload,
      textToSearch: this.groupsSearchText,
    };
    const body: GuidoAssignedUsersTeamsRequest = {
      ...payloadWithSearch,
      assignedOnly: true,
      assignedFirst: false,
    };
    const res = await this.rootStore.ApiClient.post<
      GuidoAssignedUsersTeamsRequest,
      Result<ListResult<TeamsGuidoResponseDto>>
    >(`/team/TeamsAssignedToGuido/${guidoId}`, body);
    if (res.succeeded) {
      runInAction(() => {
        const { totalAmount, amountLeft, amount, hasNext } = res.data;
        this.groupsListInfo = { totalAmount, amountLeft, amount, hasNext };
        const groups = res.data.items.map((team, idx) =>
          this.createTeamItem(team, idx)
        );
        this.groups = loadMore ? [...this.groups, ...groups] : groups;
        this.setAllChecked('groups');
        this.sortProperty('groups', SortDirection.Asc, 'creationDate');
      });
    }
  }

  public async getGuidoAvailableTeams(guidoId: string, payload: BaseQuery) {
    const payloadWithSearch = {
      ...payload,
      textToSearch: this.groupsSearchText,
    };
    const body: GuidoAssignedUsersTeamsRequest = {
      ...payloadWithSearch,
      assignedOnly: false,
      assignedFirst: true,
    };
    const res = await this.rootStore.ApiClient.post<
      GuidoAssignedUsersTeamsRequest,
      Result<ListResult<TeamsGuidoResponseDto>>
    >(`/team/TeamsAssignedToGuido/${guidoId}`, body);
    if (res.succeeded) {
      runInAction(() => {
        const { totalAmount, amountLeft, amount, hasNext } = res.data;
        this.groupsListInfo = { totalAmount, amountLeft, amount, hasNext };
        const availableGroups = res.data.items.map((team, idx) =>
          this.createTeamItem(team, idx)
        );

        const { newValues, newCache } = setItemsCache(
          this.groupsCache,
          availableGroups,
          !!payloadWithSearch.textToSearch
        );

        this.groupsCache = newCache;
        this.groups = newValues;
        this.setAllChecked('groups');
        this.sortProperty('groups', SortDirection.Asc, 'creationDate');
      });
    }
  }

  private getChangedGroups() {
    const body: UpdateAssignedTeamToGuidoRequest = {
      assignedTeams: this.getChangedItems<TeamAssignedToGuidoItem, GuidoGroups>(
        'groups',
        (group) => ({
          teamId: group.id,
        })
      ),
    };
    return body;
  }

  public async updateTeamsAssignedToGuido(guidoId: string) {
    const body = this.getChangedGroups();
    const result = await this.rootStore.ApiClient.put<
      UpdateAssignedTeamToGuidoRequest,
      Result<string>
    >(`/guido/AssignTeamsToGuido/${guidoId}`, body);
    if (result.succeeded) {
      runInAction(() => {
        this.groupsCache = [];
        this.groups = this.groupsCache;
        this.origValues = new Map<
          string,
          Map<string, number | string | boolean>
        >();
        this.groupsListInfo = listDefault;
      });
    }

    return result;
  }

  private getChangedUsers(defaultRole: string) {
    const body: UpdateAssignedUsersRequest = {
      assignedUsers: this.getChangedItems<UserAssignedToTeamItem, User>(
        'users',
        (user) => ({
          userId: user.id,
          roleId: (user as User).role || defaultRole,
        })
      ),
    };
    return body;
  }

  public async updateGuidoAssignedUsers(guidoId: string) {
    const defaultRole: string = findKeyByValue(
      this.rootStore.usersStore.roles,
      'Viewer'
    ) as string;
    const body = this.getChangedUsers(defaultRole);
    const result = await this.rootStore.ApiClient.put<
      UpdateAssignedUsersRequest,
      Result<string>
    >(`/Guido/AssignUsersToGuido/${guidoId}`, body);

    if (result.succeeded) {
      this.cleanUsersAfterUpdate();
    }

    return result;
  }
}
