import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GdChart } from '../../../components/GdChart';
import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';
import { GraphCardContainer } from './GraphCardContainer';

export const StorageInOrganizationChart = observer(() => {
  const { t } = useTranslation();
  const { admDashboardStore } = useRootStore();
  const { period } = admDashboardStore;

  const fetchChart = useCallback(
    async (loadMore = false) => {
      return await admDashboardStore.getStorageSizeDistribution(period);
    },
    [admDashboardStore, period]
  );

  const { loading } = useDataQuery({
    dataFunc: fetchChart,
  });

  return (
    <GraphCardContainer loading={loading}>
      <GdChart
        type="line"
        data={admDashboardStore.orgsByStorageChart}
        info={t('admDashboard:Label:storageInOrgLbl')}
      />
    </GraphCardContainer>
  );
});
