import { notification, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { GdList, ItemMode } from '../../../components/GdList';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { GdModal } from '../../../components/GdModal';
import Text from '../../../components/Text';
import { DEFAULT_ITEMS_COUNT } from '../../../constants';
import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';
import { ListContainer } from '../../Common/styles';
import { Guido } from '../stores/guidosStore';
import { useGuidosMeta } from '../hooks/useGuidosMeta';

export const GuidosList = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const { guidosStore } = useRootStore();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmGuido');

  const [showModal, setShowModal] = useState(false);
  const [isDeleteInProgress, setDeleteInProgress] = useState(false);
  const [selectedGuido, setSelectedGuido] = useState<Guido>();

  const { columnsMeta } = useGuidosMeta({
    onDeleteClicked: (guido) => {
      setShowModal(true);
      setSelectedGuido(guido);
    },
  });

  const {
    guidos,
    amountInfo: { amountLeft },
    filter: { name },
  } = guidosStore;

  const sendAnalytic = useCallback(
    (isSuccess: boolean | undefined) => {
      const state = isSuccess ? 'Success' : 'Failed';

      sendAnalyticEvent(
        'Delete Guido',
        `${state}|Guido ${guidosStore.selectedGuido.id} deleted`
      );
    },
    [sendAnalyticEvent, guidosStore]
  );

  const fetchGuidos = useCallback(
    async (loadMore = false) => {
      const body = {
        offset: loadMore ? guidosStore.guidos.length : 0,
        count: DEFAULT_ITEMS_COUNT,
        textToSearch: name,
      };
      return await guidosStore.getAll(body, loadMore);
    },
    [guidosStore, name]
  );

  const showMsgIfFailedFunc = useCallback(
    () => t('guidos:Notifications:fetchGuidosFailed'),
    [t]
  );
  const showDescIfFailedFunc = useCallback(() => t('users:fetchFailed'), [t]);
  const clearFunc = useCallback(
    () => guidosStore.clearSelectedGuido(),
    [guidosStore]
  );

  const { loading, loadMore: loadMoreGuidos } = useDataQuery({
    dataFunc: fetchGuidos,
    showDescIfFailedFunc,
    showMsgIfFailedFunc,
    clearFunc,
  });

  const onGuidoSelected = useCallback(
    (guido) => {
      guidosStore.setSelectedGuido(guido.id);
      history.push(`/guidos/${guido.id}`);
    },
    [guidosStore, history]
  );

  const onCloseDeleteModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onDeleteGuido = useCallback(async () => {
    setDeleteInProgress(true);
    const res = selectedGuido && (await guidosStore.delete(selectedGuido.id));
    if (res?.failed) {
      notification.error({
        message: t('guidos:DeleteModal:deleteFailedTitle'),
        description: res.message,
        placement: 'bottomRight',
        duration: 2,
      });
    }
    sendAnalytic(res?.succeeded);
    setDeleteInProgress(false);
    setShowModal(false);
  }, [sendAnalytic, selectedGuido, t, guidosStore]);

  const DeleteModalBody = () => {
    return (
      <Text fontSize={16} fontFamily="montserrat-regular">
        {selectedGuido &&
          t('guidos:DeleteModal:bodyConfirmationText', {
            guido: selectedGuido.tittle,
          })}
      </Text>
    );
  };

  return (
    <ListContainer>
      <GdList
        split={false}
        itemMode={ItemMode.Card}
        data={guidos}
        columnMeta={columnsMeta}
        totalCount={amountLeft}
        loadMore={loadMoreGuidos}
        fixScrollStartCellNumber={1}
        onItemClick={onGuidoSelected}
        loading={{
          spinning: loading,
          size: 'large',
          indicator: <Spin indicator={<GdLoadingIndicator />} />,
        }}
      />
      <GdModal
        isVisible={showModal}
        disableButtons={isDeleteInProgress}
        isClosable={!isDeleteInProgress}
        onCloseModal={onCloseDeleteModal}
        onOkHandler={onDeleteGuido}
        cancelButtonText={t('guidos:DeleteModal:cancelBtnLbl')}
        okButtonText={t('guidos:DeleteModal:deleteBtnLbl')}
        title={t('guidos:DeleteModal:title')}
        renderBody={<DeleteModalBody />}
      />
    </ListContainer>
  );
});
