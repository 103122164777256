import { Dayjs } from 'dayjs';
import { makeAutoObservable } from 'mobx';

export type FeatureType =
  | 'UsersLimit'
  | 'NumberedAnnotations'
  | 'GroupsLimit'
  | 'GuestAllowed'
  | 'EditorsLimit'
  | 'FeedbackAnnotations'
  | 'GuidosLimit'
  | 'ManagersLimit'
  | 'StorageSize'
  | 'Subscription'
  | 'SubscriptionEndDate'
  | 'GroupMaxLimit'
  | 'ExternalSharing';

export type Feature = {
  featureType: FeatureType;
  data: string | boolean | number | Dayjs;
  currentValue: string | boolean | number | Dayjs;
  isCustom: boolean;
  isUnlimited: boolean;
};

export type SettingValue = number | string | boolean | Dayjs;

export class SettingItem<T extends SettingValue> {
  name: FeatureType;
  currentValue: T;
  originalValue: T;
  isUnlimited: boolean = false;
  private origUnlimited: boolean = false;

  constructor(name: FeatureType, value: T, isUnlimited: boolean = false) {
    this.name = name;
    this.currentValue = this.originalValue = value;
    this.setLimit(isUnlimited);
    makeAutoObservable(this, {
      name: false,
    });
  }

  get idChanged() {
    return this.currentValue !== this.originalValue;
  }

  get value() {
    return this.isUnlimited ? -1 : this.currentValue;
  }

  updateValue(newValue: T) {
    this.currentValue = newValue;
    if (this.isUnlimited) {
      this.isUnlimited = !this.isUnlimited;
    }
  }

  resetToDefault() {
    this.currentValue = this.originalValue;
    this.isUnlimited = this.origUnlimited;
  }

  setLimit(isUnlimited: boolean) {
    this.origUnlimited = this.isUnlimited = isUnlimited;
  }
}
