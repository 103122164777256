import dayjs from 'dayjs';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { TransportLayer } from '../../../api/transportLayer';
import { BaseStore } from '../../../services/baseStore';
import { RootStore } from '../../../services/rootStore';
import {
  BaseQuery,
  OrderColumn,
  SortDirection,
} from '../../../types/commonTypes';
import { capitalizeFirstLetter } from '../../../utils';
import {
  LoginStateStatuses,
  Organization,
  Subscription,
} from './objects/Organization';

export type OrgState = 'Unknown' | 'Green' | 'Red' | 'Yellow';

type OrgsFilter = {
  filterBySubscription: string[];
  name: string;
  filterByState: OrgState;
};

export type OrgListItemDto = {
  activity: string;
  guidosCount: number;
  guidosSize: number;
  id: string;
  locked: boolean;
  name: string;
  subscriptionId: string;
  subscriptionName: string;
  subscriptionEndDate: string;
  usersCount: number;
  lastLogin: string;
  owner: string;
};

export type OrgListItem = {
  id: string;
  name: string;
  subscription: string;
  users: string;
  guidos: string;
  state: string;
  locked: boolean;
  lastActiveDate: string | number;
  subscriptionEndDate: string;
  storage: number;
  owner: string;
};

export type OrgDetailsDto = {
  id: string;
  name: string;
  totalUsersCount: string;
  guidosCount: string;
  teamsCount: string;
  totalCompanyGuidosSize: string;
  lastLogin: string;
  owner: string;
  activity: LoginStateStatuses;
  subscription: Subscription;
  subscriptionEndDate: string;
  usersByRoleCounts: { [key: string]: number };
};

type SubscriptionOptions = {
  [key: string]: string;
};

const defaultOrgDtoObject: OrgDetailsDto = {
  name: '',
  totalCompanyGuidosSize: '',
  totalUsersCount: '',
  teamsCount: '',
  usersByRoleCounts: {},
  subscription: {
    name: '',
    features: [],
    isDefault: false,
    id: '',
    isActive: false,
    price: '',
  },
  subscriptionEndDate: '',
  lastLogin: '',
  owner: '',
  id: '',
  guidosCount: '',
  activity: 'Red',
};

type OrgFields =
  | 'owner'
  | 'name'
  | 'subscription'
  | 'subscriptionEndDate'
  | 'users'
  | 'guidos'
  | 'state'
  | 'lastActiveDate'
  | 'storage';

type OrgProps =
  | 'Name'
  | 'Owner'
  | 'Subscription'
  | 'SubscriptionEndDate'
  | 'Users'
  | 'Guidos'
  | 'LastLoginDateTimeUtc'
  | 'TotalCompanyGuidosSize';

type FieldToPropMap = {
  [key in OrgFields]: OrgProps;
};

export class OrgListStore extends BaseStore<OrgsFilter> {
  private rootStore: RootStore;
  transportLayer: TransportLayer;
  sortOrder: SortDirection = SortDirection.Desc;
  filter: OrgsFilter = {
    name: '',
    filterBySubscription: [],
    filterByState: 'Unknown',
  };

  orderingColumns: OrderColumn = {
    TotalCompanyGuidosSize: SortDirection.Desc,
  };
  orgs: OrgListItem[] = [];
  selectedOrg: Partial<Organization> = {};

  subscriptionOptions: SubscriptionOptions = {};

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
    this.transportLayer = rootStore.transportLayer;
    this.selectedOrg = new Organization(this, defaultOrgDtoObject);
    makeObservable(this, {
      filter: observable,
      orgs: observable,
      sortOrder: observable,
      selectedOrg: observable,
      orderingColumns: observable,
      sortOrgs: action,
      getAll: action,
    });
  }

  private mapToOrgModel(orgDto: OrgListItemDto): OrgListItem {
    return {
      id: orgDto.id,
      name: orgDto.name,
      guidos: String(orgDto.guidosCount),
      users: String(orgDto.usersCount),
      storage: orgDto.guidosSize * 1024,
      locked: orgDto.locked,
      lastActiveDate: dayjs(orgDto.lastLogin).valueOf(),
      subscription: capitalizeFirstLetter(orgDto.subscriptionName),
      subscriptionEndDate: orgDto.subscriptionEndDate,
      state: orgDto.activity,
      owner: orgDto.owner,
    };
  }

  async getAll(query: BaseQuery, loadMore: boolean = false) {
    const result = await this.transportLayer.fetchOrgs(query);

    if (result.succeeded) {
      runInAction(() => {
        const { items, totalAmount, hasNext, amount, amountLeft } = result.data;
        const orgs = items.map((item) => this.mapToOrgModel(item));
        this.amountInfo = { totalAmount, hasNext, amount, amountLeft };
        this.orgs = loadMore ? this.orgs.concat([...orgs]) : orgs;
      });
    }
    return result;
  }

  async getById(orgId: string) {
    const result = await this.transportLayer.fetchOrgById(orgId);
    if (result.succeeded) {
      runInAction(() => {
        this.selectedOrg = new Organization(this, result.data);
      });
    }

    return result;
  }

  clear() {
    this.orgs = [];
  }

  clearOrg() {
    this.selectedOrg = new Organization(this, defaultOrgDtoObject);
  }
  clearFilter() {
    this.filter = {
      ...this.filter,
      name: '',
      filterBySubscription: [],
      filterByState: 'Unknown',
    };
  }

  sortOrgs(order: SortDirection, field: string) {
    this.sortOrder = order;
    const fieldsToPropMap: Partial<FieldToPropMap> = {
      users: 'Users',
      guidos: 'Guidos',
      subscription: 'Subscription',
      subscriptionEndDate: 'SubscriptionEndDate',
      owner: 'Owner',
      name: 'Name',
      lastActiveDate: 'LastLoginDateTimeUtc',
      storage: 'TotalCompanyGuidosSize',
    };

    const property = fieldsToPropMap[field as OrgFields];

    this.orderingColumns = {
      [property as string]: order,
    };
  }

  async getSubscriptionOptions() {
    const result = await this.transportLayer.fetchAllSubscriptionTypes();

    if (result.succeeded) {
      runInAction(() => {
        this.subscriptionOptions = result.data;
      });
    }
  }
}
