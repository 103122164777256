import { Button, Col, Progress, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/macro';

import { GdNumber } from '../../../components/GdNumber';
import Text from '../../../components/Text';
import {
  calculateProgress,
  capitalizeFirstLetter,
  formatBytes,
} from '../../../utils';
import { TitleWithControlContainer } from '../../Common/styles';
import Link from 'antd/lib/typography/Link';

const OrgSubscriptionCardContainer = styled.div`
  & .gd-subscription-plan-item:not(:nth-of-type(2)) {
    margin-bottom: 20px;

    & .gd-progress-item {
      padding-right: 40px;
    }
  }

  ${TitleWithControlContainer} {
    padding-right: 0;

    & .gd-form-button {
      height: 22px;
      font-size: 10px;
    }

    & .ant-btn {
      padding: 2px 15px;
    }

    & > span {
      display: inline-flex;
      align-items: center;
    }
  }
`;

export type OrgSubscriptionCardProps = {
  planName: string;
  totalUsers: number;
  limitUsers: number;
  totalStorageSize: number;
  limitStorageSize: number;
  subscriptionEndDate: Dayjs;
  hasShareCare: boolean;

  onUpdatePlan?: () => void;
};

export const OrgSubscriptionCard = observer(
  ({
    planName,
    totalStorageSize,
    totalUsers,
    limitStorageSize,
    limitUsers,
    subscriptionEndDate,
    hasShareCare,
    onUpdatePlan,
  }: OrgSubscriptionCardProps) => {
    const { t } = useTranslation();
    const { fonts, defaultFontSize, colors } = useTheme();

    if (subscriptionEndDate === undefined) {
      subscriptionEndDate = dayjs(0);
    }

    var subscriptionTitle = capitalizeFirstLetter((planName as string) || '');

    if (hasShareCare) {
      subscriptionTitle += ' + Share & Care';
    }

    const data = [
      {
        value: subscriptionTitle,
        title: t('admOrgs:Label:planBtnLbl'),
        span: 6,
      },
      {
        value: totalUsers,
        postFix: limitUsers,
        title: t('admOrgs:ListHeader:usersLbl'),
        span: 9,
      },
      {
        value: formatBytes(totalStorageSize),
        postFix: formatBytes(limitStorageSize),
        title: t('admOrgs:ListHeader:storageLbl'),
        span: 9,
      },
    ];

    return (
      <OrgSubscriptionCardContainer>
        <Row className="gd-subscription-plan-item">
          <TitleWithControlContainer>
            <Text fontFamily={fonts.bold} fontSize={defaultFontSize}>
              {t('admOrgs:Label:subscriptionCardTitle')}
            </Text>
              <Button
                type="primary"
                className="gd-form-button gd-form-button-primary"
              >
                <Link href="https://billing.stripe.com/p/login/cN22av32h1m72S45kk" target="_blank">
                  Manage Subscription & Payments
                </Link>
              </Button>
          </TitleWithControlContainer>
        </Row>
        <Row className="gd-subscription-plan-item">
          {data.map((item, idx) => (
            <Col span={item.span} key={`ordDate-${idx}`}>
              <GdNumber
                postFix={item.postFix as string}
                color={colors.black085}
                value={item.value as string}
                title={item.title}
                numberFontSz={18}
                titleFontSz={12}
              />
            </Col>
          ))}
        </Row>
        <Row className="gd-subscription-plan-item">
          <Col span={6}>
            <Text fontSize={10} color={colors.black06}>
              {t('admOrgs:Label:subscriptionExpires')}
            </Text>
            <Text fontSize={10}>{subscriptionEndDate.toString()}</Text>
          </Col>
          <Col span={9} className="gd-progress-item">
            <Progress
              percent={calculateProgress(limitUsers, totalUsers)}
              showInfo={false}
              strokeWidth={8}
              strokeColor={colors.strongBlue}
              trailColor={colors.strongBlue016}
            />
          </Col>
          <Col span={9} className="gd-progress-item">
            <Progress
              percent={calculateProgress(limitStorageSize, totalStorageSize)}
              showInfo={false}
              strokeWidth={8}
              strokeColor={colors.strongBlue}
              trailColor={colors.strongBlue016}
            />
          </Col>
        </Row>
      </OrgSubscriptionCardContainer>
    );
  }
);
