import { Select, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import DatePicker from '../../../../components/GdDatePicker';
import { FilterMeta, GdFilterBar } from '../../../../components/GdFilterBar';
import { GdLoadingIndicator } from '../../../../components/GdLoadingIndicator';
import { useRootStore } from '../../../../context/storeContext';
import { SubjectType } from '../../stores/logEventsStore';
import { EventsMap } from '../../types/orgTypes';

const { RangePicker } = DatePicker;

const Subjects: { [key: string]: string } = {
  User: 'User',
  Group: 'Group',
  Guido: 'Guido',
};

export const OrgActivityLogFilter = observer(() => {
  const { orgEventStore } = useRootStore();
  const { orgId } = useParams<{ orgId: string }>();

  const [loading, setLoading] = useState(false);
  const [subjectType, setSubjectType] = useState<SubjectType>();

  const onSearchEventAuthors = useCallback(
    async (newValue: string) => {
      setLoading(true);
      await orgEventStore.getUserOptionsByName(newValue, orgId);
      setLoading(false);
    },
    [orgEventStore, orgId]
  );

  const onSearchSubjects = useCallback(
    async (newValue) => {
      setLoading(true);
      await orgEventStore.getSubjectData(subjectType, newValue, orgId);
      setLoading(false);
    },
    [orgEventStore, orgId, subjectType]
  );

  const onSubjectTypeChanged = useCallback((newValue) => {
    setSubjectType(newValue);
  }, []);

  const onFinish = useCallback(
    (values) => {
      if (values.dateRange === null) return;
      orgEventStore.searchEvents(values);
    },
    [orgEventStore]
  );

  const clearFiltersMeta = useCallback(() => {
    orgEventStore.clearLogFiltersMeta();
  }, [orgEventStore]);

  const formMeta: FilterMeta[] = [
    {
      dateRange: {
        span: 16,
        label: 'Event Date',
        render: (form) => {
          return (
            // @ts-ignore
            <RangePicker
              className="gd-input-item"
              showTime
              onChange={() => form.submit()}
            />
          );
        },
      },
      eventType: {
        span: 8,
        label: 'Event Type',
        render: (form) => {
          return (
            <Select
              style={{ width: '100%' }}
              className="gd-input-item"
              placeholder="Event Type"
              notFoundContent={null}
              allowClear
              maxTagCount="responsive"
              optionLabelProp="label"
              onChange={() => {
                form.submit();
              }}
              options={Object.keys(EventsMap).map((jt) => ({
                label: EventsMap[jt].action,
                value: jt,
              }))}
            />
          );
        },
      },
    },
    {
      eventAuthor: {
        span: 8,
        label: 'Event Author',
        render: (form) => {
          return (
            <Select
              style={{ width: '100%' }}
              className="gd-input-item"
              showSearch
              placeholder="Event Author Name"
              defaultActiveFirstOption={false}
              showArrow={false}
              allowClear
              filterOption={false}
              onSearch={onSearchEventAuthors}
              onChange={() => form.submit()}
              onBlur={clearFiltersMeta}
              onClear={clearFiltersMeta}
              notFoundContent={
                loading ? (
                  <Spin indicator={<GdLoadingIndicator size={12} />} />
                ) : null
              }
              optionLabelProp="label"
              options={orgEventStore.eventAuthors.map((jt) => ({
                label: jt.label,
                value: jt.value,
              }))}
            />
          );
        },
      },
      subjectType: {
        span: 8,
        label: 'Subject Type',
        render: (form) => {
          return (
            <Select 
              disabled={true} // TODO: Enable and implement
              style={{ width: '100%' }}
              className="gd-input-item"
              placeholder="Event Type"
              notFoundContent={null}
              allowClear
              maxTagCount="responsive"
              optionLabelProp="label"
              onChange={onSubjectTypeChanged}
              options={Object.keys(Subjects).map((jt) => ({
                label: Subjects[jt],
                value: jt,
              }))}
            />
          );
        },
      },
      subjectId: {
        span: 8,
        label: 'Event Subject',
        render: (form) => {
          return (
            <Select
              disabled={true} // TODO: Enable and implement
              style={{ width: '100%' }}
              className="gd-input-item"
              showSearch
              placeholder="Put any subject title"
              defaultActiveFirstOption={false}
              showArrow={false}
              allowClear
              filterOption={false}
              onSearch={onSearchSubjects}
              onChange={() => form.submit()}
              onBlur={clearFiltersMeta}
              onClear={clearFiltersMeta}
              notFoundContent={
                loading ? (
                  <Spin indicator={<GdLoadingIndicator size={12} />} />
                ) : null
              }
              optionLabelProp="label"
              options={orgEventStore.subjects.map((jt) => ({
                label: jt.label,
                value: jt.value,
              }))}
            />
          );
        },
      },
    },
  ];
  return (
    <GdFilterBar
      initialValues={orgEventStore.eventsFilter}
      meta={formMeta}
      onFilter={onFinish}
    />
  );
});
