import { Avatar, Dropdown } from 'antd';
import React from 'react';
import styled from 'styled-components/macro';
import { buildImageUrlFromBase64, setAvatarLetters } from '../utils';
import Text from './Text';

type DirectionProps = { direction?: 'normal' | 'reverse'; size?: number };
type UserControlProps = {
  description?: string | React.ReactElement;
  email?: string;
  name: string | null | React.ReactElement;
  nameFontSize?: number;
  menu?: React.ReactElement;
  image?: string;
} & DirectionProps;

const UserData = styled.span<DirectionProps>`
  display: flex;
  align-content: center;
  align-items: ${({ direction }) =>
    direction && direction === 'reverse' ? 'start' : 'end'};
  flex-direction: column;
  ${({ direction }) =>
    direction && direction === 'reverse'
      ? 'padding-left: 16px'
      : 'padding-right: 16px'};

  & > span {
    line-height: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: ${({ direction }) =>
      direction && direction === 'reverse' ? 'start' : 'end'};
  }

  width: calc(100% - ${({ size }) => `${size}px`});
`;

const UserAvatarContainer = styled.div<DirectionProps>`
  width: ${({ size }) => `${size}px`};
`;

const UserInfoContainer = styled.div<DirectionProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ direction }) =>
    direction && direction === 'reverse' ? 'row-reverse' : 'row'};
`;

const DataAvatar = (
  userName: string | null | React.ReactElement,
  size: number,
  image?: string
) => {
  if (image) {
    return <Avatar size={size} src={buildImageUrlFromBase64(image)} />;
  }
  return typeof userName === 'string' ? (
    <Avatar
      size={size}
      // src={<Image src={`${process.env.PUBLIC_URL}/exampleAvatar.png`} />}
    >
      {setAvatarLetters(userName as string)}
    </Avatar>
  ) : (
    <Avatar size={size} />
  );
};
export const UserControl = ({
  direction = 'normal',
  name = '',
  description,
  email,
  nameFontSize,
  menu,
  size = 48,
  image,
}: UserControlProps) => {
  return (
    <UserInfoContainer direction={direction}>
      <UserData direction={direction} size={size}>
        <Text fontSize={nameFontSize || 14}>{name || 'Loading...'}</Text>
        {description && typeof description === 'string' ? (
          <Text fontSize={14} opacity={0.35} fontFamily="montserrat-semi-bold">
            {description}
          </Text>
        ) : (
          description
        )}
        {email && (
          <Text
            fontSize={15}
            color="rgba(0, 0, 0)"
            fontFamily="montserrat-medium"
          >
            {email}
          </Text>
        )}
      </UserData>
      <UserAvatarContainer size={size}>
        {menu ? (
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={['click']}
            overlayClassName="gd-dropdown-menu"
          >
            {DataAvatar(name as string, size, image)}
          </Dropdown>
        ) : (
          DataAvatar(name, size, image)
        )}
      </UserAvatarContainer>
    </UserInfoContainer>
  );
};
