import { Button, Col, Form, Input, Row } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { env } from '../../../env';
import { TextMainLogo } from '../components/TextMainLogo';
import {
  DetailsContainer,
  LogoImage,
  SignInFormContainer,
  SignInPageContainer,
} from '../styled';

const SignUpLink = styled.div`
  & .gd-form-button-link-primary {
    width: auto;
  }

  & .gd-form-button-link-primary:focus,
  & .gd-form-button-link-primary:hover {
    color: ${({ theme: { colors } }) => colors.strongBlue};
    background: transparent;
    border-color: transparent;
  }

  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContinueButtonContainer = styled.div`
  padding-top: 28px;
`;

const TermsLink = styled.a.attrs((props) => ({
  href: props.href || '/',
}))`
  font-family: ${({ theme: { fonts } }) => fonts.medium};
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.black};
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    color: ${({ theme: { colors } }) => colors.black};
  }
`;

function SignUpPage() {
  const { signUpStore } = useRootStore();
  const { t } = useTranslation();
  const history = useHistory();

  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values) => {
      signUpStore.setTenantOwner(values);
      history.push('/register');
    },
    [history, signUpStore]
  );

  const onSignUpClick = useCallback(() => {
    history.push('/signin');
  }, [history]);

  return (
    <SignInPageContainer>
      <TextMainLogo />
      <LogoImage src={`${env.PUBLIC_URL}/registerLogo.png`} />
      <SignInFormContainer>
        <DetailsContainer>
          <PageTitle
            title={t('SignInUpPage:Text:SignUp:mainAccount')}
            textSize={32}
          />
          <Text fontFamily="montserrat-medium" fontSize={14}>
            {t('SignInUpPage:Text:SignUp:signUpDetails')}{' '}
            <TermsLink>{t('SignInUpPage:Text:SignUp:privacyPolicy')}</TermsLink>{' '}
            and
            <TermsLink>{t('SignInUpPage:Text:SignUp:termOfUse')}</TermsLink>
          </Text>
        </DetailsContainer>
        <Form
          form={form}
          layout="vertical"
          name="normal_login"
          className="login-form"
          initialValues={{ remember: false }}
          onFinish={onFinish}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                className="gd-form-item"
                label={<Text>{t('SignInUpPage:Labels:email')}</Text>}
                name="email"
                rules={[
                  { type: 'email', message: 'The input is not valid E-mail!' },
                ]}
              >
                <Input className="gd-input-item" placeholder="Username" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="gd-form-item"
                label={<Text>{t('SignInUpPage:Labels:firstName')}</Text>}
                name="firstName"
                rules={[
                  { required: true, message: 'First Name could not be empty' },
                ]}
              >
                <Input className="gd-input-item" placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="gd-form-item"
                label={<Text>{t('SignInUpPage:Labels:surname')}</Text>}
                name="lastName"
                rules={[
                  { required: true, message: 'Last Name could not be empty' },
                ]}
              >
                <Input className="gd-input-item" placeholder="Surname" />
              </Form.Item>
            </Col>
          </Row>
          <ContinueButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              className="gd-form-button gd-form-button-primary gd-form-button-full-width"
            >
              {t('SignInUpPage:Buttons:continueBtn')}
            </Button>
          </ContinueButtonContainer>
        </Form>
        <SignUpLink>
          <Text fontFamily="montserrat-medium" fontSize={14}>
            {t('SignInUpPage:Text:SignUp:signInpLbl')}
          </Text>
          <Button
            type="link"
            className="gd-form-button gd-form-button-link-primary"
            onClick={onSignUpClick}
          >
            {t('SignInUpPage:Buttons:SignInBtnLbl')}
          </Button>
        </SignUpLink>
      </SignInFormContainer>
    </SignInPageContainer>
  );
}

export default SignUpPage;
