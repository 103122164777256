import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ListMeta } from '../../../components/GdList';
import { ColumnsMeta } from '../../../components/GdListHeader';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { SortDirection } from '../../../types/commonTypes';
import { showLocalDate } from '../../../utils';

export const useGuidosMeta = (guidosType: string) => {
  const columnMeta = useColumnsMeta(guidosType);
  const listMeta = useListMeta();

  return { columnMeta, listMeta };
};

const useListMeta = () => {
  const listMeta: ListMeta = {
    name: {
      span: 8,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {item.name}
          </Text>
        );
      },
    },
    creationDate: {
      span: 8,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {showLocalDate(item.creationDate as string)}
          </Text>
        );
      },
    },
    assignees: {
      span: 7,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {item.assignees}
          </Text>
        );
      },
    },
  };
  return listMeta;
};

const useColumnsMeta = (guidosType: string) => {
  const { userListsStore } = useRootStore();
  const { t } = useTranslation();

  const sortingHandler = useCallback(
    (order, field) => {
      userListsStore.sortProperty(guidosType, order, field);
    },
    [guidosType, userListsStore]
  );

  const columns: ColumnsMeta = {
    name: {
      colspan: 8,
      title: t('users:UserDetails:Lists:guidoNameLbl'),
    },
    creationDate: {
      colspan: 8,
      title: t('users:UserDetails:Lists:guidoCreationDateLbl'),
      sortingHandler,
      direction: SortDirection.Asc,
    },
    assignees: {
      colspan: 7,
      title: t('users:UserDetails:Lists:guidoAssigneesLbl'),
    },
  };

  return columns;
};
