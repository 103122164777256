import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GdChart } from '../../../components/GdChart';
import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';
import { GraphCardContainer } from './GraphCardContainer';

export const GuidosInOrganizationChart = () => {
  const { t } = useTranslation();

  const { admDashboardStore } = useRootStore();

  const fetchChart = useCallback(
    async (loadMore = false) => {
      return await admDashboardStore.getTopFourOrgsByGuidosChart();
    },
    [admDashboardStore]
  );

  const { loading } = useDataQuery({
    dataFunc: fetchChart,
  });

  return (
    <GraphCardContainer loading={loading}>
      <GdChart
        type="progress"
        data={admDashboardStore.topFourOrgByGuidosChart}
        info={t('admDashboard:Label:guidoInOrgLbl')}
        progressTitle={t(
          'admDashboard:Label:ProgressCharts:guidosProgressTitle'
        )}
      />
    </GraphCardContainer>
  );
};
