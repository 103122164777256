import { Card, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { GdEditableInput } from '../../../components/GdEditableInput';
import { GdMetaLabel, GdMetaValue } from '../../../components/GdMeta';
import { useRootStore } from '../../../context/storeContext';
import { formatBytes, showLocalDate } from '../../../utils';
import { CardInfoContainer, MetaContainer } from '../../Common/styles';

const GroupDetailsCardContainer = styled(CardInfoContainer)``;

export const GroupDetailsCard = observer(() => {
  const { t } = useTranslation();
  const { teamsStore, authorizeStore } = useRootStore();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmGroup');

  const [isNameEdited, setNameEdited] = useState(false);
  const {
    name,
    creator,
    creationDateUtc,
    numberOfUsers,
    id: groupId,
    totalGuidosSize,
  } = teamsStore.selectedGroup;

  const setEditMode = useCallback(async () => {
    if (isNameEdited) {
      teamsStore.updateTeamData({ name });
      await teamsStore.updateGroupDetails(groupId as string);
    }

    setNameEdited(!isNameEdited);
  }, [groupId, isNameEdited, name, teamsStore]);

  const onChangeGroupName = useCallback(
    (e) => {
      const name = e.target.value as string;
      teamsStore.updateTeamData({ name });
    },
    [teamsStore]
  );

  const onEndNameEdit = useCallback(async () => {
    setNameEdited(false);
    await teamsStore.updateGroupDetails(groupId as string);
    sendAnalyticEvent(
      'Update Group',
      `Update Group ${groupId} Name to ${teamsStore.selectedGroup.name}`
    );
  }, [sendAnalyticEvent, teamsStore, groupId]);

  return (
    <GroupDetailsCardContainer>
      <Card className="gd-card">
        <Row>
          <GdEditableInput
            value={name || ''}
            setEditMode={setEditMode}
            onChangeName={onChangeGroupName}
            isNameEdited={isNameEdited}
            onEndNameEdit={onEndNameEdit}
            editable={authorizeStore.isAuthorizedToEditGroup()}
          />
        </Row>
        <MetaContainer>
          <Row className="gd-meta-item-row">
            <Col span={11}>
              <GdMetaLabel label={t('groups:Labels:creatorLbl')} />
            </Col>
            <Col span={13}>
              <GdMetaValue value={creator} />
            </Col>
          </Row>
          <Row className="gd-meta-item-row">
            <Col span={11}>
              <GdMetaLabel label={t('groups:Labels:creationDateLbl')} />
            </Col>
            <Col span={13}>
              <GdMetaValue
                value={showLocalDate(creationDateUtc as string | number)}
              />
            </Col>
          </Row>
          <Row className="gd-meta-item-row">
            <Col span={11}>
              <GdMetaLabel label={t('groups:Labels:numberOfUsersLbl')} />
            </Col>
            <Col span={13}>
              <GdMetaValue value={numberOfUsers} />
            </Col>
          </Row>
          <Row className="gd-meta-item-row">
            <Col span={11}>
              <GdMetaLabel label={t('groups:Labels:guidoSizeLbl')} />
            </Col>
            <Col span={13}>
              <GdMetaValue value={formatBytes(Number(totalGuidosSize))} />
            </Col>
          </Row>
        </MetaContainer>
      </Card>
    </GroupDetailsCardContainer>
  );
});
