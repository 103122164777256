import styled, {
  DefaultTheme,
  createGlobalStyle,
  css,
} from 'styled-components/macro';

export const clearAntListSpinner = () => css`
  & .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    top: unset;
  }

  & .ant-spin-container.ant-spin-blur {
    & .rc-virtual-list {
      margin-top: -53px;
    }
  }
`;

export const CommandsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 3px;
  padding-top: 28px;
`;

export const BasePageContainer = styled.div`
  width: 100%;
  padding-right: 46px;
`;

export const UserContainer = styled.div`
  padding-top: 28px;
  width: 100%;

  & .gd-input-item {
    & .ant-select-selector {
      width: 100%;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const CardsDetailsContainer = styled.div`
  padding: 33px 3px 0 0;

  & .gd-details-list {
    height: calc(100vh - 285px);
    padding-bottom: 8px;
    overflow: auto;
  }
`;

export const CardInfoContainer = styled.div`
  & .gd-action-card {
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);

    & .ant-btn-link {
      padding: 0;
    }

    & .ant-card-body {
      padding: 16px 24px;
    }
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const MetaContainer = styled.div`
  padding-top: 40px;

  & .gd-meta-item-row {
    padding-bottom: 18px;

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const DetailsListsContainer = styled.div`
  & .gd-card {
    & .ant-card-body {
      padding: 16px 24px;
    }
  }

  & .gd-card:not(:last-of-type) {
    margin-bottom: 24px;
  }
  width: 100%;
`;

export const CollapsableListContainer = styled.div`
  & .gd-list-meta.gd-input-item-role {
    overflow: unset;
  }

  & .gd-list-meta {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & .gd-input-item {
      width: 100%;

      & .ant-select-selector {
        height: 25px !important;
        overflow: unset;
      }
    }
  }

  ${clearAntListSpinner()}
`;

export const ListContainer = styled.div`
  width: 100%;

  & .gd-list-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: ${({ theme: { fonts } }) => fonts.bold};
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */

    letter-spacing: -0.01em;
    padding-left: 24px;

    &.gd-list-title_regular {
      font-family: ${({ theme: { fonts } }) => fonts.regular};
    }
  }

  & .ant-spin-container.ant-spin-blur {
    & .rc-virtual-list {
      margin-top: -53px;
    }
  }
`;

export const LandingCssOverrides = createGlobalStyle<{ theme: DefaultTheme }>`
  .navigation-back {
    display: flex;
    font-size: 12px;
    align-items: center;
    font-weight: 700;
    color: ${({ theme: { colors } }) => colors.black};
    padding-left: 0;

    & > span[role="img"] {
      & > svg {
        width: 17px;
        height: 17px;
      }

      padding-top: 1px;

    }

    &:focus,
    &:hover {
      color: ${({ theme: { colors } }) => colors.black};
    }
  }

  .navigation-breadcrumbs {
    & .ant-breadcrumb {
      font-size: 12px;
    }
  }
`;

export const TitleWithControlContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-right: 40px;

  .gd-form-button {
    border-radius: 4px;
  }
`;
