import { Avatar } from 'antd';
import styled from 'styled-components/macro';
import { setAvatarLetters } from '../../../../../../utils';

const ItemNameContainer = styled.div`
  padding: 0 15px;
  display: flex;
  justify-content: start;
  align-items: center;

  & .gd-user-list-avatar {
    background: ${({ theme }) => theme.colors.strongBlue};
    margin-right: 10px;
  }
`;

const NameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
`;

type Props = {
  name: string;
  useAvatarLetter: boolean;
};

export const ListItemName = ({ name, useAvatarLetter }: Props) => {
  return (
    <ItemNameContainer>
      {useAvatarLetter && (
        <Avatar className="gd-user-list-avatar">
          {setAvatarLetters(name as string)}
        </Avatar>
      )}
      <NameContainer>{name}</NameContainer>
    </ItemNameContainer>
  );
};
