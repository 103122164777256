import { makeAutoObservable } from 'mobx';

type LockAction = 'noop' | 'activate' | 'deactivate';

export class OrgUser {
  id: string;
  name: string;
  email: string;
  role: string;

  origLockState: boolean;
  checked: boolean;

  constructor(
    id: string,
    name: string,
    email: string,
    role: string,
    isActive: boolean
  ) {
    this.name = name;
    this.role = role;
    this.email = email;
    this.id = id;
    this.checked = this.origLockState = isActive;

    makeAutoObservable(this);
  }

  get asObject() {
    const { name, role, email, checked, id } = this;
    return {
      name,
      role,
      email,
      checked,
      id,
    };
  }

  toggle(toggleState: boolean) {
    this.checked = toggleState;
  }

  get lockAction(): LockAction {
    if (this.origLockState !== this.checked) {
      return this.checked ? 'activate' : 'deactivate';
    }
    return 'noop';
  }

  reset() {
    this.checked = this.origLockState;
  }
}
