import {Popover, Row, Select} from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterMeta, GdFilterBar } from '../../../components/GdFilterBar';
import { useRootStore } from '../../../context/storeContext';
import { capitalizeFirstLetter } from '../../../utils';
import { useSubscriptionsQuery } from '../hooks/useOrgSettingsMeta';
import { OrgState } from '../stores/orgListStore';
import { InfoCircleOutlined } from '@ant-design/icons';

const stateInfoContent = (
  <div>
    <p>The state refreshes whenever an activity log event occurs.</p>
    <p>It can be that 'Last Login' is longer than 2 weeks ago,<br />but state is green because some other event happened in the past 2 weeks.</p> 
    <ul>
        <li>Green: Past 2 weeks</li>
        <li>Yellow: Between 2-4 weeks ago</li>
        <li>Red: More than 4 weeks ago</li>
    </ul>
    <p>The events include:</p>
      <ul>
      <li>User has logged in</li>
      <li>User has been created or deleted</li>
      <li>Guido has been created, renamed, deleted or restored</li>
      <li>Guido has been shared (for the first time)</li>
      <li>Guido timeline or annotation item has been created or deleted</li>
      <li>Group has been created, renamed or deleted</li>
      <li>Subscription changed</li>
      </ul>
  </div>
);

const states: { [key in string]: OrgState } = {
  Unknown: 'Unknown',
  Green: 'Green',
  Red: 'Red',
  Yellow: 'Yellow',
};

export const OrganizationsFilterBar = observer(() => {
  const { t } = useTranslation();

  const { orgListStore } = useRootStore();

  useSubscriptionsQuery();

  useEffect(() => {
    return () => {
      orgListStore.clearFilter();
    };
  }, [orgListStore]);

  const formMeta: FilterMeta = {
    name: {
      span: 6,
      label: t('admOrgs:Label:Filter:nameLbl'),
      placeholder: t('admOrgs:Label:Filter:namePlaceLbl'),
      isSearch: true,
    },
    filterBySubscription: {
      span: 6,
      label: t('admOrgs:Label:Filter:subscriptionLbl'),
      render: (form) => {
        return (
          <Select
            style={{ width: '100%' }}
            className="gd-input-item"
            placeholder={t('admOrgs:Label:Filter:subscriptionPlaceholderLbl')}
            mode="multiple"
            notFoundContent={null}
            allowClear
            maxTagCount="responsive"
            optionLabelProp="label"
            onChange={() => {
              form.submit();
            }}
            options={Object.keys(orgListStore.subscriptionOptions).map(
              (jt) => ({
                label: capitalizeFirstLetter(
                  orgListStore.subscriptionOptions[jt]
                ),
                value: jt,
              })
            )}
          />
        );
      },
    },
    filterByState: {
      span: 6,
      label: t('admOrgs:Label:Filter:stateLbl'),
      render: (form) => {
        return (
            <Select
            style={{ width: '100%' }}
            className="gd-input-item"
            placeholder={t('admOrgs:Label:Filter:statePlaceLbl')}
            allowClear
            onChange={() => {
              form.submit();
            }}
            options={Object.keys(states).map(
              (jt) => ({
                label: jt,
                value: jt,
              })
            )}
          />
        );
      },
    },
    stateInfo: {
      span: 1, 
      label: '',
      render: () => {
        return (
            <Popover title={t('admOrgs:Label:Filter:statePopoverTitle')} trigger="hover" content={stateInfoContent}><InfoCircleOutlined style={{ margin: '8px 0 0 -10px', fontSize: '18px', cursor: "pointer"}} /></Popover>
        )
      }
    },
    totalAmount: {
      span: 5,
      label: t('admOrgs:Label:Filter:orgsNumbersLbl'),
      disabled: true,
      bordered: false,
      className: 'gd-filter-count-input',
    },
  };

  const onFilter = useCallback(
    (values) => {
      const filter = {
        ...values,
      };

      console.log(values);

      orgListStore.setFilter(filter);
    },

    [orgListStore]
  );

  return (
    <GdFilterBar
      meta={formMeta}
      onFilter={onFilter}
      initialValues={orgListStore.amountInfo}
    />
  );
});
