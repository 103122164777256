import { action, makeObservable, observable, toJS } from 'mobx';

import { AmountInfo } from '../domain/Users/stores/usersStore';
import { SortDirection, UpdateCommandEnum } from '../types/commonTypes';
import { getSortFunction, sortBy } from '../utils';

export type Serv<T> = {
  [key: string]: T[keyof T];
};

export type BaseEntity = {
  id: string;
  checked: boolean;
  changed: boolean;
  original: boolean;
};

export const listDefault: AmountInfo = {
  amount: 0,
  hasNext: false,
  amountLeft: 0,
  totalAmount: 0,
};

export type ModifiedElement<T> = {
  data: T;
  command: UpdateCommandEnum;
};

export class BaseListsStore {
  origValues = new Map<string, Map<string, number | string | boolean>>();

  constructor() {
    makeObservable(this, {
      origValues: observable,
      sortProperty: action,
      updateProperty: action,
      setFilter: action,
      setAllChecked: action,
      clearEditState: action,
      setChecked: action,
      isCollectionChanged: action,
    });
  }

  isCollectionChanged(collection: string) {
    //@ts-ignore
    const array: BaseEntity[] = this[collection];
    return array.length !== 0 && array.some((item) => item.changed);
  }

  getChangedItems<T, U extends BaseEntity>(
    collection: string,
    getData: (item: U) => T
  ) {
    // @ts-ignore
    const changedItems: ModifiedElement<T>[] = this[collection]
      .filter((x: BaseEntity) => x.changed)
      .map((grp: U) => {
        const changedProps = this.origValues.get(grp.id as string);
        const checkedProp = changedProps && changedProps.get('checked');
        let command = UpdateCommandEnum.Modified;
        if (changedProps && changedProps.has('checked')) {
          command = checkedProp
            ? UpdateCommandEnum.Removed
            : UpdateCommandEnum.Added;
        }
        return {
          data: getData(grp),
          command: command,
        };
      });
    return changedItems;
  }

  public setDefaultRoleIfEmpty(defaultRole: string, collection: string) {
    // @ts-ignore
    this[`${collection}Cache`] = this[`${collection}Cache`].map((g) => {
      const newItem = g;
      if (!g.role) {
        this.updateProperty(newItem, 'role', defaultRole);
      }
      return newItem;
    });
  }

  public sortProperty(propType: string, order: SortDirection, field: string) {
    const sortFunc = getSortFunction(field, order);
    // @ts-ignore
    const property = toJS(this[propType]);
    // @ts-ignore
    this[propType] = property.sort(sortFunc);
  }

  //TODO: Refactor changes tracking function
  updateProperty<T extends number | string | boolean>(
    item: Serv<BaseEntity | { [key: string]: boolean | number }>,
    propName: string,
    newValue: T,
    collection: string = ''
  ) {
    const origValue = this.origValues.get(item.id as string);
    if (item && origValue && origValue.has(propName)) {
      const originalValue = origValue.get(propName);
      if (originalValue === newValue) {
        origValue.delete(propName);
        if (!originalValue && !newValue) {
          origValue.clear();
        }
        item[propName] = originalValue;
      } else {
        item[propName] = newValue;
        item.changed = true;
      }
      if (!origValue.size) {
        item.changed = false;
        this.origValues.delete(item.id as string);
      }
    } else if (item) {
      const propMap = this.origValues.has(item.id as string)
        ? (this.origValues.get(item.id as string) as Map<
            string,
            number | string | boolean
          >)
        : new Map();

      propMap.set(propName, item[propName]); // store original item value
      this.origValues.set(item.id as string, propMap);
      item[propName] = newValue;
      item.changed = true;
    }
    if (!collection) return;
    //@ts-ignore
    this[collection] = [...this[collection]];
  }

  public setFilter(propertyType: string, searchText: string) {
    // @ts-ignore
    this[`${propertyType}SearchText`] = searchText;
  }

  public setAllChecked(propertyType: string) {
    // @ts-ignore
    this[`${propertyType}checkAll`] =
      // @ts-ignore
      this[propertyType].every(
        // @ts-ignore
        (p) => p.checked === true
        // @ts-ignore
      ) && this[propertyType].length;
  }

  public clearEditState(collection: string) {
    //@ts-ignore
    const array = this[`${collection}Cache`];
    const changedItems = array.filter((x: BaseEntity) => x.changed);
    changedItems.forEach(
      (item: Serv<BaseEntity | { [key: string]: boolean | number }>) => {
        const origValues = this.origValues.get(item.id as string);
        origValues &&
          origValues.forEach((value, key) => {
            item[key] = value;
          });
        item.changed = false;
        origValues && origValues.clear();
        this.origValues.delete(item.id as string);
      }
    );
    //@ts-ignore
    this[collection] = this[`${collection}Cache`].filter(
      //@ts-ignore
      (item) => item.original
    );
  }

  public setChecked(collection: string, id: string | number, checked: boolean) {
    //@ts-ignore
    const array = this[collection];
    //@ts-ignore
    this[collection] = array.map((item: TeamGroup | UserGuido) => {
      const newItem = item;
      if (!id || item.id === id) {
        this.updateProperty(newItem, 'checked', checked);
      }
      return newItem;
    });
    this.setAllChecked(collection);
  }
}
