import { cloneElement, isValidElement, useCallback } from 'react';
import styled from 'styled-components/macro';

import { ListMeta } from '../../../../components/GdList';
import { Actions } from '../components/WizardSteps/shared/AddListItemControl';
import { ListItemName } from '../components/WizardSteps/shared/ListItemName';

const ActionContainer = styled.span`
  padding-right: 9px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span > svg {
    width: 20px;
    height: 20px;
  }

  & > span {
    padding: 0 6px;
  }
`;

export enum ListType {
  Users = 'Users',
  Groups = 'Groups',
}

export const useSignUpListMeta = <
  T extends { [key: string]: string | number | boolean | any }
>(
  type: ListType,
  actions: Actions[]
): ListMeta => {
  const getSpans = useCallback((actions) => {
    let nameSpan = 20,
      actionsSpan = 4;
    if (actions.length > 1) {
      nameSpan = 18;
      actionsSpan = 6;
    }
    return [nameSpan, actionsSpan];
  }, []);

  const [nameSpan, actionsSpan] = getSpans(actions);

  return {
    name: {
      span: nameSpan,
      overrideStyle: true,
      className: 'gd-user-list-card-avatar',
      render: (item) => {
        return (
          <ListItemName
            name={item.name as string}
            useAvatarLetter={type === ListType.Users}
          />
        );
      },
    },
    action: {
      span: actionsSpan,
      render: (item) => {
        return (
          <ActionContainer>
            {actions.map((act) => {
              let comp = act as JSX.Element;
              if (isValidElement(act)) {
                comp = act as JSX.Element;
              } else {
                const func = act as (group: T) => JSX.Element;
                comp = func(item as T) as JSX.Element;
              }
              return cloneElement(comp, {
                key: comp.key,
                onClick: () => comp.props.onClick(item),
              });
            })}
          </ActionContainer>
        );
      },
    },
  };
};
