import { Button, Divider, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/macro';
import { PageTitle } from '../../../../../components/PageTitle';
import Text from '../../../../../components/Text';
import { useRootStore } from '../../../../../context/storeContext';
import { AddListContainer } from '../../styled';
import { ContentProps } from '../Content';
import { FinalLogo } from './shared/FinalLogo';

const FinalStepContainer = styled(AddListContainer)`
  height: 100%;

  & .gd-final-step-row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .gd-final-step-row:nth-of-type(1) {
    flex: 1 1;
  }

  & .gd-final-step-row:nth-of-type(2) {
    flex: 2 1;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 20px;

    & > span:nth-child(2) {
      padding-top: 25px;
    }

    & .gd-form-button {
      border-radius: 4px;
      height: 50px;
    }

    & .ant-divider-horizontal.ant-divider-with-text::before,
    & .ant-divider-horizontal.ant-divider-with-text::after {
      transform: translateY(10%);
    }
  }
`;

const AppButtons = styled.div`
  display: flex;
  align-content: center;
  padding-top: 8%;
  flex-direction: column;

  & .gd-app-links {
    display: flex;
    justify-content: center;

    & > a {
      width: 40%;
      margin-right: 20px;
    }

    & > a:nth-child(2) {
      width: 45%;
    }
  }
`;
const AppleImg = styled.img.attrs({
  src: 'appleAppLogo.png',
  width: '100%',
})``;
const AndroidImg = styled.img.attrs({
  src: 'googleAppLogo.png',
  width: '100%',
})``;

const AppButton = styled.a``;

export const FinalStep = observer(({ onNext, onBack }: ContentProps) => {
  const { t } = useTranslation();
  const { signUpStore } = useRootStore();
  const { isSignedUp, isSignUpFailed } = signUpStore;

  const onClickApple = useCallback(() => {
    window.open(
      'https://apps.apple.com/de/app/guided-doing/id1500589639?l=en',
      '_blank'
    );
  }, []);

  const onClickGoogle = useCallback(() => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.guideddoing.guido&hl=en_US&gl=US',
      '_blank'
    );
  }, []);

  const theme = useTheme();

  const getProgressText = useCallback(() => {
    if (isSignUpFailed) {
      return t('identity:FinalStep:errorText');
    }
    return isSignedUp
      ? t('identity:FinalStep:mainDescription')
      : t('identity:FinalStep:inProgressText1');
  }, [isSignUpFailed, isSignedUp, t]);
  return (
    <FinalStepContainer>
      <Row className="gd-final-step-row">
        <FinalLogo />
      </Row>
      <Row className="gd-final-step-row">
        <PageTitle textSize={33} title={t('identity:FinalStep:mainTitle')} />
        <Text fontSize={20} fontFamily={theme.fonts.medium}>
          {getProgressText()}
        </Text>
        <AppButtons>
          <Row className="gd-app-links">
            <AppButton onClick={onClickApple}>
              <AppleImg />
            </AppButton>
            <AppButton onClick={onClickGoogle}>
              <AndroidImg />
            </AppButton>
          </Row>
          <Row>
            <Divider plain>OR</Divider>
          </Row>
        </AppButtons>
        <Button
          type="primary"
          disabled={isSignUpFailed || !isSignedUp}
          className="gd-form-button gd-form-button-primary"
          onClick={() => {
            window.open('https://web.guided-doing.com', '_blank');
          }}
        >
          {t('identity:FinalStep:buttonText')}
        </Button>
      </Row>
    </FinalStepContainer>
  );
});
