import { Select } from 'antd';
import React from 'react';

import { PlainObject } from '../domain/Users/stores/usersStore';

type RoleSelectorProps = {
  item: { [p: string]: string | number | boolean };
  roles: PlainObject;
  editMode: boolean;
  onChange: (value: string) => void;
  defaultRole?: string | null;
};

export const GdRoleSelector = ({
  item,
  roles = {},
  editMode,
  onChange,
  defaultRole = null,
}: RoleSelectorProps) => {
  const value =
    editMode && !item.role && item.checked ? defaultRole : item.role;

  return (
    <Select
      defaultValue={value as string}
      value={value as string}
      className="gd-input-item"
      disabled={
        !(editMode && (item.checked as boolean)) || !!item.isGlobalAdmin
      }
      onChange={onChange}
    >
      {Object.keys(roles).map((key, idx) => {
        return (
          <Select.Option key={idx} value={key}>
            {roles[key]}
          </Select.Option>
        );
      })}
    </Select>
  );
};
