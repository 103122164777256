import { makeAutoObservable, runInAction, toJS } from 'mobx';

import { Result, RootStore } from '../../../services/rootStore';
import {
  BaseQuery,
  ListResult,
  SortDirection,
} from '../../../types/commonTypes';
import { getSortFunction, sortBy, sortRoles } from '../../../utils';
import { BaseStringType } from '../../Identity/stores/authStore';

export type ApplicationUser = {
  memberType: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  guidosCount: string;
  status: string;
  roleName: string;
  name: string;
  image: string;
  isCurrentUser: boolean;
  isGuest: boolean;
  isLocked: boolean;
};

export type GetUsersQuery = {
  loadImages: boolean;
} & BaseQuery;

type AddUserCommand = {
  emails: string[];
  guidos: string[];
  roleId: string;
  teamIds: string[];
};

type AddUserResponse = {
  addedUserIds: string[];
};

type EditUserCommand = {
  email: string;
  firstName: string;
  lastName: string;
};

type ResetUserEmailCommand = {
  userId: string;
  newEmail: string;
};

export type UserMeta = {
  createdDateUtc: string;
  assignedGuidos: string;
  companyRoleId: string;
  createdGuidos: string;
  teams: string;
  image: string;
} & Omit<ApplicationUser, 'guidosCount' | 'roleName'>;

export type PlainObject = {
  [P in keyof BaseStringType]: string | number | boolean | undefined;
};

export type UsersFilter = {
  filterByGroups: string[];
  nameOrEmail: string;
  filterByStatuses: string[];
};

export type AmountInfo = Omit<ListResult<ApplicationUser>, 'items'>;

export class UsersStore {
  rootStore: RootStore;
  amountInfo: AmountInfo = {
    amount: 0,
    totalAmount: 0,
    hasNext: false,
    amountLeft: 0,
  };
  filter: UsersFilter = {
    nameOrEmail: '',
    filterByGroups: [],
    filterByStatuses: [],
  };
  roles: PlainObject = {};
  statuses: string[] = [];
  teams: PlainObject = {};
  guidos: PlainObject[] = [];

  selectedUser: Partial<ApplicationUser> & Partial<UserMeta> = {};
  sortOrder: SortDirection = SortDirection.Asc;
  tenantUsers: ApplicationUser[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  public sortUsers(order: SortDirection, field: string) {
    const sortFunc = getSortFunction(field, order);
    const users = toJS(this.tenantUsers);
    this.tenantUsers = users.sort(sortFunc);
    this.sortOrder = order;
  }

  public async getUsers(
    query: Partial<GetUsersQuery>,
    loadMore: boolean = false
  ): Promise<Result<ListResult<ApplicationUser>>> {
    const body = {
      ...query,
      loadImages: true,
      ...this.filter,
    };
    const resp = await this.rootStore.transportLayer.fetchUsers(body);

    if (resp.succeeded) {
      runInAction(() => {
        const { items, totalAmount, hasNext, amount, amountLeft } = resp.data;
        this.amountInfo = { totalAmount, hasNext, amount, amountLeft };
        const newUsers = items.map((u) => ({
          ...u,
          name: `${u.firstName} ${u.lastName}`,
          isCurrentUser: u.id === this.rootStore.authStore.signedInUser.id,
          isGuest: u.memberType !== 'Member',
        }));

        this.tenantUsers = loadMore
          ? this.tenantUsers.concat(newUsers)
          : newUsers;

        this.sortUsers(this.sortOrder, 'name');
      });
    }
    return resp;
  }

  public async getUserData(userId: string) {
    const result = await this.rootStore.ApiClient.get<Result<UserMeta>>(
      `/user/userdetails/${userId}`
    );
    if (result.succeeded) {
      runInAction(() => {
        this.selectedUser = result.data;
      });
    }
    return result.data;
  }

  clearUsers() {
    this.tenantUsers = [];
    this.filter = {
      nameOrEmail: '',
      filterByGroups: [],
      filterByStatuses: [],
    };
  }

  clearUser() {
    this.selectedUser = {};
  }

  public async getUserMeta() {
    const userMeta = await this.rootStore.transportLayer.fetchRolesMeta();

    if (userMeta.succeeded) {
      runInAction(() => {
        this.roles = sortRoles(userMeta.data);
      });
    }
  }

  public async searchGuido(value: string) {
    const guidosBody: BaseQuery = {
      offset: 0,
      count: 10,
      textToSearch: value,
      orderingColumns: null,
      publishedOnly: true,
    };

    const searchedGuidos = await this.rootStore.ApiClient.post<
      BaseQuery,
      Result<{ items: PlainObject[] }>
    >('/guido/guidos', guidosBody);

    runInAction(() => {
      this.guidos = searchedGuidos.data.items;
    });
  }

  public async getFilterBarMeta() {
    const statusesAndTeams = await Promise.all([
      this.rootStore.ApiClient.get<string[]>('/meta/userstatuses'),
      this.rootStore.ApiClient.get<Result<PlainObject>>('/user/companyTeams'),
    ]);

    runInAction(() => {
      this.statuses = statusesAndTeams[0];
      this.teams = statusesAndTeams[1].data;
    });
  }

  public async getRolesOptions() {
    const res = await this.rootStore.ApiClient.get<Result<PlainObject>>(
      '/meta/userRoles'
    );
    if (res.succeeded) {
      runInAction(() => {
        this.roles = sortRoles(res.data);
      });
    }
  }

  public setSelectedUser(userId: string) {
    this.selectedUser =
      toJS(this.tenantUsers).find((us) => us.id === userId) || {};
  }

  async addUser(command: AddUserCommand): Promise<Result<AddUserResponse>> {
    return await this.rootStore.ApiClient.post<
      AddUserCommand,
      Result<AddUserResponse>
    >('/user/addUsers', command);
  }

  async editUser(body: EditUserCommand, userId: string, image: File | null) {
    const requests: Promise<Result<string>>[] = [
      this.rootStore.ApiClient.put<EditUserCommand, Result<string>>(
        `/user/edituser/${userId}`,
        body
      ),
    ];
    if (image) {
      const form = new FormData();
      form.append('image', image);
      form.append('userId', userId);
      requests.push(
        this.rootStore.ApiClient.postForm('/image/updateUserImage', form).then(
          () => {
            return {
              succeeded: true,
              message: 'Success',
              failed: false,
              data: '',
            } as Result<string>;
          }
        )
      );
    }
    const res = await Promise.all(requests);
    return res[0];
  }

  async resetEmail(command: ResetUserEmailCommand) {
    return await this.rootStore.ApiClient.post<
      ResetUserEmailCommand,
      Result<string>
    >(`/user/RequestEmailChange/${command.userId}`, command);
  }

  async deleteUser(userId: string) {
    try {
      const res = await this.rootStore.ApiClient.delete(
        `/user/delete/${userId}`
      );
      if (res.succeeded) {
        runInAction(() => {
          this.tenantUsers = this.tenantUsers.filter(({ id }) => id !== userId);
          const { totalAmount, amountLeft } = this.amountInfo;
          this.amountInfo = {
            ...this.amountInfo,
            amountLeft: amountLeft - 1,
            totalAmount: totalAmount - 1,
          };
        });
      }
      return res;
    } catch (e) {
      const res: Result<string> = {
        data: 'Delete failed',
        message: `${e}`,
        succeeded: false,
        failed: true,
      };
      return res;
    }
  }

  public setFilter(newFilter: UsersFilter) {
    this.filter = { ...newFilter };
  }

  public updateTeamsCount(newCount: number) {
    this.selectedUser = { ...this.selectedUser, teams: String(newCount) };
  }
}
