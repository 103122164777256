import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { GdList, ItemMode } from '../../../components/GdList';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { useRootStore } from '../../../context/storeContext';
import { ListContainer } from '../../Common/styles';
import { useGuidosQuery } from '../hooks/useGuidosQuery';
import { useGuidosMeta } from '../hooks/useGuidosMeta';

export const GuidosList = observer(() => {
  const history = useHistory();

  const { guidoListStore } = useRootStore();
  const { loading, loadMoreGuidos } = useGuidosQuery();

  const listMeta = useGuidosMeta();

  const { guidos, amountInfo } = guidoListStore;

  return (
    <ListContainer>
      <GdList
        split={false}
        heightDelta={415}
        itemMode={ItemMode.Card}
        data={guidos}
        columnMeta={listMeta}
        loadMore={loadMoreGuidos}
        totalCount={amountInfo.amountLeft}

        loading={{
          spinning: loading,
          size: 'large',
          indicator: <Spin indicator={<GdLoadingIndicator />} />,
        }}
      />
    </ListContainer>
  );
});
