import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/macro';

import { ListMeta } from '../../../components/GdList';
import { GdStateBadge } from '../../../components/GdStateBadge';
import Text from '../../../components/Text';
import { formatBytes, showLocalDate } from '../../../utils';
import { LoginStateStatuses } from '../stores/objects/Organization';

type OrgMetaInput = {
  onOrgSelected: (org: { [key: string]: string | number | boolean }) => void;
};
export const useOrganizationsMeta = ({ onOrgSelected }: OrgMetaInput) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const listMeta: ListMeta = {
    name: {
      span: 3,
      overrideStyle: true,
      className: 'gd-list-title',
    },
    owner: {
      span: 3,
      overrideStyle: true,
      className: 'gd-list-title gd-list-title_regular',
    },
    subscription: {
      span: 2,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily={theme.fonts.medium}>
            {item.subscription}
          </Text>
        );
      },
    },
    subscriptionEndDate: {
      span: 3,
      render: (item) => {
        return (
            <Text fontSize={12} fontFamily={theme.fonts.regular}>
              {showLocalDate(item.subscriptionEndDate as string)}
            </Text>
        );
      },
    },
    users: {
      span: 1,
    },
    guidos: {
      span: 1,
    },
    state: {
      span: 2,
      render: (item) => {
        return <GdStateBadge state={item.state as LoginStateStatuses} />;
      },
    },
    lastActiveDate: {
      span: 3,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily={theme.fonts.regular}>
            {showLocalDate(item.lastActiveDate as string | number)}
          </Text>
        );
      },
    },
    storage: {
      span: 2,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily={theme.fonts.medium}>
            {formatBytes(Number(item.storage))}
          </Text>
        );
      },
    },
    menu: {
      render: (item) => {
        return (
          <Menu>
            <Menu.Item
              key={1}
              onClick={() => {
                onOrgSelected(item);
              }}
            >
              {t('admOrgs:ListMenu:showDetailsLbl')}
            </Menu.Item>
          </Menu>
        );
      },
    },
  };
  return listMeta;
};
