import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import styled from 'styled-components/macro';

import { DateRange } from '../../../components/GdDatePicker';
import { useRootStore } from '../../../context/storeContext';

const PeriodPickerContainer = styled.div`
  & .ant-picker-range {
    & .ant-picker-active-bar {
      background: ${({ theme: { colors } }) => colors.strongBlue};
    }
  }
`;

const PeriodDaysTag = styled.span`
  font-size: 12px;
  color: ${({ theme: { colors } }) => colors.strongBlue};
  padding: 4px 6px;

  background: linear-gradient(
      0deg,
      rgba(21, 1, 253, 0.12),
      rgba(21, 1, 253, 0.12)
    ),
    ${({ theme: { colors } }) => colors.white};
  border-radius: 5px;
`;

export const PeriodPicker = observer(() => {
  const { admDashboardStore } = useRootStore();

  const onDateChange = useCallback(
    (dates) => {
      if (dates === null) {
        admDashboardStore.setDefaultPeriod();
        return;
      }
      admDashboardStore.setPeriod(dates);
    },
    [admDashboardStore]
  );

  const getDaysDiff = useCallback(() => {
    const {
      period: { date },
    } = admDashboardStore;
    return date[1].diff(date[0], 'day');
  }, [admDashboardStore]);

  // @ts-ignore
  return (
    <PeriodPickerContainer>
      <PeriodDaysTag>{`Last ${getDaysDiff()} Days`}</PeriodDaysTag>
      <DateRange
        format={'DD MMM, YYYY'}
        value={admDashboardStore.period.date}
        bordered={false}
        onChange={onDateChange}
      />
    </PeriodPickerContainer>
  );
});
