import { notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { Command, CommandBar } from '../../../components/CommandBar';
import { GdModal } from '../../../components/GdModal';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { CommandsContainer } from '../../Common/styles';

const GuidoComandBarContainer = styled(CommandsContainer)``;

export const GuidoCommandBar = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();

  const { guidosStore, authorizeStore } = useRootStore();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmGuido');

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const createGuidoHandler = useCallback(() => {
    window.open('https://web.guided-doing.com', '_blank');
  }, []);

  const guidosCommands: Command[] = authorizeStore.isAuthorizedToAddNewGuido()
    ? [
        {
          title: t('guidos:Commands:addNewGuidosLbl'),
          handler: createGuidoHandler,
        },
      ]
    : [];

  if (
    Object.entries(guidosStore.selectedGuido).length &&
    authorizeStore.isAuthorizedToDeleteGuido()
  ) {
    const onDeleteGuido = () => {
      setShowModal(true);
    };
    guidosCommands.push({
      isDelete: true,
      title: t('guidos:Commands:deleteNewGuidosLbl'),
      handler: onDeleteGuido,
    });
  }

  const onCloseDeleteModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const sendAnalytic = useCallback(
    (isSuccess: boolean | undefined) => {
      const state = isSuccess ? 'Success' : 'Failed';

      sendAnalyticEvent(
        'Delete Guido',
        `${state}|Guido ${guidosStore.selectedGuido.id} deleted`
      );
    },
    [sendAnalyticEvent, guidosStore]
  );

  const onDeleteGuido = useCallback(async () => {
    setLoading(true);
    const res =
      guidosStore.selectedGuido &&
      (await guidosStore.delete(guidosStore.selectedGuido.id as string));
    if (res?.failed) {
      notification.error({
        message: t('groups:DeleteModal:deleteFailedTitle'),
        description: res.message,
        placement: 'bottomRight',
        duration: 2,
      });
    }
    sendAnalytic(res?.succeeded);
    history.push('/guidos');
    setLoading(false);
    setShowModal(false);
  }, [sendAnalytic, guidosStore, t, history]);

  return (
    <>
      <GuidoComandBarContainer>
        <PageTitle title={t('guidos:mainTitleDetails')} />
        <CommandBar commands={guidosCommands} />
      </GuidoComandBarContainer>
      <GdModal
        isVisible={showModal}
        disableButtons={loading}
        isClosable={!loading}
        onCloseModal={onCloseDeleteModal}
        onOkHandler={onDeleteGuido}
        cancelButtonText={t('guidos:DeleteModal:cancelBtnLbl')}
        okButtonText={t('guidos:DeleteModal:deleteBtnLbl')}
        title={t('guidos:DeleteModal:title')}
        renderBody={
          <Text fontSize={16} fontFamily="montserrat-regular">
            {guidosStore.selectedGuido &&
              t('guidos:DeleteModal:bodyConfirmationText', {
                guido: guidosStore.selectedGuido.tittle,
              })}
          </Text>
        }
      />
    </>
  );
});
