import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Steps } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { IconInt } from '../../../components/Icons';
import { useRootStore } from '../../../context/storeContext';
import { Content } from './components/Content';
import { steps } from './components/Steps';
import { SignUpPageContainer, SignUpWizardAntDCssReset } from './styled';

const getDoneIcon = () => {
  return <IconInt icon="CheckMark" className="signup-step-icon" />;
};

export const SignUpPageWizard = observer(() => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const wizardSteps = useMemo(() => steps(t), [t]);

  const { authStore, signUpStore } = useRootStore();
  const { isSignedUp, isSignUpFailed } = signUpStore;
  useEffect(() => {
    async function fetchMeta() {
      await authStore.getSignUpMeta();
    }

    fetchMeta();
  }, [authStore]);

  const onNext = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const onBack = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const getItemIcon = useCallback(
    (item, idx) => {
      const isLast = wizardSteps.length - 1 === idx;
      const isDone = idx < currentStep;
      if (isSignUpFailed && isLast && idx === currentStep) {
        return <ExclamationCircleOutlined className="signup-step-icon" />;
      } else if (!isSignedUp && isLast && idx === currentStep) {
        return (
          <Spin
            indicator={<GdLoadingIndicator size={18} />}
            className="signup-step-icon"
          />
        );
      } else if ((isSignedUp && isLast) || isDone) {
        return getDoneIcon();
      }
      return item.icon;
    },
    [wizardSteps, currentStep, isSignedUp, isSignUpFailed]
  );

  return (
    <SignUpPageContainer>
      <SignUpWizardAntDCssReset />
      <Row className="gd-sign-up-page-row">
        <Col span={24}>
          <Steps
            className="signup-steps-wizard-container"
            labelPlacement="vertical"
            current={currentStep}
          >
            {wizardSteps.map((s, idx) => (
              <Steps.Step
                key={idx}
                title={s.title}
                status={idx < currentStep || isSignedUp ? 'finish' : 'wait'}
                icon={getItemIcon(s, idx)}
              />
            ))}
          </Steps>
        </Col>
      </Row>
      <Row className="gd-sign-up-page-row">
        <Col span={24}>
          <Content current={currentStep} onNext={onNext} onBack={onBack} />
        </Col>
      </Row>
    </SignUpPageContainer>
  );
});
