import React from 'react';
import styled, {useTheme} from 'styled-components/macro';
import Text from '../../../components/Text';

const MainLogo = styled.div`
  position: absolute;
  top: 32px;
  left: 24px;
`;

export const TextMainLogo = () => {
  const theme = useTheme();
  return (
    <MainLogo>
      <Text fontSize={28} color={theme.colors.white}>
        gd
      </Text>
      <Text fontSize={28} color={theme.colors.white}>
        .
      </Text>
      <Text fontSize={14} color={theme.colors.white}>
        {' '}
        admin
      </Text>
    </MainLogo>
  );
};
