import styled, { useTheme } from 'styled-components/macro';
import { useRootStore } from '../context/storeContext';
import Text from './Text';

const TextContainer = styled.div``;

export const GdAdminMainLogo = () => {
  const { colors } = useTheme();
  const { authStore } = useRootStore();
  const textColor = authStore.signedInUser.isSuperAdmin
    ? colors.strongBlue
    : colors.mediumRed;
  const text = authStore.signedInUser.isSuperAdmin ? 'insight' : 'admin';
  return (
    <TextContainer>
      <Text fontSize={28}>gd</Text>
      <Text fontSize={28} color={textColor}>
        .
      </Text>
      <Text fontSize={14} color={textColor}>
        {' '}
        {text}
      </Text>
    </TextContainer>
  );
};
