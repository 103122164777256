import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/macro';
import { PageTitle } from '../../../../../components/PageTitle';
import Text from '../../../../../components/Text';
import { useRootStore } from '../../../../../context/storeContext';
import {
  validateConfirmPassword,
  validatePasswordRules,
} from '../../../../../utils';
import { RulesContainer } from '../../../components/ResetPassword';
import {
  CreateAccountButtonsContainer,
  SignUpStepContainer,
} from '../../styled';
import { ContentProps } from '../Content';

const SignUpContainer = styled(SignUpStepContainer)`
  & .signup-form-item-row:not(:last-child) {
    padding-bottom: 0;
  }

  & .gd-form-item {
    margin-bottom: 5px;
  }
`;

export const CreateAccountStep = observer(({ onNext }: ContentProps) => {
  const { t } = useTranslation();
  const { authStore, signUpStore } = useRootStore();
  const theme = useTheme();

  const [form] = Form.useForm();

  const {
    signUpMeta: { jobTitles, infoSources },
  } = authStore;

  useEffect(() => {
    const {
      signUpRequest: { owner },
    } = signUpStore;

    const fields = Object.keys(owner).map((key: string) => ({
      name: key,
      //@ts-ignore
      value: owner[key],
    }));
    form.setFields(fields);
  }, [signUpStore, form, authStore]);

  const onFinish = useCallback(
    async (values) => {
      signUpStore.setTenantOwner(values);
      onNext();
    },
    [onNext, signUpStore]
  );

  const onClickNext = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <SignUpContainer>
      <PageTitle title={t('SignInUpPage:Text:SignUp:mainAccount')} />
      <Row className="company-details-sign-up-form" gutter={26}>
        <Col span={16}>
          <Card className="gd-card">
            <Form
              name="signup-create-account"
              className="signup-creat-account-form"
              layout="vertical"
              onFinish={onFinish}
              form={form}
            >
              <Row className="signup-form-item-row" gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="gd-form-item"
                    label={<Text>{t('SignInUpPage:Labels:firstName')}</Text>}
                    name="firstName"
                  >
                    <Input className="gd-input-item" placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="gd-form-item"
                    label={<Text>{t('SignInUpPage:Labels:surname')}</Text>}
                    name="lastName"
                  >
                    <Input className="gd-input-item" placeholder="Surname" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="signup-form-item-row">
                <Col span={24}>
                  <Form.Item
                    className="gd-form-item"
                    label={<Text>{t('SignInUpPage:Labels:email')}</Text>}
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'E-mail could not be empty',
                      },
                    ]}
                  >
                    <Input className="gd-input-item" placeholder="Username" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="signup-form-item-row" gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="password"
                    label={<Text>{t('SignInUpPage:Labels:password')}</Text>}
                    className="gd-form-item gd-form-password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      validatePasswordRules(
                        t('identity:ErrorMessages:PasswordRules')
                      ),
                    ]}
                  >
                    <Input.Password
                      type="password"
                      className="gd-input-item"
                      placeholder="Password"
                      onChange={async () => {
                        await form.validateFields(['confirmPassword']);
                      }}
                    />
                  </Form.Item>
                  <RulesContainer>
                    <Text
                      fontSize={10}
                      fontFamily="montserrat-semi-bold"
                      color={theme.colors.lightGreen}
                    >
                      {t('identity:ResetPassword:passwordRules')}
                    </Text>
                  </RulesContainer>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="confirmPassword"
                    label={
                      <Text>{t('SignInUpPage:Labels:confirmPassword')}</Text>
                    }
                    className="gd-form-item"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      validateConfirmPassword,
                    ]}
                  >
                    <Input.Password
                      type="password"
                      className="gd-input-item"
                      placeholder="Confirm Password"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="signup-form-item-row">
                <Col span={24}>
                  <Form.Item
                    className="gd-form-item"
                    label={<Text>{t('SignInUpPage:Labels:accessCode')}</Text>}
                    name="accessCode"
                    rules={[
                      {
                        required: true,
                        message: 'Access code could not be empty',
                      },
                    ]}
                  >
                    <Input
                      className="gd-input-item"
                      placeholder="Access code"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="signup-form-item-row" gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label={<Text>{t('SignInUpPage:Labels:phoneNumber')}</Text>}
                    className="gd-form-item"
                  >
                    <Input className="gd-input-item" placeholder="Password" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="jobTitleId"
                    label={<Text>{t('SignInUpPage:Labels:jobTitle')}</Text>}
                    className="gd-form-item"
                  >
                    <Select
                      className="gd-input-item"
                      placeholder="Select job title"
                    >
                      {Object.keys(jobTitles).map((key) => (
                        <Select.Option key={key} value={key}>
                          {jobTitles[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="signup-form-item-row">
                <Col span={24}>
                  <Form.Item
                    name="infoSourceId"
                    label={<Text>{t('SignInUpPage:Labels:reference')}</Text>}
                    className="gd-form-item"
                  >
                    <Select
                      className="gd-input-item"
                      placeholder="How do you know us?"
                    >
                      {Object.keys(infoSources).map((r) => (
                        <Select.Option key={r} value={r}>
                          {infoSources[r]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <CreateAccountButtonsContainer>
            <Button
              type="primary"
              htmlType="submit"
              className="gd-form-button gd-form-button-primary"
              onClick={onClickNext}
            >
              {t('SignInUpPage:Buttons:Next')}
            </Button>
          </CreateAccountButtonsContainer>
        </Col>
        <Col span={8}>
          <Card className="gd-card">
            <Text>{t('SignInUpPage:Labels:detailsLbs')}</Text>
            <br />
            <Text fontFamily="montserrat-medium" fontSize={14}>
              {t('SignInUpPage:Text:SignUp:detailsAccount')}
            </Text>
          </Card>
        </Col>
      </Row>
    </SignUpContainer>
  );
});
