import { Button, Col, Row, Spin } from 'antd';
import Link from 'antd/lib/typography/Link';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/macro';

import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { PageTitle as TitleText } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { env } from '../../../env';
import { AdvTextContainer } from '../../Dashboard/DashBoardPage';

const BannerContainer = styled.div`
  width: 100%;

  button {
    margin-top: 12px;
  }

  & .gd-banner-image {
    position: relative;
  }

  & .gd-banner-text {
    width: 65%;
  }

  & .gd-banner-description {
    max-width: 60%;
    padding: 45px 0 0 45px;

    @media (max-width: 1024px), (max-width: 1180px) {
      max-width: unset;
    }

    & > span {
      display: block;
    }
  }
`;

export const TextContainer = styled(AdvTextContainer)`
  position: unset;
  margin-left: -75px;
  margin-top: 40px;

  & > span {
    font-size: 60px;
    padding-bottom: 6px;
    line-height: 1.09;
  }

  & > span:last-child::after {
    content: '!';
    color: ${({ theme: { colors } }) => colors.strongBlue};
  }
`;

const LogoBanner = styled.div`
  height: 625px;
  width: 100%;
  background-image: url(${env.PUBLIC_URL}/acedemyLogo.png);
  background-position: center center;
  background-size: cover;
`;

export const Banner = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { academyStore } = useRootStore();

  const [isAcademyPrepared, setIsAcademyPrepared] = useState(false);
  useEffect(() => {
    academyStore.prepareAcademy().then(() => {
      setIsAcademyPrepared(true);
    });
  }, []);

  return (
    <BannerContainer>
      <Row>
        <Col span={10}>
          <LogoBanner />
        </Col>
        <Col className="gd-banner-text" span={14}>
          <Row>
            <Col span={24}>
              <TextContainer>
                <TitleText
                  title={t('academy:Banner:text1')}
                  includeDot={false}
                  textSize={49}
                  bgColor={colors.thinOrange}
                />
                <TitleText
                  title={t('academy:Banner:text2')}
                  includeDot={false}
                  textSize={49}
                  bgColor={colors.thinOrange}
                />
              </TextContainer>
            </Col>
            <Col span={24} className="gd-banner-description">
              <Text fontFamily="montserrat-medium" fontSize={16}>
                {t('academy:Banner:description1')}
              </Text>
              <Text fontFamily="montserrat-medium" fontSize={16}>
                {t('academy:Banner:description2')}
              </Text>
              <Button
                type="primary"
                loading={!isAcademyPrepared}
                className="gd-form-button gd-form-button-primary"
              >
                <Link
                  href="https://einfach-besser-erklaert.learningsuite.io"
                  target="_blank"
                >
                  Open Academy
                </Link>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </BannerContainer>
  );
};
