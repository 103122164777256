export const tenantEvents = {
  TenantRegisteredEvent: {
    action: 'Organization Registered',
    sourceProp: 'tenantName',
  },
  TenantLockedEvent: {
    action: 'Tenant Locked',
    sourceProp: 'tenantName',
  },
  TenantSubscriptionResetedEvent: {
    action: 'Tenant subscription re-set',
    sourceProp: 'subscription',
  },
  TenantSubscriptionSetEvent: {
    action: 'Subscription set up',
    sourceProp: 'previousSubscriptionName',
    destProp: 'CurrentSubscriptionName',
  },
  TenantUnlockedEvent: {
    action: 'Tenant Unlocked',
    sourceProp: 'tenantName',
  },
};
