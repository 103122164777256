import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { Token } from '../domain/Identity/stores/authStore';

type PropType = {
  component: React.FC<RouteComponentProps>;
} & RouteProps;

function ProtectedRoute({ component: Component, ...restOfProps }: PropType) {
  const tokenJson = sessionStorage.getItem('token');
  let isAuthenticated = !!tokenJson;

  if (tokenJson) {
    const token: Token = JSON.parse(tokenJson);
    const tokenPayload = JSON.parse(atob(token.access_token.split('.')[1]));
    const expiredOn = new Date(tokenPayload.exp * 1000).getTime();
    const now = new Date().getTime();
    isAuthenticated = now - expiredOn < 0;
  }

  return (
    <Route
      {...restOfProps}
      exact
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
}

export default ProtectedRoute;
