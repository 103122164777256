import { Card } from 'antd';
import React,{ useEffect } from 'react';
import {
AssignedGuidosCollection,
CreatedGuidosCollection
} from '../../../../constants';
import { useRootStore } from '../../../../context/storeContext';
import { DetailsListsContainer } from '../../../Common/styles';
import { UserGroupsList } from './UserGroupsList';
import { UserGuidosList } from './UserGuidosList';

export const UserDetailsLists = () => {
  const { userListsStore } = useRootStore();
  useEffect(() => {
    return () => {
      userListsStore.clear();
    };
  }, [userListsStore]);
  return (
    <DetailsListsContainer>
      <Card className="gd-card">
        <UserGroupsList />
      </Card>
      <Card className="gd-card">
        <UserGuidosList guidosType={AssignedGuidosCollection} />
      </Card>
      <Card className="gd-card">
        <UserGuidosList guidosType={CreatedGuidosCollection} />
      </Card>
    </DetailsListsContainer>
  );
};
