import { SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useCallback, useState } from 'react';
import styled from 'styled-components/macro';

import { GdLoadingIndicator } from '../../../../components/GdLoadingIndicator';

type Props = {
  onSave: () => void;
  onReset: () => void;
};
export const SaveSettingsButtons = ({ onSave, onReset }: Props) => {
  const [isSaving, setIsSaving] = useState(false);

  const onSaveSettings = useCallback(async () => {
    setIsSaving(true);
    await onSave();
  }, [onSave]);

  const onResetSettings = useCallback(() => {
    onReset();
  }, [onReset]);
  return (
    <SaveResetButtonsContainer>
      {isSaving ? (
        <GdLoadingIndicator size={13} />
      ) : (
        <Button
          type="text"
          title="Save"
          icon={<SaveOutlined />}
          onClick={onSaveSettings}
        />
      )}
      <Button
        onClick={onResetSettings}
        type="text"
        disabled={isSaving}
        title="Reset to Defaults"
        icon={<UndoOutlined />}
      />
    </SaveResetButtonsContainer>
  );
};

const SaveResetButtonsContainer = styled.div`
  z-index: 3;
  & > span,
  & > button {
    background: none;
    &:focus,
    &:hover {
      color: ${({ theme: { colors } }) => colors.strongBlueHighlighted};
      background: none;
    }
    color: ${({ theme: { colors } }) => colors.strongBlue};
  }
`;
