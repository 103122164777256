import { action, makeObservable, observable, runInAction } from 'mobx';

import {
  BaseEntity,
  BaseListsStore,
  ModifiedElement,
  listDefault,
} from '../../../services/BaseListsStore';
import { Result, RootStore } from '../../../services/rootStore';
import {
  BaseQuery,
  ListResult,
  SortDirection,
} from '../../../types/commonTypes';
import { findKeyByValue, setItemsCache } from '../../../utils';

export type UserGuido = {
  name: string;
  creationDate: string | number;
  assignees: number;
} & BaseEntity;

export type TeamGroup = {
  role: string;
} & Omit<UserGuido, 'assignees'>;

type TeamAssignedToUserItem = {
  teamId: string;
  teamName: string;
  roleId: string;
};

type UpdateAssignedTeamGroupsRequest = {
  assignedTeams: ModifiedElement<TeamAssignedToUserItem>[];
};

type AssignedTeamsRequest = {
  onlyAssignedTeams: boolean;
  assignedTeamFirst: boolean;
};

type TeamsResponseDto = {
  roleId: string;
  teamId: string;
  teamName: string;
  creationDate: string;
};

type CreatedGuidosResponseDTO = {
  id: string;
  tittle: string;
  createdDateUtc: string;
  assignedUsersCount: string;
};

export class UserListsStore extends BaseListsStore {
  rootStore: RootStore;
  groupsListInfo = listDefault;
  guidosListInfo = {
    createdGuidos: listDefault,
    assignedGuidos: listDefault,
  };

  groupscheckAll: boolean = false;
  createdGuidoscheckAll: boolean = false;
  assignedGuidoscheckAll: boolean = false;

  groupsSearchText: string = '';

  createdGuidos: UserGuido[] = [];
  assignedGuidos: UserGuido[] = [];
  groups: TeamGroup[] = [];
  groupsCache: TeamGroup[] = [];

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
    makeObservable(this, {
      groupsListInfo: observable,
      guidosListInfo: observable,
      groupscheckAll: observable,
      groupsSearchText: observable,
      createdGuidoscheckAll: observable,
      assignedGuidoscheckAll: observable,
      createdGuidos: observable,
      assignedGuidos: observable,
      groups: observable,
      groupsCache: observable,
      createGroupItem: action,
      getUserTeams: action,
      getAvailableTeams: action,
      getGuidos: action,
      updateUserAssignedTeams: action,
      setDefaultGroupsRoleIfEmpty: action,
    });
  }

  public clear() {
    runInAction(() => {
      this.createdGuidos = [];
      this.assignedGuidos = [];
      this.groups = [];
      this.groupsCache = [];
    });
  }

  public clearGuidos(guidoType: string) {
    runInAction(() => {
      // @ts-ignore
      this[guidoType] = [];
    });
  }

  public clearGroups() {
    runInAction(() => {
      this.groups = [];
      this.groupsCache = [];
    });
  }

  public createGroupItem(
    group: TeamsResponseDto,
    idx: number,
    isOriginal: boolean
  ) {
    return {
      id: group.teamId,
      changed: false,
      checked: isOriginal,
      name: group.teamName,
      role: group.roleId,
      original: isOriginal,
      creationDate: group.creationDate,
    };
  }

  public getCreateGuidos(
    guido: CreatedGuidosResponseDTO,
    idx: number,
    isOriginal: boolean
  ): UserGuido {
    return {
      id: guido.id,
      changed: false,
      checked: isOriginal,
      name: guido.tittle,
      original: isOriginal,
      creationDate: guido.createdDateUtc,
      assignees: Number(guido.assignedUsersCount),
    };
  }

  public async getUserTeams(
    userId: string,
    payload: BaseQuery,
    loadMore: boolean = false
  ) {
    const payloadWithSearch = {
      ...payload,
      textToSearch: this.groupsSearchText,
    };
    const body: AssignedTeamsRequest = {
      ...payloadWithSearch,
      onlyAssignedTeams: true,
      assignedTeamFirst: false,
    };
    const res = await this.rootStore.ApiClient.post<
      AssignedTeamsRequest,
      Result<ListResult<TeamsResponseDto>>
    >(`/user/assignedteams/${userId}`, body);
    if (res.succeeded) {
      runInAction(() => {
        const { totalAmount, amountLeft, amount, hasNext } = res.data;
        this.groupsListInfo = { totalAmount, amountLeft, amount, hasNext };
        const groups = res.data.items.map((g, idx) =>
          this.createGroupItem(g, idx, true)
        );
        this.groups = loadMore ? [...this.groups, ...groups] : groups;
        this.setAllChecked('groups');
      });
    }
  }

  public async getGuidos(
    userId: string,
    payload: BaseQuery,
    guidosType: string,
    loadMore: boolean = false
  ) {
    const url =
      guidosType === 'createdGuidos'
        ? `/Guido/GuidosCreatedByUser/${userId}`
        : `/Guido/GuidosAssignedToUser/${userId}`;
    const res = await this.rootStore.ApiClient.post<
      BaseQuery,
      Result<ListResult<CreatedGuidosResponseDTO>>
    >(url, payload);
    if (res.succeeded) {
      runInAction(() => {
        const { totalAmount, amountLeft, amount, hasNext } = res.data;
        // @ts-ignore
        this.guidosListInfo[guidosType] = {
          totalAmount,
          amountLeft,
          amount,
          hasNext,
        };
        const guidos = res.data.items.map((g, idx) =>
          this.getCreateGuidos(g, idx, true)
        );
        // @ts-ignore
        this[guidosType] = loadMore ? [...this[guidosType], ...guidos] : guidos;
        this.setAllChecked(guidosType);
        this.sortProperty(guidosType, SortDirection.Asc, 'creationDate');
      });
    }
  }

  public async getAvailableTeams(userId: string, payload: BaseQuery) {
    const payloadWithSearch = {
      ...payload,
      textToSearch: this.groupsSearchText,
    };
    const body: AssignedTeamsRequest = {
      ...payloadWithSearch,
      onlyAssignedTeams: false,
      assignedTeamFirst: true,
    };
    const res = await this.rootStore.ApiClient.post<
      AssignedTeamsRequest,
      Result<ListResult<TeamsResponseDto>>
    >(`/user/assignedteams/${userId}`, body);
    if (res.succeeded) {
      runInAction(() => {
        const { totalAmount, amountLeft, amount, hasNext } = res.data;
        this.groupsListInfo = { totalAmount, amountLeft, amount, hasNext };
        const availableGroups = res.data.items.map((g, idx) =>
          this.createGroupItem(g, idx, !!g.roleId)
        );
        const { newValues, newCache } = setItemsCache(
          this.groupsCache,
          availableGroups,
          !!payloadWithSearch.textToSearch
        );

        this.groupsCache = newCache;
        this.groups = newValues;
        this.setAllChecked('groups');
        this.sortProperty('groups', SortDirection.Asc, 'creationDate');
      });
    }
  }

  public async updateUserAssignedTeams(userId: string) {
    const defaultRole: string = findKeyByValue(
      this.rootStore.usersStore.roles,
      'Viewer'
    ) as string;
    const body = this.getChangedTeams(defaultRole);
    const result = await this.rootStore.ApiClient.put<
      UpdateAssignedTeamGroupsRequest,
      Result<string>
    >(`/user/updateassignedteams/${userId}`, body);

    runInAction(() => {
      this.clearGroups();
      this.origValues = new Map<
        string,
        Map<string, number | string | boolean>
      >();
      this.groupsListInfo = listDefault;
    });

    return result;
  }

  private getChangedTeams(defaultRole: string) {
    const body: UpdateAssignedTeamGroupsRequest = {
      assignedTeams: this.getChangedItems<
        TeamAssignedToUserItem,
        UserGuido | TeamGroup
      >('groups', (group) => ({
        teamId: group.id,
        roleId: (group as TeamGroup).role || defaultRole,
        teamName: group.name,
      })),
    };
    return body;
  }

  public setDefaultGroupsRoleIfEmpty(defaultRole: string) {
    this.setDefaultRoleIfEmpty(defaultRole, 'groups');
  }
}
