import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { Command, CommandBar } from '../../../components/CommandBar';
import { GdModal } from '../../../components/GdModal';
import { GdTabs } from '../../../components/GdTabs';
import { PageTitle } from '../../../components/PageTitle';
import { useRootStore } from '../../../context/storeContext';
import { useToggle } from '../../../hooks/useToggle';
import {
  BasePageContainer,
  CardsDetailsContainer,
  CommandsContainer,
} from '../../Common/styles';
import { useSubscriptionsQuery } from '../hooks/useOrgSettingsMeta';
import { OrgActivity } from './ActivityLog/OrgActivity';
import { OrgManageUsers } from './Management/OrgManageUsers';
import { OrgSubscriptionSettings } from './Management/OrgSubscriptionSettings';
import { OrgMetaDataCard } from './OrgMetaDataCard';

const OrgDetailsContainer = styled(BasePageContainer)``;
export const OrgDetails = observer(() => {
  const { t } = useTranslation();
  const { orgId } = useParams<{ orgId: string }>();
  const { orgListStore } = useRootStore();
  const { selectedOrg, subscriptionOptions } = orgListStore;

  useSubscriptionsQuery();

  useEffect(() => {
    const getOrgData = async () => {
      await orgListStore.getById(orgId);
    };
    getOrgData();

    return () => {
      orgListStore.clearOrg();
    };
  }, [orgId, orgListStore]);

  const [isModalShown, toggleModal] = useToggle(false);
  const commands: Command[] = [
    {
      title: t('admOrgs:Commands:manageOrgLbl'),
      handler: toggleModal,
    },
  ];

  const resetModalPadding = useCallback(() => {
    return css`
      padding: 20px 0 0 0;
    `;
  }, []);

  const modalTitle = useMemo(() => {
    return <PageTitle isSuper title={t('admOrgs:ManageOrgModal:mainText')} />;
  }, [t]);

  const onModalClose = useCallback(() => {
    toggleModal();

    if (selectedOrg.isSettingsDirty) {
      selectedOrg.resetSettingsToDefaults!();
    }
  }, [selectedOrg, toggleModal]);

  return (
    <OrgDetailsContainer>
      <Row>
        <CommandsContainer>
          <PageTitle isSuper title={t('admOrgs:Label:mainTitle')} />
          <CommandBar commands={commands} />
        </CommandsContainer>
      </Row>
      <CardsDetailsContainer>
        <Row gutter={24}>
          <Col span={7}>
            <OrgMetaDataCard />
          </Col>
          <Col span={17}>
            <OrgActivity />
          </Col>
        </Row>
      </CardsDetailsContainer>
      <GdModal
        isVisible={isModalShown}
        isClosable
        onCancel={onModalClose}
        useCss={resetModalPadding}
        title={modalTitle}
        width={600}
      >
        <GdTabs
          items={[
            {
              title: t('admOrgs:ManageOrgModal:Tabs:settingsTabName'),
              children: (
                <OrgSubscriptionSettings subOptions={subscriptionOptions} />
              ),
            },
            {
              title: t('admOrgs:ManageOrgModal:Tabs:usersTabName'),
              children: <OrgManageUsers />,
            },
          ]}
        />
      </GdModal>
    </OrgDetailsContainer>
  );
});
