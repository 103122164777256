import {Button, Popover, Row} from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { PageTitle } from '../../components/PageTitle';
import {
    BasePageContainer,
    CommandsContainer as TitleContainer,
} from '../Common/styles';
import FilterCssReset from '../Users/styled';
import { OrganizationsFilterBar } from './components/OrganizationsFilterBar';
import { OrganizationsList } from './components/OrganizationsList';
import { OrganizationsListHeader } from './components/OrganizationsListHeader';
import {OrganizationsCommandBar} from "./components/OrganizationsCommandBar";

const AdminOrganizationsContainer = styled(BasePageContainer)`
  height: calc(100vh - 214px);
`;

export const AdminOrganizations = () => {
  const { t } = useTranslation();
  return (
    <AdminOrganizationsContainer>
      <FilterCssReset />
      <Row>
        <TitleContainer>
          <PageTitle isSuper title={t('admOrgs:Label:mainTitle')} />
        </TitleContainer>
      </Row>
      <Row>
        <OrganizationsFilterBar />
      </Row>
      <OrganizationsCommandBar></OrganizationsCommandBar>
      <Row>
        <OrganizationsListHeader />
      </Row>
      <Row>
        <OrganizationsList />
      </Row>
    </AdminOrganizationsContainer>
  );
};
