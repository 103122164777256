import { FilterMeta } from '../components/GdFilterBar';

export const useBaseSearchMeta = (): FilterMeta => {
  return {
    searchText: {
      span: 24,
      label: 'Search',
      placeholder: 'Text to search',
      isSearch: true,
    },
  };
};
