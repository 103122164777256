import { Col, Form, FormInstance, Input, Row } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

import { IconInt } from './Icons';
import Text from './Text';

export const DISTANCE_BETWEEN_FILTER_INPUTS = 0;

type FilterItemMeta = {
  label: string;
  span: number;
  isSearch?: boolean;
  render?: (
    form: FormInstance
  ) => string | React.ReactElement | React.Component<any>;
  placeholder?: string;
  className?: string;
} & InputProps;
export type FilterMeta = {
  [key: string]: FilterItemMeta;
};

type FilterMetaProps = {
  meta: FilterMeta | FilterMeta[];
  onFilter: (filter: { [key: string]: string }) => void;
  initialValues?: {
    [key: string]: string | number | boolean | undefined | null | Dayjs[];
  };
  inputsGutter?: number;
};

const GdFilterBarContainer = styled.div`
  width: 100%;

  & .gd-form-item {
    &.gd-filter-form-item {
      display: flex;
      flex-direction: column;

      & .ant-form-item-label {
        display: flex;
      }

      & .ant-select-selector {
        width: 100%;
      }
    }
  }
`;

export const GdFilterBar = ({
  meta,
  onFilter,
  initialValues = {},
  inputsGutter = 8,
}: FilterMetaProps) => {
  const [form] = Form.useForm();
  const timer = useRef();

  useEffect(() => {
    form.setFields(
      Object.keys(initialValues).map((key) => ({
        name: key,
        value: initialValues[key],
      }))
    );
  }, [form, initialValues]);

  const onFilterFinish = useCallback(
    (values) => {
      onFilter(values);
    },
    [onFilter]
  );

  const onChangeInput = useCallback(() => {
    clearTimeout(timer.current);
    // @ts-ignore
    timer.current = setTimeout(() => {
      form.submit();
    }, 200);
  }, [form]);

  const getFormBody = (metaRaw: FilterMeta) => {
    return Object.keys(metaRaw).map((key, idx) => {
      const metaItem = metaRaw[key];
      const {
        span,
        className,
        label,
        render,
        isSearch,
        placeholder,
        ...props
      } = metaItem;
      return (
        <Col
          key={`${key}`}
          span={metaItem.span}
          className={className ? className : ''}
        >
          <Form.Item
            className="gd-form-item gd-filter-form-item"
            label={<Text>{label}</Text>}
            name={key}
          >
            {render ? (
              render(form)
            ) : (
              <Input
                prefix={isSearch ? <IconInt icon="Search" /> : null}
                className="gd-input-item"
                allowClear
                placeholder={placeholder}
                {...props}
                onChange={onChangeInput}
              />
            )}
          </Form.Item>
        </Col>
      );
    });
  };

  const filters = Array.isArray(meta) ? meta : [meta];

  return (
    <GdFilterBarContainer>
      <Col span={24}>
        <Form
          form={form}
          layout="inline"
          name="normal_login"
          className="gd-search-form"
          onFinish={onFilterFinish}
        >
          {filters.map((filter, idx) => (
            <Row
              key={`${idx}-${filter.label}`}
              gutter={inputsGutter}
              className="gd-filters-bar"
            >
              {getFormBody(filter)}
            </Row>
          ))}
        </Form>
      </Col>
    </GdFilterBarContainer>
  );
};
