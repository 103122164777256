import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';

import { GdAdminMainLogo } from '../../components/GdAdminMainLogo';
import { UserControl } from '../../components/UserControl';
import { useRootStore } from '../../context/storeContext';
import { AcademyPage } from '../Academy';
import { CompanyEdit } from '../Company/CompanyEdit';
import { DashBoardPage } from '../Dashboard';
import { ChangePassword } from '../Dashboard/components/ChangePassword';
import { ManageSubscription } from '../Dashboard/components/ManageSubscription';
import { GroupsPage } from '../Groups';
import { AddGroup } from '../Groups/components/AddGroup';
import { GroupDetails } from '../Groups/components/GroupDetails';
import { GuidosPage } from '../Guidos';
import { GuidoDetails } from '../Guidos/components/GuidoDetails';
import { ResetEmail } from '../Identity/components/ResetEmail';
import { UsersPage } from '../Users';
import { AddUser } from '../Users/components/AddUser';
import { EditUser } from '../Users/components/EditUser';
import { UserDetails } from '../Users/components/UserDetails';
import { BreadCrumbs } from './components/BreadCrumbs';
import { GuidoSuperAdminRoutes } from './components/GuidoSuperAdminRoutes';
import { NavigationMenu } from './components/NavigationMenu';
import { useUserMenu } from './hooks/useUserMenu';

const Layout = styled.div`
  background: ${({ theme: { colors } }) => colors.whiteF9};
  height: 100vh;
  position: relative;
`;

const Header = styled.div`
  position: absolute;
  height: 80px;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0 4px 12px ${({ theme: { colors } }) => colors.black012};

  padding: 0 24px;

  & .gd-header-row {
    width: 100%;
    height: 100%;
  }

  & .gd-user-control,
  & .gd-logo {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  & .gd-user-control {
    justify-content: end;
  }
`;

const SideBar = styled.div`
  width: 266px;
  height: 100%;
  margin-right: 72px;
`;

const MiddleContainer = styled.div`
  width: 100%;
  top: 80px;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: row;
  position: relative;
`;
const Content = styled.div`
  width: calc(100vw - 266px);
`;

export const LandingPage = observer(() => {
  const history = useHistory();
  const rootStore = useRootStore();
  const { authStore, companyStore } = rootStore;
  const { path } = useRouteMatch();
  const menu = useUserMenu();

  const isDashboard = false;

  useEffect(() => {
    authStore.getUser();
    authStore.updateSignedUserName({});
    rootStore.setHistory(history);
  }, [authStore, rootStore, history]);

  useEffect(() => {
    async function getAccountImageAndData() {
      await Promise.all([
        await authStore.getUserMeta(authStore.signedInUser?.id as string),
        await authStore.getAccountAvatar(authStore.signedInUser?.id as string),
        await companyStore.getCompanyDetails(
          authStore.signedInUser.companyId as string
        ),
      ]);
    }
    getAccountImageAndData().then(() => {});
  }, [authStore, companyStore]);

  return (
    <Layout>
      <Header>
        <Row className="gd-header-row">
          <Col span={8} className="gd-logo">
            <Link to={`/dashboard`}>
              <GdAdminMainLogo />
            </Link>
          </Col>
          <Col span={16} className="gd-user-control">
            <UserControl
              name={authStore.signedInUser?.userName as string}
              description={authStore.signedInUser.email}
              menu={menu}
              image={authStore.accountAvatar}
            />
          </Col>
        </Row>
      </Header>
      <MiddleContainer>
        <SideBar>
          <NavigationMenu />
        </SideBar>
        <Content>
          {!isDashboard && (
            <Row>
              <BreadCrumbs />
            </Row>
          )}
          <Row>
            <Switch>
              <Route exact path={`${path}academy`}>
                <AcademyPage />
              </Route>
              <Route exact path={`${path}dashboard`}>
                <DashBoardPage />
              </Route>
              <Route exact path={`${path}changepassword`}>
                <ChangePassword />
              </Route>
              <Route exact path={`${path}changeemail/:userId`}>
                <ResetEmail />
              </Route>
              <Route exact path={`${path}users`}>
                <UsersPage />
              </Route>
              <Route exact strict path={`${path}users/adduser`}>
                <AddUser />
              </Route>
              <Route exact path={`${path}users/edituser/:userId`}>
                <EditUser />
              </Route>
              <Route exact path={`${path}users/:userId`}>
                <UserDetails />
              </Route>
              <Route exact path={`${path}guidos`}>
                <GuidosPage />
              </Route>
              <Route exact path={`${path}guidos/:guidoId`}>
                <GuidoDetails />
              </Route>
              <Route exact path={`${path}groups`}>
                <GroupsPage />
              </Route>
              <Route exact path={`${path}groups/addgroup`}>
                <AddGroup />
              </Route>
              <Route exact path={`${path}groups/:groupId`}>
                <GroupDetails />
              </Route>
              <Route exact path={`${path}company/edit`}>
                <CompanyEdit />
              </Route>
              <Route exact path={`${path}company/manage-subscription`}>
                <ManageSubscription />
              </Route>
              <Route path={`${path}admin`}>
                <GuidoSuperAdminRoutes />
              </Route>
            </Switch>
          </Row>
        </Content>
      </MiddleContainer>
    </Layout>
  );
});
