import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { GdCollapse } from '../../../../components/GdCollapse';
import { GdList, ItemMode } from '../../../../components/GdList';
import { GdListHeader } from '../../../../components/GdListHeader';
import { GdLoadingIndicator } from '../../../../components/GdLoadingIndicator';
import { IconInt } from '../../../../components/Icons';
import { useRootStore } from '../../../../context/storeContext';
import { setMaxListHeight } from '../../../../utils';
import { CollapsableListContainer } from '../../../Common/styles';
import { PanelBodyContainer } from '../../../Users/styled';
import { useActivityLogMeta } from '../../hooks/useActivityLogMeta';
import { GetEventsRequest, LogEvent } from '../../stores/logEventsStore';
import { OrgActivityLogFilter } from './OrgActivityLogFilter';

const OrgActivityLogContainer = styled(CollapsableListContainer)``;
const LogCardBody = styled(PanelBodyContainer)`
  & #normal_login {
    padding-top: 0;

    & .ant-form-item-label > label {
      font-size: 10px;
      height: 20px;
    }

    & .ant-form-item-control {
      & .gd-input-item {
        height: 30px;

        & .ant-select-selector {
          height: 30px;
        }
      }
    }
  }
`;

export const OrgActivityLog = observer(() => {
  const { t } = useTranslation();
  const { orgId } = useParams<{ orgId: string }>();
  const { orgEventStore } = useRootStore();

  const [startDate, endDate] = orgEventStore.eventsFilter.dateRange;
  const { eventsFilter } = orgEventStore;
  const { subjectType, eventType, eventAuthor, subjectId } = eventsFilter;

  const [loading, setLoading] = useState(false);
  const [panelActive, setPanelActive] = useState(false);

  const { columnsMeta, listMeta } = useActivityLogMeta();

  const loadEvents = useCallback(
    async (offset: number = 10, loadMore: boolean = false) => {
      const body: Partial<GetEventsRequest> = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        tenantId: orgId,
        itemsCountLoadPerRequest: offset,
        eventSubject: subjectType,
        eventSubjectId: subjectId,
        eventType,
        userPerformedOperationId: eventAuthor,
      };
      setLoading(true);
      await orgEventStore.loadEvents(orgId, body, loadMore);
      setLoading(false);
    },
    [
      startDate,
      endDate,
      orgId,
      subjectType,
      subjectId,
      eventType,
      eventAuthor,
      orgEventStore,
    ]
  );

  useEffect(() => {
    const getEvents = async () => {
      await loadEvents();
    };
    if (panelActive && eventsFilter) {
      getEvents();
    }
  }, [panelActive, eventsFilter, loadEvents]);

  const onPanelActive = useCallback(async (activePanels: string[]) => {
    setPanelActive(!!activePanels.length);
  }, []);

  const onLoadMore = useCallback(async () => {
    await loadEvents(orgEventStore.logEvents.length, true);
  }, [loadEvents, orgEventStore]);

  return (
    <OrgActivityLogContainer>
      <GdCollapse
        icon={<IconInt icon="LapTop" />}
        headerText={t('admOrgs:Label:logListTitle')}
        editable={false}
        onActive={onPanelActive}
        extended
      >
        <LogCardBody>
          <OrgActivityLogFilter />
          <GdListHeader columns={columnsMeta} topOffset={25} />
          <GdList
            split
            editable={false}
            loadMore={onLoadMore}
            itemMode={ItemMode.Basic}
            data={orgEventStore.logEvents}
            loading={{
              spinning: loading,
              size: 'large',
              indicator: <Spin indicator={<GdLoadingIndicator size={12} />} />,
            }}
            columnMeta={listMeta}
            totalCount={orgEventStore.totalEventsCount}
            maxHeight={setMaxListHeight<LogEvent>(
              orgEventStore.logEvents,
              32,
              6
            )}
            rowHeight={32}
            useMaxHeight
          />
        </LogCardBody>
      </GdCollapse>
    </OrgActivityLogContainer>
  );
});
