import { Card } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import styled from 'styled-components/macro';

import { useRootStore } from '../../../../context/storeContext';
import { DetailsListsContainer } from '../../../Common/styles';
import {
  FeaturesLimits,
  UsersByRoles,
} from '../../stores/objects/Organization';
import { OrgDetailsDto } from '../../stores/orgListStore';
import { OrgSubscriptionCard } from '../OrgSubscriptionCard';
import { OrgActivityLog } from './OrgActivityLog';

const LogContainer = styled.div`
  height: calc(100vh - 445px);
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const OrgActivity = () => {
  const { orgEventStore, orgListStore } = useRootStore();

  const { selectedOrg } = orgListStore;

  const { totalUsersCount, totalCompanyGuidosSize } =
    selectedOrg.dataAsObject as OrgDetailsDto & UsersByRoles;
  const { viewerLimit, planName, storageLimit } =
    selectedOrg.featuresLimits as FeaturesLimits;

  let endDate = dayjs(0);
  let hasShareCare = false;

  if (selectedOrg.rawFeatures != null) {
    endDate = selectedOrg.rawFeatures?.SubscriptionEndDate as Dayjs;
    hasShareCare = selectedOrg.rawFeatures?.ExternalSharing! as boolean;
  }

  useEffect(() => {
    return () => {
      orgEventStore.clearEvents();
      orgEventStore.clearEventsFilter();
    };
  }, [orgEventStore]);
  return (
    <DetailsListsContainer>
      <Card className="gd-card">
        <OrgSubscriptionCard
          planName={planName as string}
          totalUsers={Number(totalUsersCount)}
          limitUsers={Number(viewerLimit)}
          totalStorageSize={Number(totalCompanyGuidosSize)}
          limitStorageSize={Number(storageLimit)}
          subscriptionEndDate={endDate}
          hasShareCare={hasShareCare}
        />
      </Card>
      <LogContainer>
        <Card className="gd-card">
          <OrgActivityLog />
        </Card>
      </LogContainer>
    </DetailsListsContainer>
  );
};
