import { createContext, ReactNode, useContext } from 'react';
import { RootStore } from '../services/rootStore';
import {useHistory} from "react-router-dom";

let store: RootStore;

export const StoreContext = createContext<RootStore | undefined>(undefined);

// create the provider component
export const RootStoreProvider = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  //only create the store once ( store is a singleton)
  const root: RootStore = store ?? new RootStore(history);

  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
};

// create the hook
export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}
