import dayjs from 'dayjs';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { TransportLayer } from '../../../api/transportLayer';
import { BaseStore } from '../../../services/baseStore';
import { RootStore } from '../../../services/rootStore';
import {
  BaseQuery,
  OrderColumn,
  SortDirection,
} from '../../../types/commonTypes';

type GuidosFilter = {
  tittle: string;
  filterByTenant: string;
};

export type GuidoListItemDto = {
  id: string;
  tittle: string;
  tenantId: string;
  size: number;
  published: boolean;
  externalViews: number;
  createdDateUtc: string;
  lastUpdatedDateUtc: string;
};

export type GuidoListItem = {
  id: string;
  tittle: string;
  tenantName: string;
  size: number;
  published: boolean;
  externalViews: number;
  createdDateUtc: string;
  lastUpdatedDateUtc: string;
};

export type GuidoDetailsDto = {
  id: string;
  tittle: string;
  tenantId: string;
  size: number;
  published: boolean;
  externalViews: number;
};

type OrgMap = {
  [key: string]: string;
};

const defaultGuidoDtoObject: GuidoDetailsDto = {
  id: '',
  tittle: '',
  externalViews: 0,
  published: false,
  size: 0,
  tenantId: '',
};

type GuidoFields =
  | 'name'
  | 'tenantId'
  | 'size'
  | 'published'
  | 'externalViews'
  | 'createdDateUtc'
  | 'lastUpdatedDateUtc';

type GuidoProps =
  | 'Name'
  | 'TenantId'
  | 'Size'
  | 'Published'
  | 'ExternalViews'
  | 'CreatedDateUtc'
  | 'LastUpdatedDateUtc';

type FieldToPropMap = {
  [key in GuidoFields]: GuidoProps;
};

export class GuidoListStore extends BaseStore<GuidosFilter> {
  private rootStore: RootStore;
  transportLayer: TransportLayer;
  sortOrder: SortDirection = SortDirection.Desc;
  filter: GuidosFilter = {
    tittle: '',
    filterByTenant: '',
  };

  orderingColumns: OrderColumn = {};

  guidos: GuidoListItem[] = [];

  orgMap = new Map<string, string>();

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
    this.transportLayer = rootStore.transportLayer;

    makeObservable(this, {
      filter: observable,
      guidos: observable,
      sortOrder: observable,
      orderingColumns: observable,
      sortGuidos: action,
      getAll: action,
    });
  }

  private mapToGuidoModel(orgDto: GuidoListItemDto): GuidoListItem {
    return {
      id: orgDto.id,
      tittle: orgDto.tittle,
      externalViews: orgDto.externalViews,
      published: orgDto.published,
      size: orgDto.size * 1024,
      tenantName: this.orgMap.get(orgDto.tenantId) ?? '',
      createdDateUtc: orgDto.createdDateUtc,
      lastUpdatedDateUtc: orgDto.lastUpdatedDateUtc,
    };
  }

  async getAll(query: BaseQuery, loadMore: boolean = false) {
    if (this.orgMap.size === 0) {
      await this.getOrgMap();
    }

    query.customTenantId = this.filter.filterByTenant;

    const result = await this.transportLayer.fetchAllGuidos(query);

    if (result.succeeded) {
      runInAction(() => {
        const { items, totalAmount, hasNext, amount, amountLeft } = result.data;
        const guidos = items.map((item) => this.mapToGuidoModel(item));
        this.amountInfo = { totalAmount, hasNext, amount, amountLeft };
        this.guidos = loadMore ? this.guidos.concat([...guidos]) : guidos;
      });
    }
    return result;
  }

  clear() {
    this.guidos = [];
  }

  clearFilter() {
    this.filter = {
      ...this.filter,
      tittle: '',
      filterByTenant: '',
    };
  }

  sortGuidos(order: SortDirection, field: string) {
    this.sortOrder = order;
    const fieldsToPropMap: Partial<FieldToPropMap> = {
      name: 'Name',
      externalViews: 'ExternalViews',
      published: 'Published',
      size: 'Size',
      tenantId: 'TenantId',
    };

    const property = fieldsToPropMap[field as GuidoFields];

    this.orderingColumns = {
      [property as string]: order,
    };
  }

  async getShareLink(guidoId: string) {
    return (await this.transportLayer.fetchGuidoDetails(guidoId)).data
      .publicLink;
  }

  async getOrgMap() {
    const result = await this.transportLayer.fetchOrgs({
      offset: 0,
      count: 5000, // TODO(mreinfurt) This is a scaling issue and needs to be fixed somepoint in the future
    });

    if (result.succeeded) {
      runInAction(() => {
        result.data.items.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        for (var i = 0; i < result.data.items.length; i++) {
          var org = result.data.items[i];
          this.orgMap.set(org.id, org.name);
        }
      });
    }
  }
}
