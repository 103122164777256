import React from 'react';
import { useTheme } from 'styled-components/macro';
import Text from './Text';

type Props = {
  title: string;
  textSize?: number;
  textColor?: string;
  includeDot?: boolean;
  bgColor?: string;
  isSuper?: boolean;
};

export const PageTitle = ({
  title,
  textSize = 24,
  includeDot = true,
  bgColor,
  textColor,
  isSuper = false,
}: Props) => {
  const { colors } = useTheme();
  const fontColor = isSuper ? colors.white : textColor || colors.black;
  const backGroundColor = isSuper
    ? colors.strongBlue
    : bgColor || colors.lightRose;
  return (
    <Text fontSize={textSize} background={backGroundColor} color={fontColor}>
      {title}
      {includeDot && (
        <Text color={isSuper ? colors.thinOrange : colors.strongBlue}>.</Text>
      )}
    </Text>
  );
};
