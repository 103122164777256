import { Button, Checkbox, Form, Input } from 'antd';
import { FieldData, Meta } from 'rc-field-form/es/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import { env } from '../../../env';
import { TextMainLogo } from '../components/TextMainLogo';
import {
  DetailsContainer,
  ErrorSignInMessage,
  LogoImage,
  SignInFormContainer,
  SignInPageContainer,
} from '../styled';
import { deleteCookie, getCookie, setCookie } from '../utils';

type FormValues = {
  email: string;
  password: string;
  remember: boolean;
};

const PasswordLabel = styled.div`
  display: flex;
  height: 20px;
  width: 359px;
  justify-content: space-between;
`;

const SignUpLink = styled.div`
  & .gd-form-button-link-primary {
    width: auto;
  }

  & .gd-form-button-link-primary:focus,
  & .gd-form-button-link-primary:hover {
    color: ${({ theme: { colors } }) => colors.strongBlue};
    background: transparent;
    border-color: transparent;
  }

  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function SignInPage() {
  const { authStore } = useRootStore();
  const { t } = useTranslation();
  const history = useHistory();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmOrganization');

  const [loading, setLoading] = useState<boolean>(false);
  const [isReadyToSubmit, setReadyToSubmit] = useState<boolean>(false);
  const [unauthorized, setUnauthorized] = useState<boolean>(false);
  const [showConfirmation, showConfirmationMessage] = useState<boolean>(false);

  const [form] = Form.useForm();

  const setEmailIfRememberMe = useCallback(
    (userName) => {
      form.setFields([
        {
          name: 'email',
          value: userName,
        },
        {
          name: 'remember',
          value: true,
        },
      ]);
    },
    [form]
  );

  useEffect(() => {
    const username = getCookie('remember_me');
    if (username) {
      setEmailIfRememberMe(username);
    }
  }, [setEmailIfRememberMe]);

  const onFieldsChange = useCallback(
    async (changedFields, allFields) => {
      if (changedFields[0].name.includes('remember')) {
        return;
      }
      const hasErrors = allFields
        .filter((x: Meta) => !x.name.includes('remember'))
        .some((item: FieldData) => item.errors?.length || !item.value);
      setReadyToSubmit(!hasErrors);
      if (unauthorized || showConfirmation) {
        setUnauthorized(false);
        showConfirmationMessage(false);
      }
    },
    [unauthorized, showConfirmation]
  );

  const setRememberMeCookie = useCallback((values: FormValues) => {
    if (values.remember) {
      setCookie('remember_me', values.email);
    } else {
      deleteCookie('remember_me');
    }
  }, []);

  const onFinish = useCallback(
    async (values: FormValues) => {
      setRememberMeCookie(values);
      setLoading(true);
      const result = await authStore.signIn(values);
      setLoading(false);
      if (!result.failed) {
        authStore.getUser();
        const landingPath = authStore.signedInUser.isSuperAdmin
          ? '/admin/dashboard'
          : 'dashboard';
        history.push(landingPath);
        return;
      } else {
        sendAnalyticEvent('SignIn', `Failed ${values.email}`);
      }
      setUnauthorized(true);
      if (result.statusCode === 403) {
        showConfirmationMessage(true);
      }
    },
    [sendAnalyticEvent, authStore, history, setRememberMeCookie]
  );

  const onSignUpClick = useCallback(() => {
    history.push('/signup');
  }, [history]);

  const passwordLabel = useMemo(() => {
    return (
      <PasswordLabel>
        <Text>{t('SignInUpPage:Labels:password')}</Text>
        <Link className="gd-form-forgot" to="/forgotpassword">
          {t('SignInUpPage:Text:SignIn:forgotPassword')}
        </Link>
      </PasswordLabel>
    );
  }, [t]);

  return (
    <SignInPageContainer>
      <TextMainLogo />
      <LogoImage src={`${env.PUBLIC_URL}/loginLogo.png`} />
      <SignInFormContainer>
        <DetailsContainer>
          <PageTitle title={t('SignInUpPage:Text:SignIn:main')} textSize={32} />
          <Text fontFamily="montserrat-medium" fontSize={14}>
            {t('SignInUpPage:Text:SignIn:details')}
          </Text>
        </DetailsContainer>
        <Form
          form={form}
          layout="vertical"
          name="normal_login"
          className="login-form"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item
            className="gd-form-item"
            label={<Text>{t('SignInUpPage:Labels:email')}</Text>}
            name="email"
            rules={[
              { type: 'email', message: 'The input is not valid E-mail!' },
            ]}
          >
            <Input className="gd-input-item" placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            label={passwordLabel}
            className="gd-form-item"
          >
            <Input.Password
              type="password"
              className="gd-input-item"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="remember"
            className="gd-form-item"
            valuePropName="checked"
          >
            <Checkbox className="gd-form-rememberme">
              {t('SignInUpPage:Text:SignIn:rememberMe')}
            </Checkbox>
          </Form.Item>
          {unauthorized && (
            <ErrorSignInMessage>
              {showConfirmation
                ? t('SignInUpPage:Text:SignIn:Errors:showConfirmation')
                : t('SignInUpPage:Text:SignIn:Errors:signin')}
            </ErrorSignInMessage>
          )}
          <Button
            type="primary"
            htmlType="submit"
            className="gd-form-button gd-form-button-primary gd-form-button-full-width"
            loading={loading}
            disabled={!isReadyToSubmit}
          >
            {t('SignInUpPage:Buttons:SignInBtnLbl')}
          </Button>
        </Form>
        <SignUpLink>
          <Text fontFamily="montserrat-medium" fontSize={14}>
            {t('SignInUpPage:Text:SignIn:signUpLbl')}
          </Text>
          <Button
            type="link"
            className="gd-form-button gd-form-button-link-primary"
            onClick={onSignUpClick}
          >
            {t('SignInUpPage:Buttons:SignUp')}
          </Button>
        </SignUpLink>
      </SignInFormContainer>
    </SignInPageContainer>
  );
}

export default SignInPage;
