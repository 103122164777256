import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterMeta, GdFilterBar } from '../../../components/GdFilterBar';
import { useRootStore } from '../../../context/storeContext';

export const GroupsFilterBar = observer(() => {
  const { t } = useTranslation();
  const { teamsStore } = useRootStore();

  const filtersMeta: FilterMeta = {
    name: {
      span: 12,
      label: t('groups:Labels:groupsSearchLbl'),
      placeholder: t('groups:Labels:groupsSearchPlaceholder'),
      isSearch: true,
      style: { width: '336px' },
    },
    totalAmount: {
      span: 12,
      label: t('groups:Labels:numberOfGroups'),
      disabled: true,
      bordered: false,
      className: 'gd-filter-count-input',
    },
  };

  const onFilter = useCallback(
    (values) => {
      teamsStore.setFilter(values);
    },
    [teamsStore]
  );
  return (
    <GdFilterBar
      meta={filtersMeta}
      onFilter={onFilter}
      initialValues={teamsStore.amountInfo}
    />
  );
});
