import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { notification } from 'antd/es';
import { observer } from 'mobx-react-lite';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import {
  RoleLabel,
  getCurrentDomain,
  isDomainInValid,
  showCompanyRoleLabel,
  validateEmail,
  validateEmailDomain,
} from '../../../utils';
import { ButtonsContainer, UserContainer } from '../../Common/styles';
import CssUserPageResetStyles from '../styled';
import { UserCompanyRoleExplanation } from './UserInfo/UserCompanyRoleExplanation';

const AddUserButtons = styled(ButtonsContainer)`
  flex-direction: column;

  & > .ant-row {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  & > .ant-row:last-child {
    margin-top: 10px;
  }
`;

const AddUserContainer = styled(UserContainer)``;

const AddUserFormContainer = styled.div`
  padding-top: 14px;

  & .gd-form-item {
    & .gd-tags-input-item {
      & .ant-select-selector {
        border-radius: 4px;
        min-height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const AddUser = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { usersStore, authStore, companyStore } = useRootStore();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmUsers');

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRole, selectRole] = useState('');
  const [isGuest, setIsGuest] = useState(false);

  useEffect(() => {
    async function getAddUsersRelatedData() {
      const { companyId } = authStore.signedInUser;
      await Promise.all([
        usersStore.getUserMeta(),
        companyStore.getCompanyDetails(companyId as string),
      ]);
      const userRoleId = Object.keys(usersStore.roles).find(
        (key) => usersStore.roles[key] === 'Viewer'
      );
      form.setFields([
        {
          name: 'roleId',
          value: userRoleId,
        },
      ]);
      //@ts-ignore
      selectRole(usersStore.roles[userRoleId]);
    }

    getAddUsersRelatedData();
    return () => {
      form.resetFields();
    };
  }, [authStore.signedInUser, companyStore, form, usersStore]);

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onClickSendInvitation = useCallback(() => {
    form.submit();
  }, [form]);

  const sendAnalytic = useCallback(
    (isSuccess: boolean | undefined, addedUserId = '', values) => {
      const state = isSuccess ? 'Success' : 'Failed';

      sendAnalyticEvent(
        'Add New User',
        `${state}|New user ${addedUserId} name ${values.emails} added`
      );
    },
    [sendAnalyticEvent]
  );

  const onFinishForm = useCallback(
    async (values) => {
      setLoading(true);
      const body = { ...values, emails: [values.emails] };
      const res = await usersStore.addUser(body);
      const isFailed = (result: any) =>
        //@ts-ignore
        result.failed || result.statusCode === 400;
      const command = isFailed(res) ? 'error' : 'success';
      const message = isFailed(res)
        ? t('users:Notifications:addUserFailed')
        : t('users:Notifications:addUserSuccessTitle');
      const description = isFailed(res)
        ? res.message
        : t('users:Notifications:addUserSuccess');

      //@ts-ignore
      notification[command]({
        message,
        description,
        placement: 'bottomRight',
        duration: 2,
      });

      if (!isFailed(res)) {
        form.resetFields();
        const addedUserId = res.data.addedUserIds[0];
        history.push(`/users/${addedUserId}`);
      }
      sendAnalytic(
        res?.succeeded,
        res.data && res.data.addedUserIds[0],
        values
      );
      setLoading(false);
    },
    [usersStore, t, sendAnalytic, form, history]
  );

  const validateEmails = useCallback(
    ({ getFieldValue }) => ({
      validator(_: RuleObject, value: StoreValue) {
        const emails: string[] = [getFieldValue('emails')];
        if (!value || emails.every((email: string) => validateEmail(email))) {
          if (companyStore.companyDetails.isGuestAllowed)
            return Promise.resolve();
          const domain = getCurrentDomain(
            authStore.signedInUser?.email as string
          );
          return validateEmailDomain(
            emails,
            authStore.signedInUser?.email as string,
            t('users:Validation:domain', {
              currentDomain: domain,
            })
          );
        }
        return Promise.reject(new Error('Invalid email in the list!'));
      },
    }),
    [
      authStore.signedInUser?.email,
      companyStore.companyDetails.isGuestAllowed,
      t,
    ]
  );

  const onRoleChanged = useCallback(
    (value) => {
      //@ts-ignore
      selectRole(usersStore.roles[value]);
    },
    [usersStore]
  );

  const onEmailChanged = useCallback(
    (fields) => {
      const [field] = fields;
      if (
        !field.errors.length &&
        field.name.includes('emails') &&
        companyStore.companyDetails.isGuestAllowed
      ) {
        const isGuestDomain = isDomainInValid(
          [field.value],
          authStore.signedInUser?.email as string
        );
        setIsGuest(
          (isGuestDomain as boolean) &&
            (companyStore.companyDetails.isGuestAllowed as boolean)
        );
      }
    },
    [authStore.signedInUser?.email, companyStore.companyDetails.isGuestAllowed]
  );

  return (
    <AddUserContainer>
      <CssUserPageResetStyles />
      <PageTitle title={t('users:AddUser:mainTitle')} />
      <AddUserFormContainer>
        <Form
          form={form}
          name="users-adduser-form"
          layout="vertical"
          onFinish={onFinishForm}
          onFieldsChange={onEmailChanged}
          className="gd-users-adduser-form"
        >
          <Row>
            <Col span={12} xxl={8}>
              <Form.Item
                className="gd-form-item"
                name="emails"
                label={<Text>{t('users:AddUser:Labels:emailsLbl')}</Text>}
                rules={[validateEmails]}
              >
                <Input className="gd-input-item" placeholder="E-Mail" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} xxl={8}>
              <Form.Item
                className="gd-form-item"
                name="roleId"
                label={<Text>{t('users:AddUser:Labels:roleLbl')}</Text>}
              >
                <Select className="gd-input-item" onSelect={onRoleChanged}>
                  {Object.keys(usersStore.roles)
                    .map((key) => {
                      if (isGuest && usersStore.roles[key] === 'Admin') {
                        return null;
                      }
                      return (
                        <Select.Option
                          key={key}
                          value={key}
                          className="gd-users-select-option"
                        >
                          {showCompanyRoleLabel(
                            usersStore.roles[key] as RoleLabel
                          )}
                        </Select.Option>
                      );
                    })
                    .filter((item) => item)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AddUserFormContainer>
      <Row>
        <UserCompanyRoleExplanation role={selectedRole} />
      </Row>
      <Row>
        <Col span={12} xxl={8}>
          <AddUserButtons>
            <Row>
              <QuestionCircleOutlined />
              <Text fontFamily="montserrat-medium">
                {t('users:AddUser:Labels:helpText')}
              </Text>
            </Row>
            <Row>
              <Button
                type="text"
                className="gd-form-button gd-form-button-text"
                onClick={onCancel}
                disabled={loading}
              >
                {t('users:AddUser:Labels:cancelAddUserlbl')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="gd-form-button gd-form-button-primary"
                onClick={onClickSendInvitation}
                disabled={loading}
                loading={loading}
              >
                {t('users:AddUser:Labels:addUserOkLbl')}
              </Button>
            </Row>
          </AddUserButtons>
        </Col>
      </Row>
    </AddUserContainer>
  );
});
