import { Menu, Spin, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { GdList, ItemMode, ListMeta } from '../../../components/GdList';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { GdModal } from '../../../components/GdModal';
import Text from '../../../components/Text';
import { DEFAULT_ITEMS_COUNT } from '../../../constants';
import { useRootStore } from '../../../context/storeContext';
import { useDataQuery } from '../../../hooks/useDataQuery';
import { formatBytes } from '../../../utils';
import { ListContainer } from '../../Common/styles';
import { Team } from '../stores/teamsStore';

export const GroupsList = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();

  const sendAnalyticEvent = useAnalyticsEventTracker('UmGroup');

  const { teamsStore, authorizeStore } = useRootStore();
  const {
    teams,
    amountInfo: { amountLeft },
    filter: { name },
  } = teamsStore;

  const [showModal, setShowModal] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const [selectedTeam, setSelectedTeam] = useState<Team>();

  const fetchTeams = useCallback(
    async (loadMore = false) => {
      const body = {
        offset: loadMore ? teamsStore.teams.length : 0,
        count: DEFAULT_ITEMS_COUNT,
        textToSearch: name,
      };
      return await teamsStore.getAll(body, loadMore);
    },
    [name, teamsStore]
  );

  const showMsgIfFailedFunc = useCallback(
    () => 'Fetching teams failed check your connection or contact admin',
    []
  );
  const showDescIfFailedFunc = useCallback(() => 'Fetch Failed', []);
  const clearFunc = useCallback(
    () => teamsStore.clearSelectedGroup(),
    [teamsStore]
  );

  const { loading, loadMore: loadMoreTeams } = useDataQuery({
    dataFunc: fetchTeams,
    showDescIfFailedFunc,
    showMsgIfFailedFunc,
    clearFunc,
  });

  const onDeleteMenuClicked = useCallback((team) => {
    return () => {
      setShowModal(true);
      setSelectedTeam(team);
    };
  }, []);

  const onGroupDetailsMenuClicked = useCallback(
    (team) => {
      return () => {
        history.push(`/groups/${team.id}`);
      };
    },
    [history]
  );

  let listMeta: ListMeta = {
    name: {
      span: 5,
      overrideStyle: true,
      className: 'gd-list-title',
    },
    usersAmount: {
      span: 5,
    },
    assignedGuidosCount: {
      span: 5,
    },
    totalGuidosSize: {
      span: 7,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {formatBytes(Number(item.totalGuidosSize))}
          </Text>
        );
      },
    },
  };

  if (authorizeStore.isAuthorizedToEditGroupsList()) {
    listMeta = {
      ...listMeta,
      menu: {
        render: (item) => {
          return (
            <Menu key={`menu-${item.id}`}>
              <Menu.Item
                key={`menu-item-1-${item.id}`}
                onClick={onGroupDetailsMenuClicked(item)}
              >
                {t('groups:Labels:showDetails')}
              </Menu.Item>
              <Menu.Item
                key={`menu-item-2-${item.id}`}
                onClick={onDeleteMenuClicked(item)}
              >
                {t('groups:Labels:deleteGroup')}
              </Menu.Item>
            </Menu>
          );
        },
      },
    };
  }

  const DeleteModalBody = () => {
    return (
      <Text fontSize={16} fontFamily="montserrat-regular">
        {selectedTeam &&
          t('groups:DeleteModal:bodyConfirmationText', {
            group: selectedTeam.name,
          })}
      </Text>
    );
  };

  const onCloseDeleteModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const sendAnalytic = useCallback(
    (isSuccess: boolean | undefined) => {
      const state = isSuccess ? 'Success' : 'Failed';

      sendAnalyticEvent(
        'Delete Group Command Bar',
        `${state}|Group ${selectedTeam?.id} ${selectedTeam?.name} deleted`
      );
    },
    [sendAnalyticEvent, selectedTeam]
  );

  const onDeleteTeam = useCallback(async () => {
    setDeleteInProgress(true);
    const res = selectedTeam && (await teamsStore.delete(selectedTeam.id));
    if (res?.failed) {
      notification.error({
        message: t('groups:DeleteModal:deleteFailedTitle'),
        description: res.message,
        placement: 'bottomRight',
        duration: 2,
      });
    }
    sendAnalytic(res?.succeeded);
    setDeleteInProgress(false);
    setShowModal(false);
  }, [sendAnalytic, teamsStore, t, selectedTeam]);

  const onGroupSelected = useCallback(
    (group) => {
      teamsStore.setSelectedGroup(group.id);
      history.push(`/groups/${group.id}`);
    },
    [teamsStore, history]
  );

  return (
    <ListContainer>
      <GdList
        split={false}
        itemMode={ItemMode.Card}
        data={teams}
        columnMeta={listMeta}
        totalCount={amountLeft}
        loadMore={loadMoreTeams}
        fixScrollStartCellNumber={1}
        onItemClick={onGroupSelected}
        loading={{
          spinning: loading,
          size: 'large',
          indicator: <Spin indicator={<GdLoadingIndicator />} />,
        }}
      />
      <GdModal
        isVisible={showModal}
        disableButtons={deleteInProgress}
        isClosable={!deleteInProgress}
        onCloseModal={onCloseDeleteModal}
        onOkHandler={onDeleteTeam}
        cancelButtonText={t('groups:DeleteModal:cancelBtnLbl')}
        okButtonText={t('groups:DeleteModal:deleteBtnLbl')}
        title={t('groups:DeleteModal:title')}
        renderBody={<DeleteModalBody />}
      />
    </ListContainer>
  );
});
