import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components/macro';

import { IconInt } from './Icons';
import Text from './Text';

const GdEditableInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > input {
    font-size: 21px;
    font-family: ${({ theme: { fonts } }) => fonts.bold};
    padding: 0;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.strongBlue};
  }

  & > input:focus,
  & > input:hover {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.strongBlue};
  }

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const EditContainer = styled.div`
  cursor: pointer;

  & > span > svg {
    color: ${({ theme: { colors } }) => colors.black};
    opacity: 0.25;
  }
`;

type Props = {
  isNameEdited: boolean;
  editable?: boolean;
  value: string;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEndNameEdit: () => void;
  setEditMode: () => void;
};

export const GdEditableInput = ({
  value,
  isNameEdited,
  onChangeName,
  onEndNameEdit,
  setEditMode,
  editable = true,
}: Props) => {
  return (
    <GdEditableInputContainer>
      {!isNameEdited ? (
        <Text fontSize={21}>{value}</Text>
      ) : (
        <Input
          bordered={false}
          value={value}
          onChange={onChangeName}
          onPressEnter={onEndNameEdit}
        />
      )}
      {editable && (
        <EditContainer onClick={setEditMode}>
          {isNameEdited ? (
            <IconInt icon="CheckMark" />
          ) : (
            <IconInt icon="EditPen" />
          )}
        </EditContainer>
      )}
    </GdEditableInputContainer>
  );
};
