import { Divider, Menu } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useRootStore } from '../../../context/storeContext';
import { useSessionStore } from '../../../hooks/useSessionStore';
import { clearDivider } from '../../../theme/CssResetStyles';

const MenuContainer = styled.div`
  & .gd-meta-divider {
    ${clearDivider('7px 0 -7px 0')}
  }
`;

export const useUserMenu = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { authorizeStore, authStore, usersStore } = useRootStore();
  const sessionStore = useSessionStore();

  const onLogout = useCallback(() => {
    usersStore.clearUser();
    sessionStore.deleteItem('token');
    history.push('/');
  }, [history, sessionStore, usersStore]);
  return (
    <Menu>
      <Menu.Item key="user-profile">
        <Link to={`/users/edituser/${authStore.signedInUser.id}`}>
          {t('landingPage:Labels:ProfileMenu:profileSettingsLbl')}
        </Link>
      </Menu.Item>
      <Menu.Item key="change-password">
        <Link to="/changepassword">
          {t('landingPage:Labels:ProfileMenu:changePasswordLbl')}
        </Link>
      </Menu.Item>
      <Menu.Item key="change-email">
        <Link to={`/changeemail/${authStore.signedInUser.id}`}>
          {t('landingPage:Labels:ProfileMenu:changeEmail')}
        </Link>
      </Menu.Item>
      <Menu.Item key="sign-out-btn" onClick={onLogout}>
        {t('landingPage:Labels:ProfileMenu:logoutLbl')}
        <MenuContainer>
          <Divider className="gd-meta-divider" />
        </MenuContainer>
      </Menu.Item>
      {authorizeStore.isAuthorizedToEditCompanyProfile() && (
        <>
          <Menu.Item key="manage-subscription">
            <Link to={`/company/edit`}>
              {t('landingPage:Labels:ProfileMenu:orgSettingsMenuLbl')}
            </Link>
          </Menu.Item>
          <Menu.Item key="company-edit">
            <Link to={`/company/manage-subscription`}>
              {t('landingPage:Labels:ProfileMenu:manageSubscription')}
            </Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};
