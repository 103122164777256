import { EventMapType } from './orgTypes';

export const groupEvents: EventMapType = {
  GroupCreatedEvent: {
    action: 'Create Group',
    sourceProp: 'groupTitle',
  },
  GroupNameUpdatedEvent: {
    action: 'Group Name Updated ',
    sourceProp: 'oldGroupTitle',
    destProp: 'groupTitle',
  },
  GroupDeletedEvent: {
    action: 'Delete Group',
    sourceProp: 'groupName',
  },
};
