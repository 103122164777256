import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatBytes } from '../../../utils';

export type OrgMetrics =
  | 'totalTenantsCount'
  | 'newTenantsCount'
  | 'totalUsersCount'
  | 'totalGuidosSize';

type TranslationsObject = {
  [key in OrgMetrics]: string;
};

type MetricObject = {
  [key in OrgMetrics]: (value: any) => any;
};

export const useOrgMetricsMapping = () => {
  const { t } = useTranslation();
  const translations: TranslationsObject = useMemo(
    () => ({
      totalTenantsCount: t('admDashboard:Label:Numbers:orgsNumbLbl'),
      newTenantsCount: t('admDashboard:Label:Numbers:newOrgsNumbLbl'),
      totalUsersCount: t('admDashboard:Label:Numbers:throughUsersLbl'),
      totalGuidosSize: t('admDashboard:Label:Numbers:throughStorageLbl'),
    }),
    [t]
  );

  const formats: MetricObject = useMemo(
    () => ({
      totalTenantsCount: (value) => value,
      newTenantsCount: (value) => value,
      totalUsersCount: (value) => value,
      totalGuidosSize: (value) => formatBytes(value * 1024),
    }),
    []
  );

  const formatMetric = useCallback(
    (key: OrgMetrics, value) => {
      return formats[key](value);
    },
    [formats]
  );

  const getMetricTranslation = useCallback(
    (key: OrgMetrics) => {
      return translations[key];
    },
    [translations]
  );

  return { getMetricTranslation, formatMetric };
};
