declare global {
  interface Window {
    env: any;
  }
}
type EnvType = {
  REACT_APP_GOOGLE_TRACK_ID: string;
  REACT_APP_TEST: string;
  PUBLIC_URL: string;
};
export const env: EnvType = { ...process.env, ...window.env };
