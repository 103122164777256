import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { GdCollapse } from '../../../components/GdCollapse';
import { GdList, ItemMode, ListMeta } from '../../../components/GdList';
import { ColumnsMeta, GdListHeader } from '../../../components/GdListHeader';
import { GdLoadingIndicator } from '../../../components/GdLoadingIndicator';
import { GdRoleSelector } from '../../../components/GdRoleSelector';
import { IconInt } from '../../../components/Icons';
import Text from '../../../components/Text';
import { EMPTY_STRING, UsersCollection } from '../../../constants';
import { useRootStore } from '../../../context/storeContext';
import { useDataQueryById } from '../../../hooks/useDataQuery';
import { GuidoUser } from '../../../services/baseUsersStore';
import { SortDirection } from '../../../types/commonTypes';
import { getResultAndShowNotification, setMaxListHeight } from '../../../utils';
import { CollapsableListContainer } from '../../Common/styles';
import { UsersStore } from '../../Users/stores/usersStore';
import { PanelBodyContainer } from '../../Users/styled';

const GroupUserListContainer = styled(CollapsableListContainer)``;

/// TODO: move columns and list meta to meta hooks such as in users and activity log domain
export const ListColumnMeta = (
  usersStore: UsersStore,
  editMode: boolean,
  onRoleChanged: (x: any, y: any) => void
): ListMeta => {
  return {
    name: {
      span: 8,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {item.name}
          </Text>
        );
      },
    },
    email: {
      span: 8,
      render: (item) => {
        return (
          <Text fontSize={12} fontFamily="montserrat-regular">
            {item.email}
          </Text>
        );
      },
    },
    role: {
      span: 7,
      className: 'gd-input-item-role',
      render: (item) => {
        const defaultRoleId =
          Object.keys(usersStore.roles).find(
            (key) => usersStore.roles[key] === 'Viewer'
          ) || '';

        return (
          <GdRoleSelector
            item={item}
            defaultRole={defaultRoleId}
            roles={usersStore.roles}
            onChange={(value) => onRoleChanged(item, value)}
            editMode={editMode}
          />
        );
      },
    },
  };
};

export const GroupUserList = observer(() => {
  const { t } = useTranslation();
  const { groupId } = useParams<{ groupId: string }>();
  const { teamsStore, teamListsStore, usersStore, authorizeStore } =
    useRootStore();
  const { numberOfUsers } = teamsStore.selectedGroup;

  const [editMode, setEditMode] = useState(false);
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    async function getRoleOptions() {
      await usersStore.getRolesOptions();
    }

    getRoleOptions().then();
  }, [usersStore]);

  const sortingHandler = useCallback(
    (order, field) => {
      teamListsStore.sortProperty(UsersCollection, order, field);
    },
    [teamListsStore]
  );

  const loadAvailable = useCallback(
    async (groupId, body) => {
      await teamListsStore.getAvailableTeamUsers(groupId, body);
    },
    [teamListsStore]
  );

  const loadOwned = useCallback(
    async (groupId, body, loadMore) => {
      await teamListsStore.getTeamUsers(groupId, body, loadMore);
    },
    [teamListsStore]
  );

  const { loading, loadAll, loadMore, loadAssigned } = useDataQueryById({
    id: groupId,
    loadAvailable,
    loadOwned,
  });

  const columns: ColumnsMeta = useMemo(() => {
    return {
      name: {
        colspan: 8,
        title: t('groups:Labels:Lists:nameLbl'),
      },
      email: {
        colspan: 8,
        title: t('groups:Labels:Lists:emailLbl'),
        sortingHandler,
        direction: SortDirection.Asc,
      },
      role: {
        colspan: 7,
        title: t('groups:Labels:Lists:roleLbl'),
      },
    };
  }, [t, sortingHandler]);

  const onRoleChanged = useCallback(
    (item, value) => {
      const user = teamListsStore.users.find((g) => g.id === item.id);
      if (user) {
        teamListsStore.updateProperty(user, 'role', value, UsersCollection);
      }
    },
    [teamListsStore]
  );

  const onEdit = useCallback(async () => {
    const newMode = !editMode;
    setEditMode(newMode);
    if (newMode) {
      teamListsStore.clear();
      await loadAll(EMPTY_STRING);
      teamListsStore.setAllChecked(UsersCollection);
    } else {
      teamListsStore.clearEditState(UsersCollection);
    }
  }, [loadAll, editMode, teamListsStore]);

  const onSearch = useCallback(
    async (searchText) => {
      teamListsStore.setFilter(UsersCollection, searchText);
      await loadAll(searchText);
    },
    [loadAll, teamListsStore]
  );

  const onEditCancelHandler = useCallback(() => {
    setEditMode(false);
    teamListsStore.clearEditState(UsersCollection);
    teamListsStore.setAllChecked(UsersCollection);
  }, [teamListsStore]);

  const getNewUsersList = useCallback(async () => {
    setEditMode(false);
    await loadAssigned();
    const numberOfUsers = teamListsStore.users.length;
    teamsStore.updateTeamData({ numberOfUsers });
  }, [teamsStore, teamListsStore, loadAssigned]);

  const onEditSaveHandler = useCallback(async () => {
    setSaving(true);
    teamListsStore.setFilter(UsersCollection, EMPTY_STRING);
    await getResultAndShowNotification(
      async () => await teamListsStore.updateTeamAssignedUsers(groupId),
      t('users:Notifications:assignGroupsSuccess'),
      t('users:Notifications:assignGroupsFailed'),
      () => {}
    );
    setSaving(false);

    await getNewUsersList();
  }, [getNewUsersList, t, groupId, teamListsStore]);

  const onPanelActive = useCallback(
    async (activePanels: string[]) => {
      teamListsStore.clear();
      if (activePanels.length) {
        await loadAssigned();
      } else if (editMode) {
        onEditCancelHandler();
      }
    },
    [teamListsStore, loadAssigned, onEditCancelHandler, editMode]
  );

  const onGroupChecked = useCallback(
    (user, checked) => {
      teamListsStore.setChecked(UsersCollection, user.id, checked);
      teamListsStore.setAllChecked(UsersCollection);
    },
    [teamListsStore]
  );

  const onCheckAll = useCallback(
    (checked) => {
      teamListsStore.setChecked(UsersCollection, 0, checked);
    },
    [teamListsStore]
  );

  const { usersListInfo, users, userscheckAll } = teamListsStore;

  const isCollectionChanged =
    teamListsStore.isCollectionChanged(UsersCollection);

  const onLoadMore = useCallback(async () => {
    await loadMore(EMPTY_STRING, editMode, users.length);
  }, [loadMore, editMode, users]);

  return (
    <GroupUserListContainer>
      <GdCollapse
        icon={<IconInt icon="AddUser" />}
        headerText={t('groups:Labels:Lists:userListLbl', {
          number: numberOfUsers,
        })}
        editable={authorizeStore.isAuthorizedToEditGuidosList()}
        showEditPenAlways={authorizeStore.isAuthorizedToEditGuidosList()}
        extended
        onEdit={onEdit}
        isInEditeMode={editMode}
        onActive={onPanelActive}
        onSearch={onSearch}
        editControls={{
          isSaveDisabled: !isCollectionChanged || isSaving,
          editCancelText: 'Cancel',
          editSaveText: 'Save Changes',
          onEditCancel: onEditCancelHandler,
          onEditSave: onEditSaveHandler,
        }}
      >
        <PanelBodyContainer>
          <GdListHeader
            onCheckBoxTriggered={onCheckAll}
            checkAll={userscheckAll}
            edit={editMode}
            columns={columns}
            topOffset={25}
          />
          <GdList
            editable={editMode}
            loadMore={onLoadMore}
            onItemCheck={onGroupChecked}
            split
            itemMode={ItemMode.Basic}
            data={users}
            loading={{
              spinning: loading || isSaving,
              size: 'large',
              indicator: <Spin indicator={<GdLoadingIndicator size={12} />} />,
            }}
            columnMeta={ListColumnMeta(usersStore, editMode, onRoleChanged)}
            totalCount={usersListInfo.amountLeft}
            maxHeight={setMaxListHeight<GuidoUser>(users, 40)}
            rowHeight={40}
            useMaxHeight
          />
        </PanelBodyContainer>
      </GdCollapse>
    </GroupUserListContainer>
  );
});
