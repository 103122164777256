import { useTheme } from 'styled-components/macro';

import Text from './Text';

export const GdMetaLabel = ({ label }: { label: string }) => {
  return (
    <Text fontSize={15} opacity={0.35} fontFamily="montserrat-semi-bold">
      {label}
    </Text>
  );
};

type PostFixProps = {
  fSize?: number;
  fFamily?: string;
  value: string | number;
};

export const PostfixComp = ({
  fSize = 11,
  fFamily = '',
  value,
}: PostFixProps) => {
  const theme = useTheme();
  return (
    <>
      <Text
        fontSize={fSize}
        fontFamily={fFamily || theme.fonts.medium}
        color={theme.colors.black048}
      >
        {' / '}
      </Text>
      <Text
        fontSize={fSize}
        fontFamily={fFamily || theme.fonts.medium}
        color={theme.colors.black048}
      >
        {value}
      </Text>
    </>
  );
};

export const GdMetaValue = ({
  value,
  postFix,
}: {
  value: string | undefined | number;
  postFix?: string;
}) => {
  if (typeof value === 'number') {
    return (
      <>
        <Text fontSize={15} fontFamily="montserrat-medium">
          {value}
        </Text>
        {postFix && <PostfixComp value={postFix} />}
      </>
    );
  }
  return (
    <>
      <Text fontSize={15} fontFamily="montserrat-medium">
        {value || 'Loading...'}
      </Text>
      {postFix && <PostfixComp value={postFix} />}
    </>
  );
};
