import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  size?: number;
};

export const GdLoadingIndicator = ({ size = 24 }: Props) => (
  <LoadingOutlined
    style={{
      fontSize: size,
    }}
    spin
  />
);
