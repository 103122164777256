import styled from 'styled-components/macro';

export const SignInPageContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.whiteF9};
`;

export const LogoImage = styled.img`
  height: 100%;
  display: none;

  @media only screen and (min-width: 768px) {
     {
      display: block;
    }
  }
`;

export const SignInFormContainer = styled.div`
  height: 100%;
  width: 359px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
     {
      margin-left: 168px;
    }
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  & > span:nth-child(1) {
    margin-bottom: 18px;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const ErrorSignInMessage = styled.div`
  height: 68px;
  width: 100%;
  padding: 12px 16px;
  background: linear-gradient(
      0deg,
      ${({ theme: { colors } }) => colors.thinOrange},
      ${({ theme: { colors } }) => colors.thinOrange}
    ),
    ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.thinOrange};
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const ExplanationTextContainer = styled.div`
  width: 96%;
`;
