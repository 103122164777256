import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  notification,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import Avatar from 'antd/es/avatar/avatar';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useAnalyticsEventTracker } from '../../../analytics/googleAnalytics';
import { PageTitle } from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useRootStore } from '../../../context/storeContext';
import {
  RoleLabel,
  buildImageUrlFromBase64,
  getResultAndShowNotification,
  showCompanyRoleLabel,
} from '../../../utils';
import {
  ButtonsContainer as MainButtons,
  UserContainer,
} from '../../Common/styles';
import { UserCompanyRoleExplanation } from './UserInfo/UserCompanyRoleExplanation';

const EditUserContainer = styled(UserContainer)`
  & .ant-btn-link {
    color: ${({ theme: { colors } }) => colors.strongBlue};
    font-weight: bold;
    font-size: 15px;
  }

  & .gd-upload-image {
    display: flex;
    align-items: start;
    flex-direction: column;
    padding-top: 33px;
  }

  & .gd-edit-form-container {
    padding-top: 33px;
  }

  & .gd-edit-user-form {
    width: 100%;
  }
`;

const ButtonsContainer = styled.div<{ isDelete: boolean }>`
  display: flex;
  padding-top: 32px;
  align-items: center;
  justify-content: ${({ isDelete }) => (isDelete ? 'space-between' : 'end')};
  width: 100%;

  ${MainButtons} {
    margin-top: 0;
  }
`;

export const ImageInput = styled.div`
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: row;
  padding-top: 12px;
`;

export type AvatarImage = {
  src: string;
  file: File | null;
};

export const EditUser = observer(() => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();
  const sendAnalyticEvent = useAnalyticsEventTracker('UmUsers');
  const { usersStore, authStore } = useRootStore();
  const { selectedUser } = usersStore;

  const [image, setImage] = useState<AvatarImage>({ src: '', file: null });
  const [loading, setLoading] = useState(false);
  const [selectedRole, selectRole] = useState('');

  const onImageChanged = useCallback(
    async (file) => {
      const src = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
      });

      setImage({ src, file });
    },
    [setImage]
  );

  const [form] = Form.useForm();

  useEffect(() => {
    async function getUserDetails() {
      await Promise.all([
        usersStore.getUserData(userId),
        usersStore.getUserMeta(),
      ]);
    }

    getUserDetails();

    return () => {
      usersStore.clearUser();
    };
  }, [userId, usersStore, form]);

  useEffect(() => {
    form.setFields([
      { name: 'firstName', value: selectedUser.firstName },
      { name: 'lastName', value: selectedUser.lastName },
      { name: 'roleId', value: selectedUser.companyRoleId },
    ]);

    //@ts-ignore
    selectRole(usersStore.roles[selectedUser.companyRoleId]);
    if (selectedUser.image) {
      setImage({
        src: buildImageUrlFromBase64(selectedUser.image),
        file: null,
      });
    }
  }, [usersStore, form, selectedUser]);

  const updateIfSignedUser = useCallback(
    (firstName, lastName) => {
      if (userId === authStore.signedInUser.id) {
        authStore.updateSignedUserName({ firstName, lastName });
      }
    },
    [userId, authStore]
  );

  const sendAnalytic = useCallback(
    (isSuccess: boolean | undefined, values) => {
      const state = isSuccess ? 'Success' : 'Failed';

      sendAnalyticEvent(
        `User Update`,
        `${state}|User ${values.email} id ${userId} updated`
      );
    },
    [userId, sendAnalyticEvent]
  );

  const onFinish = useCallback(
    async (values) => {
      setLoading(true);
      const res = await getResultAndShowNotification(
        async () => await usersStore.editUser(values, userId, image.file),
        t('users:Notifications:editUserSucceeded'),
        t('users:Notifications:editUserFailed'),
        () => {}
      );
      if (res.succeeded) {
        updateIfSignedUser(values.firstName, values.lastName);
      }
      sendAnalytic(res.succeeded, values);
      setLoading(false);
    },
    [sendAnalytic, t, usersStore, userId, image, updateIfSignedUser]
  );

  const onCancel = useCallback(() => {
    history.push('/users');
  }, [history]);

  const onClickSave = useCallback(() => {
    form.submit();
  }, [form]);

  const onDelete = useCallback(async () => {
    setLoading(true);
    const res = selectedUser && (await usersStore.deleteUser(userId));
    setLoading(false);
    if (res?.failed) {
      notification.error({
        message: t('users:Notifications:deleteUserFailed'),
        description: res.message,
        placement: 'bottomRight',
        duration: 2,
      });
      return;
    }

    history.push('/users');
  }, [history, selectedUser, userId, t, usersStore]);

  const mainTitle =
    userId === authStore.signedInUser.id
      ? t('users:EditUser:mainTitleSettings')
      : t('users:EditUser:mainTitle');

  const onRoleChanged = useCallback(
    (value) => {
      //@ts-ignore
      selectRole(usersStore.roles[value]);
    },
    [usersStore]
  );

  return (
    <EditUserContainer>
      <Col xs={12} sm={20} md={16} lg={14} xl={14} xxl={12}>
        <Row>
          <PageTitle title={mainTitle} />
        </Row>
        <Row className="gd-upload-image">
          <Text fontSize={14}>Profile Image</Text>
          <ImageInput>
            <Avatar size={64} src={image.src} />
            <ImgCrop rotate onModalOk={onImageChanged}>
              <Upload fileList={[]} customRequest={() => Promise.resolve('')}>
                <Button type="link">Upload New Image</Button>
              </Upload>
            </ImgCrop>
          </ImageInput>
        </Row>
        <Row className="gd-edit-form-container">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="gd-edit-user-form"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  className="gd-form-item"
                  label={<Text>{t('users:EditUser:Form:firstNameLbl')}</Text>}
                  name="firstName"
                >
                  <Input className="gd-input-item" placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="gd-form-item"
                  label={<Text>{t('users:EditUser:Form:lastNameLbl')}</Text>}
                  name="lastName"
                >
                  <Input className="gd-input-item" placeholder="Surname" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  className="gd-form-item"
                  name="roleId"
                  label={<Text>{t('users:AddUser:Labels:roleLbl')}</Text>}
                >
                  <Select
                    className="gd-input-item"
                    disabled={authStore.signedInUser.id === userId}
                    onSelect={onRoleChanged}
                    options={Object.keys(usersStore.roles).map((key) => ({
                      label: showCompanyRoleLabel(
                        usersStore.roles[key] as RoleLabel
                      ),
                      value: key,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row>
          <UserCompanyRoleExplanation role={selectedRole} />
        </Row>
        <Row>
          <Col span={24}>
            <ButtonsContainer isDelete={userId !== authStore.signedInUser.id}>
              {userId !== authStore.signedInUser.id && (
                <Button
                  type="primary"
                  className="gd-form-button gd-form-button-primary"
                  onClick={onDelete}
                  disabled={loading}
                >
                  {t('users:EditUser:Form:deleteBtnLbl')}
                </Button>
              )}
              <MainButtons>
                <Button
                  type="text"
                  className="gd-form-button gd-form-button-text"
                  onClick={onCancel}
                  disabled={loading}
                >
                  {t('users:EditUser:Form:cancelBtnLbl')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="gd-form-button gd-form-button-primary"
                  onClick={onClickSave}
                  disabled={loading}
                  loading={loading}
                >
                  {t('users:EditUser:Form:saveBtnLbl')}
                </Button>
              </MainButtons>
            </ButtonsContainer>
          </Col>
        </Row>
      </Col>
    </EditUserContainer>
  );
});
